import firebase from 'firebase.js';
import { firebaseError } from 'utils';
import { Collections } from 'utils/enums';
import { collection } from 'utils/firebase';

export const createQuestionTags = async ({ questionTags, locale }) => {
  const tagsRollbackBatch = firebase.firestore().batch();

  try {
    const createTagsBatch = firebase.firestore().batch();

    questionTags.forEach((data) => {
      const questionTagRef = collection(Collections.QUESTION_TAG).doc();

      createTagsBatch.set(questionTagRef, data);
      tagsRollbackBatch.delete(questionTagRef);
    });

    await createTagsBatch.commit();
  } catch (error) {
    console.error(error);

    const errorMessage = firebaseError(error.code, locale);

    throw new Error(errorMessage);
  }

  const rollback = async () => {
    await tagsRollbackBatch.commit();
  };

  return rollback;
};
