import { useState, useCallback } from 'react';

const useModal = () => {
  const [modal, setModal] = useState({
    type: null,
  });

  const onOpenModalHandler = useCallback(
    (modalType, props) =>
      setModal({
        type: modalType,
        ...props,
      }),
    []
  );

  const onCloseModalHandler = useCallback(
    () =>
      setModal({
        type: null,
      }),
    []
  );

  return {
    modal,
    onOpenModalHandler,
    onCloseModalHandler,
  };
};

export default useModal;
