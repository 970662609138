import { Collections } from 'utils/enums';
import { collection } from 'utils/firebase';

export const fetchSectionSaveService = async ({ sectionIdentifier }) => {
  try {
    const sectionSave = await collection(Collections.SECTIONS_SAVES).doc(sectionIdentifier).get();

    return sectionSave.data();
  } catch (error) {
    console.error(error);

    throw error;
  }
};
