/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Select from 'react-select';

import paths from 'pages/Router/paths';
import { groupsCleanUp } from 'state/actions/groups';
import { useChangeHandler, useSelectChangeHandler } from 'hooks';
import { numberHandler } from 'utils';
import {
  fetchOrganizations,
  fetchUsers,
  fetchWavesOptions,
} from 'utils/fetching';
import './GroupForm.scss';

const GroupForm = ({ action, groupData, isEditing }) => {
  const { loading, defaultOrganization } = useSelector(
    (state) => ({
      loading: state.groups.loading,
      defaultOrganization: state.preferences.defaultOrganization,
    }),
    shallowEqual
  );

  const [organizationOptions, setOrganizationOptions] = useState({
    data: [],
    loading: false,
    fetched: false,
  });
  const [managerOptions, setManagerOptions] = useState({
    data: [],
    loading: false,
    fetched: false,
  });
  const [waveOptions, setWaveOptions] = useState({
    data: [],
    loading: false,
    fetched: false,
  });

  const dispatch = useDispatch();

  const [group, setGroup] = useState(groupData);

  const onChangeHandler = useChangeHandler(setGroup);

  const onSelectChangeHandler = useSelectChangeHandler(setGroup);

  useEffect(() => {
    if (!isEditing) {
      const filterInactives = true;
      fetchOrganizations(setOrganizationOptions, filterInactives);
    }

    return () => dispatch(groupsCleanUp());
  }, [dispatch, isEditing]);

  useEffect(() => {
    if (group.organization) {
      const filterInactives = true;
      const filterByOrganization = group.organization.value.displayName;
      const filterByManager = true;
      fetchUsers(
        managerOptions,
        setManagerOptions,
        filterInactives,
        filterByOrganization,
        filterByManager
      );
    }
  }, [dispatch, group.organization, managerOptions]);

  useEffect(() => {
    if (group.organization) {
      const filterByOrganization = group.organization.value.displayName;
      fetchWavesOptions(
        waveOptions,
        setWaveOptions,
        filterByOrganization,
        group.waves,
        setGroup
      );
    }
  }, [group.organization, group.waves]);

  const clearManagers = () => {
    setGroup((prevState) => ({
      ...prevState,
      manager: null,
    }));
    setManagerOptions((prevState) => ({ ...prevState, fetched: false }));
  };

  const clearWaves = () => {
    setGroup((prevState) => ({
      ...prevState,
      waves: null,
    }));
    setWaveOptions((prevState) => ({ ...prevState, fetched: false }));
  };

  useEffect(() => {
    if (!isEditing && defaultOrganization) {
      setGroup((prevState) => ({
        ...prevState,
        organization: defaultOrganization,
      }));
      clearManagers();
      clearWaves();
    }
  }, [defaultOrganization, isEditing]);

  const onSubmitHandler = (event) => {
    event.preventDefault();
    dispatch(
      action({
        ...group,
        organizationName: group.organization.value.displayName,
        manager: group.manager?.value || null,
        expectedCount: group.expectedCount ? Number(group.expectedCount) : null,
        waves: group.waves,
      })
    );
  };

  const canSubmit =
    group.organization && group.displayName && group.displayName.trim();

  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account-edit default" />
                </span>
                Group Information
              </p>
            </header>
            <div className="card-content">
              <form onSubmit={onSubmitHandler}>
                <div className="card-content columns">
                  <div className="column">
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">Organization</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <Select
                              isDisabled={
                                organizationOptions.loading || isEditing
                              }
                              isLoading={organizationOptions.loading}
                              isClearable
                              options={organizationOptions.data}
                              onChange={(select) => {
                                onSelectChangeHandler('organization', select);
                                clearManagers();
                                clearWaves();
                              }}
                              value={group.organization}
                            />
                          </div>
                          <small>Select an organization for this group.</small>
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">Name</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              id="name"
                              className="input"
                              type="text"
                              required
                              name="displayName"
                              value={group.displayName}
                              onChange={onChangeHandler}
                            />
                          </div>
                          <small>
                            Name of the group/department. This will be used to
                            identify the group within the system.
                          </small>
                        </div>
                      </div>
                    </div>

                    <div className="field has-check is-horizontal">
                      <div className="field-label">
                        <label className="label">Active</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="field">
                            <div className="control">
                              <label className="b-checkbox checkbox">
                                <input
                                  type="checkbox"
                                  name="active"
                                  onChange={onChangeHandler}
                                  checked={group.active}
                                />
                                <span className="check is-primary" />
                              </label>
                            </div>
                            <small>
                              Designates whether this group should be treated as
                              active or archived.
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="column">
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">Description</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              className="input"
                              type="text"
                              name="description"
                              value={group.description}
                              onChange={onChangeHandler}
                            />
                          </div>
                          <small>
                            A brief description of the group&apos;s function.
                            Optional.
                          </small>
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">Manager</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <Select
                              classNamePrefix="select"
                              isDisabled={
                                managerOptions.loading || !group.organization
                              }
                              isLoading={managerOptions.loading}
                              options={managerOptions.data}
                              onChange={(select) =>
                                onSelectChangeHandler('manager', select)
                              }
                              noOptionsMessage={() =>
                                'The organization does not have manager users'
                              }
                              value={group.manager}
                            />
                          </div>
                          <small>
                            The acting manager/supervisor for the group. Can be
                            anyone in the organization that is set up as a
                            manager.
                          </small>
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal">
                      <div className="field-label">
                        <label className="label">Expected count</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              className="input"
                              type="text"
                              inputMode="numeric"
                              pattern="^-?[0-9]\d*$"
                              min="0"
                              name="expectedCount"
                              value={group.expectedCount}
                              onChange={(e) =>
                                numberHandler(e, onChangeHandler)
                              }
                            />
                          </div>
                          <small>
                            Number of expected responses/users for this group.
                          </small>
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">Waves</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <Select
                              className="basic-multi-select"
                              classNamePrefix="select"
                              name="waves"
                              isLoading={waveOptions.loading}
                              isMulti
                              isDisabled={
                                waveOptions.loading || !group.organization
                              }
                              options={waveOptions.data}
                              onChange={(select) =>
                                onSelectChangeHandler('waves', select)
                              }
                              noOptionsMessage={() =>
                                'The organization does not have waves'
                              }
                              value={group.waves}
                            />
                          </div>
                          <small>
                            Waves in which the group is currently active.
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label" />
                  <div className="field-body">
                    <div className="field">
                      <div className="field is-grouped">
                        <div className="control">
                          <button
                            type="submit"
                            className={classNames('button is-primary', {
                              'is-loading': loading,
                            })}
                            disabled={!canSubmit}
                          >
                            <span>Submit</span>
                          </button>
                        </div>
                        <Link to={paths.GROUPS} className="button">
                          Go Back
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

GroupForm.propTypes = {
  groupData: PropTypes.shape({
    id: PropTypes.string,
    organization: PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.shape({
        displayName: PropTypes.string.isRequired,
      }).isRequired,
    }),
    displayName: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    manager: PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.shape({
        id: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
      }).isRequired,
    }),
    active: PropTypes.bool.isRequired,
    expectedCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
  }),
  action: PropTypes.func.isRequired,
};

export default GroupForm;
