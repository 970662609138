import React from 'react';
import PropTypes from 'prop-types';

import classes from './CardHeader.module.scss';

const CardHeader = ({ children }) => {
  return (
    <header id="children-placeholder" className={classes['card-header']}>
      {children}
    </header>
  );
};

CardHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CardHeader;
