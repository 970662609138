import { createReducer } from 'redux-act';

import {
  REPROCESS_RESPONSES_INIT,
  REPROCESS_RESPONSES_SUCCESS,
  REPROCESS_RESPONSES_FAIL,
  REPROCESS_RESPONSES_CLEAR_STATE,
} from 'state/actions/reprocessResponses';

const initialState = {
  loading: false,
  success: false,
  error: null,
};

export const reprocessResponsesReducer = createReducer(
  {
    [REPROCESS_RESPONSES_INIT]: () => ({
      ...initialState,
      loading: true,
      success: false,
    }),
    [REPROCESS_RESPONSES_SUCCESS]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
    }),
    [REPROCESS_RESPONSES_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
      success: false,
    }),
    [REPROCESS_RESPONSES_CLEAR_STATE]: () => initialState,
  },
  initialState
);
