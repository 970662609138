const initialLayout = {
  charts: {
    'filter-responses-by-date': {
      id: 'filter-responses-by-date',
      content: 'Filter responses by date',
      weight: 12,
    },
    'response-rate': {
      id: 'response-rate',
      content: 'Response Rate',
      weight: 4,
    },
    'survey-responses': {
      id: 'survey-responses',
      content: 'Survey Responses',
      weight: 8,
    },
    reports: { id: 'reports', content: 'Reports', weight: 12 },
    segmentation: { id: 'segmentation', content: 'Segmentation', weight: 6 },
    'demographics-breakdown': {
      id: 'demographics-breakdown',
      content: 'Demographics Breakdow',
      weight: 6,
    },
    'safety-awareness-breakdown': {
      id: 'safety-awareness-breakdown',
      content: 'Safety Awareness Breakdown',
      weight: 6,
    },
    'safety-awareness': {
      id: 'safety-awareness',
      content: 'Safety Awareness',
      weight: 3,
    },
    'below-threshold-group': {
      id: 'below-threshold-group',
      content: 'Below Threshold Group',
      weight: 3,
    },
    'areas-of-risks': {
      id: 'areas-of-risks',
      content: 'Areas of Risks',
      weight: 12,
    },
  },
  columns: {
    'a53099de-e06c-4c9a-b1d2-4e04b6e9be1b': {
      id: 'a53099de-e06c-4c9a-b1d2-4e04b6e9be1b',
      title: 'Row',
      chartsIds: ['filter-responses-by-date'],
    },
    'a62a1236-9499-4eb7-a4c2-cb7f3e776742': {
      id: 'a62a1236-9499-4eb7-a4c2-cb7f3e776742',
      title: 'Row',
      chartsIds: ['response-rate', 'survey-responses'],
    },
    '308d1b56-8fd5-4857-9f78-3adddb61d893': {
      id: '308d1b56-8fd5-4857-9f78-3adddb61d893',
      title: 'Row',
      chartsIds: ['reports'],
    },
    '09a350c7-07aa-495e-8742-784cdb4610b7': {
      id: '09a350c7-07aa-495e-8742-784cdb4610b7',
      title: 'Row',
      chartsIds: ['segmentation', 'demographics-breakdown'],
    },
    '794a8dea-8741-4cd0-ab88-c500a0db84ca': {
      id: '794a8dea-8741-4cd0-ab88-c500a0db84ca',
      title: 'Row',
      chartsIds: [
        'safety-awareness-breakdown',
        'safety-awareness',
        'below-threshold-group',
      ],
    },
    'b57cb01a-275e-421f-be43-e4e487cd908e': {
      id: 'b57cb01a-275e-421f-be43-e4e487cd908e',
      title: 'Row',
      chartsIds: ['areas-of-risks'],
    },
    storage: {
      id: 'storage',
      title: 'Storage',
      chartsIds: [],
    },
  },
  columnOrder: [
    'a53099de-e06c-4c9a-b1d2-4e04b6e9be1b',
    'a62a1236-9499-4eb7-a4c2-cb7f3e776742',
    '308d1b56-8fd5-4857-9f78-3adddb61d893',
    '09a350c7-07aa-495e-8742-784cdb4610b7',
    '794a8dea-8741-4cd0-ab88-c500a0db84ca',
    'b57cb01a-275e-421f-be43-e4e487cd908e',
    'storage',
  ],
};

export default initialLayout;
