import { createSelector } from 'reselect';
import { selectQuestionChoicesState } from '../questionChoices';

export const selectDataSetsState = (state) => state.dataSets;

export const selectDataSetsOptionsState = createSelector(
  selectDataSetsState,
  selectQuestionChoicesState,
  (
    { data: dataSetsData, loading: dataSetsLoading },
    { loading: questionChoicesLoading, data: questionChoicesData }
  ) => ({
    dataSetsOptions: dataSetsData.map(
      ({ id, title, tagDisplayName, tagOrganizationName }) => ({
        label: tagDisplayName,
        value: {
          id,
          name: tagDisplayName,
          title,
          organization: tagOrganizationName,
        },
      })
    ),
    fetchingDataSetsOptions: dataSetsLoading,
    questionChoices: questionChoicesData,
    fetchingQuestionChoices: questionChoicesLoading,
  })
);
