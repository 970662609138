/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';

import classes from './DragItem.module.scss';

const DragItem = ({ dragId, index, children }) => (
  <Draggable draggableId={dragId} index={index}>
    {(provided) => (
      <div
        id="children-placeholder"
        className={classes.drag}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
      >
        {children}
      </div>
    )}
  </Draggable>
);

DragItem.propTypes = {
  dragId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default DragItem;
