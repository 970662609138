import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ModalType } from 'utils/enums';
import classes from './Modal.module.scss';
import './Modal.scss';

const Modal = ({
  isActive,
  isLoading,
  title,
  body,
  confirmButtonMessage = '',
  onConfirmation = null,
  cancelButtonMessage,
  onCancel,
  showConfirmButton = true,
  type = ModalType.CONFIRMATION,
}) => {
  const modifiers = isActive && 'is-active';
  const loadingModifier = isLoading && 'is-loading';

  return (
    <div className={`modal ${modifiers}`}>
      <div
        className="modal-background"
        onClick={!isLoading ? onCancel : undefined}
      />
      <div className="modal-card">
        <header className="modal-card-head">
          <p
            className={classNames('modal-card-title', {
              [classes['error-title']]: type === ModalType.ERROR,
            })}
          >
            {title}
          </p>
        </header>
        <section className="modal-card-body">{body}</section>
        <footer className="modal-card-foot">
          {showConfirmButton && (
            <button
              type="button"
              className={`button is-danger ${loadingModifier}`}
              onClick={onConfirmation}
            >
              {confirmButtonMessage}
            </button>
          )}
          <button
            type="button"
            className="button"
            onClick={onCancel}
            disabled={isLoading}
          >
            {cancelButtonMessage}
          </button>
        </footer>
      </div>
    </div>
  );
};

Modal.propTypes = {
  isActive: PropTypes.bool,
  isLoading: PropTypes.bool,
  title: PropTypes.string.isRequired,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  confirmButtonMessage: PropTypes.string,
  onConfirmation: PropTypes.func,
  cancelButtonMessage: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  showConfirmButton: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(ModalType)),
};

export default Modal;
