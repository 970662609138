/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import classNames from 'classnames';

import createSurveyCopy from 'state/actions/surveys/create/createSurveyCopy';
import { selectOrganizationsState } from 'state/selectors/organizations';
import { selectCreateSurveyCopyModalState } from 'state/selectors/surveys';
import { clearSurveyCopyState } from 'state/actions/surveys';

import classes from './CopySurveyModal.module.scss';

const CopySurveyModal = ({ onClose, survey, surveyId }) => {
  const dispatch = useDispatch();

  const { data } = useSelector(selectOrganizationsState, shallowEqual);
  const { createdSurveyCopy, creatingSurveyCopy, errorCreatingSurveyCopy } =
    useSelector(selectCreateSurveyCopyModalState, shallowEqual);

  const [values, setValues] = useState({
    selectedOrganization: null,
    surveyName: '',
  });

  const { selectedOrganization, surveyName } = values;

  const organizationOptions = useMemo(
    () =>
      data?.map(({ displayName, id }) => ({
        value: id,
        label: displayName,
      })) ?? [],
    [data]
  );


  const onSubmit = (e) => {
    e.preventDefault();

    dispatch(
      createSurveyCopy({
        surveyId,
        surveyData: survey,
        organizationId: selectedOrganization.value,
        newSurveyData: {
          name: surveyName,
        },
      })
    );
  };

  useEffect(() => {
    dispatch(clearSurveyCopyState());
  }, []);

  useEffect(() => {
    if (createdSurveyCopy) {
      dispatch(clearSurveyCopyState());

      onClose();
    }
  }, [createdSurveyCopy]);

  const canSubmit = useMemo(
    () => values.selectedOrganization !== null && !creatingSurveyCopy,
    [values.selectedOrganization]
  );

  return (
    <div className="modal is-active">
      <div
        id="background"
        className="modal-background"
        onClick={!creatingSurveyCopy ? onClose : null}
      />
      <div className={classNames('modal-content', classes['modal-content'])}>
        <form id="submit" onSubmit={onSubmit}>
          <header className="modal-card-head">
            <p className="modal-card-title">Copy Survey</p>
            <button
              id="secondary-cancel-button"
              type="button"
              className="delete"
              aria-label="close"
              disabled={creatingSurveyCopy}
              onClick={onClose}
            />
          </header>
          <section
            className={classNames('modal-card-body', classes['modal-body'])}
          >
            <span>
              Copy survey to organization:
              <Select
                options={organizationOptions}
                onChange={(selected) =>
                  setValues((prevState) => ({
                    ...prevState,
                    selectedOrganization: selected,
                  }))
                }
              />
            </span>
            <span
              className={classNames({
                'has-text-danger': errorCreatingSurveyCopy,
              })}
            >
              New Survey name:
              <input
                type="text"
                className={classNames('input', {
                  'is-danger': errorCreatingSurveyCopy,
                })}
                value={surveyName}
                onChange={(e) => {
                  const { value } = e.target;

                  setValues((prevState) => ({
                    ...prevState,
                    surveyName: value,
                  }));
                  // }
                }}
              />
            </span>
          </section>
          <footer className={classNames('modal-card-foot', classes.footer)}>
            <button
              id="primary-cancel-button"
              type="button"
              className="button is-light"
              disabled={creatingSurveyCopy}
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              id="submit-button"
              type="submit"
              className={classNames('button is-success', {
                'is-loading': creatingSurveyCopy,
              })}
              disabled={!canSubmit}
            >
              Copy Survey
            </button>
          </footer>
        </form>
      </div>
    </div>
  );
};

CopySurveyModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  surveyId: PropTypes.string.isRequired,
  survey: PropTypes.any.isRequired,
};

export default CopySurveyModal;
