/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import { updateOrganizationLayout } from 'state/actions/organizations';
import Layout from 'components/Organization/Layout/index';
import classes from './OrganizationLayout.module.scss';

const OrganizationLayout = () => {
  const { siteName } = useParams();

  const { updatingLayout, organizationId, organizationLayout } = useSelector(
    ({ organizations }) => {
      const organizationData = organizations.data.find(
        (organization) =>
          organization.siteName === siteName.toLowerCase().trim()
      );

      return {
        organizationId: organizationData.id,
        organizationLayout: organizationData.layout,
        updatingLayout: organizations.updatingLayout,
      };
    },
    shallowEqual
  );

  const [layout, setLayout] = useState(organizationLayout);

  const dispatch = useDispatch();

  const onUpdateLayoutHandler = useCallback(() => {
    dispatch(updateOrganizationLayout(organizationId, layout));
  }, [dispatch, layout, organizationId]);

  return (
    <div className="tile is-parent">
      <div className="card tile is-child">
        <header className="card-header">
          <p className="card-header-title">
            <span className="icon">
              <i className="mdi mdi-view-dashboard" />
            </span>
            Organization Layout
          </p>
        </header>
        <div className="card-content">
          <div
            className={classNames(
              'field is-horizontal',
              classes['layout-container']
            )}
          >
            <Layout layout={layout} setLayout={setLayout} />
          </div>
        </div>
        <footer>
          <div className={classes['update-button']}>
            <button
              type="button"
              className={classNames('button is-primary', {
                'is-loading': updatingLayout,
              })}
              disabled={updatingLayout}
              onClick={onUpdateLayoutHandler}
            >
              <span>Update</span>
            </button>
          </div>
        </footer>
      </div>
    </div>
  );
};

OrganizationLayout.propTypes = {};

export default OrganizationLayout;
