/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import paths from 'pages/Router/paths';
import { useChangeHandler } from 'hooks';
import classes from './IndustryForm.module.scss';

const IndustryForm = ({ action, industryData, isEditing }) => {
  const { loadingCreating, loadingEdit } = useSelector(
    (state) => ({
      loadingCreating: state.industries.creatingIndustry,
      loadingEdit: state.industries.loading,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const [industry, setIndustry] = useState(industryData);

  const onChangeHandler = useChangeHandler(setIndustry);

  const onSubmitHandler = (event) => {
    event.preventDefault();
    dispatch(
      action({
        ...industry,
      })
    );
  };

  const canSubmit = industry.displayName && industry.displayName.trim();

  return (
    <>
      <section className="section is-main-section">
        <div className="card">
          <header className="card-header">
            <p className="card-header-title">
              <span className="icon">
                <i className="mdi mdi-tag default" />
              </span>
              Industry information
            </p>
          </header>
          <form onSubmit={onSubmitHandler}>
            <div className="card-content columns">
              <div className="column">
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Name</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          id="name-input"
                          className={classNames('input', classes.input)}
                          type="text"
                          name="displayName"
                          required
                          onChange={onChangeHandler}
                          value={industry.displayName}
                        />
                      </div>
                      <p className="help">Name of the industry.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <footer className={classNames('card-footer', classes.cardFooter)}>
              <div className={classNames('buttons', classes.buttons)}>
                <button
                  type="submit"
                  className={classNames('button', 'is-primary', {
                    'is-loading': loadingEdit || loadingCreating,
                  })}
                  disabled={loadingEdit || loadingCreating || !canSubmit}
                >
                  {isEditing ? 'Update' : 'Create'}
                </button>
                <Link to={paths.INDUSTRIES}>
                  <button
                    id="go-back"
                    className="button"
                    disabled={loadingEdit || loadingCreating}
                  >
                    Go Back
                  </button>
                </Link>
              </div>
            </footer>
          </form>
        </div>
      </section>
    </>
  );
};

IndustryForm.propTypes = {
  industryData: PropTypes.shape({
    id: PropTypes.string,
    displayName: PropTypes.string.isRequired,
  }),
  action: PropTypes.func.isRequired,
};

export default IndustryForm;
