/* eslint-disable no-restricted-syntax */
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';

import { REACT_APP_ALGOLIA_QUESTIONS_CONTENT_INDEX } from 'constants/environment';
import {
  fetchTags,
  removeTag,
  addTag,
  createTag,
  clearCreateTagState,
} from 'state/actions/tags';
import { TagType } from 'utils/enums';
import { SurveyType, QuestionType } from 'utils/surveyBuilder/enums';
import { getAvailableLanguages } from 'utils/surveyBuilder';
import QuestionTag from 'components/SurveyBuilder/QuestionTag';
import Autocomplete from 'components/Algolia/Autocomplete';
import QuestionContent, { getItemInputValue } from 'components/Algolia/Autocomplete/QuestionContent';

import classes from './QuestionForm.module.scss';

const defaultQuestionState = (availableLanguages) => ({
  id: uuidv4(),
  content: getAvailableLanguages(availableLanguages),
  weight: '',
  tags: [],
  type: QuestionType.SINGLE_RESPONSE,
  required: false,
  selectedTag: null,
  choicesOrder: null,
  tag: null,
  visible: true,
});

const QuestionForm = ({
  organizationName,
  surveyType,
  availableLanguages,
  surveyLanguage,
  onCreate,
  deploymentId,
  editedQuestion,
  setEditedQuestion,
}) => {
  const dispatch = useDispatch();

  const {
    fetchingTags,
    tagOptions,
    creatingTag,
    successCreatingTag,
    errorCreatingTag,
    successFetchingTags,
  } = useSelector(
    ({ tags }) => ({
      tagOptions: tags.data.map((tag) => ({
        label: `${tag.displayName} (${tag.type})`,
        value: tag,
      })),
      fetchingTags: tags.fetchingTags,
      creatingTag: tags.creatingTag,
      successCreatingTag: tags.successCreatingTag,
      errorCreatingTag: tags.errorCreatingTag,
      successFetchingTags: tags.successFetchingTags,
    }),
    shallowEqual
  );

  const [
    {
      id,
      content,
      weight,
      tags,
      selectedTag,
      required,
      type,
      choicesOrder,
      visible,
    },
    setQuestion,
  ] = useState(defaultQuestionState(availableLanguages));

  useEffect(() => {
    const organizations = ['global'];

    if (surveyType === SurveyType.ORGANIZATION && organizationName) {
      organizations.push(organizationName);
    }

    dispatch(
      fetchTags({
        filterByActive: true,
        filterByNotType: TagType.DATA_SET,
        filterByOrganizations: organizations,
      })
    );
  }, [dispatch, organizationName, surveyType]);

  useEffect(() => {
    if (successCreatingTag) {
      const organizations = ['global'];

      if (surveyType === SurveyType.ORGANIZATION && organizationName) {
        organizations.push(organizationName);
      }

      dispatch(
        fetchTags({
          filterByActive: true,
          filterByNotType: TagType.DATA_SET,
          filterByOrganizations: organizations,
        })
      );
    }
  }, [dispatch, successCreatingTag, organizationName, surveyType]);

  useEffect(() => {
    if (errorCreatingTag && selectedTag) {
      setQuestion((prevState) => ({ ...prevState, selectedTag: null }));
      dispatch(clearCreateTagState());
    }
  }, [errorCreatingTag, selectedTag, dispatch]);

  useEffect(() => {
    if (successCreatingTag && successFetchingTags && selectedTag) {
      const { label: tagName, value } = selectedTag;
      const selected = tagOptions.find(({ label }) => label === tagName);
      if (!value && selected) {
        setQuestion((prevState) => ({ ...prevState, selectedTag: selected }));
        dispatch(clearCreateTagState());
      }
    }
  }, [
    successCreatingTag,
    successFetchingTags,
    selectedTag,
    tagOptions,
    dispatch,
  ]);

  const onChangeContentHandler = useCallback(
    (value) => {
      setQuestion((prevSate) => ({
        ...prevSate,
        content: {
          ...prevSate.content,
          [surveyLanguage]: value,
        },
      }));
    },
    [surveyLanguage]
  );

  const onChangeWeightHandler = useCallback((event) => {
    const { value } = event.target;
    setQuestion((prevSate) => ({ ...prevSate, weight: value }));
  }, []);

  const onChangeTagHandler = useCallback((select) => {
    setQuestion((prevState) => {
      if (select) {
        const { value } = select;
        return {
          ...prevState,
          selectedTag: select,
          tag: value,
        };
      }
      return {
        ...prevState,
        selectedTag: null,
        tag: null,
      };
    });
  }, []);

  const onAddTagHandler = useCallback(
    (event) => {
      event.preventDefault();
      setQuestion((prevState) => {
        const { weight: tagWeight } = prevState;
        const tag = {
          ...prevState.selectedTag.value,
          weight: tagWeight ? Number(tagWeight) : null,
        };
        dispatch(removeTag(tag));
        return {
          ...prevState,
          tags: [...prevState.tags, tag],
          selectedTag: null,
          weight: '',
        };
      });
    },
    [dispatch]
  );

  const onRemoveTagHandler = useCallback(
    (tag) =>
      setQuestion((prevState) => {
        dispatch(addTag(tag));
        const filtered = prevState.tags.filter(
          ({ id: tagId }) => tagId !== tag.id
        );
        return {
          ...prevState,
          tags: filtered,
        };
      }),
    [dispatch]
  );

  const onRequiredChangeHandler = useCallback(() => {
    setQuestion((prevState) => ({
      ...prevState,
      required: !prevState.required,
    }));
  }, []);

  const [autocompleteQuestion, setAutocompleteQuestion] = useState(
    content[surveyLanguage]
  );

  const onCreateQuestionHandler = useCallback(
    (event) => {
      event.preventDefault();
      const question = {
        id,
        content: {
          ...content,
          [surveyLanguage]: content[surveyLanguage].trim(),
        },
        weight: weight || null,
        tags,
        required,
        type,
        choicesOrder,
        visible,
      };

      onCreate(question);

      setQuestion(defaultQuestionState(availableLanguages));

      tags.forEach((tag) => dispatch(addTag(tag)));

      setEditedQuestion(null);
    },
    [
      onCreate,
      content,
      weight,
      tags,
      visible,
      dispatch,
      surveyLanguage,
      availableLanguages,
      choicesOrder,
      id,
      required,
      type,
      setEditedQuestion,
      setAutocompleteQuestion,
    ]
  );

  const onCreateTagHandler = useCallback(
    (tagName) => {
      const tag = {
        name: tagName,
        global: surveyType === SurveyType.TEMPLATE,
        organization: organizationName,
        active: true,
        type: TagType.QUESTION,
      };
      dispatch(createTag(tag));
      setQuestion((prevState) => ({
        ...prevState,
        selectedTag: { label: tagName, value: null },
      }));
    },
    [dispatch, organizationName, surveyType]
  );

  const canCreateQuestion = useMemo(
    () => content[surveyLanguage].trim(),
    [content, surveyLanguage]
  );

  useEffect(() => {
    onChangeContentHandler(autocompleteQuestion);
  }, [autocompleteQuestion, onChangeContentHandler]);

  useEffect(() => {
    if (editedQuestion) {
      setQuestion((prevState) => ({
        ...prevState,
        ...editedQuestion,
      }));

      for (const tag of editedQuestion.tags) {
        dispatch(removeTag(tag));
      }
    }
  }, [editedQuestion]);

  const initialState = useMemo(
    () => (editedQuestion ? editedQuestion.content[surveyLanguage] : ''),
    [editedQuestion]
  );

  const selectedTagIsConstruct = useMemo(
    () => selectedTag?.value?.type === TagType.CONSTRUCT,
    [selectedTag]
  );

  const tagWeightIsFilled = useMemo(
    () => !selectedTagIsConstruct || !!weight,
    [selectedTagIsConstruct, weight]
  );

  const canAddTag = useMemo(
    () => selectedTag && !fetchingTags && !creatingTag && tagWeightIsFilled,
    [selectedTag, fetchingTags, creatingTag, tagWeightIsFilled]
  );

  return (
    <>
      <form id="create-question-form" onSubmit={onCreateQuestionHandler} />
      <form id="add-tag-form" onSubmit={onAddTagHandler} />
      <div className="field">
        <div
          className={classNames('control', {
            [classes.disabled]: deploymentId,
          })}
        >
          <Autocomplete
            initialState={initialState}
            setAutocompleteQuestion={setAutocompleteQuestion}
            placeholder="Question"
            sourceId="questionsContent"
            indexName={REACT_APP_ALGOLIA_QUESTIONS_CONTENT_INDEX}
            ItemComponent={QuestionContent}
            getItemInputValue={getItemInputValue}
          />
        </div>
      </div>
      <div className="field">
        <label
          htmlFor="required-checkbox"
          className="b-checkbox checkbox has-text-weight-bold"
        >
          Required
          <input
            id="required-checkbox"
            type="checkbox"
            name="required"
            onChange={onRequiredChangeHandler}
            checked={required}
          />
          <span className={classNames('check is-primary', classes.checkbox)} />
        </label>
      </div>
      <div className="field is-grouped is-grouped-multiline">
        <div
          className={classNames(
            'control',
            'is-expanded',
            classes['tag-select']
          )}
        >
          {surveyType === SurveyType.ORGANIZATION && !organizationName && (
            <Select
              placeholder="Tags"
              value={selectedTag}
              isLoading={fetchingTags}
              isSearchable
              options={tagOptions}
              onChange={onChangeTagHandler}
              maxMenuHeight={200}
              isClearable
            />
          )}
          {(surveyType === SurveyType.TEMPLATE || organizationName) && (
            <CreatableSelect
              placeholder="Tags"
              value={selectedTag}
              isLoading={fetchingTags || creatingTag}
              isSearchable
              options={tagOptions}
              onChange={onChangeTagHandler}
              onCreateOption={onCreateTagHandler}
              maxMenuHeight={200}
              isClearable
            />
          )}
        </div>
        <div className="control">
          <input
            form="add-tag-form"
            className="input"
            type="number"
            placeholder="Tag Weight"
            disabled={!selectedTagIsConstruct}
            value={weight}
            onChange={onChangeWeightHandler}
          />
        </div>
        <div className="control">
          <button
            form="add-tag-form"
            type="submit"
            className="button is-info"
            disabled={!canAddTag}
          >
            Add
          </button>
        </div>
      </div>
      <div className="box">
        <div className="tags">
          {tags.map((tag) => (
            <QuestionTag key={tag.id} tag={tag} onRemove={onRemoveTagHandler} inQuestionGroupBuilder />
          ))}
        </div>
      </div>
      <div className="field">
        <div className="control">
          <button
            form="create-question-form"
            className="button is-fullwidth"
            type="submit"
            disabled={!canCreateQuestion || deploymentId}
          >
            {`${!editedQuestion ? 'Create' : 'Update'} Question`}
          </button>
        </div>
      </div>
    </>
  );
};

QuestionForm.propTypes = {
  surveyType: PropTypes.oneOf([SurveyType.ORGANIZATION, SurveyType.TEMPLATE])
    .isRequired,
  availableLanguages: PropTypes.arrayOf(PropTypes.string).isRequired,
  surveyLanguage: PropTypes.string.isRequired,
  onCreate: PropTypes.func.isRequired,
  organizationName: PropTypes.string,
};

export default QuestionForm;
