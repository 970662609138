/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Collapsable from 'components/Collapsable';
import classes from './CardBody.module.scss';

const CardBody = ({ collapsed, innerRef, children, ...rest }) => (
  <div
    className={classNames('card-content', {
      [classes['card-collapsed']]: collapsed,
    })}
    ref={innerRef}
    {...rest}
  >
    <Collapsable id="card-collapsable" collapsed={collapsed}>
      {children}
    </Collapsable>
  </div>
);

CardBody.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  innerRef: PropTypes.oneOfType([
    PropTypes.func, 
    // eslint-disable-next-line react/forbid-prop-types
    PropTypes.shape({ current: PropTypes.any })
]),
};

export default CardBody;
