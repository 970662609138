import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

import classes from './Choice.module.scss';

import './Choice.scss';

const Choice = ({
  parentIndex,
  index,
  number,
  content,
  visible,
  notApplicable,
  onChangeVisibility,
  onChangeNotApplicable,
}) => (
  <div className="field is-grouped">
    <div className={classNames('control', classes.number)}>
      <p id="choice-number-placeholder">{`${number}.`}</p>
    </div>
    <div className="control is-expanded">
      <input
        id="choice-content-input"
        className="input"
        value={content}
        disabled
      />
    </div>
    <div className={classNames('control', classes['choice-options'])}>
      <button
        id="change-visibility-question-choice"
        className={classNames('icon', 'is-medium', 'question-choice__action')}
        data-tip="Visibility"
        data-for="choices-options"
        type="button"
        onClick={() => onChangeVisibility({ parentIndex, choiceIndex: index })}
      >
        <i
          className={classNames('mdi', {
            'mdi-eye': visible,
            'mdi-eye-off': !visible,
          })}
        />
      </button>
      <button
        id="make-not-aplicable-question-choice"
        className={classNames('icon', 'is-medium', 'question-choice__action')}
        data-tip="Not Applicable"
        data-for="choices-options"
        type="button"
        onClick={() =>
          onChangeNotApplicable({ parentIndex, choiceIndex: index })
        }
      >
        <i
          className={classNames('mdi', 'mdi-cancel', {
            'question-choice__action--selected': notApplicable,
          })}
        />
      </button>
    </div>
    <ReactTooltip id="choices-options" />
  </div>
);

Choice.propTypes = {
  index: PropTypes.number.isRequired,
  number: PropTypes.number.isRequired,
  content: PropTypes.string.isRequired,
  visible: PropTypes.bool,
  notApplicable: PropTypes.bool,
  onChangeVisibility: PropTypes.func.isRequired,
  onChangeNotApplicable: PropTypes.func.isRequired,
};

export default Choice;
