/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

const Track = ({ source, target, getTrackProps, disabled, style = {} }) => {
  const { backgroundColor: definedBackgroundColor, ...styles } = style;

  const backgroundColor = definedBackgroundColor ?? 'hsl(171, 100%, 90%)';

  const disabledBackgroundColor = '#999';

  return (
    <div
      style={{
        position: 'absolute',
        transform: 'translate(0%, -50%)',
        height: 14,
        zIndex: 1,
        backgroundColor: disabled ? disabledBackgroundColor : backgroundColor,
        borderRadius: 7,
        cursor: 'pointer',
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
        ...styles,
      }}
      {...getTrackProps()}
    />
  );
};

Track.propTypes = {
  source: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }).isRequired,
  target: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }).isRequired,
  getTrackProps: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  style: PropTypes.shape({}),
};

Track.defaultProps = {
  disabled: false,
};

export default Track;
