import React, { createElement, Fragment, useEffect, useRef } from 'react';
import { render } from 'react-dom';
import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js';
import PropTypes from 'prop-types';
import '@algolia/autocomplete-theme-classic/dist/theme.css';

import { initAlgoliaClient } from 'utils';
import QuestionContent from './QuestionContent';

import classes from './Autocomplete.module.scss';
import SectionSaveOption from './SectionSave';

const searchClient = initAlgoliaClient();

const Autocomplete = ({
  setAutocompleteQuestion,
  setItemsCollections = null,
  placeholder,
  initialState,
  sourceId,
  indexName,
  ItemComponent,
  getItemInputValue,
  onselect = null,
}) => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (!containerRef.current) {
      return undefined;
    }

    const search = autocomplete({
      initialState: { query: initialState },
      onStateChange({ state }) {
        setAutocompleteQuestion(state.query);
        if (setItemsCollections) {
          setItemsCollections(
            state?.collections?.flatMap((collection) => collection.items) ?? []
          );
        }
      },
      container: containerRef.current,
      renderer: { createElement, Fragment },
      render({ children }, root) {
        render(children, root);
      },
      detachedMediaQuery: false,
      openOnFocus: true,
      placeholder,
      classNames: {
        form: classes['autocomplete-form'],
        panel: classes['autocomplete-panel'],
      },
      getSources: ({ query }) => [
        {
          sourceId,
          getItemInputValue,
          ...(onselect && { onselect }),
          getItems() {
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName,
                  query,
                },
              ],
            });
          },
          templates: {
            item({ item, components }) {
              return <ItemComponent hit={item} components={components} />;
            },
          },
        },
      ],
    });

    return () => {
      search.destroy();
    };
  }, [placeholder, setAutocompleteQuestion, initialState]);

  return <div ref={containerRef} />;
};

Autocomplete.propTypes = {
  setAutocompleteQuestion: PropTypes.func.isRequired,
  setItemsCollections: PropTypes.func,
  placeholder: PropTypes.string,
  initialState: PropTypes.string,
  sourceId: PropTypes.string.isRequired,
  indexName: PropTypes.string.isRequired,
  ItemComponent: PropTypes.oneOf([SectionSaveOption, QuestionContent])
    .isRequired,
  getItemInputValue: PropTypes.func.isRequired,
  onselect: PropTypes.func,
};

export default Autocomplete;
