import { createReducer } from 'redux-act';

import {
  WAVES_FETCH_DATA_FAIL,
  WAVES_FETCH_DATA_INIT,
  WAVES_FETCH_DATA_SUCCESS,
  WAVES_CREATE_WAVE_FAIL,
  WAVES_CREATE_WAVE_INIT,
  WAVES_CREATE_WAVE_SUCCESS,
  WAVES_DELETE_WAVE_FAIL,
  WAVES_DELETE_WAVE_INIT,
  WAVES_DELETE_WAVE_SUCCESS,
  WAVES_CLEAR_DATA,
  WAVES_MODIFY_WAVE_FAIL,
  WAVES_MODIFY_WAVE_INIT,
  WAVES_MODIFY_WAVE_SUCCESS,
  WAVES_CLEAR_UPDATED_WAVE,
} from 'state/actions/waves';

export const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
  updatedWave: false,
};

export const wavesReducer = createReducer(
  {
    [WAVES_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [WAVES_FETCH_DATA_SUCCESS]: (state, { waves }) => ({
      ...state,
      data: waves,
      loading: false,
      error: null,
    }),
    [WAVES_FETCH_DATA_FAIL]: (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
    [WAVES_CREATE_WAVE_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [WAVES_CREATE_WAVE_SUCCESS]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
    }),
    [WAVES_CREATE_WAVE_FAIL]: (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
    [WAVES_DELETE_WAVE_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [WAVES_DELETE_WAVE_SUCCESS]: (state, { id }) => {
      const { data } = state;

      return {
        ...state,
        data: data.filter((elem) => elem.id !== id),
        loading: false,
        error: null,
        deleted: true,
      };
    },
    [WAVES_DELETE_WAVE_FAIL]: (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
    [WAVES_MODIFY_WAVE_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [WAVES_MODIFY_WAVE_SUCCESS]: (state, { id, wave }) => {
      const { data } = state;

      return {
        ...state,
        data: data.map((elem) => {
          if (elem.id === id) {
            return wave;
          }
          return elem;
        }),
        loading: false,
        error: null,
        success: true,
        updatedWave: true,
      };
    },
    [WAVES_MODIFY_WAVE_FAIL]: (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
    [WAVES_CLEAR_DATA]: () => initialState,
    [WAVES_CLEAR_UPDATED_WAVE]: (state) => ({
      ...state,
      updatedWave: false,
    }),
  },
  initialState
);
