import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import classes from './Translation.module.scss';

const Translation = ({ name, percentageCompleted, onTranslate, onDelete }) => (
  <div className={classNames('columns', classes.translation)}>
    <div className="column">
      <div className="field">
        <div className="control">
          <b id="translation-name-placeholder">{name}</b>
        </div>
      </div>
    </div>
    <div className="column">
      <div className="field">
        <div className="control">
          <button
            id="translation-translate"
            className={classNames(classes.translate)}
            onClick={onTranslate}
          >
            Translate
          </button>
        </div>
      </div>
    </div>
    <div className="column">
      <div className="field">
        <div className="control">
          <p id="translation-completion-percentage-placeholder">{`${percentageCompleted}% complete`}</p>
        </div>
      </div>
    </div>
    <div className="column">
      <div
        className={classNames(
          'field',
          'is-grouped',
          'is-grouped-right',
          classes.actions
        )}
      >
        <div className="control">
          <div className="buttons">
            <button
              id="translation-delete-translation"
              type="button"
              className="button is-small is-danger"
              onClick={onDelete}
            >
              <span className="icon is-small">
                <i className="mdi mdi-delete" />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

Translation.propTypes = {
  name: PropTypes.string.isRequired,
  percentageCompleted: PropTypes.number.isRequired,
  onTranslate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default Translation;
