import { toastr } from 'react-redux-toastr';
import { createSurveyDeploymentService, updateSurveyService } from 'services/surveys';

import {
  SURVEYS_CREATE_DEPLOYMENT_FAIL,
  SURVEYS_CREATE_DEPLOYMENT_INIT,
  SURVEYS_CREATE_DEPLOYMENT_SUCCESS,
} from 'state/actionCreators/surveys';

export const createDeployment = (surveyVersionData, surveyDeploymentData) => {
  return async (dispatch, getState) => {
    dispatch(SURVEYS_CREATE_DEPLOYMENT_INIT());

    try {
      await updateSurveyService({ surveyData: surveyVersionData }, { getState });
      await createSurveyDeploymentService({ surveyData: surveyDeploymentData }, { getState });
    } catch (error) {
      toastr.error('Error Deploying Survey', error);
      return dispatch(SURVEYS_CREATE_DEPLOYMENT_FAIL({ error }));
    }

    toastr.success('', 'Survey successfully deployed.');

    return dispatch(SURVEYS_CREATE_DEPLOYMENT_SUCCESS());
  };
};
