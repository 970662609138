/* eslint-disable no-param-reassign */
import { v4 as uuid } from 'uuid';

import { AvailableActions } from 'utils/enums';
import { demographicsAssign, getQuestionDemographics } from './surveys';

const processDeploymentQuestionItem = ({
  // Identifiers
  id,
  items,
  waveId,
  surveyId,
  deploymentId,
  organizationId,

  // Static data
  question,

  // Dynamic data
  sections,
  questions,
  newActions,
  dataSetIds,
  questionTag,
  demographics,
  questionChoices,
  previousDemographics,
}) => {
  const {
    id: previousQuestionId,
    content,
    dataSet,
    tags,
    choices,
    required,
    choicesOrder,
    sliderProps,
    template,
    regionalBreakdown,
  } = question;

  const questionId = uuid();

  const questionContentParsed = {};

  const questionDemographics = getQuestionDemographics(
    waveId,
    tags,
    choices,
    previousDemographics
  );

  demographics.push(...demographicsAssign(demographics, questionDemographics));

  const demographicsId =
    questionDemographics.length !== 0
      ? questionDemographics.map((demographic) => demographic.id)
      : null;

  items[questionId] = {
    ...items[id],
    id: questionId,
    question: {
      ...items[id].question,
      id: questionId,
      demographicsId,
    },
  };

  Object.entries(sections).forEach(([sectionId, section]) => {
    const itemIndex = section.itemIds.indexOf(id);

    if (itemIndex !== -1) {
      sections[sectionId].itemIds[itemIndex] = questionId;
    }
  });

  Object.entries(content).forEach(([language, questionContent]) => {
    questionContentParsed[language] = questionContent.trim();
  });

  questions.push({
    id: questionId,
    question: {
      content: questionContentParsed,
      type: question.type,
      dataSetId: dataSet.id ?? null,
      sliderProps: sliderProps ?? null,
      surveyId,
      surveyVersionId: null,
      surveyDeploymentId: deploymentId,
      template,
      required,
      choicesOrder,
      regionalBreakdown,
      organizationId,
      demographicsId,
    },
  });

  tags.forEach(({ id: tagId, weight }) => {
    questionTag.push({ tagId, questionId, weight });
  });

  newActions.forEach(
    ({ actions: oldActions, conditions: oldConditions }, index) => {
      oldActions.forEach((action, indexAction) => {
        if (action.action === AvailableActions.RANDOMIZE_QUESTIONS) {
          action.subject.forEach((subjectId, indexSubject) => {
            if (subjectId === previousQuestionId) {
              newActions[index].actions[indexAction].subject[indexSubject] =
                questionId;
            }
          });
        }

        if (action.subject === previousQuestionId) {
          newActions[index].actions[indexAction].subject = questionId;
        }
      });

      oldConditions.forEach((condition, indexCondition) => {
        if (condition.subject === previousQuestionId) {
          newActions[index].conditions[indexCondition].subject = questionId;
        }
      });
    }
  );

  if (!dataSet.id) {
    choices.forEach(
      (
        {
          id: oldChoiceId,
          option,
          paramOne,
          paramTwo,
          openEnded,
          notApplicable,
          visible,
          sortOrder,
        },
        index
      ) => {
        const choiceId = uuid();

        newActions.forEach(
          (
            { actions: changedActions, conditions: changedConditions },
            indexOfAction
          ) => {
            changedActions.forEach((action, actionIndex) => {
              if (
                action.action === AvailableActions.HIDE_CHOICES ||
                action.action === AvailableActions.SHOW_CHOICES ||
                action.action === AvailableActions.RANDOMIZE_CHOICES
              ) {
                if (action.subject === questionId) {
                  action.choices.forEach((choice, choiceIndex) => {
                    if (choice === oldChoiceId) {
                      newActions[indexOfAction].actions[actionIndex].choices[
                        choiceIndex
                      ] = choiceId;
                    }
                  });
                }
              }
            });

            changedConditions.forEach((condition, conditionIndex) => {
              if (condition.subject === questionId) {
                condition.values.forEach((value, valueIndex) => {
                  if (value === oldChoiceId) {
                    newActions[indexOfAction].conditions[conditionIndex].values[
                      valueIndex
                    ] = choiceId;
                  }
                });
              }
            });
          }
        );

        items[questionId].question.choices[index].id = choiceId;

        const choiceOptionParsed = {};

        Object.entries(option).forEach(([language, choiceOption]) => {
          choiceOptionParsed[language] = choiceOption.trim();
        });

        questionChoices.push({
          id: choiceId,
          choice: {
            questionId,
            option: choiceOptionParsed,
            paramOne: paramOne?.trim() || null,
            paramTwo: paramTwo?.trim() || null,
            openEnded,
            notApplicable,
            dataSetId: null,
            sortOrder,
            visible,
          },
        });
      }
    );
  }

  delete items[id];

  if (dataSet.id) {
    dataSetIds.push(dataSet.id);
  }
};

export default processDeploymentQuestionItem;
