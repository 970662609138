import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Button = ({ className, icon, disabled, onClick, children }) => (
  <button
    className={classNames('button', className)}
    type="button"
    onClick={onClick}
    disabled={disabled}
  >
    {icon && (
      <span className="icon">
        <i className={icon} />
      </span>
    )}
    {children && <span>{children}</span>}
  </button>
);

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

Button.defaultProps = {
  icon: '',
  disabled: false,
  className: '',
};

export default Button;
