import React, { useEffect, useMemo } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';

import paths from 'pages/Router/paths';
import {
  createOrganization,
  modifyOrganization,
  organizationsCleanUp,
} from 'state/actions/organizations';
import {
  validSiteNameCharacters,
  validateName,
  siteNameIsNew,
  isUntilDateValid,
} from 'utils';

import { fetchOrganization } from 'utils/fetching';
import WaveForm from 'pages/Wave/WaveForm';
import Toggle from 'components/Toggle';
import OrganizationForm from './OrganizationForm';
import WaveHierarchyForm from './WaveHierarchyForm';
import OrganizationLayout from './OrganizationLayout';

const Organization = () => {
  const { siteName } = useParams();

  const { success } = useSelector(
    (state) => ({
      success: state.organizations.success,
    }),
    shallowEqual
  );

  const [waveToggle, setWaveToggle] = React.useState(true);

  const [organization, setOrganization] = React.useState({
    data: {
      displayName: '',
      displaySiteName: '',
      industry: null,
      processingThreshold: 15,
      active: true,
    },
    loading: false,
    fetched: false,
    error: null,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(organizationsCleanUp());
  }, [dispatch]);

  const validName = validateName(organization.data?.displayName);

  const validSiteName = {
    correctCharacters: validSiteNameCharacters(
      organization.data?.displaySiteName
    ),
    isNew: siteNameIsNew(organization.data?.displaySiteName),
  };

  const [wave, setWave] = React.useState({
    index: 1,
    name: '',
    startingOn: new Date(),
    until: null,
    expectedResponses: '',
    reportingThreshold: 15,
    organization: null,
  });

  const onChangeHandler = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    setOrganization((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [name]: value,
      },
    }));
  };

  const onSelectChangeHandler = (name, value) => {
    setOrganization((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [name]: value,
      },
    }));
  };

  const isEditing = useMemo(() => !!siteName, [siteName]);

  const onSubmitHandler = (event) => {
    event.preventDefault();

    const organizationInfo = {
      ...organization.data,
      processingThreshold: Number(organization.data.processingThreshold),
      industry: organization.data.industry?.value || null,
    };

    const waveInfo = {
      ...wave,
      name: wave.name || null,
      expectedResponses: wave.expectedResponses
        ? Number(wave.expectedResponses)
        : null,
      startingOn: wave.startingOn,
      until: wave.until || null,
      organizationName: organization.data.displayName,
      organizationId: null,
      reportingThreshold: Number(wave.reportingThreshold),
    };

    if (!isEditing) {
      dispatch(
        createOrganization({
          organization: { ...organizationInfo },
          wave: { ...waveInfo },
          createWave: waveToggle,
        })
      );
    } else {
      dispatch(modifyOrganization(organizationInfo));
    }
  };

  const validUntilDate = isUntilDateValid(wave?.startingOn, wave?.until);

  const canSubmitWave = !!(
    wave.startingOn &&
    validUntilDate &&
    wave.reportingThreshold !== ''
  );

  const canSubmitOrganization = !!(
    organization.data.displayName &&
    validName &&
    organization.data.displaySiteName &&
    !validSiteName.isNew &&
    validSiteName.correctCharacters &&
    organization.data.industry &&
    organization.data.processingThreshold !== ''
  );

  const canSubmit = waveToggle
    ? canSubmitOrganization && canSubmitWave
    : canSubmitOrganization;

  useEffect(() => {
    if (isEditing) {
      fetchOrganization(organization, setOrganization, siteName);
    }
  }, [isEditing, organization, siteName]);

  const redirect = (organization.error || success) && (
    <Redirect to={paths.ORGANIZATIONS} />
  );

  return (
    <>
      {redirect}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">
            {isEditing ? 'Edit Organization' : 'New Organization'}
          </h1>
        </div>
      </section>
      <section className="section is-main-section">
        {isEditing && !organization.fetched ? (
          <ClipLoader />
        ) : (
          <>
            <form onSubmit={onSubmitHandler}>
              <div className="tile is-ancestor">
                <OrganizationForm
                  organization={organization.data}
                  validName={validName}
                  validSiteName={validSiteName}
                  setOrganization={setOrganization}
                  onSelectChangeHandler={onSelectChangeHandler}
                  onChangeHandler={onChangeHandler}
                  canSubmit={canSubmit}
                />
                {!isEditing && (
                  <div className="tile is-parent">
                    <div className="card tile is-child">
                      <header className="card-header">
                        <p className="card-header-title">
                          <span className="icon">
                            <i className="mdi mdi-waves default" />
                          </span>
                          Wave Creation
                          <Toggle
                            toggled={waveToggle}
                            onToggle={setWaveToggle}
                            toggledText=""
                            unToggledText=""
                          />
                        </p>
                      </header>
                      <WaveForm
                        isEmbedded
                        waveToggle={waveToggle}
                        wave={wave}
                        setWave={setWave}
                        validUntilDate={validUntilDate}
                      />
                    </div>
                  </div>
                )}
              </div>
              {isEditing && (
                <div className="tile is-ancestor">
                  <WaveHierarchyForm organization={organization.data} />
                </div>
              )}
              {isEditing && (
                <div className="tile is-ancestor">
                  <OrganizationLayout />
                </div>
              )}
            </form>
          </>
        )}
      </section>
    </>
  );
};

export default Organization;
