import React from 'react';
import PropTypes from 'prop-types';

const TextBox = ({ content }) => (
  <div id="content-placeholder" className="content is-medium">
    {content}
  </div>
);

TextBox.propTypes = {
  content: PropTypes.string.isRequired,
};

export default TextBox;
