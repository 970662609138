import React from 'react';

import { useFormatMessage } from 'hooks';
import NewModal from 'components/NewModal';

import classes from './ConfirmNextPageModal.module.scss';

const ConfirmNextPageModal = ({ isOpen, toggle, onSubmit }) => {
  const modalContent = useFormatMessage('LiveSurvey.nextPageWarningModal');
  const modalConfirmationButton = useFormatMessage('LiveSurvey.nextPageWarningModalConfirmationButton');

  return (
    <NewModal
      isOpen={isOpen}
      toggle={toggle}
      showSubmitButton
      showHeader={false}
      submitButtonProps={{
        onSubmit,
        canSubmit: true,
        loading: false,
        buttonText: modalConfirmationButton,
      }}
    >
      <div>
        <p className={classes.text}>{modalContent}</p>
      </div>
    </NewModal>
  );
};

export default ConfirmNextPageModal;
