import React from 'react';

const SectionSaveOption = ({ hit, components }) => {
  return (
    <a href={hit.url} className="aa-ItemLink">
      <div className="aa-ItemContent">
        <div className='aa-ItemContentBody"'>
          <div className="aa-ItemTitle">
            <components.Highlight hit={hit} attribute="sectionIdentifier" />
          </div>
          <div className="aa-ItemContentDescription">
            <components.Snippet hit={hit} attribute="description" />
          </div>
        </div>
      </div>
    </a>
  );
};

const getItemInputValue = ({ item }) => {
  return item.objectID;
};

export { getItemInputValue };

export default SectionSaveOption;
