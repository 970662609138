import React from 'react';

const QuestionContent = ({ hit, components }) => {
  return (
    <a href={hit.url} className="aa-ItemLink">
      <div className="aa-ItemContent">
        <div className="aa-ItemTitle">
          <components.Highlight hit={hit} attribute="content" />
        </div>
      </div>
    </a>
  );
};

const getItemInputValue = ({ item }) => {
  return item.content;
};

export { getItemInputValue };

export default QuestionContent;
