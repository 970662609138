import { createReducer } from 'redux-act';

import {
  TAGS_FETCH_DATA_INIT,
  TAGS_FETCH_DATA_SUCCESS,
  TAGS_FETCH_DATA_FAIL,
  TAGS_CREATE_TAG_INIT,
  TAGS_CREATE_TAG_SUCCESS,
  TAGS_CREATE_TAG_FAIL,
  TAGS_CLEAR_CREATE_TAG_STATE,
  TAGS_MODIFY_TAG_INIT,
  TAGS_MODIFY_TAG_SUCCESS,
  TAGS_MODIFY_TAG_FAIL,
  TAGS_DELETE_TAG_INIT,
  TAGS_DELETE_TAG_SUCCESS,
  TAGS_DELETE_TAG_FAIL,
  TAGS_CLEAR_DATA,
  TAGS_REMOVE_TAG,
  TAGS_ADD_TAG,
  TAGS_FETCH_RELATED_DATA_INIT,
  TAGS_FETCH_RELATED_DATA_SUCCESS,
  TAGS_FETCH_RELATED_DATA_FAIL,
} from 'state/actionCreators/tags';

export const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleting: false,
  deleted: false,
  fetchingTags: false,
  fetchingRelatedData: false,
  errorFetchingRelatedData: null,
  relatedData: [],
  successFetchingTags: false,
  errorFetchingTags: null,
  creatingTag: false,
  successCreatingTag: false,
  errorCreatingTag: null,
  createdTag: null,
};

export const tagsReducer = createReducer(
  {
    [TAGS_FETCH_DATA_INIT]: (state) => ({
      ...state,
      fetchingTags: true,
    }),
    [TAGS_FETCH_DATA_SUCCESS]: (state, { tags }) => ({
      ...state,
      data: tags,
      fetchingTags: false,
      successFetchingTags: true,
      errorFetchingTags: null,
    }),
    [TAGS_FETCH_DATA_FAIL]: (state, { error }) => ({
      ...state,
      errorFetchingTags: error,
      fetchingTags: false,
      successFetchingTags: false,
    }),
    [TAGS_FETCH_RELATED_DATA_INIT]: (state) => ({
      ...state,
      fetchingRelatedData: true,
    }),
    [TAGS_FETCH_RELATED_DATA_SUCCESS]: (state, { relatedData }) => ({
      ...state,
      fetchingRelatedData: false,
      errorFetchingRelatedData: null,
      relatedData,
    }),
    [TAGS_FETCH_RELATED_DATA_FAIL]: (state, { error }) => ({
      ...state,
      fetchingRelatedData: false,
      errorFetchingRelatedData: error,
    }),
    [TAGS_CREATE_TAG_INIT]: (state) => ({
      ...state,
      creatingTag: true,
    }),
    [TAGS_CREATE_TAG_SUCCESS]: (state, { tag }) => ({
      ...state,
      creatingTag: false,
      errorCreatingTag: null,
      successCreatingTag: true,
      createdTag: tag,
    }),
    [TAGS_CREATE_TAG_FAIL]: (state, { error }) => ({
      ...state,
      creatingTag: false,
      errorCreatingTag: error,
      successCreatingTag: false,
      createdTag: null,
    }),
    [TAGS_CLEAR_CREATE_TAG_STATE]: (state) => ({
      ...state,
      creatingTag: false,
      errorCreatingTag: null,
      successCreatingTag: false,
      createdTag: null,
    }),
    [TAGS_MODIFY_TAG_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [TAGS_MODIFY_TAG_SUCCESS]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
    }),
    [TAGS_MODIFY_TAG_FAIL]: (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
    [TAGS_DELETE_TAG_INIT]: (state) => ({
      ...state,
      deleting: true,
    }),
    [TAGS_DELETE_TAG_SUCCESS]: (state) => ({
      ...state,
      deleting: false,
      deleted: true,
      error: null,
    }),
    [TAGS_DELETE_TAG_FAIL]: (state, { error }) => ({
      ...state,
      deleting: false,
      error,
    }),
    [TAGS_CLEAR_DATA]: () => initialState,
    [TAGS_REMOVE_TAG]: (state, { tag: payloadTag }) => ({
      ...state,
      data: state.data.filter((tag) => tag.id !== payloadTag.id),
    }),
    [TAGS_ADD_TAG]: (state, payload) => ({
      ...state,
      data: state.data.concat([payload.tag]),
    }),
  },
  initialState
);
