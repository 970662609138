/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import classes from './Translate.module.scss';

const Translate = ({
  translationCode,
  translation,
  defaultTranslation,
  onUpdate,
}) =>
  translation.map((field, index) => (
    <div key={`${translationCode}.${index}`} className="field">
      <div className="field-body">
        <div className="field">
          {index === 0 && (
            <label className={classNames('label', classes.title)}>
              Original
            </label>
          )}
          <div className="control is-expanded">
            <input
              id="default-translation-field"
              className="input"
              type="text"
              value={defaultTranslation[index]}
              disabled
            />
          </div>
        </div>
        <div className="field">
          {index === 0 && (
            <label className={classNames('label', classes.title)}>
              Translation
            </label>
          )}
          <div className="control is-expanded">
            <input
              id="translation-field"
              className="input"
              type="text"
              placeholder="Input translation..."
              value={field}
              onChange={(e) => {
                const { value } = e.target;
                onUpdate({
                  code: translationCode,
                  id: index,
                  value,
                  isDataItem: index > 0,
                });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  ));

Translate.propTypes = {
  translationCode: PropTypes.string.isRequired,
  translation: PropTypes.arrayOf(PropTypes.string).isRequired,
  defaultTranslation: PropTypes.arrayOf(PropTypes.string).isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default Translate;
