import { createReducer } from 'redux-act';

import {
  QUESTION_CHOICES_FETCH_DATA_INIT,
  QUESTION_CHOICES_FETCH_DATA_SUCCESS,
  QUESTION_CHOICES_FETCH_DATA_FAIL,
  QUESTION_CHOICES_CLEAR_STATE,
} from '../../actions/questionChoices';

export const initialState = {
  data: [],
  loading: false,
  error: null,
  fetchedQuestionChoices: false,
};

export const questionChoicesReducer = createReducer(
  {
    [QUESTION_CHOICES_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [QUESTION_CHOICES_FETCH_DATA_SUCCESS]: (state, { questionChoices }) => ({
      ...state,
      data: questionChoices,
      loading: false,
      error: null,
      fetchedQuestionChoices: true,
    }),
    [QUESTION_CHOICES_FETCH_DATA_FAIL]: (state, { error }) => ({
      ...state,
      error,
      loading: false,
    }),
    [QUESTION_CHOICES_CLEAR_STATE]: () => initialState,
  },
  initialState
);
