export const {
  REACT_APP_ALGOLIA_TAGS_INDEX,
  REACT_APP_ALGOLIA_TAGS_CREATED_AT_ASC_INDEX,
  REACT_APP_ALGOLIA_TAGS_CREATED_AT_DESC_INDEX,
  REACT_APP_ALGOLIA_DATA_SETS_INDEX,
  REACT_APP_ALGOLIA_DATA_SETS_TAG_DISPLAY_NAME_ASC_INDEX,
  REACT_APP_ALGOLIA_DATA_SETS_TAG_DISPLAY_NAME_DESC_INDEX,
  REACT_APP_ALGOLIA_DATA_SETS_CREATED_AT_ASC_INDEX,
  REACT_APP_ALGOLIA_DATA_SETS_CREATED_AT_DESC_INDEX,
  REACT_APP_ALGOLIA_USERS_INDEX,
  REACT_APP_ALGOLIA_USERS_CREATED_AT_ASC_INDEX,
  REACT_APP_ALGOLIA_USERS_CREATED_AT_DESC_INDEX,
  REACT_APP_ALGOLIA_SURVEYS_INDEX,
  REACT_APP_ALGOLIA_SURVEYS_CREATED_AT_ASC_INDEX,
  REACT_APP_ALGOLIA_SURVEYS_CREATED_AT_DESC_INDEX,
  REACT_APP_ALGOLIA_SURVEYS_TITLE_ASC_INDEX,
  REACT_APP_ALGOLIA_SURVEYS_TITLE_DESC_INDEX,
  REACT_APP_ALGOLIA_WAVES_INDEX,
  REACT_APP_ALGOLIA_WAVES_WAVE_INDEX_ASC_INDEX,
  REACT_APP_ALGOLIA_WAVES_WAVE_INDEX_DESC_INDEX,
  REACT_APP_ALGOLIA_GROUPS_INDEX,
  REACT_APP_ALGOLIA_INDUSTRIES_INDEX,
  REACT_APP_ALGOLIA_QUESTIONS_CONTENT_INDEX,
  REACT_APP_ALGOLIA_SECTIONS_SAVES_INDEX,
} = JSON.parse(process.env.REACT_APP_ALGOLIA_INDEXES);
