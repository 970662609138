import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';
import algoliasearch from 'algoliasearch';

import { REACT_APP_ALGOLIA_GROUPS_INDEX } from 'constants/environment';
import firebase from 'firebase.js';
import { firebaseError } from 'utils';

export const GROUPS_FETCH_DATA_INIT = createAction('GROUPS_FETCH_DATA_INIT');
export const GROUPS_FETCH_DATA_FAIL = createAction('GROUPS_FETCH_DATA_FAIL');
export const GROUPS_FETCH_DATA_SUCCESS = createAction(
  'GROUPS_FETCH_DATA_SUCCESS'
);
export const GROUPS_CREATE_GROUP_INIT = createAction(
  'GROUPS_CREATE_GROUP_INIT'
);
export const GROUPS_CREATE_GROUP_SUCCESS = createAction(
  'GROUPS_CREATE_GROUP_SUCCESS'
);
export const GROUPS_CREATE_GROUP_FAIL = createAction(
  'GROUPS_CREATE_GROUP_FAIL'
);
export const GROUPS_MODIFY_GROUP_INIT = createAction(
  'GROUPS_MODIFY_GROUP_INIT'
);
export const GROUPS_MODIFY_GROUP_SUCCESS = createAction(
  'GROUPS_MODIFY_GROUP_SUCCESS'
);
export const GROUPS_MODIFY_GROUP_FAIL = createAction(
  'GROUPS_MODIFY_GROUP_FAIL'
);
export const GROUPS_DELETE_GROUP_INIT = createAction(
  'GROUPS_DELETE_GROUP_INIT'
);
export const GROUPS_DELETE_GROUP_SUCCESS = createAction(
  'GROUPS_DELETE_GROUP_SUCCESS'
);
export const GROUPS_DELETE_GROUP_FAIL = createAction(
  'GROUPS_DELETE_GROUP_FAIL'
);
export const GROUPS_CLEAR_DATA = createAction('GROUPS_CLEAR_DATA');
export const GROUPS_CLEAN_UP = createAction('GROUPS_CLEAN_UP');

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_ADMIN_KEY
);
const indexGroups = searchClient.initIndex(
  REACT_APP_ALGOLIA_GROUPS_INDEX
);

export const fetchGroups = (filterInactives, filterByOrganization) => {
  return async (dispatch) => {
    dispatch(GROUPS_FETCH_DATA_INIT());

    const groups = [];

    try {
      let baseQuery = firebase.firestore().collection('groups');

      if (filterInactives) {
        baseQuery = baseQuery.where('active', '==', true);
      }
      if (filterByOrganization) {
        baseQuery = baseQuery.where(
          'organizationName',
          '==',
          filterByOrganization
        );
      }

      const queryResult = await baseQuery.get();

      queryResult.forEach((group) => {
        const groupData = { id: group.id, ...group.data() };
        groups.push({ ...groupData, createdAt: groupData.createdAt.toDate() });
      });
    } catch (error) {
      toastr.error(error.message);
      return dispatch(GROUPS_FETCH_DATA_FAIL({ error }));
    }

    return dispatch(
      GROUPS_FETCH_DATA_SUCCESS({
        groups,
      })
    );
  };
};

const checkDuplicatedProperties = async ({ name, organizationName, id }) => {
  const duplicateName = await firebase
    .firestore()
    .collection('groups')
    .where('name', '==', name)
    .where('organizationName', '==', organizationName)
    .get();

  if (!duplicateName.empty && duplicateName.docs[0].id !== id) {
    return 'name';
  }
  return '';
};

export const createGroup = ({
  organizationName,
  displayName,
  description,
  manager,
  active,
  expectedCount,
  waves,
}) => {
  return async (dispatch, getState) => {
    dispatch(GROUPS_CREATE_GROUP_INIT());

    const { locale } = getState().preferences;
    const { userData } = getState().auth;

    const waveData = [];
    waves.forEach((wave) => {
      waveData.push(wave.value.id);
    });

    const group = {
      organizationName,
      name: displayName.toLowerCase().trim(),
      displayName: displayName.trim(),
      description,
      manager,
      active,
      expectedCount,
      usersCount: 0,
      waves: waveData,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      createdBy: `${userData.firstName} ${userData.lastName}`,
    };

    let duplicatedProperty;

    try {
      duplicatedProperty = await checkDuplicatedProperties(group);

      if (!duplicatedProperty) {
        const { id } = await firebase
          .firestore()
          .collection('groups')
          .add(group);

        await indexGroups
          .saveObject({
            objectID: id,
            ...group,
            createdAt: +new Date(),
          })
          .wait();

        toastr.success('', 'Group created successfully');
        return dispatch(GROUPS_CREATE_GROUP_SUCCESS());
      }
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        GROUPS_CREATE_GROUP_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.error(
      '',
      `Group ${duplicatedProperty} already exists in organization ${group.organizationName}!`
    );
    return dispatch(
      GROUPS_CREATE_GROUP_FAIL({
        error: `Group ${duplicatedProperty} already exists in organization ${group.organizationName}!`,
      })
    );
  };
};

export const modifyGroup = ({
  organizationName,
  displayName,
  description,
  manager,
  active,
  expectedCount,
  id,
  waves,
  usersCount,
}) => {
  return async (dispatch, getState) => {
    dispatch(GROUPS_MODIFY_GROUP_INIT());

    const { locale } = getState().preferences;

    const waveData = [];
    waves.forEach((wave) => {
      waveData.push(wave.value.id);
    });

    const groupData = {
      organizationName,
      name: displayName.toLowerCase().trim(),
      displayName,
      description,
      manager,
      active,
      expectedCount,
      usersCount,
      waves: waveData,
    };

    let duplicatedProperty;

    const updateGroupInDb = firebase
      .firestore()
      .collection('groups')
      .doc(id)
      .update(groupData);

    const updateGroupInAlgolia = indexGroups
      .partialUpdateObject({
        objectID: id,
        ...groupData,
      })
      .wait();

    try {
      duplicatedProperty = await checkDuplicatedProperties({
        ...groupData,
        id,
      });
      if (!duplicatedProperty) {
        await Promise.all([updateGroupInDb, updateGroupInAlgolia]);

        toastr.success('', 'Group updated successfully');

        return dispatch(
          GROUPS_MODIFY_GROUP_SUCCESS({
            group: { ...groupData, id },
          })
        );
      }
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        GROUPS_MODIFY_GROUP_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.error(
      '',
      `Group ${duplicatedProperty} already exists in organization ${groupData.organizationName}!`
    );
    return dispatch(
      GROUPS_MODIFY_GROUP_FAIL({
        error: `Group ${duplicatedProperty} already exists in organization ${groupData.organizationName}!`,
      })
    );
  };
};

export const deleteGroup = (id) => {
  return async (dispatch, getState) => {
    dispatch(GROUPS_DELETE_GROUP_INIT());

    const { locale } = getState().preferences;

    const deleteGroupFromDb = firebase
      .firestore()
      .collection('groups')
      .doc(id)
      .delete();

    const deleteGroupFromAlgolia = indexGroups.deleteObject(id).wait();

    try {
      await Promise.all([deleteGroupFromDb, deleteGroupFromAlgolia]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        GROUPS_DELETE_GROUP_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'The group was deleted.');
    return dispatch(GROUPS_DELETE_GROUP_SUCCESS({ id }));
  };
};

export const clearGroupsData = () => {
  return (dispatch) => {
    dispatch(GROUPS_CLEAR_DATA());
  };
};

export const groupsCleanUp = () => (dispatch) => dispatch(GROUPS_CLEAN_UP());
