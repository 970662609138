/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Select from 'react-select';

import paths from 'pages/Router/paths';
import { usersCleanUp } from 'state/actions/users';
import {
  useChangeHandler,
  useFormatMessage,
  useSelectChangeHandler,
} from 'hooks';
import { validateEmail, rolesList, roles } from 'utils';
import { fetchOrganizations, fetchGroups } from 'utils/fetching';
import './UserForm.scss';

const { ADMIN, RESPONDENT } = roles;

const UserForm = ({ isEditing, isProfile, userData, action }) => {
  const { loading, defaultOrganization } = useSelector(
    (state) => ({
      loading: state.users.loading,
      success: state.users.success,
      defaultOrganization: state.preferences.defaultOrganization,
    }),
    shallowEqual
  );

  const [organizations, setOrganizations] = useState({
    data: [],
    loading: false,
    fetched: false,
  });
  const [groups, setGroups] = useState({
    data: [],
    loading: false,
    fetched: false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(usersCleanUp());
  }, [dispatch]);

  const [user, setUser] = useState(userData);

  const onChangeHandler = useChangeHandler(setUser);

  const onSelectChangeHandler = useSelectChangeHandler(setUser);

  useEffect(() => {
    if (user.role?.value !== ADMIN || !isEditing) {
      const filterInactives = true;
      fetchOrganizations(setOrganizations, filterInactives);
    }
  }, [user.role, isEditing]);

  useEffect(() => {
    if (user.organization) {
      const filterInactives = true;
      const filterByOrganization = user.organization.value.displayName;
      fetchGroups(groups, setGroups, filterInactives, filterByOrganization);
    }
  }, [user.organization, groups]);

  const onFileChangedHandler = (event) => {
    const file = event.target.files[0];
    setUser((prevState) => ({ ...prevState, file, logoUrl: null }));
  };

  const clearGroups = () => {
    setUser((prevState) => ({
      ...prevState,
      groups: [],
    }));
    setGroups((prevState) => ({ ...prevState, data: [], fetched: false }));
  };

  useEffect(() => {
    if (!isEditing && defaultOrganization) {
      setUser((prevState) => ({
        ...prevState,
        organization: defaultOrganization,
      }));
      clearGroups();
    }
  }, [defaultOrganization, isEditing]);

  const onSubmitHandler = (event) => {
    event.preventDefault();
    const groupsData = user.groups
      ? user.groups.map((group) => group.value)
      : [];
    dispatch(
      action({
        ...user,
        organizationId: user.organization?.value.id || '',
        organizationName: user.organization?.value.displayName || '',
        isEditing,
        isProfile,
        email: user.email || null,
        groups: groupsData,
        role: user.role.value,
        roleBefore: userData.role?.value,
        emailBefore: userData.emailBefore || null,
      })
    );
  };

  let emailInput = {
    modifier: null,
    message: { modifier: null, content: null },
  };

  const invalidEmail = user.email && !validateEmail(user.email);

  const invalidEmailMessage = useFormatMessage('UserForm.invalidEmail');

  if (invalidEmail) {
    emailInput = {
      modifier: 'is-danger',
      message: {
        modifier: 'is-danger',
        content: invalidEmailMessage,
      },
    };
  }

  const organizationNameIsMandatory =
    user.role?.value === ADMIN ? !user.organization : user.organization;

  const emailCorrect = user.email || user.role?.value === RESPONDENT;

  const canSubmit =
    user.firstName &&
    user.lastName &&
    user.role &&
    emailCorrect &&
    !invalidEmail &&
    organizationNameIsMandatory;

  const goBackMessage = useFormatMessage('UserForm.goBack');
  const pickAnotherFileMessage = useFormatMessage('UserForm.pickAnotherFile');
  const pickFileMessage = useFormatMessage('UserForm.pickFile');
  const emailMessage = useFormatMessage('UserForm.email');

  const clearOrganizationAndGroups = (userRole) => {
    if (userRole === ADMIN) {
      setUser((prevState) => ({
        ...prevState,
        organization: null,
        groups: [],
      }));
      setGroups((prevState) => ({ ...prevState, data: [], fetched: false }));
    }
  };

  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account-edit default" />
                </span>
                {useFormatMessage('UserForm.userInfo')}
              </p>
            </header>
            <div className="card-content">
              <form onSubmit={onSubmitHandler}>
                <div className="card-content columns">
                  <div className="column">
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">First name</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              id="firstName"
                              className="input"
                              type="text"
                              required
                              name="firstName"
                              value={user.firstName}
                              onChange={onChangeHandler}
                            />
                          </div>
                          <small>The user&apos;s given first name.</small>
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">Last name</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              className="input"
                              type="text"
                              name="lastName"
                              required
                              value={user.lastName}
                              onChange={onChangeHandler}
                            />
                          </div>
                          <small>The user&apos;s given last name.</small>
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">{emailMessage}</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            {isEditing && user.emailBefore ? (
                              <input
                                type="text"
                                readOnly="readOnly"
                                className="input is-static"
                                value={user.email}
                              />
                            ) : (
                              <input
                                className={`input ${emailInput.modifier}`}
                                type="email"
                                name="email"
                                value={user.email}
                                onChange={onChangeHandler}
                              />
                            )}
                          </div>
                          <small>
                            The user&apos;s email address. This will be used to
                            identify the user in the system. Email must be
                            unique in the system.
                          </small>
                        </div>
                      </div>
                    </div>

                    <div className="field has-check is-horizontal">
                      <div className="field-label">
                        <label className="label">Active</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="field">
                            <div className="control">
                              <label className="b-checkbox checkbox">
                                <input
                                  type="checkbox"
                                  name="active"
                                  onChange={onChangeHandler}
                                  checked={user.active}
                                />
                                <span className="check is-primary" />
                              </label>
                            </div>
                            <small>
                              Specify wether this user is active or archived.
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="field has-check is-horizontal">
                      <div className="field-label">
                        <label className="label">Manager</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="field">
                            <div className="control">
                              <label className="b-checkbox checkbox">
                                <input
                                  type="checkbox"
                                  name="manager"
                                  onChange={onChangeHandler}
                                  checked={user.manager}
                                />
                                <span className="check is-primary" />
                              </label>
                            </div>
                            <small>
                              Specify wether this user acts as the
                              supervisor/manager of other users.
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="column">
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">Role</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <Select
                              classNamePrefix="select"
                              options={rolesList}
                              isClearable
                              onChange={(select) => {
                                onSelectChangeHandler('role', select);
                                clearOrganizationAndGroups(select?.value);
                              }}
                              value={user.role}
                            />
                            <span className="check is-primary" />
                          </div>
                          <small>
                            System role for the user. A user can only have a
                            single role at a time.
                          </small>
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">Organization</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <Select
                              isDisabled={
                                organizations.loading ||
                                user.role?.value === ADMIN
                              }
                              isLoading={organizations.loading}
                              options={organizations.data}
                              onChange={(select) => {
                                onSelectChangeHandler('organization', select);
                                if (select?.value !== user.organization)
                                  clearGroups();
                              }}
                              value={user.organization}
                            />
                            <span className="check is-primary" />
                          </div>
                          <small>
                            Organization that the user belongs to. (Mandatory)
                            <br /> Admins do not have any organization.
                          </small>
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">Groups</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="field">
                            <div className="control">
                              <Select
                                isDisabled={
                                  groups.loading ||
                                  user.role === ADMIN ||
                                  !user.organization
                                }
                                isLoading={groups.loading}
                                isClearable
                                isMulti
                                noOptionsMessage={() =>
                                  'The organization does not have groups'
                                }
                                className="basic-multi-select"
                                classNamePrefix="select"
                                options={groups.data}
                                placeholder=""
                                onChange={(select) => {
                                  onSelectChangeHandler('groups', select);
                                }}
                                value={user.groups}
                              />
                              <span className="check is-primary" />
                            </div>
                            <small>
                              Groups that the user belongs to. (Optional)
                              <br />
                              Admins do not belong to any groups.
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">Logo</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="file has-name">
                            <label className="file-label">
                              <input
                                className="file-input"
                                type="file"
                                accept="image/*"
                                onChange={onFileChangedHandler}
                              />
                              <span className="file-cta">
                                <span className="file-icon">
                                  <i className="fas fa-upload" />
                                </span>
                                <span className="file-label">
                                  {user.file
                                    ? pickAnotherFileMessage
                                    : pickFileMessage}
                                </span>
                              </span>
                              <span className="file-name">
                                {user.file && user.file.name}
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <hr />
                <div className="field is-horizontal">
                  <div className="field-label" />
                  <div className="field-body">
                    <div className="field">
                      <div className="field is-grouped">
                        <div className="control">
                          <button
                            type="submit"
                            className={`button is-primary ${
                              loading && 'is-loading'
                            }`}
                            disabled={!canSubmit}
                          >
                            <span>{useFormatMessage('UserForm.submit')}</span>
                          </button>
                        </div>
                        {!isProfile && (
                          <Link to={paths.USERS} className="button">
                            {goBackMessage}
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

UserForm.propTypes = {
  isEditing: PropTypes.bool,
  userData: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    manager: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
    organization: PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.shape({
        displayName: PropTypes.string.isRequired,
      }),
    }),
    groups: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ).isRequired,
  }),
  action: PropTypes.func.isRequired,
};

export default UserForm;
