import { QuestionType } from 'utils/surveyBuilder/enums';

export const useChangeInputHandler = (setState) => {
  const onChangeInputHandler = (event) => {
    const { name, value, type } = event.target;
    const questionId = event.target.getAttribute?.('data-questionid');
    const dataSetName = event.target.getAttribute?.('data-datasetname');

    const setAllRadioOptionsToFalse = (state) => {
      const prevChoices = questionId ? state[questionId].choices : state[name].choices;

      const newChoices = {
        ...prevChoices,
      };

      if (dataSetName) {
        Object.keys(prevChoices[dataSetName]).forEach((key) => {
          newChoices[dataSetName][key] = false;
        });

        return newChoices;
      }

      Object.keys(prevChoices).forEach((key) => {
        newChoices[key] = false;
      });

      return newChoices;
    };

    const emptyAllOpenEndedAnswers = (state) => {
      const { openEndedChoiceAnswers } = questionId ? state[questionId] : state[name];
      Object.keys(openEndedChoiceAnswers).forEach((key) => {
        openEndedChoiceAnswers[key] = '';
      });
    };

    if (type === 'checkbox') {
      const { checked } = event.target;

      return setState((prevState) => {
        if (!checked) {
          const { openEndedChoiceAnswers } = prevState[name];
          openEndedChoiceAnswers[value] = '';
        }

        return {
          ...prevState,
          [name]: {
            ...prevState[name],
            choices: {
              ...prevState[name].choices,
              [value]: checked,
            },
            openAnswer: '',
          },
        };
      });
    }

    if (type === 'radio') {
      const { checked } = event.target;

      return setState((prevState) => {
        const newChoices = setAllRadioOptionsToFalse(prevState);
        emptyAllOpenEndedAnswers(prevState);

        if (dataSetName) {
          return {
            ...prevState,
            [questionId]: {
              ...prevState[questionId],
              choices: {
                ...newChoices,
                [dataSetName]: {
                  ...newChoices[dataSetName],
                  [value]: checked,
                },
              },
              openAnswer: '',
            },
          };
        }

        return {
          ...prevState,
          [name]: {
            ...prevState[name],
            choices: {
              ...newChoices,
              [value]: checked,
            },
            openAnswer: '',
          },
        };
      });
    }

    if (type === QuestionType.DROPDOWN) {
      return setState((prevState) => {
        const newChoices = setAllRadioOptionsToFalse(prevState);

        if (value) {
          return {
            ...prevState,
            [name]: {
              ...prevState[name],
              choices: {
                ...newChoices,
                [value]: true,
              },
            },
          };
        }

        return { ...prevState };
      });
    }

    if (type === 'textarea') {

      return setState((prevState) => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          openAnswer: value,
        },
      }));
    }

    if (type === 'open-ended') {
      const { choiceId } = event.target;

      return setState((prevState) => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          openEndedChoiceAnswers: {
            ...prevState[name].openEndedChoiceAnswers,
            [choiceId]: value,
          },
        },
      }));
    }

    if (type === QuestionType.SLIDER) {
      const { min, max, step, defaultValue } = event.target;

      return setState((prevState) => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          sliderProps: {
            ...(prevState[name]?.sliderProps ?? {}),
            value: value[0],
            defaultValue,
            step,
            min,
            max,
          },
        },
      }));
    }

    if (type === QuestionType.RANK_ORDER) {
      return setState((prevState) => {
        const { choices } = prevState[name];
        const { rank, choiceId } = value;

        Object.entries(choices).forEach(([id, choiceValue]) => {
          if (rank === choiceValue) {
            choices[id] = null;
          }
        });

        return {
          ...prevState,
          [name]: {
            ...prevState[name],
            choices: {
              ...choices,
              [choiceId]: rank,
            },
          },
        };
      });
    }

    if (type === QuestionType.TEXT_AREA) {
      const { singleLine } = event.target;

      return setState((prevState) => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          singleLine: !!singleLine,
          openAnswer: value,
        },
      }));
    }

    return setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return onChangeInputHandler;
};
