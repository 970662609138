import React from 'react';
import PropTypes from 'prop-types';

import { SurveyTableClickableColumn } from 'utils/surveyBuilder/enums';
import Version from './Version';
import Deployment from './Deployment';

import './SurveyDetails.scss';

const SurveyDetails = ({
  index,
  surveyName,
  surveyId,
  versions,
  deployments,
  surveyType,
  columnClicked,
  onUseVersion,
  onDeleteVersion,
  onDeleteDeployment,
  onTriggerAnalytics,
}) => (
  <>
    {columnClicked[index] === SurveyTableClickableColumn.VERSIONS && (
      <div className="columns is-multiline" style={{ width: '100%' }}>
        <div className="column is-full">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <p className="subtitle">
                  <strong>{`${surveyName} Versions`}</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="column is-full">
          {versions.map(({ id, name, status, createdAt }) => (
            <Version
              key={id}
              surveyName={surveyName}
              versionId={id}
              surveyId={surveyId}
              name={name}
              status={status}
              createdAt={createdAt}
              surveyType={surveyType}
              onUse={onUseVersion}
              onDelete={onDeleteVersion}
            />
          ))}
        </div>
      </div>
    )}
    {columnClicked[index] === SurveyTableClickableColumn.DEPLOYMENTS && (
      <div className="columns is-multiline" style={{ width: '100%' }}>
        <div className="column is-full">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <p className="subtitle">
                  <strong>{`${surveyName} Deployments`}</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="column is-full">
          <div className="columns is-vcentered is-multiline">
            <div className="column deployment-attribute__title--desktop ">
              <strong>Status</strong>
            </div>
            <div className="column deployment-attribute__title--desktop ">
              <strong>Wave index</strong>
            </div>
            <div className="column deployment-attribute__title--desktop ">
              <strong>Wave name</strong>
            </div>
            <div className="column deployment-attribute__title--desktop ">
              <strong>Version</strong>
            </div>
            <div className="column deployment-attribute__title--desktop ">
              <strong>Unprocessed</strong>
            </div>
            <div className="column deployment-attribute__title--desktop ">
              <strong>Processed</strong>
            </div>
            <div className="column deployment-attribute__title--desktop ">
              <strong>Start date</strong>
            </div>
            <div className="column deployment-attribute__title--desktop ">
              <strong>End date</strong>
            </div>
            <div className="column deployment-attribute__title--desktop ">
              <strong>Last time processed</strong>
            </div>
            <div className="column deployment-attribute__title--desktop ">
              <strong>Created at</strong>
            </div>
            <div className="column deployment-attribute__title--desktop ">
              <strong>Created by</strong>
            </div>
            <div className="column deployment-attribute__title--desktop ">
              <strong>Options</strong>
            </div>
          </div>
          {deployments.map(
            ({
              id,
              url,
              waveIndex,
              waveName,
              waveId,
              waveStatus,
              versionName,
              versionId,
              startDate,
              endDate,
              createdAt,
              createdBy,
              totalResponses,
              unprocessedResponses,
              lastTimeProcessed,
            }) => {
              return (
                <Deployment
                  deploymentId={id}
                  key={id}
                  surveyId={surveyId}
                  url={url}
                  waveIndex={waveIndex}
                  waveName={waveName}
                  waveId={waveId}
                  waveStatus={waveStatus}
                  versionName={versionName}
                  versionId={versionId}
                  startDate={startDate}
                  endDate={endDate}
                  createdAt={createdAt}
                  createdBy={createdBy}
                  totalResponses={totalResponses}
                  unprocessedResponses={unprocessedResponses}
                  lastTimeProcessed={lastTimeProcessed}
                  onTriggerAnalytics={onTriggerAnalytics}
                  onDelete={onDeleteDeployment}
                />
              );
            }
          )}
        </div>
      </div>
    )}
  </>
);

SurveyDetails.propTypes = {
  index: PropTypes.number.isRequired,
  surveyName: PropTypes.string.isRequired,
  surveyType: PropTypes.string.isRequired,
  versions: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  deployments: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

export default SurveyDetails;
