import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';

import { REACT_APP_ALGOLIA_WAVES_INDEX, REACT_APP_ALGOLIA_WAVES_WAVE_INDEX_ASC_INDEX, REACT_APP_ALGOLIA_WAVES_WAVE_INDEX_DESC_INDEX } from 'constants/environment';
import {
  reprocessResponses,
  reprocessResponsesClearState,
} from 'state/actions/reprocessResponses';
import { roles, initAlgoliaClient } from 'utils';
import { useFormatDate, useAlgoliaSearch } from 'hooks';
import { deleteWave, clearData } from 'state/actions/waves';
import { InstantSearch } from 'react-instantsearch-dom';
import {
  Filter,
  FilterOption,
  Menu,
  SortBy,
  SearchBox,
  Table,
} from 'components/Algolia';
import Modal from 'components/Modal';
import paths from 'pages/Router/paths';
import classes from './Waves.module.scss';

const searchClient = initAlgoliaClient();

const waveIndex = REACT_APP_ALGOLIA_WAVES_INDEX;
const waveIndexAsc = REACT_APP_ALGOLIA_WAVES_WAVE_INDEX_ASC_INDEX;
const waveIndexDesc = REACT_APP_ALGOLIA_WAVES_WAVE_INDEX_DESC_INDEX;

const { ADMIN } = roles;

const Waves = () => {
  const {
    role,
    loading,
    deleted,
    defaultOrganization,
    isReprocessingResponses,
  } = useSelector(
    (state) => ({
      loading: state.waves.loading,
      deleted: state.waves.deleted,
      role: state.auth.userData.role,
      defaultOrganization: state.preferences.defaultOrganization,
      isReprocessingResponses: state.reprocessResponses.loading,
    }),
    shallowEqual
  );

  const [deleteModal, setDeleteModal] = useState({
    waveId: null,
    isOpen: false,
  });

  const dispatch = useDispatch();

  const { search, refresh } = useAlgoliaSearch();

  useEffect(() => {
    if (deleted) {
      refresh();
      setDeleteModal((prevState) => ({
        waveId: null,
        open: !prevState.open,
      }));
      dispatch(clearData());
    }
  }, [deleted, dispatch, refresh]);

  useEffect(() => {
    return () => dispatch(reprocessResponsesClearState());
  }, [dispatch]);

  const redirect = useMemo(
    () => role !== ADMIN && <Redirect to={paths.ROOT} />,
    [role]
  );

  const onRemoveButtonClickHandler = useCallback((waveId) => {
    setDeleteModal((prevState) => ({
      waveId,
      isOpen: !prevState.isOpen,
    }));
  }, []);

  const onCloseModalHandler = useCallback(() => {
    setDeleteModal({ waveId: null, isOpen: false });
  }, []);

  const onDeleteWaveHandler = useCallback(() => {
    dispatch(deleteWave(deleteModal.waveId));
  }, [dispatch, deleteModal.waveId]);

  const columns = useMemo(
    () => [
      {
        Header: 'Index',
        accessor: 'index',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Period',
        accessor: 'startingOn',
        Cell: ({ row }) => {
          const untilDate = useFormatDate(row.original.until, {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
          });
          return (
            <div>
              <span>
                {useFormatDate(row.original.startingOn, {
                  year: '2-digit',
                  month: '2-digit',
                  day: '2-digit',
                })}
              </span>
              <span>{` - ${row.original.until ? untilDate : 'None'}`}</span>
            </div>
          );
        },
      },
      {
        Header: 'Organization',
        accessor: 'organizationName',
      },
      {
        Header: 'Responses',
        accessor: 'responses',
      },
      {
        Header: 'Expected',
        accessor: 'expectedResponses',
        Cell: ({ row }) => (
          <span>
            {row.original.expectedResponses === null
              ? '-'
              : row.original.expectedResponses}
          </span>
        ),
      },
      {
        Header: 'Processed',
        accessor: 'processed',
        Cell: ({ row }) => <span>{row.original.processed}</span>,
      },
      {
        Header: 'Invalid',
        accessor: 'invalid',
        Cell: ({ row }) => <span>{row.original.invalid}</span>,
      },
      {
        Header: 'Created at',
        accessor: 'createdAt',
        Cell: ({ row }) => (
          <small className="has-text-grey is-abbr-like">
            {useFormatDate(row.original.createdAt, {
              weekday: 'short',
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
          </small>
        ),
      },
      {
        Header: 'Created by',
        accessor: 'createdBy',
        Cell: ({ row }) => (
          <small className="has-text-grey is-abbr-like">
            {row.original.createdBy}
          </small>
        ),
      },
      {
        Header: '',
        id: 'actions',
        accessor: 'actions',
        Cell: ({ row }) => (
          <>
            {role === ADMIN && (
              <div className={classNames('buttons is-right', classes.actions)}>
                <Link
                  to={`${paths.WAVES}/${row.original.objectID}`}
                  className="button is-small is-primary"
                  data-tip="Update wave"
                >
                  <span className="icon is-small">
                    <i className="mdi mdi-account-edit" />
                  </span>
                </Link>

                <button
                  type="button"
                  className={classNames('button is-small is-info', {
                    'is-loading': isReprocessingResponses,
                  })}
                  data-tip="Reprocess responses"
                  disabled={isReprocessingResponses}
                  onClick={() =>
                    dispatch(reprocessResponses(row.original.objectID))
                  }
                >
                  <span className="icon is-small">
                    <i
                      className={classNames(
                        'mdi mdi-refresh',
                        classes['reprocess-icon']
                      )}
                    />
                  </span>
                </button>

                <button
                  type="button"
                  className="button is-small is-danger"
                  data-tip="Delete wave"
                  onClick={() =>
                    onRemoveButtonClickHandler(row.original.objectID)
                  }
                >
                  <span className="icon is-small">
                    <i className="mdi mdi-trash-can" />
                  </span>
                </button>
                <ReactTooltip />
              </div>
            )}
          </>
        ),
        disableSortBy: true,
      },
    ],
    [onRemoveButtonClickHandler, role]
  );

  return (
    <>
      {redirect}
      {deleteModal.isOpen && (
        <Modal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage="Delete"
          title="Confirm action"
          body="This will permanently delete the wave. Action cannot be undone."
          cancelButtonMessage="Cancel"
          onConfirmation={onDeleteWaveHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      <InstantSearch
        searchClient={searchClient}
        indexName={waveIndex}
        refresh={search.refresh}
      >
        <section className="hero is-hero-bar">
          <div className="hero-body">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <h1 className="title">Waves</h1>
                </div>
              </div>
              <div className="level-right">
                <div className="level-item">
                  <Link to={paths.ADD_WAVE} className="button">
                    New Wave
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section is-main-section">
          <Filter>
            <FilterOption label="Organization">
              <Menu
                predefinedRefinement={defaultOrganization?.value.displayName}
                predefinedOption={defaultOrganization}
                attribute="organizationName"
                emptyPlaceholder="No organizations"
                defaultPlaceholder="Select an organization"
                isSearchable
                isClearable
                limit={20}
              />
            </FilterOption>
            <FilterOption label="Sort by">
              <SortBy
                defaultRefinement={waveIndex}
                items={[
                  { value: waveIndex, label: 'Featured' },
                  { value: waveIndexAsc, label: 'Index asc.' },
                  { value: waveIndexDesc, label: 'Index desc.' },
                ]}
              />
            </FilterOption>
          </Filter>
          <div className="card has-table has-mobile-sort-spaced">
            <header className="card-header">
              <p className="card-header-title table-header">
                <span>Search</span>
                <SearchBox />
              </p>
            </header>
            <Table columns={columns} defaultPerPageOption={10} />
          </div>
        </section>
      </InstantSearch>
    </>
  );
};

export default Waves;
