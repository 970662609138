import React, { useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { InstantSearch, Configure } from 'react-instantsearch-dom';
import { Link, Redirect, useParams } from 'react-router-dom';
import classNames from 'classnames';

import { REACT_APP_ALGOLIA_TAGS_CREATED_AT_ASC_INDEX, REACT_APP_ALGOLIA_TAGS_CREATED_AT_DESC_INDEX, REACT_APP_ALGOLIA_TAGS_INDEX } from 'constants/environment';
import { useAlgoliaSearch, useFormatDate } from 'hooks';
import { deleteTag, clearData } from 'state/actions/tags';
import {
  Table,
  SearchBox,
  SortBy,
  Menu,
  ToggleRefinement,
  Filter,
  FilterOption,
} from 'components/Algolia';
import Modal from 'components/Modal';
import paths from 'pages/Router/paths';
import {
  getTypeFilter,
  validTagPath,
  roles,
  getCreateTagPath,
  tagPathToType,
  getModifyTagPath,
  initAlgoliaClient,
} from 'utils';
import classes from './Tags.module.scss';


const searchClient = initAlgoliaClient();

const index = REACT_APP_ALGOLIA_TAGS_INDEX;
const indexCreatedAtAsc = REACT_APP_ALGOLIA_TAGS_CREATED_AT_ASC_INDEX;
const indexCreatedAtDesc = REACT_APP_ALGOLIA_TAGS_CREATED_AT_DESC_INDEX;

const Tags = () => {
  const { type } = useParams();

  const { deleting, deleted, success, isAdmin, defaultOrganization, error } =
    useSelector(
      (state) => ({
        error: state.tags.error,
        deleting: state.tags.deleting,
        deleted: state.tags.deleted,
        success: state.tags.success,
        isAdmin: state.auth.userData.role === roles.ADMIN,
        defaultOrganization: state.preferences.defaultOrganization,
      }),
      shallowEqual
    );

  const dispatch = useDispatch();

  const [deleteModal, setDeleteModal] = React.useState({
    open: false,
    tagId: null,
  });
  const { search, refresh } = useAlgoliaSearch();

  useEffect(() => {
    if (deleted) {
      refresh();
      setDeleteModal((prevState) => ({
        tagId: null,
        open: !prevState.open,
      }));
      dispatch(clearData());
    }
  }, [deleted, dispatch, refresh]);

  useEffect(() => {
    if (success) {
      dispatch(clearData());
    }
  }, [success, dispatch]);

  useEffect(() => {
    if (error) {
      setDeleteModal((prevState) => ({
        tagId: null,
        open: !prevState.open,
      }));
      dispatch(clearData());
    }
  }, [error, dispatch]);

  const onDeleteTagHandler = useCallback(() => {
    dispatch(deleteTag(deleteModal.tagId));
  }, [dispatch, deleteModal.tagId]);

  const onRemoveButtonClickHandler = useCallback((tagId) => {
    setDeleteModal((prevState) => ({
      tagId,
      open: !prevState.isOpen,
    }));
  }, []);

  const onCloseModalHandler = useCallback(() => {
    setDeleteModal({ tagId: null, open: false });
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'displayName',
        Cell: ({ row }) => (
          <Link
            className={classes['table-name']}
            to={getModifyTagPath(type, row.original.objectID)}
          >
            {row.original.displayName}
          </Link>
        ),
      },
      {
        Header: 'Organization',
        accessor: 'organizationName',
        Cell: ({ row }) => (
          <>
            {row.original.organizationName === 'global'
              ? 'Global'
              : row.original.organizationName}
          </>
        ),
      },
      {
        Header: 'Active',
        accessor: 'active',
        Cell: ({ row }) => (
          <small className="has-text-grey is-abbr-like">
            {row.original.active ? (
              <span className="icon">
                <i className="mdi mdi-check" />
              </span>
            ) : (
              <span className="icon">
                <i className="mdi mdi-close" />
              </span>
            )}
          </small>
        ),
      },
      {
        Header: 'Created at',
        accessor: 'createdAt',
        Cell: ({ row }) => (
          <small className="has-text-grey is-abbr-like">
            {useFormatDate(row.original.createdAt, {
              weekday: 'short',
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
          </small>
        ),
      },
      {
        Header: 'Created by',
        accessor: 'createdBy',
        Cell: ({ row }) => (
          <small className="has-text-grey is-abbr-like">
            {row.original.createdBy}
          </small>
        ),
      },
      {
        Header: '',
        accessor: 'actions',
        Cell: ({ row }) => (
          <div className={classNames('buttons is-right', classes.actions)}>
            <Link
              to={getModifyTagPath(type, row.original.objectID)}
              className="button is-small is-primary"
            >
              <span className="icon is-small">
                <i className="mdi mdi-account-edit" />
              </span>
            </Link>
            <button
              type="button"
              className="button is-small is-danger"
              onClick={() => onRemoveButtonClickHandler(row.original.objectID)}
            >
              <span className="icon is-small">
                <i className="mdi mdi-trash-can" />
              </span>
            </button>
          </div>
        ),
      },
    ],
    [onRemoveButtonClickHandler, type]
  );

  const typeFilter = useMemo(() => getTypeFilter(type), [type]);

  const redirect = useMemo(
    () => (!validTagPath(type) || !isAdmin) && <Redirect to={paths.ROOT} />,
    [type, isAdmin]
  );

  const createTagPath = useMemo(() => getCreateTagPath(type), [type]);

  return (
    <>
      {redirect}
      {deleteModal.open && (
        <Modal
          isActive={deleteModal.open}
          isLoading={deleting}
          confirmButtonMessage="Delete"
          title="Confirm action"
          body="This will permanently delete the tag. Action can not be undone."
          cancelButtonMessage="Cancel"
          onConfirmation={onDeleteTagHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      <InstantSearch
        searchClient={searchClient}
        indexName={index}
        refresh={search.refresh}
      >
        <Configure filters={typeFilter} />
        <section className="hero is-hero-bar">
          <div className="hero-body">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <h1 className="title">{`${tagPathToType[type]} Tags`}</h1>
                </div>
              </div>
              <div className="level-right">
                <Link to={createTagPath} className="button">
                  New Tag
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section className="section is-main-section">
          <Filter>
            <FilterOption label="Organization">
              <Menu
                predefinedRefinement={defaultOrganization?.value.displayName}
                predefinedOption={defaultOrganization}
                attribute="organizationName"
                emptyPlaceholder="No organizations"
                defaultPlaceholder="Select an organization"
                isSearchable
                isClearable
                limit={20}
              />
            </FilterOption>
            <FilterOption label="Global" check>
              <ToggleRefinement
                attribute="organizationName"
                value="global"
                label="Global"
              />
            </FilterOption>
            <FilterOption label="Active" check>
              <ToggleRefinement
                attribute="active"
                value
                defaultRefinement
                label="Active"
              />
            </FilterOption>
            <FilterOption label="Sort by">
              <SortBy
                defaultRefinement={indexCreatedAtDesc}
                items={[
                  { value: index, label: 'Featured' },
                  { value: indexCreatedAtAsc, label: 'Date asc.' },
                  { value: indexCreatedAtDesc, label: 'Date desc.' },
                ]}
                defaultValue={2}
              />
            </FilterOption>
          </Filter>
          <div className="card has-table has-mobile-sort-spaced">
            <header className="card-header">
              <p className="card-header-title table-header">
                <span>Search</span>
                <SearchBox />
              </p>
            </header>
            <Table columns={columns} defaultPerPageOption={10} />
          </div>
        </section>
      </InstantSearch>
    </>
  );
};

export default Tags;
