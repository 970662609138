import { toastr } from 'react-redux-toastr';

import firebase from 'firebase.js';
import {
  SURVEYS_FETCH_DEPLOYMENT_FAIL,
  SURVEYS_FETCH_DEPLOYMENT_INIT,
  SURVEYS_FETCH_DEPLOYMENT_SUCCESS,
} from 'state/actionCreators/surveys';

export const fetchDeployment = (url) => {
  return async (dispatch) => {
    dispatch(SURVEYS_FETCH_DEPLOYMENT_INIT());

    try {
      const deployments = await firebase
        .firestore()
        .collectionGroup('deployments')
        .where('url', '==', url)
        .get();

      if (deployments.empty) {
        return dispatch(
          SURVEYS_FETCH_DEPLOYMENT_FAIL({ error: 'Invalid deployment url.' })
        );
      }

      const deploymentDoc = deployments.docs[0];

      const { startDate, endDate, lastTimeProcessed } = deploymentDoc.data();

      const deployment = {
        id: deploymentDoc.id,
        ...deploymentDoc.data(),
        startDate: startDate.toDate(),
        endDate: endDate?.toDate() || null,
        lastTimeProcessed: lastTimeProcessed?.toDate() || null,
      };

      return dispatch(SURVEYS_FETCH_DEPLOYMENT_SUCCESS({ deployment }));
    } catch (error) {
      console.log('error', error);
      const errorMessage = 'Unkown error. Please contact your adminsitrator';
      toastr.error('', errorMessage);
      return dispatch(
        SURVEYS_FETCH_DEPLOYMENT_FAIL({
          error: errorMessage,
        })
      );
    }
  };
};
