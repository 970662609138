/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import classNames from 'classnames';
import Select from 'react-select';
import { useChangeHandler } from 'hooks';
import ClipLoader from 'react-spinners/ClipLoader';
import ReactTooltip from 'react-tooltip';

import {
  LanguageName,
  LanguageIsoCode,
  ModalType,
  CollectionGroup,
} from 'utils/enums';
import {
  createTag,
  fetchDataRelatedToTag,
  updateTag,
} from 'state/actions/tags';
import {
  selectDataRelatedWithTag,
  selectTagFormDataStateWithCustomError,
} from 'state/selectors/tags';
import {
  getTagTypeOption,
  getListTagPath,
  tagPathToType,
  transformOrganizationSelectOptions,
  textFilter,
  onlyLettersAndNumbers,
} from 'utils';
import useModal from 'hooks/useModal';
import { fetchTag } from 'utils/fetching';
import { fetchOrganizations } from 'state/actions/organizations';
import Modal from 'components/Modal';
import classes from './TagForm.module.scss';
import ConstructTagScores from '../ConstructTagScores';

const languagesForDescription = (function getLanguagesForDescription() {
  const languages = {};
  Object.keys(LanguageName).forEach((languageCode) => {
    languages[languageCode] = '';
  });
  return languages;
})();

const TagForm = ({ tagType, tagId }) => {
  const dispatch = useDispatch();

  const { modal, onOpenModalHandler, onCloseModalHandler } = useModal();

  const {
    loading,
    defaultOrganization,
    organizations,
    loadingOrganizations,
    error,
  } = useSelector(selectTagFormDataStateWithCustomError, shallowEqual);

  const { fetchingRelatedData, relatedData } = useSelector(
    selectDataRelatedWithTag,
    shallowEqual
  );

  const { data: errorData, message: errorMessage } = error;

  const { questionsData } = errorData || {};

  const isEditing = useMemo(() => !!tagId, [tagId]);

  const tagTypeOption = useMemo(() => getTagTypeOption(tagType), [tagType]);
  const isConstruct = useMemo(
    () => tagTypeOption.value === tagPathToType.construct,
    [tagTypeOption.value]
  );

  const [language, setLanguage] = React.useState({
    label: LanguageName[LanguageIsoCode.EN],
    value: LanguageIsoCode.EN,
  });

  const [oldTag, setOldTag] = React.useState({});
  const [tag, setTag] = React.useState({
    active: true,
    global: false,
    type: tagTypeOption.value,
    organization: null,
    name: '',
    displayName: '',
    loading: false,
    fetched: false,
    error: null,
    scores: isConstruct
      ? [{ start: 0, end: 100, description: { ...languagesForDescription } }]
      : null,
  });
  const [inputNameError, setInputNameError] = React.useState(false);

  const [ranges, setRanges] = React.useState({
    values: [],
    update: [],
    descriptions: [],
  });

  const [organizationOptions, setOrganizationOptions] = useState([]);

  useEffect(() => {
    if (questionsData?.length > 0) {
      onOpenModalHandler(ModalType.QUESTIONS_DATA_SETS_INFORMATION);
    }
  }, [questionsData, onOpenModalHandler]);

  useEffect(() => {
    const filterInactives = true;
    dispatch(fetchOrganizations(filterInactives));
  }, [dispatch]);

  useEffect(() => {
    if (organizations.length > 0) {
      transformOrganizationSelectOptions(organizations, setOrganizationOptions);
    }
  }, [organizations]);

  useEffect(() => {
    if (isEditing) {
      fetchTag(tag, setTag, setOldTag, tagId);
      dispatch(fetchDataRelatedToTag(tagId, tag.type));
    }
  }, [dispatch, tagId, tag, isEditing]);

  useEffect(() => {
    if (!isEditing && !tag.global) {
      setTag((prevState) => ({
        ...prevState,
        organization: defaultOrganization,
      }));
    }
  }, [defaultOrganization, isEditing, tag.global]);

  const valueFilter = useCallback(
    (value) => textFilter(value, onlyLettersAndNumbers, setInputNameError),
    []
  );

  const onChangeHandler = useChangeHandler(setTag);

  const onChangeHandlerOnlyLettersAndNumbers = useChangeHandler(
    setTag,
    valueFilter
  );

  const transformTagScores = useCallback(() => {
    const newValues = [];
    ranges.values.forEach((range, index) => {
      if (index !== ranges.values.length - 1) {
        let startValue = range;
        if (index !== 0) {
          startValue += 1;
        }
        newValues.push({
          start: startValue,
          end: ranges.values[index + 1],
          description: ranges.descriptions[index],
        });
      }
    });
    return newValues;
  }, [ranges.values, ranges.descriptions]);

  const onSubmitHandler = useCallback(
    (event) => {
      event.preventDefault();
      let data = {
        ...tag,
        organization: tag.organization?.value.displayName || null,
      };
      if (isConstruct) {
        const scores = transformTagScores();
        data = {
          ...tag,
          organization: tag.organization?.value.displayName || null,
          scores,
        };
      }
      if (tagId) {
        const dataOldTag = {
          ...oldTag,
          organization: oldTag.organization?.value.displayName || null,
        };
        dispatch(updateTag(tagId, data, dataOldTag));
      } else {
        dispatch(createTag(data));
      }
    },
    [dispatch, tag, tagId, oldTag, isConstruct, transformTagScores]
  );

  const onGlobalChangeHandler = useCallback(
    (event) => {
      const { target } = event;
      onChangeHandler(event);

      if (target.checked) {
        setTag((prevState) => ({ ...prevState, organization: null }));
      }
      if (!target.checked) {
        setTag((prevState) => ({
          ...prevState,
          organization: oldTag.organization,
        }));
      }
    },
    [oldTag.organization, onChangeHandler]
  );

  const onClickSeeMoreInfoHandler = useCallback(
    () => () => onOpenModalHandler(ModalType.QUESTIONS_DATA_SETS_INFORMATION),
    [onOpenModalHandler]
  );

  const canSubmit = useMemo(
    () =>
      !!tag.name?.trim() &&
      (!!tag.global || !!tag.organization) &&
      (isConstruct ? !!tag.displayName?.trim() : true),
    [tag.name, tag.global, tag.organization, isConstruct, tag.displayName]
  );

  const redirect = useMemo(
    () => tag.error && <Redirect to={getListTagPath(tagType)} />,
    [tag.error, tagType]
  );

  const goBackPath = useMemo(() => getListTagPath(tagType), [tagType]);

  const onOrganizationChangeHandler = useCallback(
    (select) => setTag((prevState) => ({ ...prevState, organization: select })),
    []
  );

  const onAddRangeHandler = (newRange) => {
    const newValues = [];
    const rangeNumber = parseInt(newRange, 10);
    if (rangeNumber > 0 && rangeNumber < 100) {
      let added = false;
      tag.scores.forEach((score, index) => {
        if (index !== ranges.values.length) {
          if (ranges.values[index + 1] > rangeNumber && !added) {
            newValues.push(
              {
                start: ranges.values[index],
                end: rangeNumber,
                description: { ...ranges.descriptions[index] },
              },
              {
                start: rangeNumber + 1,
                end: ranges.values[index + 1],
                description: { ...ranges.descriptions[index] },
              }
            );
            added = true;
          } else {
            newValues.push({
              start: ranges.values[index],
              end: ranges.values[index + 1],
              description: { ...ranges.descriptions[index] },
            });
          }
        }
        if (index === tag.scores.length - 1 && !added && rangeNumber < 100) {
          newValues.push({
            start: ranges.values[index],
            end: rangeNumber,
            description: { ...ranges.descriptions[index] },
          });
        }
      });
      setTag((prevState) => ({
        ...prevState,
        scores: newValues,
      }));
    }
  };

  const onDeleteRangeHandler = (indexDelete) => {
    const newValues = [];
    for (let index = 0; index < tag.scores.length; index += 1) {
      if (indexDelete === index) {
        newValues.push({
          start: ranges.values[index],
          end: ranges.values[index + 2],
          description: { ...ranges.descriptions[index + 1] },
        });
        index += 1;
      } else if (indexDelete === index + 1) {
        newValues.push({
          start: ranges.values[index],
          end: ranges.values[index + 2],
          description: { ...ranges.descriptions[index] },
        });
        index += 1;
      } else {
        newValues.push({
          start: ranges.values[index],
          end: ranges.values[index + 1],
          description: { ...ranges.descriptions[index] },
        });
      }
    }
    setTag((prevState) => ({
      ...prevState,
      scores: newValues,
    }));
  };

  const onChangeLanguageHandler = useCallback(
    (selectedLanguage) => {
      const newValues = [];
      ranges.values.forEach((range, index) => {
        if (index !== ranges.values.length - 1) {
          newValues.push({
            start: range,
            end: ranges.values[index + 1],
            description: { ...ranges.descriptions[index] },
          });
        }
      });
      setLanguage(selectedLanguage);
      setTag((prevState) => ({
        ...prevState,
        scores: newValues,
      }));
    },
    [ranges.values, ranges.descriptions]
  );

  const questionsOrDataSetsAffected = useMemo(() => {
    const affectedData = [];

    const dataIsAffected =
      (relatedData?.length > 0 && relatedData) ||
      (questionsData?.length > 0 && questionsData);

    if (dataIsAffected) {
      dataIsAffected.forEach((data) => {
        const { type, title, name, questionContent } = data;

        const surveyType =
          type === CollectionGroup.SURVEY_VERSION ? 'Version' : 'Deployment';

        affectedData.push(
          <div
            key={title + name + questionContent}
            className={classes['data-container']}
          >
            <span className={classes.data}>
              <span className={classes.title}>
                Survey {surveyType} Title:&nbsp;
              </span>
              {title}
            </span>
            {name && (
              <span className={classes.data}>
                <span className={classes.title}>
                  Survey {surveyType} Name:&nbsp;
                </span>
                {name}
              </span>
            )}
            <span className={classes.data}>
              <span className={classes.title}>Question:&nbsp;</span>
              {questionContent}
            </span>
          </div>
        );
      });
    }

    return <div className={classes['affected-data']}>{affectedData}</div>;
  }, [questionsData, relatedData]);

  const shouldNotAllowToChangeOrganization = useMemo(
    () => relatedData && relatedData.length > 0,
    [relatedData]
  );

  return (
    <>
      {redirect}
      {modal.type === ModalType.QUESTIONS_DATA_SETS_INFORMATION && (
        <Modal
          isActive
          isLoading={false}
          title={errorMessage || 'Affected Data'}
          body={questionsOrDataSetsAffected}
          cancelButtonMessage="Close"
          onCancel={onCloseModalHandler}
          showConfirmButton={false}
          type={ModalType.ERROR}
        />
      )}
      <section className="section is-main-section">
        {isEditing && !tag.fetched ? (
          <ClipLoader />
        ) : (
          <>
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-tag default" />
                  </span>
                  Tag information
                </p>
              </header>
              <form onSubmit={onSubmitHandler}>
                <div className="card-content columns">
                  <div className="column">
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">Name</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              id="name-input"
                              className={classNames('input', classes.input)}
                              type="text"
                              name="name"
                              required
                              onChange={
                                isConstruct
                                  ? onChangeHandlerOnlyLettersAndNumbers
                                  : onChangeHandler
                              }
                              value={tag.name}
                            />
                          </div>
                          {!inputNameError && (
                            <p className="help">
                              Name in combination with Type must be unique in
                              the system if it is Global, or unique within a
                              given organization.
                            </p>
                          )}
                          {inputNameError && (
                            <p className="help is-danger">
                              Name can not include special characters or spaces.
                            </p>
                          )}
                        </div>
                      </div>
                    </div>

                    {isConstruct && (
                      <div className="field is-horizontal">
                        <div className="field-label is-normal">
                          <label className="label">Display Name</label>
                        </div>
                        <div className="field-body">
                          <div className="field">
                            <div className="control">
                              <input
                                id="name-input"
                                className={classNames('input', classes.input)}
                                type="text"
                                name="displayName"
                                required
                                onChange={onChangeHandler}
                                value={tag.displayName}
                              />
                            </div>
                            <p className="help">
                              This name will be the name that will be displayed
                            </p>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="field has-check is-horizontal">
                      <div className="field-label">
                        <label className="label">Global</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <label
                              className="b-checkbox checkbox"
                              disabled={oldTag?.global}
                              data-tip="You can't change a global tag to an organization tag"
                              data-for="global-check"
                            >
                              <input
                                id="global-checkbox"
                                type="checkbox"
                                name="global"
                                onChange={onGlobalChangeHandler}
                                checked={tag.global}
                                disabled={oldTag?.global}
                              />
                              <span className="check is-primary" />
                            </label>
                          </div>
                          <p className="help">
                            If a tag is global, it can be used with any
                            organization.
                          </p>
                        </div>
                        <ReactTooltip
                          id="global-check"
                          disable={!oldTag?.global}
                          className={classes['tooltip-width']}
                        />
                      </div>
                    </div>

                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">Organization</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div
                            className={classNames(
                              'control',
                              classes['organization-select']
                            )}
                          >
                            <div
                              data-tip="Tag is being used, click on the information icon to see the affected data"
                              data-for="organization-select"
                              className={
                                classes['organization-select-container']
                              }
                            >
                              <Select
                                className={classNames(
                                  classes.input,
                                  classes.select
                                )}
                                isDisabled={
                                  tag.global ||
                                  loadingOrganizations ||
                                  shouldNotAllowToChangeOrganization
                                }
                                isLoading={loadingOrganizations}
                                isClearable
                                value={tag.organization}
                                options={organizationOptions}
                                onChange={onOrganizationChangeHandler}
                              />
                            </div>
                            {!tag.global &&
                              shouldNotAllowToChangeOrganization && (
                                <i
                                  onClick={onClickSeeMoreInfoHandler()}
                                  className={classNames(
                                    'mdi mdi-information-outline',
                                    classes['information-outline'],
                                    {
                                      [classes['disable-icon']]:
                                        fetchingRelatedData,
                                      [classes['icon-interactions']]:
                                        !fetchingRelatedData,
                                    }
                                  )}
                                />
                              )}
                          </div>
                          <ReactTooltip
                            id="organization-select"
                            disable={
                              !shouldNotAllowToChangeOrganization || tag.global
                            }
                            className={classes['tooltip-width']}
                          />
                          <p className="help">
                            Specify which organization this tag should be used
                            in.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="field has-check is-horizontal">
                      <div className="field-label">
                        <label className="label">Active</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <label className="b-checkbox checkbox">
                              <input
                                id="active-checkbox"
                                type="checkbox"
                                name="active"
                                onChange={onChangeHandler}
                                checked={tag.active}
                              />
                              <span className="check is-primary" />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {!isConstruct && (
                  <footer
                    className={classNames('card-footer', classes.cardFooter)}
                  >
                    <div className={classNames('buttons', classes.buttons)}>
                      <button
                        type="submit"
                        className={classNames('button', 'is-primary', {
                          'is-loading': loading,
                        })}
                        disabled={loading || !canSubmit}
                      >
                        {tagId ? 'Update' : 'Create'}
                      </button>
                      <Link to={goBackPath}>
                        <button
                          id="go-back"
                          className="button"
                          disabled={loading}
                        >
                          Go Back
                        </button>
                      </Link>
                    </div>
                  </footer>
                )}
              </form>
            </div>
            <div className="card">
              {isConstruct && (
                <ConstructTagScores
                  tag={tag}
                  ranges={ranges}
                  setRanges={setRanges}
                  onAddRange={onAddRangeHandler}
                  onDeleteRange={onDeleteRangeHandler}
                  onSubmit={onSubmitHandler}
                  goBackPath={goBackPath}
                  canSubmit={canSubmit}
                  isEditing={isEditing}
                  language={language}
                  onChangeLanguage={onChangeLanguageHandler}
                />
              )}
            </div>
          </>
        )}
      </section>
    </>
  );
};

TagForm.propTypes = {
  tagType: PropTypes.oneOf([
    'question',
    'data-set',
    'construct',
    'demographic',
  ]),
  tagId: PropTypes.string,
};

TagForm.defaultProps = {
  tagId: '',
};

export default TagForm;
