import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

import { ActionBuilderConditionTypes, AvailableActions } from 'utils/enums';
import classes from './ActionListItem.module.scss';

const ActionsListItem = ({
  actions,
  conditions,
  id,
  onEditAction,
  onDeleteAction,
}) => {
  const onClickEditHandler = useCallback(() => {
    onEditAction(actions, conditions, id);
  }, [actions, conditions, id, onEditAction]);

  const onClickDeleteHandler = useCallback(() => {
    onDeleteAction(id);
  }, [id, onDeleteAction]);

  return (
    <tr>
      <td className={classes.td}>
        {conditions.map((condition, conditionsIndex) => {
          const label = `${conditionsIndex} ${condition.type}`;
          if (condition.type === ActionBuilderConditionTypes.CONDITION) {
            let values = null;
            if (condition.values.length > 0) {
              values = (
                <ul className={classes.ul}>
                  {condition.values.map((value) => (
                    <li
                      data-tip={value.title}
                      className={classes.li}
                      key={value.title}
                    >
                      {value.title}
                    </li>
                  ))}
                </ul>
              );
            }
            return (
              <div className={classes['conditions-div']} key={label}>
                <div
                  className={classes.subject}
                  data-tip={condition.subject.title}
                >
                  {condition.subject.title}
                </div>
                <span className={classNames('tag is-dark', classes.criteria)}>
                  {condition.criteria}
                </span>
                <div className={classNames('content', classes.choices)}>
                  {values}
                </div>
                <ReactTooltip />
              </div>
            );
          }
          return (
            <div key={label} className={classes.operator}>
              {condition.operator}
            </div>
          );
        })}
      </td>
      <td className={classes.td}>
        {actions.map((action, actionsIndex) => {
          const label = `${actionsIndex} ${action.action}`;
          if (
            action.action === AvailableActions.SHOW_CHOICES ||
            action.action === AvailableActions.HIDE_CHOICES
          ) {
            const choices = (
              <ul className={classes.ul}>
                {action.choices.map((choice) => (
                  <li
                    data-tip={choice.title}
                    className={classes.li}
                    key={choice.title}
                  >
                    {choice.title}
                  </li>
                ))}
              </ul>
            );
            return (
              <div key={label} className={classes['actions-div']}>
                <div className={classes.action}>
                  {actionsIndex + 1}. {action.action}
                </div>
                <div
                  data-tip={action.subject.title}
                  className={classes['action-subject']}
                >
                  Choices for question: {action.subject.title}
                </div>
                <div className={classes['action-subject']}>{choices}</div>
                <ReactTooltip />
              </div>
            );
          }
          if (
            action.action === AvailableActions.RANDOMIZE_QUESTIONS ||
            action.action === AvailableActions.RANDOMIZE_SECTIONS ||
            action.action === AvailableActions.RANDOMIZE_PAGES
          ) {
            const items = (
              <ul className={classes.ul}>
                {action.subject.map((item) => (
                  <li
                    data-tip={item.title}
                    className={classes.li}
                    key={item.title}
                  >
                    {item.title}
                  </li>
                ))}
              </ul>
            );
            return (
              <div key={label} className={classes['actions-div']}>
                <div className={classes.action}>
                  {actionsIndex + 1}. {action.action}:
                </div>
                Randomize {action.randomize} from:
                <div className={classes['action-subject']}>{items}</div>
                <ReactTooltip />
              </div>
            );
          }
          if (action.action === AvailableActions.RANDOMIZE_CHOICES) {
            const choices = (
              <ul className={classes.ul}>
                {action.choices.map((choice) => (
                  <li
                    data-tip={choice.title}
                    className={classes.li}
                    key={choice.title}
                  >
                    {choice.title}
                  </li>
                ))}
              </ul>
            );
            return (
              <div key={label} className={classes['actions-div']}>
                <div className={classes.action}>
                  {actionsIndex + 1}. {action.action}
                </div>
                <div
                  data-tip={action.subject.title}
                  className={classes['action-subject']}
                >
                  {action.randomize} choices for question {action.subject.title}
                  :
                </div>
                <div data-tip={choices} className={classes['action-subject']}>
                  {choices}
                </div>
                <ReactTooltip />
              </div>
            );
          }
          return (
            <div className={classes['actions-div']} key={label}>
              <div className={classes.action}>
                {actionsIndex + 1}. {action.action}:
              </div>
              <div
                data-tip={action.subject.title}
                className={classes['action-subject']}
              >
                {action.subject.title}
              </div>
              <ReactTooltip />
            </div>
          );
        })}
      </td>
      <td className={classes.td}>
        <div className={classes['edit-div']}>
          <button
            className={classNames('button is-medium', classes.button)}
            onClick={onClickEditHandler}
          >
            <span className="icon is-small">
              <i className="mdi mdi-pencil" />
            </span>
          </button>
          <button
            className={classNames('button is-medium', classes.button)}
            onClick={onClickDeleteHandler}
          >
            <span className="icon is-small">
              <i className="mdi mdi-delete" />
            </span>
          </button>
        </div>
      </td>
    </tr>
  );
};

ActionsListItem.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        action: PropTypes.string,
        subject: PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
        }),
      }),
      PropTypes.shape({
        action: PropTypes.string,
        subject: PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
        }),
        choices: PropTypes.arrayOf(
          PropTypes.shape({ id: PropTypes.string, title: PropTypes.string })
        ),
      }),
      PropTypes.shape({
        action: PropTypes.string,
        subject: PropTypes.arrayOf(
          PropTypes.shape({ id: PropTypes.string, title: PropTypes.string })
        ),
        randomize: PropTypes.string,
      }),
      PropTypes.shape({
        action: PropTypes.string,
        subject: PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
        }),
        choices: PropTypes.arrayOf(
          PropTypes.shape({ id: PropTypes.string, title: PropTypes.string })
        ),
        randomize: PropTypes.string,
      }),
    ])
  ),
  conditions: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        subject: PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
        }),
        criteria: PropTypes.string,
        values: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
          })
        ),
        type: PropTypes.string,
      }),
      PropTypes.shape({
        operator: PropTypes.string,
        type: PropTypes.string,
      }),
    ])
  ),
  id: PropTypes.string,
  onEditAction: PropTypes.func.isRequired,
  onDeleteAction: PropTypes.func.isRequired,
};

export default ActionsListItem;
