import { createReducer } from 'redux-act';

import {
  USERS_FETCH_DATA_INIT,
  USERS_FETCH_DATA_SUCCESS,
  USERS_FETCH_DATA_FAIL,
  USERS_DELETE_USER_INIT,
  USERS_DELETE_USER_SUCCESS,
  USERS_DELETE_USER_FAIL,
  USERS_CREATE_USER_INIT,
  USERS_CREATE_USER_SUCCESS,
  USERS_CREATE_USER_FAIL,
  USERS_MODIFY_USER_INIT,
  USERS_MODIFY_USER_SUCCESS,
  USERS_MODIFY_USER_FAIL,
  USERS_CLEAN_UP,
} from 'state/actions/users';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const usersReducer = createReducer(
  {
    [USERS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [USERS_FETCH_DATA_SUCCESS]: (state, { users }) => ({
      ...state,
      data: users,
      loading: false,
      error: null,
    }),
    [USERS_FETCH_DATA_FAIL]: (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
    [USERS_DELETE_USER_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [USERS_DELETE_USER_SUCCESS]: (state, { id }) => {
      const { data } = state;

      return {
        ...state,
        data: data.filter((elem) => elem.id !== id),
        loading: false,
        error: null,
        deleted: true,
      };
    },
    [USERS_DELETE_USER_FAIL]: (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
    [USERS_CREATE_USER_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [USERS_CREATE_USER_SUCCESS]: (state, { user }) => {
      const { data } = state;

      return {
        ...state,
        data: data.concat(user),
        loading: false,
        error: null,
        success: true,
      };
    },
    [USERS_CREATE_USER_FAIL]: (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
    [USERS_MODIFY_USER_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [USERS_MODIFY_USER_SUCCESS]: (state, { user }) => {
      const { data } = state;

      return {
        ...state,
        data: Array.isArray(data)
          ? data?.map((elem) => {
              if (elem.id === user.id) {
                return {
                  ...user,
                  email: elem.email,
                };
              }
              return elem;
            })
          : { ...user, email: data.email },
        loading: false,
        error: null,
        success: true,
      };
    },
    [USERS_MODIFY_USER_FAIL]: (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
    [USERS_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
    }),
  },
  initialState
);
