import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  getQuestionOptions,
  getQuestionGroupOptions,
  getTextBoxOptions,
  getPagesOptions,
  getSectionsOptions,
  getAllQuestionsOptions,
} from 'utils/actionBuilder';
import classes from './CreateAction.module.scss';
import Conditions from './Conditions';
import Actions from './Actions';

const CreateAction = ({
  onShowListAction,
  conditions,
  onAddCondition,
  onDeleteCondition,
  onChangeOperator,
  onChangeCondition,
  actions,
  onAddAction,
  onDeleteAction,
  onChangeAction,
  isEditing,
  onEdit,
  onCreate,
  canSubmit,
  questions,
  allQuestions,
  questionGroups,
  textBoxes,
  pages,
  pagesOrder,
  sections,
}) => {
  const [allQuestionsOptions, setAllQuestionsOptions] = useState([]);
  const [questionsAndGroupsOptions, setQuestionsAndGroupsOptions] = useState(
    []
  );
  const [questionOptions, setQuestionOptions] = useState([]);
  const [questionGroupOptions, setQuestionGroupOptions] = useState([]);
  const [pageOptions, setPageOptions] = useState([]);
  const [sectionOptions, setSectionOptions] = useState([]);
  const [textBoxOptions, setTextBoxOptions] = useState([]);

  useEffect(() => {
    const { allOptions, questionsAndGroups } = getAllQuestionsOptions(
      pages,
      pagesOrder,
      sections,
      questions,
      questionGroups,
      allQuestions
    );
    setAllQuestionsOptions(allOptions);
    setQuestionsAndGroupsOptions(questionsAndGroups);
  }, [pages, pagesOrder, sections, questions, questionGroups, allQuestions]);

  useEffect(() => {
    const options = getQuestionOptions(pages, pagesOrder, sections, questions);
    setQuestionOptions(options);
  }, [pages, pagesOrder, sections, questions]);

  useEffect(() => {
    const allQuestionGroups = getQuestionGroupOptions(
      pages,
      pagesOrder,
      sections,
      questionGroups
    );
    setQuestionGroupOptions(allQuestionGroups);
  }, [pages, pagesOrder, sections, questionGroups]);

  useEffect(() => {
    const allTextBoxes = getTextBoxOptions(
      pages,
      pagesOrder,
      sections,
      textBoxes
    );
    setTextBoxOptions(allTextBoxes);
  }, [pages, pagesOrder, sections, textBoxes]);

  useEffect(() => {
    const allPages = getPagesOptions(pages, pagesOrder);
    setPageOptions(allPages);
  }, [pages, pagesOrder]);

  useEffect(() => {
    const allSections = getSectionsOptions(pages, pagesOrder, sections);
    setSectionOptions(allSections);
  }, [pages, pagesOrder, sections]);

  return (
    <>
      <section
        className={classNames(
          'modal-card-body',
          classes['modal-card-divided-body']
        )}
      >
        <Conditions
          conditions={conditions}
          onAddCondition={onAddCondition}
          onDeleteCondition={onDeleteCondition}
          onChangeOperator={onChangeOperator}
          onChangeCondition={onChangeCondition}
          subjects={allQuestionsOptions}
        />
        <Actions
          actions={actions}
          onAddAction={onAddAction}
          onDeleteAction={onDeleteAction}
          onChangeAction={onChangeAction}
          questionOptions={questionOptions}
          questionGroupOptions={questionGroupOptions}
          allQuestionsOptions={allQuestionsOptions}
          questionsAndGroupsOptions={questionsAndGroupsOptions}
          textBoxOptions={textBoxOptions}
          pageOptions={pageOptions}
          sectionOptions={sectionOptions}
        />
      </section>
      <footer className="modal-card-foot">
        <button
          id="action-builder-go-back"
          className="button"
          onClick={onShowListAction}
        >
          Go back
        </button>
        <button
          id="action-builder-create"
          className="button is-primary"
          onClick={isEditing ? onEdit : onCreate}
          disabled={!canSubmit}
        >
          {isEditing ? 'Edit' : 'Create'}
        </button>
      </footer>
    </>
  );
};

CreateAction.propTypes = {
  onShowListAction: PropTypes.func.isRequired,
  conditions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  onAddCondition: PropTypes.func.isRequired,
  onDeleteCondition: PropTypes.func.isRequired,
  onChangeOperator: PropTypes.func.isRequired,
  onChangeCondition: PropTypes.func.isRequired,
  actions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  onAddAction: PropTypes.func.isRequired,
  onDeleteAction: PropTypes.func.isRequired,
  onChangeAction: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  canSubmit: PropTypes.bool.isRequired,
  questions: PropTypes.objectOf(PropTypes.objectOf(PropTypes.any)),
  questionGroups: PropTypes.objectOf(PropTypes.objectOf(PropTypes.any)),
  allQuestions: PropTypes.objectOf(PropTypes.objectOf(PropTypes.any)),
  textBoxes: PropTypes.objectOf(PropTypes.objectOf(PropTypes.any)),
  pages: PropTypes.objectOf(PropTypes.objectOf(PropTypes.any)),
  pagesOrder: PropTypes.arrayOf(PropTypes.string),
  sections: PropTypes.objectOf(PropTypes.objectOf(PropTypes.any)),
};

export default CreateAction;
