import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Select from 'react-select';
import { connectMenu, connectStateResults } from 'react-instantsearch-dom';
import Proptypes from 'prop-types';

const optionsAreEqual = (options, items) =>
  !items.every((item) =>
    options.includes({
      label: `${item.label} (${item.count})`,
      value: item.value,
    })
  );

const Menu = ({
  items,
  refine,
  isClearable,
  isSearchable,
  isSearchStalled,
  defaultPlaceholder,
  emptyPlaceholder,
  predefinedOption,
  predefinedRefinement,
}) => {
  const [selectOptions, setSelectOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [selectedOrganization, setSelectedOrganization] = useState(null);

  const optionsChanged = useMemo(
    () => optionsAreEqual(selectOptions, items),
    [selectOptions, items]
  );

  useEffect(() => {
    if (items.length !== 0 && (selectOptions.length === 0 || optionsChanged)) {
      const filteredList = items
        .filter((item) => item.label !== 'global')
        .map((item) => ({
          label: `${item.label} (${item.count})`,
          value: item.value,
        }));

      setSelectOptions(filteredList);

      if (filteredList.length !== 0) {
        setIsDisabled(false);
      } else if (!isDisabled) {
        setIsDisabled(true);
      }
    }
  }, [items, isDisabled, selectOptions.length, items.length, optionsChanged]);

  useEffect(() => {
    if (isSearchStalled && isLoading) {
      setIsLoading(false);
    }
  }, [isSearchStalled, isLoading]);

  const placeholder = useMemo(
    () => (selectOptions.length === 0 ? emptyPlaceholder : defaultPlaceholder),
    [selectOptions.length, emptyPlaceholder, defaultPlaceholder]
  );

  const onChangeHandler = useCallback(
    (event) => {
      refine(event?.value);
      setSelectedOrganization(event);
    },
    [refine]
  );

  useEffect(() => {
    setSelectedOrganization(predefinedOption);
    refine(predefinedRefinement);
  }, [predefinedOption, predefinedRefinement, refine]);

  return (
    <Select
      value={selectedOrganization}
      placeholder={placeholder}
      options={selectOptions}
      onChange={onChangeHandler}
      isLoading={isLoading}
      isDisabled={isDisabled || isLoading}
      isClearable={isClearable}
      isSearchable={isSearchable}
    />
  );
};

Menu.propTypes = {
  items: Proptypes.arrayOf(
    Proptypes.shape({
      label: Proptypes.string.isRequired,
      value: Proptypes.string.isRequired,
      count: Proptypes.number.isRequired,
      isRefined: Proptypes.bool.isRequired,
    })
  ).isRequired,
  refine: Proptypes.func.isRequired,
  isSearchStalled: Proptypes.bool.isRequired,
  isClearable: Proptypes.bool,
  isSearchable: Proptypes.bool,
  defaultPlaceholder: Proptypes.string,
  emptyPlaceholder: Proptypes.string,
  predefinedOption: Proptypes.shape({
    label: Proptypes.string.isRequired,
    value: Proptypes.oneOfType([Proptypes.string, Proptypes.object]),
  }),
  predefinedRefinement: Proptypes.string,
};

Menu.defaultProps = {
  isClearable: false,
  isSearchable: false,
  defaultPlaceholder: 'Select an option',
  emptyPlaceholder: 'No options available',
  predefinedOption: null,
  predefinedRefinement: '',
};

export default connectMenu(connectStateResults(Menu));
