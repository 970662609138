import React, { useCallback } from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import PropTypes from 'prop-types';

const SearchBox = ({ currentRefinement, refine }) => {
  const onChangeHandler = useCallback(
    (event) => refine(event.currentTarget.value),
    [refine]
  );
  return (
    <input
      type="search"
      className="input"
      value={currentRefinement}
      onChange={onChangeHandler}
    />
  );
};

SearchBox.propTypes = {
  currentRefinement: PropTypes.string.isRequired,
  refine: PropTypes.func.isRequired,
};

export default connectSearchBox(SearchBox);
