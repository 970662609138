/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import classNames from 'classnames';

import classes from './FilterOption.module.scss';

const FilterOption = ({ label, children, check }) => (
  <div
    className={classNames(
      'field',
      'is-horizontal',
      { 'is-check': check },
      classes.width
    )}
  >
    <div className="field-label is-normal">
      <label className="label">{label}</label>
    </div>
    <div className="field-body">
      <div className="field">
        <div className="control">{children}</div>
      </div>
    </div>
  </div>
);

export default FilterOption;
