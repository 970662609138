import firebase from 'firebase.js';
import { getBasicSurveyInfo } from 'utils/actions';
import { firebaseError } from 'utils';
import { SurveyElement, SurveyType } from 'utils/surveyBuilder/enums';
import { collection } from 'utils/firebase';
import { Collections } from 'utils/enums';
import { indexSurveys } from 'utils/algolia';

export const updateSurveyService = async ({ surveyData }, { getState }) => {
  const { locale } = getBasicSurveyInfo({
    getState,
  });

  const {
    surveyId,
    surveyVersionId,
    name,
    actions,
    versionTitle,
    versionName,
    versions,
    type,
    pages,
    pagesOrder,
    sections,
    items,
    defaultLanguage,
    availableLanguages,
    isCondensed,
    languageCompletion = {},
    organizationId,
    disableDeselect = false,
  } = surveyData;

  const versionTitleParsed = {};

  Object.entries(versionTitle).forEach(([language, title]) => {
    versionTitleParsed[language] = title.trim();
  });

  const surveyVersion = {
    title: versionTitleParsed,
    name: versionName.trim(),
    pages,
    pagesOrder,
    sections,
    items,
    defaultLanguage,
    availableLanguages,
    isCondensed,
    actions,
    languageCompletion,
    disableDeselect,
  };

  const survey = {
    name: name.trim(),
    type,
    versions: versions.map((version) => {
      if (version.id === surveyVersionId) {
        return {
          ...version,
          title: versionTitle[defaultLanguage].trim(),
          name: versionName.trim(),
        };
      }
      return version;
    }),
    languageCompletion,
  };

  const questions = [];
  const questionTag = [];
  const questionChoices = [];
  const newActions = [...actions];

  Object.values(items).forEach((item) => {
    if (item.type === SurveyElement.QUESTION) {
      const { id, question } = item;
      const {
        id: questionId,
        content,
        dataSet,
        tags,
        choices,
        required,
        choicesOrder,
        regionalBreakdown,
        sliderProps,
        template: previousQuestionTemplate,
      } = question;

      const newTemplate =
        type === SurveyType.TEMPLATE
          ? {
              id,
              surveyId,
              versionId: surveyVersionId,
            }
          : null;

      const template = previousQuestionTemplate || newTemplate;

      items[id].question.template = template;

      const questionContentParsed = {};

      Object.entries(content).forEach(([language, questionContent]) => {
        questionContentParsed[language] = questionContent.trim();
      });

      questions.push({
        id: questionId,
        question: {
          id: questionId,
          content: questionContentParsed,
          type: question.type,
          dataSetId: dataSet.id ?? null,
          sliderProps: sliderProps ?? null,
          surveyId,
          surveyVersionId,
          surveyDeploymentId: null,
          required,
          choicesOrder,
          template,
          regionalBreakdown,
          organizationId,
        },
      });

      tags.forEach(({ id: tagId, weight }) => {
        questionTag.push({ tagId, questionId, weight });
      });

      if (!dataSet.id) {
        choices.forEach(
          ({
            id: choiceId,
            option,
            paramOne,
            paramTwo,
            openEnded,
            notApplicable,
            visible,
            sortOrder,
          }) => {
            const choiceOptionParsed = {};

            Object.entries(option).forEach(([language, choiceOption]) => {
              choiceOptionParsed[language] = choiceOption.trim();
            });

            questionChoices.push({
              id: choiceId,
              choice: {
                id: choiceId,
                questionId,
                option: choiceOptionParsed,
                paramOne: paramOne?.trim() || null,
                paramTwo: paramTwo?.trim() || null,
                openEnded,
                notApplicable,
                dataSetId: null,
                sortOrder,
                visible,
              },
            });
          }
        );
      }
    }

    if (item.type === SurveyElement.QUESTION_GROUP) {
      const {
        id: questionGroupId,
        questionGroup: {
          dataSet,
          questions: questionsInGroup,
        },
      } = item;

      let dataSetId = dataSet.id;

      if (Array.isArray(dataSet)) {
        dataSetId = [];
        dataSet.forEach((dataSetItem) => {
          if (dataSetItem.id) {
            dataSetId.push(dataSetItem.id);
          }
        });
      }

      questionsInGroup.forEach(
        ({
          id: questionInGroupId,
          content,
          tags,
          type: questionInGroupType,
          required,
          choicesOrder,
        }) => {
          const template =
            type === SurveyType.TEMPLATE
              ? {
                  questionGroupId,
                  surveyId,
                  versionId: surveyVersionId,
                }
              : null;

          items[questionGroupId].questionGroup.template = template;

          const questionContentParsed = {};

          Object.entries(content).forEach(([language, questionContent]) => {
            questionContentParsed[language] = questionContent.trim();
          });

          questions.push({
            id: questionInGroupId,
            question: {
              id: questionInGroupId,
              content: questionContentParsed,
              type: questionInGroupType,
              dataSetId: dataSetId ?? null,
              surveyId,
              surveyVersionId,
              surveyDeploymentId: null,
              required,
              choicesOrder,
              template,
              regionalBreakdown: false,
              organizationId,
            },
          });

          tags.forEach(({ id: tagId, weight }) => {
            questionTag.push({
              tagId,
              questionId: questionInGroupId,
              weight,
            });
          });
        }
      );
    }
  });

  surveyVersion.actions = newActions;

  const surveyRef = collection(Collections.SURVEYS).doc(surveyId);
  const versionRef = surveyRef
    .collection(Collections.VERSIONS)
    .doc(surveyVersionId);

    try {
      const batch = firebase.firestore().batch();

      const questionsToDelete = await collection(Collections.QUESTIONS)
        .where('surveyId', '==', surveyId)
        .where('surveyVersionId', '==', surveyVersionId)
        .get();

      const questionsData = [];

      questionsToDelete.forEach((question) => {
        batch.delete(collection(Collections.QUESTIONS).doc(question.id));

        questionsData.push({ id: question.id, question: question.data() });
      });

      const questionsId = questions.map(({ id }) => id);

      batch.update(surveyRef, survey);
      batch.update(versionRef, surveyVersion);

      const deleteChoicesFromQuestions = firebase
        .functions()
        .httpsCallable('httpsDeleteChoicesFromQuestions');

      const removeQuestionTags = firebase
        .functions()
        .httpsCallable('httpsRemoveQuestionTags');

      await Promise.all([
        batch.commit(),
        deleteChoicesFromQuestions(questionsData),
        removeQuestionTags(questionsId),
      ]);
    } catch (error) {
      console.error(error);

      const errorMessage = firebaseError(error.code, locale);
      
      
      throw errorMessage;
    }

    try {
      const batch = firebase.firestore().batch();

      questions.forEach(({ id, question }) => {
        batch.set(collection(Collections.QUESTIONS).doc(id), question);
      });

      await batch.commit();
    } catch (error) {
      console.error(error);

      const errorMessage = firebaseError(error.code, locale);
      
      throw errorMessage;
    }

    try {
      const createQuestionTagTasks = [];

      questionTag.forEach((data) => {
        createQuestionTagTasks.push(collection(Collections.QUESTION_TAG).add(data));
      });

      const createQuestionChoiceTasks = [];

      questionChoices.forEach(({ id, choice }) =>
        createQuestionChoiceTasks.push(
          collection(Collections.QUESTION_CHOICE).doc(id).set(choice)
        )
      );

      await Promise.all([
        ...createQuestionChoiceTasks,
        ...createQuestionTagTasks,
      ]);
    } catch (error) {
      console.error(error);

      const errorMessage = firebaseError(error.code, locale);
      
      throw errorMessage;
    }

    try {
      await indexSurveys
        .partialUpdateObject({
          objectID: surveyId,
          ...survey,
        })
        .wait();
    } catch (error) {
      console.error(error);

      throw error.message;
    }

};
