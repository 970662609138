import React, { useState, Fragment } from 'react';
import Select from 'react-select';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { availableLanguages } from 'utils';

import AddTranslation from './AddTranslation';
import Translation from './Translation';
import Translate from './Translate';

import classes from './Translations.module.scss';

const Translations = ({
  defaultTranslation,
  additionalTranslations,
  onClose,
  onAddTranslation,
  onRemoveTranslation,
  onUpdateTranslation,
}) => {
  const [{ selectedTranslation, selectedTranslationOption }, setState] =
    useState({
      selectedTranslation: null,
      selectedTranslationOption: null,
    });

  const additionalTranslationsOptions = additionalTranslations.map(
    ({ name }, index) => ({ label: name, value: index })
  );

  const availableLanguagesOptions = availableLanguages(
    additionalTranslations.map(({ code }) => code),
    defaultTranslation.code
  );

  const onTranslateHandler = (translationCode) => {
    setState((prevState) => ({
      ...prevState,
      selectedTranslation: additionalTranslations[translationCode],
      selectedTranslationOption: additionalTranslationsOptions[translationCode],
    }));
  };

  const onRemoveTranslationHandler = (translationCode) => {
    onRemoveTranslation(translationCode);
  };

  const onChangeSelectedTranslationHandler = ({ value: translationCode }) => {
    setState((prevState) => ({
      ...prevState,
      selectedTranslation: additionalTranslations[translationCode],
      selectedTranslationOption: additionalTranslationsOptions[translationCode],
    }));
  };

  const onGoBackHandler = () => {
    setState((prevState) => ({
      ...prevState,
      selectedTranslation: null,
    }));
  };

  return (
    <div className={classNames('modal', 'is-active', classes.modal)}>
      <div
        id="translations-background"
        className="modal-background"
        onClick={onClose}
      />
      <div className={classNames('modal-card', classes['modal-card'])}>
        <header className="modal-card-head">
          <div
            className={classNames('level', classes['modal-header'], {
              'is-mobile': !selectedTranslation,
            })}
          >
            <div className="level-left">
              <div className="level-item">
                <p
                  className={classNames(
                    'modal-card-title',
                    'title',
                    'is-4',
                    classes['modal-title']
                  )}
                >
                  Translations
                </p>
              </div>
            </div>
            <div className="level-right">
              {selectedTranslation && (
                <div
                  className={classNames(
                    'level-item',
                    classes['available-translations']
                  )}
                >
                  <div
                    className={classNames(
                      classes['available-translations__dropdown']
                    )}
                  >
                    <Select
                      maxMenuHeight={100}
                      options={additionalTranslationsOptions}
                      value={selectedTranslationOption}
                      onChange={onChangeSelectedTranslationHandler}
                    />
                  </div>
                  {selectedTranslation && (
                    <button
                      id="translations-close"
                      className="delete"
                      aria-label="close"
                      onClick={onClose}
                    />
                  )}
                </div>
              )}
              {!selectedTranslation && (
                <div className="level-item">
                  <button
                    id="translations-close"
                    className="delete"
                    aria-label="close"
                    onClick={onClose}
                  />
                </div>
              )}
            </div>
          </div>
        </header>
        <section className="modal-card-body">
          {selectedTranslation && (
            <Translate
              defaultTranslation={defaultTranslation.content}
              translationCode={selectedTranslation.code}
              translation={
                additionalTranslations[selectedTranslation.index].content
              }
              onUpdate={onUpdateTranslation}
            />
          )}
          {!selectedTranslation && (
            <>
              {additionalTranslations.map(
                ({ name, percentageCompleted, code }, index) => (
                  <Fragment key={code}>
                    <Translation
                      name={name}
                      percentageCompleted={percentageCompleted}
                      onDelete={() => onRemoveTranslationHandler(code)}
                      onTranslate={() => onTranslateHandler(index)}
                    />
                    <hr />
                  </Fragment>
                )
              )}
              <AddTranslation
                availableLanguages={availableLanguagesOptions}
                onAddTranslation={onAddTranslation}
              />
            </>
          )}
        </section>
        <footer className="modal-card-foot">
          {!selectedTranslation && (
            <button
              id="translations-done"
              className="button is-success"
              onClick={onClose}
            >
              Done
            </button>
          )}
          {selectedTranslation && (
            <button className="button is-light" onClick={onGoBackHandler}>
              Save
            </button>
          )}
        </footer>
      </div>
    </div>
  );
};

Translations.propTypes = {
  defaultTranslation: PropTypes.shape({
    name: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    content: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  additionalTranslations: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      index: PropTypes.number.isRequired,
      content: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ).isRequired,
  onClose: PropTypes.func.isRequired,
  onAddTranslation: PropTypes.func.isRequired,
  onRemoveTranslation: PropTypes.func.isRequired,
  onUpdateTranslation: PropTypes.func.isRequired,
};

export default Translations;
