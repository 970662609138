import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import classNames from 'classnames';

import {
  fetchOrganizations,
  clearOrganizationsData,
  deleteOrganization,
} from 'state/actions/organizations';
import { setDefaultOrganization } from 'state/actions/preferences';
import { useFormatMessage, useFormatDate } from 'hooks';
import { roles } from 'utils';
import paths from 'pages/Router/paths';
import Modal from 'components/Modal';
import Table from 'components/Table';

import classes from './Organizations.module.scss';

const { ADMIN } = roles;

const Organizations = () => {
  const { organizationsList, role, loading, deleted, defaultOrganization } =
    useSelector(
      (state) => ({
        organizationsList: state.organizations.data,
        role: state.auth.userData.role,
        loading: state.organizations.loading,
        deleted: state.organizations.deleted,
        defaultOrganization: state.preferences.defaultOrganization,
      }),
      shallowEqual
    );

  const [deleteModal, setDeleteModal] = useState({
    organizationId: null,
    isOpen: false,
  });

  const dispatch = useDispatch();

  const [search, setSearch] = useState();

  useEffect(() => {
    if (role === ADMIN) {
      const filterInactives = false;
      const filterByOrganization = defaultOrganization?.value.name;
      dispatch(fetchOrganizations(filterInactives, filterByOrganization));
    }

    return () => dispatch(clearOrganizationsData());
  }, [dispatch, role, defaultOrganization]);

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal(() => ({
        organizationId: null,
        isOpen: false,
      }));
    }
  }, [deleted, loading]);

  const redirect = role !== ADMIN && <Redirect to={paths.ROOT} />;

  const onRemoveButtonClickHandler = (organizationId) => {
    setDeleteModal((prevState) => ({
      organizationId,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ organizationId: null, isOpen: false });
  };

  const onDeleteOrganizationHandler = () => {
    dispatch(deleteOrganization(deleteModal.organizationId));
  };

  const changeOrganizationHandler = useCallback(
    ({ displayName, displaySiteName, currentWave, name, id }) =>
      () =>
        dispatch(
          setDefaultOrganization({
            label: displayName,
            value: {
              currentWave,
              displayName,
              displaySiteName,
              name,
              id,
            },
          })
        ),
    []
  );

  const columns = [
    {
      Header: useFormatMessage('Organizations.name'),
      accessor: 'displayName',
      Cell: ({ row }) => (
        <Link to={`${paths.ORGANIZATIONS}/${row.original.displaySiteName}`}>
          {row.original.displayName}
        </Link>
      ),
    },
    {
      Header: useFormatMessage('Organizations.siteName'),
      accessor: 'displaySiteName',
    },
    {
      Header: useFormatMessage('Organizations.industry'),
      accessor: 'industry',
    },
    {
      Header: useFormatMessage('Organizations.currentWave'),
      accessor: 'currentWave',
      Cell: ({ row }) => row.original.currentWave || 'None',
    },
    {
      Header: useFormatMessage('Organizations.usersNumber'),
      accessor: 'users',
    },
    {
      Header: useFormatMessage('Organizations.groupsNumber'),
      accessor: 'groups',
    },
    {
      Header: useFormatMessage('Organizations.active'),
      accessor: 'active',
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {row.original.active ? (
            <span className="icon">
              <i className="mdi mdi-check" />
            </span>
          ) : (
            <span className="icon">
              <i className="mdi mdi-close" />
            </span>
          )}
        </small>
      ),
    },
    {
      Header: useFormatMessage('Organizations.createdAt'),
      id: 'createdAt',
      accessor: (row) => new Date(row.createdAt),
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {useFormatDate(row.original.createdAt, {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })}
        </small>
      ),
      sortType: 'datetime',
    },
    {
      Header: useFormatMessage('Organizations.createdBy'),
      accessor: 'createdBy',
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {row.original.createdBy}
        </small>
      ),
    },
    {
      Header: '',
      id: 'actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <>
          {role === ADMIN && (
            <div className={classNames('buttons is-right', classes.actions)}>
              <button
                type="button"
                className="button is-small is-info"
                onClick={changeOrganizationHandler(row.original)}
              >
                <span className="icon is-small">
                  <i className="mdi mdi-cursor-default-click" />
                </span>
              </button>
              <Link
                to={`${paths.ORGANIZATIONS}/${row.original.displaySiteName}`}
                className="button is-small is-primary"
              >
                <span className="icon is-small">
                  <i className="mdi mdi-account-edit" />
                </span>
              </Link>

              <button
                type="button"
                className="button is-small is-danger"
                onClick={() => onRemoveButtonClickHandler(row.original.id)}
              >
                <span className="icon is-small">
                  <i className="mdi mdi-trash-can" />
                </span>
              </button>
            </div>
          )}
        </>
      ),
      disableSortBy: true,
    },
  ];

  const data = search
    ? organizationsList.filter((el) => {
        const clonedElem = { ...el };
        delete clonedElem.id;
        delete clonedElem.active;
        delete clonedElem.createdAt;
        delete clonedElem.groups;
        delete clonedElem.users;
        delete clonedElem.currentWave;
        delete clonedElem.processingThreshold;
        delete clonedElem.waves;
        delete clonedElem.layout;
        return Object.values(clonedElem).some((field) =>
          field.toLowerCase().includes(search.toLowerCase())
        );
      })
    : organizationsList;

  return (
    <>
      {redirect}
      {deleteModal.isOpen && (
        <Modal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage="Delete"
          title="Confirm action"
          body="This will permanently delete the organization. Action cannot be undone."
          cancelButtonMessage="Cancel"
          onConfirmation={onDeleteOrganizationHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h1 className="title">
                  {useFormatMessage('Organizations.organizations')}
                </h1>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <Link to={paths.ADD_ORGANIZATION} className="button">
                  {useFormatMessage('Organizations.newOrganization')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section is-main-section">
        <div className="card has-table has-mobile-sort-spaced">
          <header className="card-header">
            <p className="card-header-title table-header">
              <span>{useFormatMessage('Organizations.search')}</span>
              <input
                type="text"
                className="input"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </p>
          </header>
          <div className="b-table">
            {loading ? <ClipLoader /> : <Table columns={columns} data={data} />}
          </div>
        </div>
      </section>
    </>
  );
};

export default Organizations;
