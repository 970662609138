import { isString } from 'utils';
import firebase from 'firebase.js';
import { TagType } from './enums';

const getFirestoreRef = (path) => firebase.firestore().collection(path);

export const fetchDocument = async (collection, id) => {
  const document = await getFirestoreRef(collection).doc(id).get();

  if (!document.exists) {
    return null;
  }

  return { id: document.id, ...document.data() };
};

export const fetchCollection = async (collection, options = {}) => {
  const data = [];

  let baseQuery = getFirestoreRef(collection);

  if (options.filterByActive) {
    const { filterByActive } = options;
    baseQuery = baseQuery.where('active', '==', filterByActive);
  }

  if (options.filterByOrganization) {
    const { filterByOrganization } = options;
    baseQuery = baseQuery.where('organizationName', '==', filterByOrganization);
  }

  if (options.queries) {
    const { queries } = options;
    queries.forEach(({ attribute, operator, value }) => {
      baseQuery = baseQuery.where(attribute, operator, value);
    });
  }

  if (options.sort) {
    const { attribute, order } = options.sort;
    baseQuery = baseQuery.orderBy(attribute, order);
  }

  (await baseQuery.get()).forEach((doc) =>
    data.push({ id: doc.id, ...doc.data() })
  );

  return data;
};

export const fetchOrganizations = async (setState, filterInactives = false) => {
  setState((prevState) => ({ ...prevState, loading: true }));

  let organizations;

  try {
    const options = {};

    if (filterInactives) {
      options.filterByActive = true;
    }

    organizations = await fetchCollection('organizations', options);
  } catch (error) {
    return setState({ error: true });
  }

  const data = organizations.map(
    ({ displayName, id, currentWave, waves, name, displaySiteName }) => {
      const wavesOpt = (waves ?? []).map((wave) => ({
        label: wave,
        value: wave,
      }));

      return {
        label: displayName,
        value: {
          displayName,
          waves: wavesOpt,
          id,
          currentWave,
          name,
          displaySiteName,
        },
      };
    }
  );

  return setState((prevState) => ({
    ...prevState,
    loading: false,
    fetched: true,
    data,
  }));
};

export const fetchTags = async (
  state,
  setState,
  tagType = null,
  organization = null
) => {
  if (state.fetched || state.loading) return null;

  setState((prevState) => ({ ...prevState, loading: true }));

  let tags;
  try {
    const queries = [];
    const options = {};

    if (tagType) {
      queries.push({ attribute: 'type', operator: '==', value: tagType });
    }

    if (organization) {
      options.filterByOrganization = organization;
    }

    tags = await fetchCollection('tags', { ...options, queries });
  } catch (error) {
    return setState({ error: true });
  }

  const data = tags.map(({ id, displayName, organizationName, type }) => ({
    label: displayName,
    value: {
      tagId: id,
      tagDisplayName: displayName,
      tagOrganizationName: organizationName,
      tagType: type,
    },
  }));

  return setState((prevState) => ({
    ...prevState,
    loading: false,
    fetched: true,
    data,
  }));
};

export const fetchDataSet = async (id, setState, setDataItems) => {
  setState((prevState) => ({ ...prevState, loading: true }));

  let data;
  try {
    const getQuestionChoices = fetchCollection('questionChoice', {
      queries: [{ attribute: 'dataSetId', operator: '==', value: id }],
      sort: { attribute: 'sortOrder', order: 'asc' },
    });

    const {
      tagId,
      tagDisplayName,
      tagOrganizationName,
      tagType,
      startWave,
      active,
      title,
    } = await fetchDocument('dataSets', id);

    const global = tagOrganizationName === 'global';

    const getOrganization = global
      ? Promise.resolve()
      : fetchCollection('organizations', {
          queries: [
            {
              attribute: 'displayName',
              operator: '==',
              value: tagOrganizationName,
            },
          ],
        });

    const [questionChoices, organization] = await Promise.all([
      getQuestionChoices,
      getOrganization,
    ]);

    const waves = global ? [] : organization.pop()?.waves || [1, 2, 3];
    const wavesOpt = waves.map((wave) => ({ label: wave, value: wave }));

    const parsedTitle = (isString(title) ? { en: title } : title) || {
      en: '',
    };

    const dataItems = {};
    const previous = {};

    questionChoices.forEach(
      ({ id: dataItemId, option, paramOne, paramTwo }) => {
        Object.entries(option).forEach(([languageCode, translation]) => {
          const dataItem = {
            id: dataItemId,
            option: translation,
            paramOne: paramOne || '',
            paramTwo: paramTwo || '',
          };

          if (dataItems[languageCode]) {
            dataItems[languageCode].push(dataItem);
            previous[languageCode].push(dataItem);
          } else {
            dataItems[languageCode] = [dataItem];
            previous[languageCode] = [dataItem];
          }
        });
      }
    );

    setDataItems({ data: dataItems, previous });

    data = {
      tag: {
        label: tagDisplayName,
        value: {
          tagId,
          tagDisplayName,
          tagOrganizationName,
          tagType,
        },
      },
      global,
      organization: global
        ? null
        : {
            label: tagOrganizationName,
            value: { displayName: tagOrganizationName, waves: wavesOpt },
          },
      startWave:
        global || !startWave ? null : { label: startWave, value: startWave },
      active,
      title: parsedTitle,
    };
  } catch (error) {
    return setState((prevState) => ({ ...prevState, error: true }));
  }

  return setState((prevState) => ({
    ...prevState,
    loading: false,
    ...data,
  }));
};

export const fetchGroup = async (state, setState, id) => {
  if (state.fetched || state.loading) return null;

  setState((prevState) => ({ ...prevState, loading: true }));

  let data;
  try {
    const group = await fetchDocument('groups', id);

    if (!group) {
      return setState((prevState) => ({ ...prevState, error: true }));
    }

    const {
      organizationName,
      displayName,
      description,
      manager,
      active,
      expectedCount,
      waves,
      usersCount,
    } = group;

    data = {
      id: group.id,
      organization: {
        label: organizationName,
        value: {
          displayName: organizationName,
        },
      },
      displayName,
      description,
      manager: manager
        ? {
            label: `${manager.firstName} ${manager.lastName}`,
            value: manager,
          }
        : null,
      active,
      expectedCount: expectedCount !== null ? expectedCount : '',
      waves,
      usersCount,
    };
  } catch (error) {
    return setState((prevState) => ({ ...prevState, error: true }));
  }

  return setState((prevState) => ({
    ...prevState,
    loading: false,
    fetched: true,
    data,
  }));
};

export const fetchUsers = async (
  state,
  setState,
  filterInactives = false,
  organization = null,
  filterByManager = false
) => {
  if (state.fetched || state.loading) return null;

  setState((prevState) => ({ ...prevState, loading: true }));

  let users;
  try {
    const options = {};
    const queries = [];

    if (filterInactives) {
      options.filterByActive = true;
    }

    if (organization) {
      options.filterByOrganization = organization;
    }

    if (filterByManager) {
      queries.push({
        attribute: 'manager',
        operator: '==',
        value: true,
      });
    }

    users = await fetchCollection('users', { ...options, queries });
  } catch (error) {
    return setState((prevState) => ({ ...prevState, error: true }));
  }

  const data = users.map((user) => {
    return {
      label: `${user.firstName} ${user.lastName}`,
      value: {
        firstName: user.firstName,
        lastName: user.lastName,
        id: user.id,
      },
    };
  });

  return setState((prevState) => ({
    ...prevState,
    loading: false,
    fetched: true,
    data,
  }));
};

export const fetchWavesOptions = async (
  state,
  setState,
  organization = null,
  groupWaves = [],
  setGroup = null
) => {
  if (state.loading) return null;

  setState((prevState) => ({ ...prevState, loading: true }));

  let waves;
  try {
    const options = {};
    if (organization) {
      options.filterByOrganization = organization;
    }
    waves = await fetchCollection('waves', { ...options });
  } catch (error) {
    return setState((prevState) => ({ ...prevState, error: true }));
  }

  const returnData = [];
  const selectedData = [];
  const selectedWaves = groupWaves || [];

  waves.forEach((wave) => {
    const isSelected = selectedWaves.filter((selected) => {
      if (selected.value) {
        return selected.value.id === wave.id;
      }
      return selected === wave.id;
    });
    if (isSelected.length === 0) {
      returnData.push({
        label: wave.name ? `${wave.name}` : `${wave.index}`,
        value: {
          name: wave.name,
          id: wave.id,
          index: wave.index,
        },
      });
    } else {
      selectedData.push({
        label: wave.name ? `${wave.name}` : `${wave.index}`,
        value: {
          name: wave.name,
          id: wave.id,
          index: wave.index,
        },
      });
    }
  });

  if (setGroup) {
    setGroup((prevState) => ({
      ...prevState,
      waves: selectedData,
    }));
  }

  return setState({
    data: returnData,
    loading: false,
    fetched: true,
  });
};

export const fetchOrganization = async (state, setState, siteName) => {
  if (state.fetched || state.loading) return null;

  setState((prevState) => ({ ...prevState, loading: true }));

  let data;
  try {
    const queryResult = await fetchCollection('organizations', {
      queries: [
        { attribute: 'displaySiteName', operator: '==', value: siteName },
      ],
    });

    if (queryResult.length === 0) {
      return setState((prevState) => ({ ...prevState, error: true }));
    }

    data = queryResult.pop();
  } catch (error) {
    return setState((prevState) => ({ ...prevState, error: true }));
  }

  return setState((prevState) => ({
    ...prevState,
    loading: false,
    fetched: true,
    data: { ...data, industry: { value: data.industry, label: data.industry } },
  }));
};

export const fetchDefaultOrganization = async (state, setState, siteName) => {
  if (state.fetched || state.loading) return null;

  setState((prevState) => ({ ...prevState, loading: true }));

  let data;
  try {
    const queryResult = await fetchCollection('organizations', {
      queries: [{ attribute: 'displayName', operator: '==', value: siteName }],
    });

    if (queryResult.length === 0) {
      return setState((prevState) => ({ ...prevState, error: true }));
    }

    data = queryResult.pop();
  } catch (error) {
    return setState((prevState) => ({ ...prevState, error: true }));
  }

  if (data.active) {
    return setState((prevState) => ({
      ...prevState,
      loading: false,
      fetched: true,
      data: {
        ...data,
        industry: { value: data.industry, label: data.industry },
      },
    }));
  }
  return setState((prevState) => ({ ...prevState, error: true }));
};

export const fetchUser = async (setState, id) => {
  setState((prevState) => ({ ...prevState, loading: true }));

  let data;
  try {
    const user = await fetchDocument('users', id);

    if (!user) {
      return setState((prevState) => ({ ...prevState, error: true }));
    }

    data = {
      ...user,
      organization: user.organizationName
        ? {
            label: user.organizationName,
            value: {
              displayName: user.organizationName,
              id: user.organizationId,
            },
          }
        : null,
      role: {
        label: user.role,
        value: user.role,
      },
      groups: user.groups.map((group) => ({ label: group, value: group })),
      emailBefore: user.email,
      email: user.email || '',
    };
  } catch (error) {
    return setState((prevState) => ({ ...prevState, error: true }));
  }

  return setState((prevState) => ({
    ...prevState,
    loading: false,
    fetched: true,
    data,
  }));
};

export const fetchGroups = async (
  state,
  setState,
  filterInactives = false,
  organization = null
) => {
  if (state.fetched || state.loading) return null;

  setState((prevState) => ({ ...prevState, loading: true }));

  let groups;
  try {
    const options = {};

    if (filterInactives) {
      options.filterByActive = true;
    }

    if (organization) {
      options.filterByOrganization = organization;
    }

    groups = await fetchCollection('groups', options);
  } catch (error) {
    return setState((prevState) => ({ ...prevState, error: true }));
  }

  const data = groups.map((group) => {
    const { displayName } = group;
    return {
      label: displayName,
      value: displayName,
    };
  });

  return setState((prevState) => ({
    ...prevState,
    loading: false,
    fetched: true,
    data,
  }));
};

export const fetchTag = async (state, setState, setOldTag, id) => {
  if (state.fetched || state.loading) return null;

  setState((prevState) => ({ ...prevState, loading: true }));

  let data;
  try {
    const tag = await fetchDocument('tags', id);

    if (!tag) {
      return setState((prevState) => ({ ...prevState, error: true }));
    }

    const { name, displayName, type, organizationName, active } = tag;
    const global = organizationName === 'global';

    data = {
      ...tag,
      name: type === TagType.CONSTRUCT ? name : displayName,
      type,
      organization: global
        ? null
        : { label: organizationName, value: { displayName: organizationName } },
      active,
      global,
    };
  } catch (error) {
    return setState((prevState) => ({ ...prevState, error: true }));
  }

  setOldTag(data);
  return setState((prevState) => ({
    ...prevState,
    ...data,
    loading: false,
    fetched: true,
  }));
};

export const fetchQuestionsWithTag = async (state, setState, tagId) => {
  if (state.fetched || state.loading) return null;

  setState((prevState) => ({ ...prevState, loading: true }));

  let value = false;
  let organization = null;
  try {
    const queries = [];
    queries.push({ attribute: 'tagId', operator: '==', value: tagId });
    const questionTags = await fetchCollection('questionTag', {
      queries,
    });
    if (questionTags.length > 0) {
      value = true;
      const questionsIds = [];
      questionTags.forEach((questionTag) => {
        questionsIds.push(questionTag.questionId);
      });
      const questionQueries = [];
      questionQueries.push({
        attribute: '__name__',
        operator: 'in',
        value: questionsIds,
      });
      const questionsWithTag = await fetchCollection('questions', {
        queries: questionQueries,
      });
      if (questionsWithTag.length > 0) {
        const organizationIds = [];
        questionsWithTag.forEach((question) => {
          if (
            !organizationIds.includes(question.organizationId) ||
            question.organizationId === null
          ) {
            organizationIds.push(question.organizationId);
          }
        });
        if (organizationIds.length === 1) {
          const [organizationId] = organizationIds;
          organization = organizationId;
        }
      }
    }
  } catch (error) {
    return setState((prevState) => ({ ...prevState, error: true }));
  }

  return setState((prevState) => ({
    ...prevState,
    value,
    organization,
    loading: false,
    fetched: true,
  }));
};

export const fetchDataSetsWithTag = async (state, setState, tagId) => {
  if (state.fetched || state.loading) return null;

  setState((prevState) => ({ ...prevState, loading: true }));

  let value = false;
  try {
    const queries = [];
    queries.push({ attribute: 'tagId', operator: '==', value: tagId });
    const dataSetsTags = await fetchCollection('dataSets', {
      queries,
    });
    if (dataSetsTags.length > 0) {
      value = true;
    }
  } catch (error) {
    return setState((prevState) => ({ ...prevState, error: true }));
  }

  return setState((prevState) => ({
    ...prevState,
    value,
    loading: false,
    fetched: true,
  }));
};

export const fetchQuestionsWithDataSet = async (state, setState, dataSetId) => {
  if (state.fetched || state.loading) return null;

  setState((prevState) => ({ ...prevState, loading: true }));

  let value = false;
  let organization = null;
  try {
    const queries = [];
    queries.push({ attribute: 'dataSetId', operator: '==', value: dataSetId });
    const questionsWithDataSet = await fetchCollection('questions', {
      queries,
    });
    const organizationIds = [];
    if (questionsWithDataSet.length > 0) {
      value = true;
      questionsWithDataSet.forEach((question) => {
        if (
          !organizationIds.includes(question.organizationId) ||
          question.organizationId === null
        ) {
          organizationIds.push(question.organizationId);
        }
      });
      if (organizationIds.length === 1) {
        const [organizationId] = organizationIds;
        organization = organizationId;
      }
    }
  } catch (error) {
    return setState((prevState) => ({
      ...prevState,
      loading: false,
      error: true,
    }));
  }

  return setState((prevState) => ({
    ...prevState,
    value,
    organization,
    loading: false,
    fetched: true,
  }));
};

export const fetchIndustry = async (state, setState, id) => {
  if (state.fetched || state.loading) return null;

  setState((prevState) => ({ ...prevState, loading: true }));

  let data;
  try {
    const industry = await fetchDocument('industries', id);

    if (!industry) {
      return setState((prevState) => ({ ...prevState, error: true }));
    }

    const { displayName } = industry;

    data = {
      ...industry,
      displayName,
    };
  } catch (error) {
    return setState((prevState) => ({ ...prevState, error: true }));
  }

  return setState((prevState) => ({
    ...prevState,
    data,
    loading: false,
    fetched: true,
  }));
};

export const fetchSelectedGroup = async (state, setState, id) => {
  if (state.fetched || state.loading) return null;

  setState((prevState) => ({ ...prevState, loading: true }));

  let data;
  try {
    const group = await fetchDocument('groups', id);

    if (!group) {
      return setState((prevState) => ({ ...prevState, group: null }));
    }

    const { displayName } = group;
    data = {
      label: displayName,
      value: displayName,
    };
  } catch (error) {
    return setState((prevState) => ({ ...prevState, group: null }));
  }

  return setState((prevState) => ({
    ...prevState,
    group: data,
    loading: false,
    fetched: true,
  }));
};

export const fetchIndustries = async (state, setState) => {
  if (state.fetched || state.loading) return null;

  setState((prevState) => ({ ...prevState, loading: true }));
  let industries;
  try {
    industries = await fetchCollection('industries', {});
  } catch (error) {
    return setState((prevState) => ({ ...prevState, error: true }));
  }

  const data = industries.map((industry) => {
    const { displayName } = industry;
    return {
      label: displayName,
      value: displayName,
    };
  });

  return setState((prevState) => ({
    ...prevState,
    loading: false,
    fetched: true,
    data,
  }));
};
