import React from 'react';
import PropTypes from 'prop-types';

import classes from './Section.module.scss';

const Section = ({ title, children }) => (
  <div className={classes.section}>
    {title && (
      <h3 id="title-placeholder" className="subtitle is-4">
        {title}
      </h3>
    )}
    <div id="children-placeholder">{children}</div>
  </div>
);

Section.propTypes = {
  title: PropTypes.string,
};

Section.defaultProps = {
  title: '',
};

export default Section;
