import React from 'react';
import PropTypes from 'prop-types';
import Deployment from '../Deployment';

import classes from './SurveyDeployments.module.scss';

const SurveyDeployments = ({
  deployments,
  organization,
  version,
  surveyId,
}) => {
  const versionDeployments = deployments.filter(
    (deployment) => deployment.versionId === version.value
  );

  return (
    <>
      {versionDeployments.length === 0 && (
        <div className={classes['no-deployments']}>
          There are not deployments of version <strong>{version.label}</strong>
        </div>
      )}
      {versionDeployments.length > 0 && (
        <div className="columns is-multiline" style={{ width: '100%' }}>
          <div className="column is-full">
            <div className="columns is-vcentered is-multiline">
              <div className="column deployment-attribute__title--desktop ">
                <strong>Organization</strong>
              </div>
              <div className="column deployment-attribute__title--desktop ">
                <strong>Wave</strong>
              </div>
              <div className="column deployment-attribute__title--desktop ">
                <strong>From</strong>
              </div>
              <div className="column deployment-attribute__title--desktop ">
                <strong>To</strong>
              </div>
              <div className="column deployment-attribute__title--desktop ">
                <strong>Status</strong>
              </div>
              <div className="column deployment-attribute__title--desktop " />
            </div>

            {versionDeployments.map(
              (
                { id, url, waveIndex, waveId, startDate, endDate, waveStatus },
                index
              ) => {
                return (
                  <Deployment
                    key={id}
                    organization={organization}
                    url={url}
                    waveIndex={waveIndex}
                    waveId={waveId}
                    startDate={startDate}
                    endDate={endDate}
                    waveStatus={waveStatus}
                    isLastElement={index === versionDeployments.length - 1}
                    surveyId={surveyId}
                    deploymentId={id}
                  />
                );
              }
            )}
          </div>
        </div>
      )}
    </>
  );
};

SurveyDeployments.propTypes = {
  deployments: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      waveId: PropTypes.string.isRequired,
      createdBy: PropTypes.string.isRequired,
      versionName: PropTypes.string.isRequired,
      waveName: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      versionId: PropTypes.string.isRequired,
      totalResponses: PropTypes.number.isRequired,
      unprocessedResponses: PropTypes.number.isRequired,
      waveIndex: PropTypes.number.isRequired,
      startDate: PropTypes.shape({
        seconds: PropTypes.number.isRequired,
        nanoseconds: PropTypes.number.isRequired,
      }).isRequired,
      endDate: PropTypes.shape({
        seconds: PropTypes.number.isRequired,
        nanoseconds: PropTypes.number.isRequired,
      }),
      lastTimeProcessed: PropTypes.shape({
        seconds: PropTypes.number.isRequired,
        nanoseconds: PropTypes.number.isRequired,
      }).isRequired,
      createdAt: PropTypes.shape({
        seconds: PropTypes.number.isRequired,
        nanoseconds: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired
  ),
  organization: PropTypes.string.isRequired,
  version: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
};

export default SurveyDeployments;
