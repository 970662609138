export const createQuestionInGroupObject = ({
  id,
  content,
  type,
  dataSetId,
  surveyId,
  surveyVersionId,
  surveyDeploymentId,
  required,
  choicesOrder,
  template,
  regionalBreakdown,
  organizationId,
}) => {
  const questionInGroup = {
    id,
    question: {
      id,
      type,
      content,
      required,
      template,
      surveyId,
      dataSetId,
      choicesOrder,
      organizationId,
      surveyVersionId,
      regionalBreakdown,
      surveyDeploymentId: surveyDeploymentId ?? null,
    },
  };

  return questionInGroup;
};
