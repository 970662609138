/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';

import HierarchyTree from 'components/HierarchyTree';
import {
  clearData,
  clearUpdatedWave,
  fetchWaves,
  modifyWave,
} from 'state/actions/waves';
import classes from './WaveHierarchyForm.module.scss';

const WaveHierarchyForm = ({ organization }) => {
  const { loadingWaves, waveOptions, updatedWave } = useSelector(
    (state) => ({
      loadingWaves: state.waves.loading,
      updatedWave: state.waves.updatedWave,
      waveOptions: state.waves.data.map((wave) => ({
        label: wave.name || `Wave ${wave.index}`,
        value: wave,
      })),
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const [selectedWave, setSelectedWave] = useState(null);
  const [waveHierarchy, setWaveHierarchy] = useState([]);

  useEffect(() => {
    const options = { filterByOrganization: organization.displayName };

    dispatch(fetchWaves(options));
  }, [organization.displayName]);

  useEffect(() => {
    return () => {
      dispatch(clearData());
    };
  }, [dispatch]);

  useEffect(() => {
    if (updatedWave) {
      const options = { filterByOrganization: organization.displayName };

      dispatch(fetchWaves(options));
      dispatch(clearUpdatedWave());
    }
  }, [updatedWave, organization.displayName, dispatch]);

  const onChangeWaveHierarchy = useCallback((newWaveHierarchy) => {
    setWaveHierarchy(newWaveHierarchy);
  }, []);

  const adaptToHierarchyTree = useCallback((hierarchies) => {
    const hierarchyTreeList = [];
    let adaptedHierarchy = {};

    hierarchies.forEach((hierarchy) => {
      Object.entries(hierarchy).forEach(([key, value]) => {
        if (key === 'groupName') {
          adaptedHierarchy = { ...adaptedHierarchy, title: value };
        } else if (key === 'children') {
          const adaptedChildren = adaptToHierarchyTree(value);
          adaptedHierarchy = { ...adaptedHierarchy, [key]: adaptedChildren };
        } else {
          adaptedHierarchy = { ...adaptedHierarchy, [key]: value };
        }
      });

      hierarchyTreeList.push(adaptedHierarchy);
    });

    return hierarchyTreeList;
  }, []);

  const adaptToWaveHierarchy = useCallback((hierarchies) => {
    const waveHierarchyList = [];
    let adaptedHierarchy = {};

    hierarchies.forEach((hierarchy) => {
      Object.entries(hierarchy).forEach(([key, value]) => {
        if (key === 'title') {
          adaptedHierarchy = { ...adaptedHierarchy, groupName: value };
        } else if (key === 'children') {
          const adaptedChildren = adaptToWaveHierarchy(value);
          adaptedHierarchy = { ...adaptedHierarchy, [key]: adaptedChildren };
        } else if (key !== 'expanded') {
          adaptedHierarchy = { ...adaptedHierarchy, [key]: value };
        }
      });

      waveHierarchyList.push(adaptedHierarchy);
    });

    return waveHierarchyList;
  }, []);

  const currentWaveContentIsDifferentFromSelected = useMemo(() => {
    const currentWave = waveOptions?.find(
      (option) => option?.value.id === selectedWave?.value.id
    );

    const isDifferent =
      JSON.stringify(currentWave?.value.hierarchy) !==
      JSON.stringify(selectedWave?.value.hierarchy);

    if (isDifferent) {
      return currentWave;
    }

    return isDifferent;
  }, [waveOptions, selectedWave]);

  useEffect(() => {
    if (selectedWave) {
      const waveHierarchyList = adaptToHierarchyTree(
        selectedWave.value.hierarchy
      );

      onChangeWaveHierarchy(waveHierarchyList);
    }
  }, [selectedWave, onChangeWaveHierarchy, adaptToHierarchyTree]);

  const onSelectWaveHandler = useCallback((wave) => setSelectedWave(wave), []);

  useEffect(() => {
    if (waveOptions.length > 0 && selectedWave === null) {
      onSelectWaveHandler(waveOptions[0]);
    }

    if (currentWaveContentIsDifferentFromSelected) {
      onSelectWaveHandler(currentWaveContentIsDifferentFromSelected);
    }
  }, [
    currentWaveContentIsDifferentFromSelected,
    waveOptions,
    waveOptions.length,
    selectedWave,
    onSelectWaveHandler,
  ]);

  const canUpdateHierarchy = useMemo(
    () => !!selectedWave && !loadingWaves && waveHierarchy.length > 0,
    [selectedWave, loadingWaves, waveHierarchy.length]
  );

  const updateWaveHierarchy = useCallback(() => {
    if (canUpdateHierarchy) {
      const hierarchy = adaptToWaveHierarchy(waveHierarchy);

      const updatedWaveHierarchy = {
        ...selectedWave.value,
        name: selectedWave.value.name || null,
        expectedResponses: selectedWave.value.expectedResponses
          ? Number(selectedWave.value.expectedResponses)
          : null,
        waveId: selectedWave.value.id,
        hierarchy,
        isUpdatingHierarchy: true,
      };
      dispatch(modifyWave(updatedWaveHierarchy));
    }
  }, [
    canUpdateHierarchy,
    selectedWave,
    waveHierarchy,
    dispatch,
    adaptToWaveHierarchy,
  ]);

  return (
    <div className="tile is-parent">
      <div className="card tile is-child">
        <header className="card-header">
          <p className="card-header-title">
            <span className="icon">
              <i className="mdi mdi-file-tree" />
            </span>
            Wave Hierarchy
          </p>
          <div className="card-header-title">
            <div className="field-label">
              <label className="label">Waves</label>
            </div>
            <div className={classNames('control', classes['wave-select'])}>
              <Select
                classNamePrefix="select"
                options={waveOptions}
                onChange={onSelectWaveHandler}
                value={selectedWave}
                noOptionsMessage={() => 'The organization does not have waves'}
                isDisabled={loadingWaves}
                isLoading={loadingWaves}
              />
            </div>
          </div>
        </header>
        <div className="card-content">
          <div className="field is-horizontal">
            {waveHierarchy.length > 0 && (
              <HierarchyTree
                treeData={waveHierarchy}
                onChangeTreeData={onChangeWaveHierarchy}
              />
            )}
            {waveHierarchy.length === 0 && selectedWave && (
              <div className={classes['empty-hierarchies']}>
                This wave does not have any hierarchies
              </div>
            )}
            {waveOptions.length === 0 && (
              <div className={classes['empty-waves']}>
                This organization does not have any waves
              </div>
            )}
          </div>
        </div>
        <footer>
          <div className={classes['update-button']}>
            <button
              type="button"
              className={classNames('button is-primary', {
                'is-loading': loadingWaves,
              })}
              disabled={!canUpdateHierarchy}
              onClick={updateWaveHierarchy}
            >
              <span>Update</span>
            </button>
          </div>
        </footer>
      </div>
    </div>
  );
};

WaveHierarchyForm.propTypes = {
  organization: PropTypes.shape({
    id: PropTypes.string,
    active: PropTypes.bool.isRequired,
    displayName: PropTypes.string.isRequired,
    displaySiteName: PropTypes.string.isRequired,
    processingThreshold: PropTypes.oneOfType([
      PropTypes.number.isRequired,
      PropTypes.string.isRequired,
    ]),
  }).isRequired,
};

export default WaveHierarchyForm;
