import React from 'react';

import Input, { Type as InputType } from 'components/Input';

const ColumnsWidths = ({ widths, onChange }) => {
  const onChangeFirstColumnWidth = (e) => {
    const { value } = e.target;

    const parsedValue = Number(value);
    const isValidInteger = (Number.isInteger(parsedValue) && parsedValue >= 0 && parsedValue < 10);
    const isEmpty = value === '';

    if (isEmpty || isValidInteger) {
      onChange({
        column: 0,
        width: value,
      });
    }
  };

  return (
    <>
      <h2 className="subtitle columns-widths__title">
        Questions Column Span (1 to 10)
      </h2>
      <div className="columns-widths__content">
        <Input
          type={InputType.Text}
          value={widths[0]}
          min={1}
          max={10}
          onChange={onChangeFirstColumnWidth}
        />
      </div>
    </>
  );
};

export default ColumnsWidths;
