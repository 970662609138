import { v4 as uuid } from 'uuid';

import firebase from 'firebase.js';

export const getBasicSurveyInfo = ({
  getState,
  surveyVersionId: definedSurveyVersionId,
  surveyId: definedSurveyId,
}) => {
  const { locale } = getState().preferences;
  const { firstName, lastName } = getState().auth.userData;

  const createdAt = firebase.firestore.Timestamp.fromDate(new Date());

  const surveyVersionId = definedSurveyVersionId ?? uuid();
  const surveyId = definedSurveyId ?? uuid();

  return {
    locale,
    firstName,
    lastName,
    createdAt,
    surveyVersionId,
    surveyId,
    fullName: `${firstName} ${lastName}`,
  };
};
