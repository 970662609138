import algoliasearch from 'algoliasearch';

import { REACT_APP_ALGOLIA_SECTIONS_SAVES_INDEX } from 'constants/environment';
import { Collections } from 'utils/enums';
import { collection } from 'utils/firebase';

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_ADMIN_KEY
);

const sectionsSavesIndex = searchClient.initIndex(
  REACT_APP_ALGOLIA_SECTIONS_SAVES_INDEX
);

export const deleteSectionSaveService = async ({ objectID }) => {
  try {
    await sectionsSavesIndex.deleteObject(objectID).wait();
    await collection(Collections.SECTIONS_SAVES).doc(objectID).delete();
  } catch (error) {
    console.error(error);

    throw error;
  }
};
