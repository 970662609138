import React from 'react';
import { connectPagination } from 'react-instantsearch-dom';
import classNames from 'classnames';

import classes from './Pagination.module.scss';

const Pagination = ({ currentRefinement, nbPages, refine }) => {
  const pageIndex = currentRefinement - 1;
  const pageCount = nbPages;
  return (
    <nav className="pagination">
      <button
        type="button"
        className="pagination-link pagination-previous"
        onClick={() => refine(currentRefinement - 1)}
        disabled={currentRefinement === 1}
      >
        <span className="icon" aria-hidden="true">
          <i className="mdi mdi-chevron-left mdi-24px" />
        </span>
      </button>
      <ul className="pagination-list">
        {pageIndex !== 0 && (
          <li>
            <button
              type="button"
              className="pagination-link"
              onClick={() => refine(1)}
            >
              1
            </button>
          </li>
        )}
        {pageIndex > 3 && (
          <li>
            <span className="pagination-ellipsis">…</span>
          </li>
        )}
        {pageIndex === 3 && (
          <li>
            <button
              type="button"
              className="pagination-link"
              onClick={() => refine(2)}
            >
              2
            </button>
          </li>
        )}
        {pageIndex - 1 > 0 && (
          <li>
            <button
              type="button"
              className="pagination-link"
              onClick={() => refine(pageIndex)}
            >
              {pageIndex}
            </button>
          </li>
        )}
        <li>
          <button
            type="button"
            className={classNames('pagination-link', classes.currentPage)}
            aria-current="true"
          >
            {pageIndex + 1}
          </button>
        </li>
        {pageIndex < pageCount - 1 && (
          <li>
            <button
              type="button"
              className="pagination-link"
              onClick={() => refine(pageIndex + 2)}
            >
              {pageIndex + 2}
            </button>
          </li>
        )}
        {pageCount - pageIndex === 4 && (
          <li>
            <button
              type="button"
              className="pagination-link"
              onClick={() => refine(pageCount - 1)}
            >
              {pageCount - 1}
            </button>
          </li>
        )}
        {pageCount - pageIndex > 4 && (
          <li>
            <span className="pagination-ellipsis">…</span>
          </li>
        )}
        {currentRefinement + 1 < pageCount && (
          <li>
            <button
              type="button"
              className="pagination-link"
              onClick={() => refine(pageCount)}
            >
              {pageCount}
            </button>
          </li>
        )}
      </ul>
      <button
        type="button"
        className="pagination-link pagination-previous"
        onClick={() => refine(currentRefinement + 1)}
        disabled={currentRefinement === nbPages || nbPages === 0}
      >
        <span className="icon" aria-hidden="true">
          <i className="mdi mdi-chevron-right mdi-24px" />
        </span>
      </button>
    </nav>
  );
};

export default connectPagination(Pagination);
