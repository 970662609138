import firebase from 'firebase.js';
import { firebaseError } from 'utils';
import { Collections } from 'utils/enums';
import { collection } from 'utils/firebase';

export const setQuestions = async ({ questions, locale }) => {
  const batchRollback = firebase.firestore().batch();

  try {
    const batch = firebase.firestore().batch();

    questions.forEach(({ id, question }) => {
      const questionRef = collection(Collections.QUESTIONS).doc(id);

      batch.set(questionRef, question);
      batchRollback.delete(questionRef, question);
    });

    await batch.commit();
  } catch (error) {
    console.error(error);

    const errorMessage = firebaseError(error.code, locale);

    throw new Error(errorMessage);
  }

  const rollback = async () => {
    await batchRollback.commit();
  };

  return rollback;
};
