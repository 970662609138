/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider';

import SliderRail from 'components/CompoundSlider/SliderRail';
import Handle from 'components/CompoundSlider/Handle';
import Track from 'components/CompoundSlider/Track';
import Tick from 'components/CompoundSlider/Tick';
import classes from './ScoreItem.module.scss';

const sliderStyle = {
  position: 'relative',
  width: '100%',
  padding: '1.5rem 0rem',
};

const domain = [0, 100];

const ScoreItem = ({ tag, ranges, setRanges, onDeleteRange, language }) => {
  const [rendering, setRendering] = useState([]);

  useEffect(() => {
    const values = [];
    const descriptions = [];
    tag.scores.forEach((score, index) => {
      if (index === 0) {
        values.push(score.start, score.end);
      } else {
        values.push(score.end);
      }
      descriptions.push(score.description);
    });
    setRanges((prevState) => ({
      ...prevState,
      values,
      update: values,
      descriptions,
    }));
  }, [tag.scores, setRanges]);

  const onUpdate = useCallback(
    (update) => {
      setRanges((prevState) => ({ ...prevState, update }));
    },
    [setRanges]
  );

  const onChange = useCallback(
    (values) => {
      setRanges((prevState) => ({ ...prevState, values }));
    },
    [setRanges]
  );

  const handleChangeText = useCallback(
    (text, index) => {
      const newDescriptions = [...ranges.descriptions];
      newDescriptions[index][language.value] = text;
      setRanges((prevState) => ({
        ...prevState,
        descriptions: newDescriptions,
      }));
    },
    [ranges.descriptions, setRanges, language]
  );

  const renderScores = useCallback(() => {
    const newRendering = [];

    const sortedRanges = {
      ...ranges,
      values: ranges.values?.sort((a, b) => a - b),
      update: ranges.update?.sort((a, b) => a - b),
    };

    for (let index = 0; index < sortedRanges.update.length - 1; index += 1) {
      let startElement = sortedRanges.update[index];

      if (index !== 0) {
        startElement += 1;
      }

      const endElement = sortedRanges.update[index + 1];
      const description = sortedRanges.descriptions[index];

      newRendering.push(
        <div className="field is-horizontal" key={index}>
          <div className="field-label is-normal">
            <label id="data-item-label-placeholder" className="label">
              {startElement} - {endElement}
            </label>
          </div>
          <div className="field-body">
            <div className={classNames('field', classes.option)}>
              <div className="control is-expanded">
                <textarea
                  id={`text-box-item-${index}`}
                  rows="2"
                  className="textarea"
                  name="option"
                  required
                  value={description[language.value]}
                  onChange={(e) => handleChangeText(e.target.value, index)}
                />
              </div>
            </div>
            <button
              id="data-item-remove-item"
              type="button"
              className="button"
              onClick={() => onDeleteRange(index)}
              disabled={sortedRanges.values.length <= 2}
            >
              <span className="icon">
                <i className="mdi mdi-close" />
              </span>
            </button>
          </div>
        </div>
      );
    }

    setRendering(newRendering);
  }, [
    handleChangeText,
    onDeleteRange,
    ranges.descriptions,
    ranges.update,
    ranges.values,
    language.value,
  ]);

  useEffect(() => {
    renderScores();
  }, [ranges.update, ranges.descriptions, renderScores]);

  return (
    <>
      <div className="field is-horizontal">
        <div className="field-label is-normal" />
        <div className="field-body">
          <div className={classNames('field', classes.slider)}>
            <Slider
              mode={2}
              step={1}
              domain={domain}
              rootStyle={sliderStyle}
              onUpdate={onUpdate}
              onChange={onChange}
              values={ranges.values}
            >
              <Rail>
                {({ getRailProps }) => (
                  <SliderRail getRailProps={getRailProps} />
                )}
              </Rail>
              <Handles>
                {({ handles, getHandleProps }) => (
                  <div className="slider-handles">
                    {handles.map((handle) => (
                      <Handle
                        key={handle.id}
                        handle={handle}
                        domain={domain}
                        getHandleProps={getHandleProps}
                      />
                    ))}
                  </div>
                )}
              </Handles>
              <Tracks left={false} right={false}>
                {({ tracks, getTrackProps }) => (
                  <div className="slider-tracks">
                    {tracks.map(({ id, source, target }) => (
                      <Track
                        key={id}
                        source={source}
                        target={target}
                        getTrackProps={getTrackProps}
                      />
                    ))}
                  </div>
                )}
              </Tracks>
              <Ticks count={5}>
                {({ ticks }) => (
                  <div className="slider-ticks">
                    {ticks.map((tick) => (
                      <Tick key={tick.id} tick={tick} count={ticks.length} />
                    ))}
                  </div>
                )}
              </Ticks>
            </Slider>
          </div>
        </div>
      </div>
      {rendering}
    </>
  );
};

ScoreItem.propTypes = {
  tag: PropTypes.shape({
    active: PropTypes.bool,
    global: PropTypes.bool,
    type: PropTypes.string,
    organization: PropTypes.object || null,
    name: PropTypes.string,
    loading: PropTypes.bool,
    fetched: PropTypes.bool,
    error: PropTypes.string || null,
    scores: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  }),
  ranges: PropTypes.shape({
    values: PropTypes.arrayOf(PropTypes.number),
    update: PropTypes.arrayOf(PropTypes.number),
    descriptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    rendering: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  }),
  setRanges: PropTypes.func.isRequired,
  onDeleteRange: PropTypes.func.isRequired,
  language: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
};

export default ScoreItem;
