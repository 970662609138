import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Spinner from 'react-spinners/ClipLoader';
import classNames from 'classnames';

import { selectSectionSaveCreation } from 'state/selectors/sectionsSaves';
import NewModal from 'components/NewModal';
import { colors } from 'styles';

import classes from './SaveSectionConfirmationModal.module.scss';

const CustomFooter = ({ onSubmit, onCancel, loading }) => (
  <footer className="modal-card-foot">
    <div className="is-flex level container">
      <button
        id="primary-cancel-button"
        type="button"
        className="button is-light"
        disabled={loading}
        onClick={onCancel}
      >
        Cancel
      </button>
      <button
        type="button"
        onClick={onSubmit}
        disabled={loading}
        className={classNames('button is-success', {
          'is-loading': loading,
        })}
      >
        {loading ? (
          <Spinner size="18" color={colors.white} />
        ) : (
          'Update Section'
        )}
      </button>
    </div>
  </footer>
);

const SaveSectionConfirmationModal = ({ isOpen, closeModal, onConfirm }) => {
  const { loading, success } = useSelector(selectSectionSaveCreation);

  useEffect(() => {
    if (success) {
      closeModal();
    }
  }, [success, closeModal]);

  return (
    <NewModal
      title="Save Section"
      portalId="save-section-confirmation-modal-root"
      isOpen={isOpen}
      toggle={null}
      CustomFooter={CustomFooter}
      showHeader={false}
      footerProps={{
        onSubmit: onConfirm,
        onCancel: closeModal,
        loading,
      }}
      classNames={{
        body: classes.body,
      }}
    >
      <div className="field">
        <p className="label">
          A save with the same id exists, would you like to update it?
        </p>
      </div>
    </NewModal>
  );
};

SaveSectionConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default SaveSectionConfirmationModal;
