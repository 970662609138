import React, { useState, useEffect, useMemo } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';

import UserForm from 'components/UserForm';
import { createUser, modifyUser } from 'state/actions/users';
import paths from 'pages/Router/paths';
import { fetchUser } from 'utils/fetching';

const User = () => {
  const { id } = useParams();

  const { success } = useSelector(
    (state) => ({
      success: state.users.success,
    }),
    shallowEqual
  );

  const isEditing = useMemo(() => !!id, [id]);

  const [user, setUser] = useState({
    data: {
      firstName: '',
      lastName: '',
      email: '',
      emailBefore: '',
      role: null,
      manager: false,
      active: true,
      organization: null,
      groups: [],
    },
    loading: isEditing,
    error: null,
  });

  useEffect(() => {
    if (isEditing) {
      fetchUser(setUser, id);
    }
  }, [id, isEditing]);

  const redirect = (user.error || success) && <Redirect to={paths.USERS} />;

  return (
    <>
      {redirect}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">{isEditing ? 'Edit user' : 'New user'}</h1>
        </div>
      </section>
      <section className="section is-main-section">
        {isEditing && user.loading ? (
          <ClipLoader />
        ) : (
          <UserForm
            isEditing={isEditing}
            userData={user.data}
            action={isEditing ? modifyUser : createUser}
          />
        )}
      </section>
    </>
  );
};

export default User;
