import React, { useEffect } from 'react';
import Spinner from 'react-spinners/ClipLoader';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { colors } from 'styles';

import ReactPortal from './ReactPortal';

import classes from './NewModal.module.scss';

const NewModal = ({
  children,
  portalId = 'modal-root',
  title = '',
  isOpen,
  toggle = null,
  showHeader = true,
  CustomFooter = null,
  footerProps = {},
  canExitByClickingBackground = true,
  showSubmitButton,
  submitButtonProps = {
    onSubmit: null,
    canSubmit: true,
    loading: false,
    buttonText: '',
  },
  classNames = {
    header: '',
    body: '',
    footer: '',
  },
  onUnmount = null,
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
    } else {
      document.body.style.position = 'unset';

      if (onUnmount) {
        onUnmount();
      }
    }
  }, [isOpen, onUnmount]);

  if (!isOpen) return null;

  return (
    <ReactPortal wrapperId={portalId}>
      <div className="modal is-active">
        <div
          id="background"
          className="modal-background"
          onClick={canExitByClickingBackground ? toggle : null}
        />
        <div className="modal-card">
          {showHeader && (
            <header className={cn('modal-card-head', classNames.header)}>
              <p className="modal-card-title">{title}</p>
              <button
                id="secondary-cancel-button"
                type="button"
                className="delete"
                aria-label="close"
                onClick={toggle}
              />
            </header>
          )}
          <section
            className={cn('modal-card-body', classNames.body, {
              [classes['content-without-header']]: !showHeader,
            })}
          >
            {children}
          </section>
          {CustomFooter ? (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <CustomFooter {...footerProps} />
          ) : (
            <footer
              className={cn('modal-card-foot', classNames.footer, {
                [classes.footer]: showSubmitButton,
              })}
            >
              <button
                id="secondary-cancel-button"
                type="button"
                className="button"
                aria-label="close"
                onClick={toggle}
                disabled={!toggle}
              >
                Close
              </button>
              {showSubmitButton && (
                <button
                  type="button"
                  className={cn('button is-success', {
                    'is-loading': submitButtonProps.loading,
                  })}
                  onClick={submitButtonProps.onSubmit}
                  disabled={
                    !submitButtonProps.canSubmit || submitButtonProps.loading
                  }
                >
                  {submitButtonProps.loading ? (
                    <Spinner size="18" color={colors.white} />
                  ) : (
                    submitButtonProps.buttonText
                  )}
                </button>
              )}
            </footer>
          )}
        </div>
      </div>
    </ReactPortal>
  );
};

NewModal.propTypes = {
  children: PropTypes.node.isRequired,
  portalId: PropTypes.string,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  CustomFooter: PropTypes.func,
  classNames: PropTypes.shape({
    header: PropTypes.string,
    body: PropTypes.string,
    footer: PropTypes.string,
  }),
  footerProps: PropTypes.shape({}),
  onUnmount: PropTypes.func,
};

export default NewModal;
