/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Spinner from 'react-spinners/ClipLoader';

import Table from 'components/Table';
import paths from 'pages/Router/paths';

import { colors } from 'styles';
import { useFormatDate } from 'hooks';

import { selectTriggerAnalyticsState } from 'state/selectors/analytics';
import { surveyDeploymentStatusDisplayText } from 'utils/surveyBuilder';
import { SurveyDeploymentStatus } from 'utils/surveyBuilder/enums';
import getDeploymentStatus from 'utils/surveyBuilder/getDeploymentStatus';
import getDashboardWavePreviewUrl from 'utils/getDashboardWavePreviewUrl';

import classes from './DeploymentsTable.module.scss';
import './DeploymentsTable.scss';

const DeploymentsTable = ({ data, isLoading, onTriggerAnalytics }) => {
  const { loading } = useSelector(selectTriggerAnalyticsState, shallowEqual);

  const [search, setSearch] = useState('');

  const onChangeSearchHandler = (searchValue) => setSearch(searchValue);

  const columns = [
    {
      Header: 'Status',
      accessor: '',
      Cell: ({ row }) => {
        const { startDate, endDate, waveStatus } = row.original;
        const deploymentStatus = getDeploymentStatus(
          startDate,
          endDate,
          waveStatus
        );
        return (
          <span
            className={classNames('tag ', {
              'is-warning': deploymentStatus === SurveyDeploymentStatus.PENDING,
              'is-success': deploymentStatus === SurveyDeploymentStatus.ACTIVE,
              'is-danger': deploymentStatus === SurveyDeploymentStatus.CLOSED,
              'is-light': deploymentStatus === SurveyDeploymentStatus.INACTIVE,
            })}
          >
            {surveyDeploymentStatusDisplayText(deploymentStatus)}
          </span>
        );
      },
    },
    {
      Header: 'Survey',
      accessor: 'surveyName',
    },
    {
      Header: 'Version',
      accessor: 'versionName',
      Cell: ({ row }) => (
        <a
          href={`${paths.SURVEYS}/${row.original.surveyId}/versions/${row.original.versionId}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {row.original.versionName}
        </a>
      ),
    },
    {
      Header: 'Organization',
      accessor: 'organizationName',
      Cell: ({ row }) => (
        <a
          href={`${paths.ORGANIZATIONS}/${row.original.organizationDisplaySiteName}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {row.original.organizationName}
        </a>
      ),
    },
    {
      Header: 'Wave index',
      accessor: 'waveIndex',
      Cell: ({ row }) => (
        <a
          href={`${paths.WAVES}/${row.original.waveId}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {row.original.waveIndex}
        </a>
      ),
    },
    {
      Header: 'Wave name',
      accessor: 'waveName',
      Cell: ({ row }) => row.original.waveName || '-',
    },
    {
      Header: 'Unprocessed',
      accessor: 'unprocessedResponses',
    },
    {
      Header: 'Processed',
      accessor: 'processed',
      Cell: ({ row }) =>
        row.original.totalResponses - row.original.unprocessedResponses,
    },
    {
      Header: 'Start date',
      accessor: 'startDate',
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {useFormatDate(row.original.startDate, {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })}
        </small>
      ),
    },
    {
      Header: 'End date',
      accessor: 'endDate',
      Cell: ({ row }) => {
        const { endDate } = row.original;
        const parsedEndDate = useFormatDate(endDate, {
          weekday: 'short',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        });
        return (
          <small className="has-text-grey is-abbr-like">
            {endDate ? parsedEndDate : 'Forever'}
          </small>
        );
      },
    },
    {
      Header: 'Last time processed',
      accessor: 'lastTimeProcessed',
      Cell: ({ row }) => {
        const { lastTimeProcessed } = row.original;
        const parsedLTimeProcessed = useFormatDate(lastTimeProcessed, {
          weekday: 'short',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        });
        return (
          <small className="has-text-grey is-abbr-like">
            {lastTimeProcessed ? parsedLTimeProcessed : '-'}
          </small>
        );
      },
    },
    {
      Header: '',
      id: 'actions',
      accessor: 'actions',
      Cell: ({ row }) => {
        const {
          startDate,
          endDate,
          waveId,
          waveStatus,
          surveyId,
          id: deploymentId,
        } = row.original;

        const deploymentStatus = getDeploymentStatus(
          startDate,
          endDate,
          waveStatus
        );

        const deploymentIsLoading =
          loading &&
          loading.find(
            (loadingDeploymentId) => loadingDeploymentId === deploymentId
          );

        const wavePreviewUrl = getDashboardWavePreviewUrl(waveId);
        return (
          <div className={classNames('buttons', classes.buttons)}>
            <a
              href={`${paths.SURVEYS}/${surveyId}/deployments/${deploymentId}`}
              rel="noopener noreferrer"
              target="_blank"
              className="button is-link is-small"
              data-tip="Edit deployment"
            >
              <span className="icon is-small">
                <i className="mdi mdi-pencil" />
              </span>
            </a>
            <a
              href={`${paths.LIVE_SURVEY}/${row.original.url}`}
              rel="noopener noreferrer"
              target="_blank"
              className="button is-primary is-small"
              data-tip="View live survey"
            >
              <span className="icon is-small">
                <i className="mdi mdi-eye" />
              </span>
            </a>
            <a
              href={wavePreviewUrl}
              rel="noopener noreferrer"
              target="_blank"
              className="button is-link is-small"
              data-tip="View deployment details"
            >
              <span className="icon is-small">
                <i className="mdi mdi-chart-areaspline" />
              </span>
            </a>
            {(deploymentStatus === SurveyDeploymentStatus.ACTIVE ||
              deploymentStatus === SurveyDeploymentStatus.CLOSED) && (
              <span
                data-tip={
                  loading
                    ? 'Wait until current trigger is finished'
                    : 'Trigger processing of the responses'
                }
                className={classNames(
                  'button',
                  classes['trigger-analytics-data-tip']
                )}
              >
                <button
                  type="button"
                  className={classNames(
                    'button is-success is-small',
                    classes['trigger-analytics']
                  )}
                  onClick={() =>
                    onTriggerAnalytics({ surveyId, deploymentId, waveId })
                  }
                  disabled={loading}
                >
                  <span className="icon is-small">
                    {deploymentIsLoading ? (
                      <Spinner size="18" color={colors.white} />
                    ) : (
                      <i className="mdi mdi-play" />
                    )}
                  </span>
                </button>
              </span>
            )}
            <ReactTooltip />
          </div>
        );
      },
      disableSortBy: true,
    },
  ];

  const tableDate = search
    ? data.filter((el) => {
        const clonedElem = { ...el };
        delete clonedElem.createdAt;
        delete clonedElem.createdBy;
        delete clonedElem.endDate;
        delete clonedElem.id;
        delete clonedElem.items;
        delete clonedElem.organizationId;
        delete clonedElem.organizationSiteName;
        delete clonedElem.pages;
        delete clonedElem.pagesOrder;
        delete clonedElem.sections;
        delete clonedElem.startDate;
        delete clonedElem.surveyId;
        delete clonedElem.timeProcessed;
        delete clonedElem.unprocessedResponses;
        delete clonedElem.totalResponses;
        delete clonedElem.versionId;
        delete clonedElem.waveId;
        return Object.values(clonedElem).some((field) =>
          field?.toString().toLowerCase().includes(search.toLowerCase())
        );
      })
    : data;

  return (
    <div className="card has-table has-mobile-sort-spaced">
      <header className="card-header table-header">
        <div className="level" style={{ width: '100%' }}>
          <div className="level-left">
            <div className="level-item">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-cloud-upload-outline" />
                </span>
                <span>Deployments</span>
              </p>
            </div>
          </div>
          <div className="level-right">
            <div className={classNames('level-item', classes['search-input'])}>
              <span>
                <b>Search</b>
              </span>
              <input
                className="input"
                type="text"
                value={search}
                onChange={(e) => onChangeSearchHandler(e.target.value)}
              />
            </div>
          </div>
        </div>
      </header>
      <div className="deployment-table">
        <Table
          id="deployments-table"
          columns={columns}
          data={tableDate}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

DeploymentsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onTriggerAnalytics: PropTypes.func.isRequired,
};

export default DeploymentsTable;
