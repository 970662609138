import firebase from 'firebase';

// TO DO: change the next collections references to collection(COLLECTIONS.SELECTED_COLLECTION) when are used
export const groupsCollection = firebase.firestore().collection('groups');
export const demographicsCollection = firebase
  .firestore()
  .collection('demographics');
export const surveysCollection = firebase.firestore().collection('surveys');
export const questionsCollection = firebase.firestore().collection('questions');
export const questionTagCollection = firebase
  .firestore()
  .collection('questionTag');
export const questionChoicesCollection = firebase
  .firestore()
  .collection('questionChoice');
export const tagsCollection = firebase.firestore().collection('tags');
export const responsesCollection = firebase.firestore().collection('responses');
export const surveyVersionsCollection = (surveyId) =>
  surveysCollection.doc(surveyId).collection('versions');
export const surveyDeploymentsCollection = (surveyId) =>
  surveysCollection.doc(surveyId).collection('deployments');
