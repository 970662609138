import React, { useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ActionBuilderConditionTypes } from 'utils/enums';
import classes from './Conditions.module.scss';
import Condition from './Condition';

const Conditions = ({
  conditions,
  onAddCondition,
  onDeleteCondition,
  onChangeOperator,
  onChangeCondition,
  subjects,
}) => {
  const canDelete = useMemo(() => conditions.length > 1, [conditions]);
  return (
    <div className={classes.conditions}>
      <div className="card-header">
        <p className="card-header-title">Conditions</p>
      </div>
      <div
        className={classNames('card-content', classes['conditions-content'])}
      >
        {conditions.map((condition, index) => {
          const key = index;
          return condition.type === ActionBuilderConditionTypes.OPERATOR ? (
            <div
              className={classNames('field, is-horizontal', classes.operator)}
              key={key}
            >
              <button
                id="add-operator"
                type="button"
                className="button has-text-light has-background-grey"
                onClick={() => onChangeOperator(index)}
              >
                <span className="icon">
                  <i className="mdi mdi-flash" />
                </span>
                <span>{condition.operator}</span>
              </button>
            </div>
          ) : (
            <Condition
              key={key}
              condition={condition}
              index={index}
              onChangeCondition={onChangeCondition}
              onDeleteCondition={onDeleteCondition}
              canDelete={canDelete}
              subjects={subjects}
            />
          );
        })}
        <button
          id="add-condition"
          type="button"
          className="button is-white"
          onClick={onAddCondition}
        >
          <span className="icon">
            <i className="mdi mdi-plus-circle-outline" />
          </span>
          <span>Add condition</span>
        </button>
      </div>
    </div>
  );
};

Conditions.propTypes = {
  conditions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  onAddCondition: PropTypes.func.isRequired,
  onDeleteCondition: PropTypes.func.isRequired,
  onChangeOperator: PropTypes.func.isRequired,
  onChangeCondition: PropTypes.func.isRequired,
  subjects: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
};

export default Conditions;
