import React, { useState, useEffect, useMemo } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';

import {
  createIndustry,
  modifyIndustry,
  clearIndustriesData,
} from 'state/actions/industries';
import paths from 'pages/Router/paths';
import { fetchIndustry } from 'utils/fetching';
import IndustryForm from './IndustryForm';

const Industry = () => {
  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(clearIndustriesData());
  }, [dispatch]);

  const { successUpdatingIndustry, successCreatingIndustry } = useSelector(
    (state) => ({
      successUpdatingIndustry: state.industries.successUpdatingIndustry,
      successCreatingIndustry: state.industries.successCreatingIndustry,
    }),
    shallowEqual
  );

  const isEditing = useMemo(() => !!id, [id]);

  const [industry, setIndustry] = useState({
    data: {
      displayName: '',
    },
    loading: false,
    fetched: false,
    error: null,
  });

  useEffect(() => {
    if (isEditing) {
      fetchIndustry(industry, setIndustry, id);
    }
  }, [id, isEditing, industry]);

  const redirect = (industry.error ||
    successUpdatingIndustry ||
    successCreatingIndustry) && <Redirect to={paths.INDUSTRIES} />;

  return (
    <>
      {redirect}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">
            {isEditing ? 'Edit industry' : 'New industry'}
          </h1>
        </div>
      </section>
      <section className="section is-main-section">
        {isEditing && industry.loading ? (
          <ClipLoader />
        ) : (
          <IndustryForm
            isEditing={isEditing}
            industryData={industry.data}
            action={isEditing ? modifyIndustry : createIndustry}
          />
        )}
      </section>
    </>
  );
};

export default Industry;
