/* eslint-disable import/no-extraneous-dependencies */

import firebase from 'firebase/compat/app';
// import { getDatabase, connectDatabaseEmulator } from 'firebase/database';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/functions';

import Cookies from 'js-cookie';
import * as qs from 'query-string';

const config = {
  apiKey: process.env.REACT_APP_FIRE_BASE_KEY,
  authDomain: process.env.REACT_APP_FIRE_BASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIRE_BASE_DB_URL,
  projectId: process.env.REACT_APP_FIRE_BASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIRE_BASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIRE_BASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIRE_BASE_APP_ID,
  measurementId: process.env.REACT_APP_FIRE_BASE_MEASURMENT_ID,
};

firebase.initializeApp(config);

const db = firebase.firestore();
const functions = firebase.functions();
const storage = firebase.storage();

if (window.location.hostname === 'localhost') {
  const params = qs.parse(window.location.search);

  if (params.emulator === 'true') {
    Cookies.set('emulator', true);
  }

  if (params.emulator === 'false') {
    Cookies.remove('emulator');
  }

  if (Cookies.get('emulator')) {
    db.useEmulator('localhost', 8080);
    functions.useEmulator('localhost', 5001);
    storage.useEmulator('localhost', 9199);
  }
}

export default firebase;
