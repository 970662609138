/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback } from 'react';
import { connectToggleRefinement } from 'react-instantsearch-dom';
import classNames from 'classnames';
import Proptypes from 'prop-types';

import classes from './ToggleRefinement.module.scss';

const ToggleRefinement = ({ currentRefinement, refine }) => {
  const onChangeHandler = useCallback(
    () => refine(!currentRefinement),
    [refine, currentRefinement]
  );
  return (
    <label className={classNames('b-checkbox checkbox', classes.checkbox)}>
      <input
        type="checkbox"
        onChange={onChangeHandler}
        checked={currentRefinement}
      />
      <span className="check is-primary" />
    </label>
  );
};

ToggleRefinement.propTypes = {
  currentRefinement: Proptypes.bool.isRequired,
  refine: Proptypes.func.isRequired,
};

export default connectToggleRefinement(ToggleRefinement);
