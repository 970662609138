import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import classes from './Deployments.module.scss';
import SurveyDeployments from './SurveyDeployments';

const DeploymentsModal = ({
  onClose,
  deployments,
  organization,
  version,
  surveyId,
}) => {
  return (
    <div className={classNames('modal', 'is-active', classes.modal)}>
      <div
        id="deploymentModal-background"
        className="modal-background"
        onClick={onClose}
      />
      <div className={classNames('modal-card', classes['modal-card'])}>
        <header className="modal-card-head">
          <div
            className={classNames('level is-mobile', classes['modal-header'])}
          >
            <div className="level-left">
              <div className="level-item">
                <p
                  className={classNames(
                    'modal-card-title',
                    'title',
                    'is-4',
                    classes['modal-title']
                  )}
                >
                  Deployments
                </p>
              </div>
            </div>
            <div className="level-right">
              <button
                id="deploymentsModal-crossButton"
                className="delete"
                aria-label="close"
                onClick={onClose}
              />
            </div>
          </div>
        </header>
        <section className="modal-card-body">
          {deployments && (
            <SurveyDeployments
              deployments={deployments}
              organization={organization}
              version={version}
              surveyId={surveyId}
            />
          )}
        </section>
        <footer className="modal-card-foot">
          <button
            id="deploymentModal-close"
            className="button is-light"
            onClick={onClose}
          >
            Close
          </button>
        </footer>
      </div>
    </div>
  );
};

DeploymentsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  deployments: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      waveId: PropTypes.string.isRequired,
      createdBy: PropTypes.string.isRequired,
      versionName: PropTypes.string.isRequired,
      waveName: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      versionId: PropTypes.string.isRequired,
      totalResponses: PropTypes.number.isRequired,
      unprocessedResponses: PropTypes.number.isRequired,
      waveIndex: PropTypes.number.isRequired,
      startDate: PropTypes.shape({
        seconds: PropTypes.number.isRequired,
        nanoseconds: PropTypes.number.isRequired,
      }).isRequired,
      endDate: PropTypes.shape({
        seconds: PropTypes.number.isRequired,
        nanoseconds: PropTypes.number.isRequired,
      }),
      lastTimeProcessed: PropTypes.shape({
        seconds: PropTypes.number.isRequired,
        nanoseconds: PropTypes.number.isRequired,
      }).isRequired,
      createdAt: PropTypes.shape({
        seconds: PropTypes.number.isRequired,
        nanoseconds: PropTypes.number.isRequired,
      }).isRequired,
    })
  ),
  organization: PropTypes.string,
  version: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
};

export default DeploymentsModal;
