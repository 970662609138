import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PropTypes from 'prop-types';
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';
import { shallowEqual, useSelector } from 'react-redux';

import './DatePicker.scss';

registerLocale('en', en);
registerLocale('es', es);

const dateFormat = (locale) => {
  switch (locale) {
    case 'en':
      return 'MM-dd-yy';
    case 'es':
      return 'dd/MM/yy';
    default:
      return 'MM-dd-yy';
  }
};

const DatePickerStyled = ({
  name,
  date,
  onChange,
  setState,
  minDate,
  maxDate,
  className,
  disabled,
  openToDate,
}) => {
  const onDateChangedHandler = (value) => {
    if (onChange) {
      return onChange(value);
    }
    
    return setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const { locale } = useSelector(
    (state) => ({
      locale: state.preferences.locale,
    }),
    shallowEqual
  );

  return (
    <DatePicker
      locale={locale}
      dateFormat={dateFormat(locale)}
      selected={date}
      minDate={minDate}
      maxDate={maxDate}
      onChange={onDateChangedHandler}
      className={className}
      disabled={disabled}
      openToDate={openToDate}
    />
  );
};

DatePickerStyled.propTypes = {
  name: PropTypes.string.isRequired,
  setState: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  date: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  openToDate: PropTypes.instanceOf(Date),
};

DatePickerStyled.defaultProps = {
  date: null,
  onChange: null,
  className: '',
  disabled: false,
  openToDate: null,
  minDate: null,
  maxDate: null,
};

export default DatePickerStyled;
