/* eslint-disable no-await-in-loop */
import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';
import algoliasearch from 'algoliasearch';

import { REACT_APP_ALGOLIA_DATA_SETS_INDEX } from 'constants/environment';
import firebase from 'firebase.js';
import { firebaseError } from 'utils';
import { Collections } from 'utils/enums';
import { collection } from 'utils/firebase';

export const DATA_SETS_CREATE_DATA_SET_INIT = createAction(
  'DATA_SETS_CREATE_DATA_SET_INIT'
);
export const DATA_SETS_CREATE_DATA_SET_SUCCESS = createAction(
  'DATA_SETS_CREATE_DATA_SET_SUCCESS'
);
export const DATA_SETS_CREATE_DATA_SET_FAIL = createAction(
  'DATA_SETS_CREATE_DATA_SET_FAIL'
);

export const DATA_SETS_FETCH_DATA_INIT = createAction(
  'DATA_SETS_FETCH_DATA_INIT'
);
export const DATA_SETS_FETCH_DATA_SUCCESS = createAction(
  'DATA_SETS_FETCH_DATA_SUCCESS'
);
export const DATA_SETS_FETCH_DATA_FAIL = createAction(
  'DATA_SETS_FETCH_DATA_FAIL'
);

export const DATA_SETS_DELETE_DATA_SET_INIT = createAction(
  'DATA_SETS_DELETE_DATA_SET_INIT'
);
export const DATA_SETS_DELETE_DATA_SET_SUCCESS = createAction(
  'DATA_SETS_DELETE_DATA_SET_SUCCESS'
);
export const DATA_SETS_DELETE_DATA_SET_FAIL = createAction(
  'DATA_SETS_DELETE_DATA_SET_FAIL'
);

export const DATA_SETS_CLEAR_DATA = createAction('DATA_SETS_CLEAR_DATA');

export const DATA_SETS_UPDATE_DATA_SET_INIT = createAction(
  'DATA_SETS_UPDATE_DATA_SET_INIT'
);
export const DATA_SETS_UPDATE_DATA_SET_SUCCESS = createAction(
  'DATA_SETS_UPDATE_DATA_SET_SUCCESS'
);
export const DATA_SETS_UPDATE_DATA_SET_FAIL = createAction(
  'DATA_SETS_UPDATE_DATA_SET_FAIL'
);

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_ADMIN_KEY
);
const indexDataSets = searchClient.initIndex(REACT_APP_ALGOLIA_DATA_SETS_INDEX);

export const fetchDataSets = (options = {}) => {
  return async (dispatch) => {
    dispatch(DATA_SETS_FETCH_DATA_INIT());

    const dataSets = [];

    try {
      const dataSetsRef = firebase.firestore().collection('dataSets');
      let baseQuery = dataSetsRef;

      if (options.filterByTagType) {
        const { filterByTagType } = options;
        baseQuery = baseQuery.where('tagType', '==', filterByTagType);
      }

      if (options.filterByActive) {
        const { filterByActive } = options;
        baseQuery = baseQuery.where('active', '==', filterByActive);
      }

      if (options.filterByOrganizations) {
        const { filterByOrganizations } = options;
        baseQuery = baseQuery.where(
          'tagOrganizationName',
          'in',
          filterByOrganizations
        );
      }

      if (options.getById) {
        const { getById } = options;
        const tag = (await dataSetsRef.doc(getById).get()).data();
        dataSets.push({
          id: tag.id,
          ...tag,
          createdAt: tag.createdAt.toDate(),
        });
      } else {
        (await baseQuery.get()).forEach((dataSet) => {
          const data = dataSet.data();
          dataSets.push({
            id: dataSet.id,
            ...data,
            createdAt: data.createdAt.toDate(),
          });
        });
      }
    } catch (error) {
      const errorMessage = firebaseError(error.code, 'en');
      toastr.error(errorMessage);
      return dispatch(DATA_SETS_FETCH_DATA_FAIL({ error: errorMessage }));
    }

    return dispatch(DATA_SETS_FETCH_DATA_SUCCESS({ dataSets }));
  };
};

const checkDataSetExistance = async (
  { tagId, startWave },
  dataSetId = null
) => {
  let dataSetsFound = (
    await firebase
      .firestore()
      .collection('dataSets')
      .where('tagId', '==', tagId)
      .where('startWave', '==', startWave)
      .get()
  ).docs;

  if (dataSetId) {
    dataSetsFound = dataSetsFound.filter((dataSet) => dataSet.id !== dataSetId);
  }

  return dataSetsFound.length !== 0;
};

export const createDataSet = (
  {
    tagId,
    tagDisplayName,
    tagOrganizationName,
    tagType,
    startWave,
    active,
    title,
  },
  dataItems
) => {
  return async (dispatch, getState) => {
    dispatch(DATA_SETS_CREATE_DATA_SET_INIT());

    const { firstName, lastName } = getState().auth.userData;

    const dataSet = {
      tagId,
      tagDisplayName,
      tagOrganizationName,
      tagType,
      startWave,
      createdBy: `${firstName} ${lastName}`,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      numberOfItems: dataItems.en.length,
      active,
      title,
    };

    let dataSetExists;
    try {
      dataSetExists = await checkDataSetExistance(dataSet);
    } catch (error) {
      const errorMessage = firebaseError(error.code, 'en');
      toastr.error('', errorMessage);
      return dispatch(
        DATA_SETS_CREATE_DATA_SET_FAIL({
          error: errorMessage,
        })
      );
    }

    if (dataSetExists) {
      const error = 'Data set already exists';
      toastr.error('', error);
      return dispatch(
        DATA_SETS_CREATE_DATA_SET_FAIL({
          error,
        })
      );
    }

    let createdDataSet;
    try {
      createdDataSet = await firebase
        .firestore()
        .collection('dataSets')
        .add(dataSet);
    } catch (error) {
      const errorMessage = firebaseError(error.code, 'en');
      toastr.error('', errorMessage);
      return dispatch(
        DATA_SETS_CREATE_DATA_SET_FAIL({
          error: errorMessage,
        })
      );
    }

    const questionChoiceRef = firebase.firestore().collection('questionChoice');

    const createQuestionChoiceTasks = [];

    const languages = Object.entries(dataItems).map(
      ([languageIsoCode, dataItem]) => [
        languageIsoCode,
        dataItem.map(({ option }) => option),
      ]
    );

    dataItems.en.forEach(({ paramOne, paramTwo }, index) => {
      const option = {};

      languages.forEach(([languageIsoCode, options]) => {
        option[languageIsoCode] = options[index];
      });

      const dataItem = {
        dataSetId: createdDataSet.id,
        questionId: null,
        openEnded: false,
        notApplicable: false,
        sortOrder: index,
        option,
        paramOne: paramOne || null,
        paramTwo: paramTwo || null,
        visible: true,
      };

      createQuestionChoiceTasks.push(questionChoiceRef.add(dataItem));
    });

    const createDataSetAlgolia = indexDataSets
      .saveObject({
        objectID: createdDataSet.id,
        ...dataSet,
        createdAt: +new Date(),
      })
      .wait();

    try {
      await Promise.all([...createQuestionChoiceTasks, createDataSetAlgolia]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, 'en');
      toastr.error('', errorMessage);
      return dispatch(
        DATA_SETS_CREATE_DATA_SET_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'Data set created successfully');
    return dispatch(
      DATA_SETS_CREATE_DATA_SET_SUCCESS({ id: createdDataSet.id })
    );
  };
};

export const deleteDataSet = (id) => {
  return async (dispatch) => {
    dispatch(DATA_SETS_DELETE_DATA_SET_INIT());

    try {
      const questionsWithDataset = (
        await firebase
          .firestore()
          .collection('questions')
          .where('dataSetId', '==', id)
          .get()
      ).docs;

      if (questionsWithDataset.length > 0) {
        toastr.error(
          '',
          'This data set is being used in a question of a survey'
        );
        return dispatch(
          DATA_SETS_DELETE_DATA_SET_FAIL({
            error: 'This data set is being used in a question of a survey',
          })
        );
      }
    } catch (error) {
      const errorMessage = firebaseError(error.code, 'en');
      toastr.error('', errorMessage);
      return dispatch(
        DATA_SETS_DELETE_DATA_SET_FAIL({
          error: errorMessage,
        })
      );
    }

    const deleteDataSetFromDB = firebase
      .firestore()
      .collection('dataSets')
      .doc(id)
      .delete();
    const deleteDataSetFromAlgolia = indexDataSets.deleteObject(id).wait();

    const questionChoiceRef = firebase.firestore().collection('questionChoice');

    const dataSetQuestionChoices = await questionChoiceRef
      .where('dataSetId', '==', id)
      .get();

    const deleteQuestionChoices = [];

    dataSetQuestionChoices.forEach((questionChoice) =>
      deleteQuestionChoices.push(
        questionChoiceRef.doc(questionChoice.id).delete()
      )
    );

    try {
      await Promise.all([
        deleteDataSetFromDB,
        deleteDataSetFromAlgolia,
        ...deleteQuestionChoices,
      ]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, 'en');
      toastr.error('', errorMessage);
      return dispatch(
        DATA_SETS_DELETE_DATA_SET_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'The data set was deleted.');
    return dispatch(DATA_SETS_DELETE_DATA_SET_SUCCESS());
  };
};

export const clearData = () => (dispatch) => dispatch(DATA_SETS_CLEAR_DATA());

export const updateDataSet = (
  dataSetId,
  {
    tagId,
    tagDisplayName,
    tagOrganizationName,
    tagType,
    startWave,
    active,
    title,
  },
  dataItems,
  oldDataItems
) => {
  return async (dispatch) => {
    dispatch(DATA_SETS_UPDATE_DATA_SET_INIT());

    const languages = Object.entries(dataItems).map(
      ([languageIsoCode, dataItem]) => [
        languageIsoCode,
        dataItem.map(({ option }) => option),
      ]
    );

    const dataItemsWithOrder = dataItems.en.map((dataItem, index) => {
      const option = {};

      languages.forEach(([languageIsoCode, options]) => {
        option[languageIsoCode] = options[index];
      });

      return {
        ...dataItem,
        option,
        sortOrder: index,
      };
    });

    const oldDataItemsWithOrder = oldDataItems.en.map((dataItem, index) => {
      const option = {};

      languages.forEach(([languageIsoCode, options]) => {
        option[languageIsoCode] = options[index];
      });

      return {
        ...dataItem,
        option,
        sortOrder: index,
      };
    });

    const batch = firebase.firestore().batch();

    const dataSet = {
      tagId,
      tagDisplayName,
      tagOrganizationName,
      tagType,
      startWave,
      active,
      title,
      numberOfItems: dataItems.en.length,
    };

    try {
      const dataSetExists = await checkDataSetExistance(dataSet, dataSetId);

      if (dataSetExists) {
        const error = 'Data set already exists';
        toastr.error('', error);
        return dispatch(
          DATA_SETS_CREATE_DATA_SET_FAIL({
            error,
          })
        );
      }
    } catch (error) {
      const errorMessage = firebaseError(error.code, 'en');
      toastr.error('', errorMessage);
      return dispatch(
        DATA_SETS_CREATE_DATA_SET_FAIL({
          error: errorMessage,
        })
      );
    }

    const dataSetRef = collection(Collections.DATA_SETS);

    batch.update(dataSetRef.doc(dataSetId), dataSet);
    const updateTagInAlgolia = indexDataSets
      .partialUpdateObject({ objectID: dataSetId, ...dataSet })
      .wait();

    const questionChoiceRef = firebase.firestore().collection('questionChoice');

    const dataItemstoUpdate = dataItemsWithOrder.filter(({ id }) =>
      oldDataItemsWithOrder.some((dataItem) => dataItem.id === id)
    );

    const dataItemsToCreate = dataItemsWithOrder.filter(
      ({ id }) => !oldDataItemsWithOrder.some((dataItem) => dataItem.id === id)
    );

    const dataItemsToDelete = oldDataItemsWithOrder.filter(
      ({ id }) => !dataItemsWithOrder.some((dataItem) => dataItem.id === id)
    );

    dataItemstoUpdate.forEach(({ id, option, paramOne, paramTwo }) =>
      batch.update(questionChoiceRef.doc(id), {
        option,
        paramOne: paramOne || null,
        paramTwo: paramTwo || null,
      })
    );

    dataItemsToDelete.forEach(({ id }) =>
      batch.delete(questionChoiceRef.doc(id))
    );

    dataItemsToCreate.forEach(({ option, paramOne, paramTwo, sortOrder }) => {
      const dataItem = {
        dataSetId,
        questionId: null,
        openEnded: false,
        notApplicable: false,
        sortOrder,
        option,
        paramOne: paramOne || null,
        paramTwo: paramTwo || null,
      };
      batch.set(questionChoiceRef.doc(), dataItem);
    });

    try {
      await Promise.all([updateTagInAlgolia, batch.commit()]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, 'en');
      toastr.error('', errorMessage);
      return dispatch(
        DATA_SETS_UPDATE_DATA_SET_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'Data set updated successfully');
    return dispatch(DATA_SETS_UPDATE_DATA_SET_SUCCESS());
  };
};
