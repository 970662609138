/* eslint-disable no-lonely-if */
import { toastr } from 'react-redux-toastr';
import algoliasearch from 'algoliasearch';

import { REACT_APP_ALGOLIA_TAGS_INDEX } from 'constants/environment';
import firebase from 'firebase.js';
import {
  TAGS_ADD_TAG,
  TAGS_CLEAR_CREATE_TAG_STATE,
  TAGS_CLEAR_DATA,
  TAGS_CREATE_TAG_FAIL,
  TAGS_CREATE_TAG_INIT,
  TAGS_CREATE_TAG_SUCCESS,
  TAGS_DELETE_TAG_FAIL,
  TAGS_DELETE_TAG_INIT,
  TAGS_DELETE_TAG_SUCCESS,
  TAGS_FETCH_DATA_FAIL,
  TAGS_FETCH_DATA_INIT,
  TAGS_FETCH_DATA_SUCCESS,
  TAGS_FETCH_RELATED_DATA_FAIL,
  TAGS_FETCH_RELATED_DATA_INIT,
  TAGS_FETCH_RELATED_DATA_SUCCESS,
  TAGS_MODIFY_TAG_FAIL,
  TAGS_MODIFY_TAG_INIT,
  TAGS_MODIFY_TAG_SUCCESS,
  TAGS_REMOVE_TAG,
} from 'state/actionCreators/tags';
import { createError, firebaseError, onlyLettersAndNumbers } from 'utils';
import { collection } from 'utils/firebase';
import { Collections, CollectionGroup, TagType } from 'utils/enums';

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_ADMIN_KEY
);
const index = searchClient.initIndex(REACT_APP_ALGOLIA_TAGS_INDEX);

export const fetchTags = (options = {}) => {
  return async (dispatch) => {
    dispatch(TAGS_FETCH_DATA_INIT());

    const tags = [];

    try {
      const tagsRef = firebase.firestore().collection('tags');
      let baseQuery = tagsRef;

      if (options.filterByActive) {
        const { filterByActive } = options;
        baseQuery = baseQuery.where('active', '==', filterByActive);
      }

      if (options.filterByNotType) {
        const { filterByNotType } = options;
        baseQuery = baseQuery.where('type', '!=', filterByNotType);
      }

      if (options.filterByOrganizations) {
        const { filterByOrganizations } = options;
        baseQuery = baseQuery.where(
          'organizationName',
          'in',
          filterByOrganizations
        );
      }

      if (options.getById) {
        const { getById } = options;
        const tag = (await tagsRef.doc(getById).get()).data();
        tags.push({
          id: tag.id,
          ...tag,
          createdAt: tag.createdAt.toDate(),
        });
      } else {
        (await baseQuery.get()).forEach((tag) => {
          const data = tag.data();
          tags.push({
            id: tag.id,
            ...data,
            createdAt: data.createdAt.toDate(),
          });
        });
      }
    } catch (error) {
      const errorMessage = firebaseError(error.code, 'en');
      toastr.error(errorMessage);
      return dispatch(TAGS_FETCH_DATA_FAIL({ error: errorMessage }));
    }

    return dispatch(TAGS_FETCH_DATA_SUCCESS({ tags }));
  };
};

const checkTagExistence = async ({ type, name, organizationName }, global) => {
  const baseQuery = firebase
    .firestore()
    .collection('tags')
    .where('type', '==', type)
    .where('name', '==', name);

  if (global) {
    return !(await baseQuery.get()).empty;
  }

  return !(
    await baseQuery
      .where('organizationName', 'in', [organizationName, 'global'])
      .get()
  ).empty;
};

export const createTag = ({
  name,
  global,
  organization,
  active,
  type,
  scores = null,
  displayName,
}) => {
  return async (dispatch, getState) => {
    dispatch(TAGS_CREATE_TAG_INIT());

    const { userData } = getState().auth;

    const tagData = {
      name:
        type === TagType.CONSTRUCT
          ? onlyLettersAndNumbers(name)
          : onlyLettersAndNumbers(name.toLowerCase()),
      displayName: displayName || name,
      type,
      active,
      organizationName: global ? 'global' : organization,
      createdBy: `${userData.firstName} ${userData.lastName}`,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    };

    let tag = tagData;
    if (scores) {
      tag = { ...tagData, scores };
    }

    let tagExists;
    try {
      tagExists = await checkTagExistence(tag, global);
    } catch (error) {
      const errorMessage = firebaseError(error.code, 'en');
      toastr.error(errorMessage);
      return dispatch(TAGS_CREATE_TAG_FAIL({ error: errorMessage }));
    }

    if (tagExists) {
      const error = 'Tag already exists';
      toastr.error('', error);
      return dispatch(TAGS_CREATE_TAG_FAIL({ error }));
    }

    try {
      const { id } = await firebase.firestore().collection('tags').add(tag);
      await index
        .saveObject({ objectID: id, ...tag, createdAt: +new Date() })
        .wait();

      toastr.success('', 'Tag created successfully');
      return dispatch(TAGS_CREATE_TAG_SUCCESS({ tag: { ...tag, id } }));
    } catch (error) {
      const errorMessage = firebaseError(error.code, 'en');
      toastr.error(errorMessage);
      return dispatch(TAGS_CREATE_TAG_FAIL({ error: errorMessage }));
    }
  };
};

export const clearCreateTagState = () => (dispatch) => {
  dispatch(TAGS_CLEAR_CREATE_TAG_STATE());
};

const getTagValidationMessage = async (
  { type, name, organizationName },
  oldTag
) => {
  const nameChanged = name !== oldTag.name;
  const orgChanged = organizationName !== oldTag.organizationName;
  const wasGlobal = oldTag.organizationName === 'global';

  if (wasGlobal && !nameChanged) {
    return { valid: true, message: '' };
  }

  const baseQuery = firebase
    .firestore()
    .collection('tags')
    .where('type', '==', type)
    .where('name', '==', name);

  if (nameChanged || orgChanged) {
    const tags = (await baseQuery.get()).docs.map((tag) => ({
      ...tag.data(),
    }));

    const foundGlobalTag =
      tags.filter((tag) => tag.organizationName === 'global').length !== 0;

    if (foundGlobalTag) {
      return { valid: false, message: 'Tag already exists as a global tag.' };
    }

    let organizationTags = tags.filter(
      (tag) => tag.organizationName !== 'global'
    );

    if (!nameChanged) {
      organizationTags = organizationTags.filter((tag) => tag.name !== name);
    }

    if (organizationTags.length !== 0) {
      let message;

      if (organizationTags.length > 1) {
        message = 'Tag already exists in multiple organizations';
      } else {
        const tag = organizationTags.pop();
        message = `Tag already exists in the organization called "${tag.organizationName}".`;
      }

      return {
        valid: false,
        message,
      };
    }

    return { valid: true, message: '' };
  }

  return { valid: true, message: '' };
};

const normalizeTag = ({
  name,
  global,
  organization,
  active,
  type,
  scores = null,
  displayName,
}) => {
  if (scores) {
    return {
      name: onlyLettersAndNumbers(name),
      displayName,
      type,
      active,
      organizationName: global ? 'global' : organization,
      scores,
    };
  }
  return {
    name: name.toLowerCase().trim(),
    displayName: name,
    type,
    active,
    organizationName: global ? 'global' : organization,
  };
};

export const updateTag = (id, updatedTag, previousTag) => {
  return async (dispatch) => {
    dispatch(TAGS_MODIFY_TAG_INIT());

    let tagValidation;

    const {
      organization: updatedOrganization,
      type,
      global: updatedGlobal,
      id: tagId,
    } = updatedTag;
    const { organization: previousOrganization } = previousTag;

    if (
      !updatedGlobal &&
      previousOrganization &&
      updatedOrganization !== previousOrganization
    ) {
      if (
        type === TagType.QUESTION ||
        type === TagType.CONSTRUCT ||
        type === TagType.DEMOGRAPHIC
      ) {
        let questionsTagsRelatedToTag;

        try {
          questionsTagsRelatedToTag = await collection(Collections.QUESTION_TAG)
            .where('tagId', '==', tagId)
            .get();
        } catch (error) {
          const errorMessage = firebaseError(error.code, 'en');
          toastr.error('', errorMessage);

          return dispatch(
            TAGS_MODIFY_TAG_FAIL({
              error: errorMessage,
            })
          );
        }

        if (questionsTagsRelatedToTag.docs.length > 0) {
          const questionsRelatedToTagPromises =
            questionsTagsRelatedToTag.docs.map((questionTag) =>
              collection(Collections.QUESTIONS)
                .doc(questionTag.data().questionId)
                .get()
            );

          let questionsRelatedToTag;
          try {
            questionsRelatedToTag = await Promise.all(
              questionsRelatedToTagPromises
            );
          } catch (error) {
            const errorMessage = firebaseError(error.code, 'en');
            toastr.error('', errorMessage);

            return dispatch(
              TAGS_MODIFY_TAG_FAIL({
                error: errorMessage,
              })
            );
          }

          const surveysRelatedToQuestionsPromises = questionsRelatedToTag.map(
            (question) => {
              const { surveyId, surveyVersionId, surveyDeploymentId } =
                question.data();

              return collection(Collections.SURVEYS)
                .doc(surveyId)
                .collection(
                  surveyVersionId
                    ? CollectionGroup.SURVEY_VERSION
                    : CollectionGroup.SURVEY_DEPLOYMENT
                )
                .doc(surveyVersionId || surveyDeploymentId)
                .get();
            }
          );

          let surveysRelatedToQuestions;

          try {
            surveysRelatedToQuestions = await Promise.all(
              surveysRelatedToQuestionsPromises
            );
          } catch (error) {
            const errorMessage = firebaseError(error.code, 'en');
            toastr.error('', errorMessage);

            return dispatch(
              TAGS_MODIFY_TAG_FAIL({
                error: errorMessage,
              })
            );
          }

          const questionsData = questionsRelatedToTag.map((question) => {
            const {
              surveyVersionId,
              surveyDeploymentId,
              content,
              id: questionId,
            } = question.data();

            const surveyType = surveyVersionId
              ? CollectionGroup.SURVEY_VERSION
              : CollectionGroup.SURVEY_DEPLOYMENT;

            const relatedSurvey = surveysRelatedToQuestions.filter(
              ({ id: surveyVersionOrDeploymentId }) =>
                surveyVersionOrDeploymentId ===
                (surveyVersionId || surveyDeploymentId)
            )[0];

            const { name, title } = relatedSurvey.data();

            return {
              type: surveyType,
              title: title.en,
              name,
              id: surveyVersionId,
              questionContent: content.en,
              questionId,
            };
          });

          return dispatch(
            TAGS_MODIFY_TAG_FAIL(
              createError('Tag is being used in the next questions: ', {
                questionsData,
              })
            )
          );
        }
      }

      if (type === TagType.DATA_SET) {
        let dataSetsRelatedToTag;

        try {
          dataSetsRelatedToTag = await collection(Collections.DATA_SETS)
            .where('tagId', '==', tagId)
            .get();
        } catch (error) {
          const errorMessage = firebaseError(error.code, 'en');
          toastr.error('', errorMessage);

          return dispatch(
            TAGS_MODIFY_TAG_FAIL({
              error: errorMessage,
            })
          );
        }

        if (dataSetsRelatedToTag.docs.length > 0) {
          toastr.error('Tag is being used in dataset/s');
          return dispatch(
            TAGS_MODIFY_TAG_FAIL(createError('Tag is being used in dataSet/s'))
          );
        }
      }
    }

    try {
      tagValidation = await getTagValidationMessage(
        normalizeTag(updatedTag),
        normalizeTag(previousTag)
      );
    } catch (error) {
      const errorMessage = firebaseError(error.code, 'en');

      toastr.error(errorMessage);
      return dispatch(TAGS_MODIFY_TAG_FAIL({ error: errorMessage }));
    }

    if (!tagValidation.valid) {
      const errorMessage = tagValidation.message;

      toastr.error('', errorMessage);
      return dispatch(TAGS_MODIFY_TAG_FAIL({ error: errorMessage }));
    }

    const updateTagInDB = firebase
      .firestore()
      .collection(Collections.TAGS)
      .doc(id)
      .update(normalizeTag(updatedTag));
    const updateTagInAlgolia = index
      .partialUpdateObject({
        objectID: id,
        ...normalizeTag(updatedTag),
      })
      .wait();

    try {
      await Promise.all([updateTagInDB, updateTagInAlgolia]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, 'en');
      toastr.error(errorMessage);
      return dispatch(TAGS_MODIFY_TAG_FAIL({ error: errorMessage }));
    }

    toastr.success('', 'Tag updated successfully');
    return dispatch(TAGS_MODIFY_TAG_SUCCESS());
  };
};

export const deleteTag = (id) => {
  return async (dispatch) => {
    dispatch(TAGS_DELETE_TAG_INIT());

    try {
      const dataSetsWithTag = (
        await firebase
          .firestore()
          .collection(Collections.DATA_SETS)
          .where('tagId', '==', id)
          .get()
      ).docs[0]?.id;

      if (dataSetsWithTag) {
        const questionsWithDataset = (
          await firebase
            .firestore()
            .collection(Collections.QUESTIONS)
            .where('dataSetId', '==', dataSetsWithTag)
            .get()
        ).docs;

        if (questionsWithDataset.length > 0) {
          toastr.error(
            '',
            'This tag is being used by a data set in a question'
          );
          return dispatch(
            TAGS_DELETE_TAG_FAIL({
              error: 'This tag is being used by a data set in a question',
            })
          );
        }
      }
    } catch (error) {
      const errorMessage = firebaseError(error.code, 'en');
      toastr.error('', errorMessage);
      return dispatch(
        TAGS_DELETE_TAG_FAIL({
          error: errorMessage,
        })
      );
    }

    const deleteTagFromDB = firebase
      .firestore()
      .collection(Collections.TAGS)
      .doc(id)
      .delete();
    const deleteTagFromAlgolia = index.deleteObject(id).wait();

    try {
      await Promise.all([deleteTagFromDB, deleteTagFromAlgolia]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, 'en');
      toastr.error('', errorMessage);
      return dispatch(
        TAGS_DELETE_TAG_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'The tag was deleted.');
    return dispatch(TAGS_DELETE_TAG_SUCCESS());
  };
};

export const fetchDataRelatedToTag = (tagId, tagType) => async (dispatch) => {
  dispatch(TAGS_FETCH_RELATED_DATA_INIT());
  const affectedData = [];

  if (
    tagType === TagType.QUESTION ||
    tagType === TagType.CONSTRUCT ||
    tagType === TagType.DEMOGRAPHIC
  ) {
    let questionsTagsRelatedToTag;

    try {
      questionsTagsRelatedToTag = await collection(Collections.QUESTION_TAG)
        .where('tagId', '==', tagId)
        .get();
    } catch (error) {
      const errorMessage = firebaseError(error.code, 'en');
      toastr.error('', errorMessage);

      return dispatch(
        TAGS_FETCH_RELATED_DATA_FAIL({
          error: errorMessage,
        })
      );
    }

    if (questionsTagsRelatedToTag.docs.length > 0) {
      const questionsRelatedToTagPromises = questionsTagsRelatedToTag.docs.map(
        (questionTag) =>
          collection(Collections.QUESTIONS)
            .doc(questionTag.data().questionId)
            .get()
      );

      let questionsRelatedToTag;

      try {
        questionsRelatedToTag = await Promise.all(
          questionsRelatedToTagPromises
        );
      } catch (error) {
        const errorMessage = firebaseError(error.code, 'en');
        toastr.error('', errorMessage);

        return dispatch(
          TAGS_FETCH_RELATED_DATA_FAIL({
            error: errorMessage,
          })
        );
      }

      const surveysRelatedToQuestionsPromises = questionsRelatedToTag.map(
        (question) => {
          const { surveyId, surveyVersionId, surveyDeploymentId } =
            question.data();

          return collection(Collections.SURVEYS)
            .doc(surveyId)
            .collection(
              surveyVersionId
                ? CollectionGroup.SURVEY_VERSION
                : CollectionGroup.SURVEY_DEPLOYMENT
            )
            .doc(surveyVersionId || surveyDeploymentId)
            .get();
        }
      );

      let surveysRelatedToQuestions;

      try {
        surveysRelatedToQuestions = await Promise.all(
          surveysRelatedToQuestionsPromises
        );
      } catch (error) {
        const errorMessage = firebaseError(error.code, 'en');
        toastr.error('', errorMessage);

        return dispatch(
          TAGS_FETCH_RELATED_DATA_FAIL({
            error: errorMessage,
          })
        );
      }

      const questionsData = questionsRelatedToTag.map((question) => {
        const {
          surveyVersionId,
          surveyDeploymentId,
          content,
          id: questionId,
        } = question.data();

        const surveyType = surveyVersionId
          ? CollectionGroup.SURVEY_VERSION
          : CollectionGroup.SURVEY_DEPLOYMENT;

        const relatedSurvey = surveysRelatedToQuestions.filter(
          ({ id: surveyVersionOrDeploymentId }) =>
            surveyVersionOrDeploymentId ===
            (surveyVersionId || surveyDeploymentId)
        )[0];

        const { name, title } = relatedSurvey.data();

        return {
          type: surveyType,
          title: title.en,
          name,
          id: surveyVersionId,
          questionContent: content.en,
          questionId,
        };
      });

      questionsData.forEach((question) => affectedData.push(question));
    }
  }

  if (tagType === TagType.DATA_SET) {
    let dataSetsRelatedToTag;

    try {
      dataSetsRelatedToTag = await collection(Collections.DATA_SETS)
        .where('tagId', '==', tagId)
        .get();
    } catch (error) {
      const errorMessage = firebaseError(error.code, 'en');
      toastr.error('', errorMessage);

      return dispatch(
        TAGS_FETCH_RELATED_DATA_FAIL({
          error: errorMessage,
        })
      );
    }

    if (dataSetsRelatedToTag.docs.length > 0) {
      dataSetsRelatedToTag.docs.forEach((dataSet) =>
        affectedData.push(dataSet.data())
      );
    }
  }

  return dispatch(
    TAGS_FETCH_RELATED_DATA_SUCCESS({
      relatedData: affectedData,
    })
  );
};

export const clearData = () => TAGS_CLEAR_DATA();

export const removeTag = (tag) => TAGS_REMOVE_TAG({ tag });

export const addTag = (tag) => TAGS_ADD_TAG({ tag });
