import { Collections } from 'utils/enums';
import { collectionGroup } from 'utils/firebase';

export const checkDeploymentExistance = async (url) => {
  const deployments = await collectionGroup(Collections.DEPLOYMENTS)
    .where('url', '==', url)
    .get();

  return !deployments.empty;
};
