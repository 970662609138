import firebase from 'firebase.js';
import {
  SURVEYS_UPDATE_DEPLOYMENTS_HITS_FAIL,
  SURVEYS_UPDATE_DEPLOYMENTS_HITS_INIT,
  SURVEYS_UPDATE_DEPLOYMENTS_HITS_SUCCESS,
} from 'state/actionCreators/surveys';
import { firebaseError, increment } from 'utils';

export const updateHitsOnSurvey =
  (surveyId, deploymentId) => async (dispatch, getState) => {
    dispatch(SURVEYS_UPDATE_DEPLOYMENTS_HITS_INIT());

    const { locale } = getState().preferences;

    const date = new Date();

    const { month, day, year } = {
      month: String(date.getUTCMonth() + 1).padStart(2, '0'),
      day: String(date.getUTCDate()).padStart(2, '0'),
      year: date.getUTCFullYear(),
    };

    const utcDate = [day, month, year].join('/');

    const deploymentRef = firebase
      .firestore()
      .collection('surveys')
      .doc(surveyId)
      .collection('deployments')
      .doc(deploymentId);

    try {
      deploymentRef.set(
        {
          hits: { [utcDate]: increment() },
        },
        { merge: true }
      );
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);

      dispatch(
        SURVEYS_UPDATE_DEPLOYMENTS_HITS_FAIL({
          error: errorMessage,
        })
      );
    }

    dispatch(SURVEYS_UPDATE_DEPLOYMENTS_HITS_SUCCESS());
  };
