import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import 'bulma-pageloader';

const PageLoader = ({ loading, background }) => (
  <div
    id="spinner"
    style={{ background: background || '#ccc' }}
    className={classNames('pageloader', {
      'is-active': loading,
    })}
  />
);

PageLoader.propTypes = {
  loading: PropTypes.bool.isRequired,
  background: PropTypes.string,
};

PageLoader.defaultProps = {
  background: '',
};

export default PageLoader;
