import React from 'react';

const Checkbox = ({ id, checked, name, onChange, disabled }) => (
  <label htmlFor={id} className="b-checkbox checkbox">
    <input
      id={id}
      type="checkbox"
      name={name}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
    />
    <span className="check is-primary" />
  </label>
);

export default Checkbox;
