/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useMemo } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';

import {
  addItemSelectOptions as selectOptions,
  addItemSelectStyles,
  getItem,
  isNotItemInActions,
} from 'utils/surveyBuilder';
import { SurveyElement } from 'utils/surveyBuilder/enums';
import { ModalType } from 'utils/enums';
import Item from 'components/SurveyBuilder/Item';
import DragItem from 'components/DragAndDrop/DragItem';
import QuestionGroupItem from '../QuestionGroupItem';

const Items = ({
  survey,
  setSurvey,
  sectionId,
  sectionIndex,
  pageIndex,
  items,
  sectionIsVisible,
  onOpenModal,
  deploymentId,
}) => {
  const onDeleteItemHandler = useCallback(
    (itemIndex) => {
      const section = survey.sections[sectionId];
      const itemId = section.itemIds[itemIndex];
      const item = survey.items[itemId];
      const idsToSearch = [];
      if (item.questionGroup) {
        idsToSearch.push(item.id);
        item.questionGroup.questions.forEach((question) => {
          idsToSearch.push(question.id);
        });
      } else if (item.question) {
        idsToSearch.push(item.question.id);
      } else {
        idsToSearch.push(itemId);
      }
      const canDelete = survey.actions.every((action) => {
        let hasNoActions = true;
        idsToSearch.forEach((id) => {
          const isNotInActions = isNotItemInActions(action, id);
          hasNoActions = hasNoActions && isNotInActions;
        });
        return hasNoActions;
      });
      if (canDelete) {
        setSurvey((prevState) => {
          const { sections, items: stateItems } = prevState;

          const sectionToDelete = sections[sectionId];
          const [itemIdToDelete] = sectionToDelete.itemIds.splice(itemIndex, 1);
          delete stateItems[itemIdToDelete];

          return { ...prevState, sections, items: stateItems };
        });
      } else {
        onOpenModal(ModalType.CANNOT_DELETE_SURVEY_ITEM);
      }
    },
    [survey, onOpenModal, sectionId, setSurvey]
  );

  const onAddItemHandler = useCallback(
    (itemType, itemIndex, optionalProps = {}) => {
      setSurvey((prevState) => {
        const { sections, items: itemsState, availableLanguages } = prevState;

        const item = getItem({
          itemType,
          availableLanguages,
        });
        const section = sections[sectionId];

        section.itemIds.splice(itemIndex, 0, item.id);

        itemsState[item.id] = { ...item, ...optionalProps };

        return { ...prevState, sections, items: itemsState };
      });
    },
    [setSurvey, sectionId]
  );

  const onSelectItemOptionHandler = useCallback(
    (value, itemIndex, itemId, optionalProps = {}) => {
      if (value === SurveyElement.QUESTION) {
        return onOpenModal(ModalType.SURVEY_QUESTION_BUILDER, {
          itemIndex,
          itemId,
          sectionId,
          ...optionalProps,
        });
      }

      if (value === SurveyElement.QUESTION_GROUP) {
        return onOpenModal(ModalType.SURVEY_QUESTION_GROUP_BUILDER, {
          itemIndex,
          itemId,
          sectionId,
          ...optionalProps,
        });
      }

      return onAddItemHandler(value, itemIndex, {
        visible: sectionIsVisible,
      });
    },
    [onOpenModal, onAddItemHandler, sectionId, sectionIsVisible]
  );

  const onItemPropertyChangeHandler = useCallback(
    (prop, itemId) =>
      setSurvey((prevState) => {
        const { items: stateItems } = prevState;
        const item = stateItems[itemId];
        return {
          ...prevState,
          items: {
            ...stateItems,
            [item.id]: {
              ...item,
              ...prop,
            },
          },
        };
      }),
    [setSurvey]
  );

  const onCondenseQuestionHandler = useCallback(
    (questionId, condensed) =>
      setSurvey((prevState) => {
        const { items: stateItems } = prevState;

        stateItems[questionId].condensed = condensed;

        return {
          ...prevState,
          items: {
            ...stateItems,
          },
        };
      }),
    [setSurvey]
  );

  const onCheckItemHasAction = useCallback(
    (itemId) => {
      return !survey?.actions?.every((action) =>
        isNotItemInActions(action, itemId)
      );
    },
    [survey]
  );

  const onClickSeeActionsOfItem = useCallback(
    ({ itemId }) => {
      onOpenModal(ModalType.ACTIONS, { searchItem: itemId });
    },
    [onOpenModal]
  );

  const addItemSelectOptions = useMemo(
    () =>
      deploymentId
        ? selectOptions.filter(({ label }) => label === 'Text Box')
        : selectOptions,
    [deploymentId]
  );

  return (
    <Droppable droppableId={sectionId.toString()} type="item">
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          {items.length === 0 && !snapshot.isDraggingOver && (
            <div
              id="section-empty-message"
              className="container has-text-centered"
            >
              <h3 className="title is-4">This section is empty.</h3>
              <h4 className="subtitle is-6">
                Use the menu below to add new items to this section.
              </h4>
              <div className="level">
                <div className="level-item has-text-centered">
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">Item:</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <div style={{ minWidth: '200px' }}>
                            <Select
                              isSearchable={false}
                              value={null}
                              styles={addItemSelectStyles}
                              options={addItemSelectOptions}
                              onChange={({ value }) =>
                                onSelectItemOptionHandler(value, 0, null)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {items.map(
            (
              {
                id,
                type,
                visible,
                content,
                richText,
                question,
                condensed,
                questionGroup,
              },
              index
            ) => (
              <DragItem key={id} dragId={id} index={index}>
                {type === SurveyElement.QUESTION_GROUP && (
                  <QuestionGroupItem
                    survey={survey}
                    onOpenModal={onOpenModal}
                    sectionId={sectionId}
                    itemId={id}
                    pageIndex={pageIndex}
                    sectionIndex={sectionIndex}
                    itemIndex={index}
                    visible={visible}
                    condensed={condensed}
                    selectedLanguage={survey.selectedLanguage}
                    label={questionGroup.label.en}
                    choices={questionGroup.choices}
                    questions={questionGroup.questions}
                    onCondense={onCondenseQuestionHandler}
                    onSelectItemOption={onSelectItemOptionHandler}
                    onPropertyChange={onItemPropertyChangeHandler}
                    onDelete={onDeleteItemHandler}
                    onCheckHasAction={onCheckItemHasAction}
                    onClickSeeActions={onClickSeeActionsOfItem}
                    deploymentId={deploymentId}
                  />
                )}
                {type !== SurveyElement.QUESTION_GROUP && (
                  <Item
                    survey={survey}
                    setSurvey={setSurvey}
                    itemId={id}
                    pageIndex={pageIndex}
                    sectionIndex={sectionIndex}
                    itemIndex={index}
                    sectionId={sectionId}
                    type={type}
                    visible={visible}
                    content={content}
                    richText={richText}
                    condensed={condensed}
                    onDeleteItem={onDeleteItemHandler}
                    onSelectItemOption={onSelectItemOptionHandler}
                    onItemPropertyChange={onItemPropertyChangeHandler}
                    question={question}
                    onCondenseQuestion={onCondenseQuestionHandler}
                    deploymentId={deploymentId}
                    onCheckHasAction={onCheckItemHasAction}
                    onClickSeeActions={onClickSeeActionsOfItem}
                    onOpenModal={onOpenModal}
                  />
                )}
              </DragItem>
            )
          )}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

Items.propTypes = {
  sectionId: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  sectionIsVisible: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  survey: PropTypes.shape({}).isRequired,
  setSurvey: PropTypes.func.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  pageIndex: PropTypes.number.isRequired,
  deploymentId: PropTypes.string,
};

export default Items;
