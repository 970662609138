import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';
import algoliasearch from 'algoliasearch';

import { REACT_APP_ALGOLIA_WAVES_INDEX } from 'constants/environment';
import firebase from 'firebase.js';
import { firebaseError, siteNameIsNew } from 'utils';
import initialLayout from 'pages/Organization/OrganizationLayout/initialLayout';

export const ORGANIZATIONS_FETCH_DATA_INIT = createAction(
  'ORGANIZATIONS_FETCH_DATA_INIT'
);
export const ORGANIZATIONS_FETCH_DATA_FAIL = createAction(
  'ORGANIZATIONS_FETCH_DATA_FAIL'
);
export const ORGANIZATIONS_FETCH_DATA_SUCCESS = createAction(
  'ORGANIZATIONS_FETCH_DATA_SUCCESS'
);
export const ORGANIZATIONS_CLEAR_DATA = createAction(
  'ORGANIZATIONS_CLEAR_DATA'
);
export const ORGANIZATIONS_CREATE_ORGANIZATION_INIT = createAction(
  'ORGANIZATIONS_CREATE_ORGANIZATION_INIT'
);
export const ORGANIZATIONS_CREATE_ORGANIZATION_SUCCESS = createAction(
  'ORGANIZATIONS_CREATE_ORGANIZATION_SUCCESS'
);
export const ORGANIZATIONS_CREATE_ORGANIZATION_FAIL = createAction(
  'ORGANIZATIONS_CREATE_ORGANIZATION_FAIL'
);
export const ORGANIZATIONS_MODIFY_ORGANIZATION_INIT = createAction(
  'ORGANIZATIONS_MODIFY_ORGANIZATION_INIT'
);
export const ORGANIZATIONS_MODIFY_ORGANIZATION_SUCCESS = createAction(
  'ORGANIZATIONS_MODIFY_ORGANIZATION_SUCCESS'
);
export const ORGANIZATIONS_MODIFY_ORGANIZATION_FAIL = createAction(
  'ORGANIZATIONS_MODIFY_ORGANIZATION_FAIL'
);
export const ORGANIZATIONS_DELETE_ORGANIZATION_INIT = createAction(
  'ORGANIZATIONS_DELETE_ORGANIZATION_INIT'
);
export const ORGANIZATIONS_DELETE_ORGANIZATION_SUCCESS = createAction(
  'ORGANIZATIONS_DELETE_ORGANIZATION_SUCCESS'
);
export const ORGANIZATIONS_DELETE_ORGANIZATION_FAIL = createAction(
  'ORGANIZATIONS_DELETE_ORGANIZATION_FAIL'
);
export const ORGANIZATIONS_CLEAN_UP = createAction('ORGANIZATIONS_CLEAN_UP');
export const ORGANIZATIONS_UPDATE_LAYOUT_INIT = createAction(
  'ORGANIZATIONS_UPDATE_LAYOUT_INIT'
);
export const ORGANIZATIONS_UPDATE_LAYOUT_SUCCESS = createAction(
  'ORGANIZATIONS_UPDATE_LAYOUT_SUCCESS'
);
export const ORGANIZATIONS_UPDATE_LAYOUT_FAIL = createAction(
  'ORGANIZATIONS_UPDATE_LAYOUT_FAIL'
);

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_ADMIN_KEY
);
const indexWaves = searchClient.initIndex(
  REACT_APP_ALGOLIA_WAVES_INDEX
);

export const fetchOrganizations = (
  filterInactives,
  filterByOrganization = null
) => {
  return async (dispatch) => {
    dispatch(ORGANIZATIONS_FETCH_DATA_INIT());

    const organizations = [];

    try {
      let baseQuery = firebase.firestore().collection('organizations');

      if (filterInactives) {
        baseQuery = baseQuery.where('active', '==', true);
      }

      if (filterByOrganization) {
        baseQuery = baseQuery.where('name', '==', filterByOrganization);
      }

      const queryResult = await baseQuery.get();

      queryResult.forEach((organization) => {
        const organizationData = {
          id: organization.id,
          ...organization.data(),
        };
        organizations.push({
          ...organizationData,
          createdAt: organizationData.createdAt.toDate(),
        });
      });
    } catch (error) {
      toastr.error(error.message);
      return dispatch(ORGANIZATIONS_FETCH_DATA_FAIL({ error }));
    }

    return dispatch(
      ORGANIZATIONS_FETCH_DATA_SUCCESS({
        organizations,
      })
    );
  };
};

export const clearOrganizationsData = () => {
  return (dispatch) => {
    dispatch(ORGANIZATIONS_CLEAR_DATA());
  };
};

const checkDuplicatedProperties = async (organization) => {
  const duplicateNamePromise = firebase
    .firestore()
    .collection('organizations')
    .where('name', '==', organization.name)
    .get();

  const dupliateSiteNamePromise = firebase
    .firestore()
    .collection('organizations')
    .where('siteName', '==', organization.siteName)
    .get();

  const [duplicateName, duplicateSiteName] = await Promise.all([
    duplicateNamePromise,
    dupliateSiteNamePromise,
  ]);

  if (!duplicateName.empty && duplicateName.docs[0].id !== organization.id) {
    return 'name';
  }
  if (
    !duplicateSiteName.empty &&
    duplicateSiteName.docs[0].id !== organization.id
  ) {
    return 'site name';
  }
  return '';
};

export const createOrganization = ({ organization, wave, createWave }) => {
  return async (dispatch, getState) => {
    dispatch(ORGANIZATIONS_CREATE_ORGANIZATION_INIT());
    const { locale } = getState().preferences;
    const { userData } = getState().auth;

    const organizationData = {
      name: organization.displayName.toLowerCase().trim(),
      displayName: organization.displayName,
      siteName: organization.displaySiteName.toLowerCase().trim(),
      displaySiteName: organization.displaySiteName,
      industry: organization.industry,
      active: organization.active,
      processingThreshold: organization.processingThreshold,
      users: 0,
      groups: 0,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      createdBy: `${userData.firstName} ${userData.lastName}`,
      currentWave: null,
      waves: [],
      layout: initialLayout,
    };

    const waveData = {
      index: wave.index,
      name: wave.name,
      startingOn: firebase.firestore.Timestamp.fromDate(wave.startingOn),
      until: wave.until
        ? firebase.firestore.Timestamp.fromDate(wave.until)
        : null,
      expectedResponses: wave.expectedResponses,
      reportingThreshold: wave.reportingThreshold,
      organizationName: wave.organizationName,
      organizationId: wave.organizationId,
      responses: 0,
      processed: 0,
      invalid: 0,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      createdBy: `${userData.firstName} ${userData.lastName}`,
      hierarchy: [],
    };

    if (siteNameIsNew(organizationData.siteName)) {
      toastr.error('', 'Cannot use new as organization site name!');
      return dispatch(
        ORGANIZATIONS_CREATE_ORGANIZATION_FAIL({
          error: 'Cannot use new as organization site name!',
        })
      );
    }

    let duplicatedProperty;

    try {
      duplicatedProperty = await checkDuplicatedProperties(organizationData);
      if (!duplicatedProperty) {
        const { id: organizationId } = await firebase
          .firestore()
          .collection('organizations')
          .add(organizationData);

        if (createWave) {
          const { id: waveId } = await firebase
            .firestore()
            .collection('waves')
            .add({ ...waveData, organizationId });

          await indexWaves
            .saveObject({
              objectID: waveId,
              ...waveData,
              startingOn: +new Date(wave.startingOn),
              until: wave.until ? +new Date(wave.until) : null,
              createdAt: +new Date(),
            })
            .wait();
        }

        toastr.success('', 'Organization created successfully');
        return dispatch(ORGANIZATIONS_CREATE_ORGANIZATION_SUCCESS());
      }
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        ORGANIZATIONS_CREATE_ORGANIZATION_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.error('', `Organization ${duplicatedProperty} already exists!`);
    return dispatch(
      ORGANIZATIONS_CREATE_ORGANIZATION_FAIL({
        error: `Organization ${duplicatedProperty} already exists!`,
      })
    );
  };
};

export const modifyOrganization = ({
  displaySiteName,
  displayName,
  industry,
  processingThreshold,
  active,
  id,
}) => {
  return async (dispatch, getState) => {
    dispatch(ORGANIZATIONS_MODIFY_ORGANIZATION_INIT());

    const { locale } = getState().preferences;

    const organizationData = {
      name: displayName.toLowerCase().trim(),
      displayName,
      siteName: displaySiteName.toLowerCase().trim(),
      displaySiteName,
      industry,
      processingThreshold,
      active,
    };

    if (siteNameIsNew(organizationData.siteName)) {
      toastr.error('', 'Cannot use new as organization site name!');
      return dispatch(
        ORGANIZATIONS_CREATE_ORGANIZATION_FAIL({
          error: 'Cannot use new as organization site name!',
        })
      );
    }

    let duplicatedProperty;

    try {
      duplicatedProperty = await checkDuplicatedProperties({
        ...organizationData,
        id,
      });
      if (!duplicatedProperty) {
        await firebase
          .firestore()
          .collection('organizations')
          .doc(id)
          .update(organizationData);

        toastr.success('', 'Organization updated successfully');

        return dispatch(
          ORGANIZATIONS_MODIFY_ORGANIZATION_SUCCESS({
            organization: { ...organizationData, id },
          })
        );
      }
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        ORGANIZATIONS_MODIFY_ORGANIZATION_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.error('', `Organization ${duplicatedProperty} already exists!`);
    return dispatch(
      ORGANIZATIONS_MODIFY_ORGANIZATION_FAIL({
        error: `Organization ${duplicatedProperty} already exists!`,
      })
    );
  };
};

export const deleteOrganization = (id) => {
  return async (dispatch, getState) => {
    dispatch(ORGANIZATIONS_DELETE_ORGANIZATION_INIT());

    const { locale } = getState().preferences;

    try {
      await firebase.firestore().collection('organizations').doc(id).delete();
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        ORGANIZATIONS_DELETE_ORGANIZATION_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'The organization was deleted.');
    return dispatch(ORGANIZATIONS_DELETE_ORGANIZATION_SUCCESS({ id }));
  };
};

export const updateOrganizationLayout = (organizationId, layout) => {
  return async (dispatch, getState) => {
    dispatch(ORGANIZATIONS_UPDATE_LAYOUT_INIT());

    const { locale } = getState().preferences;

    try {
      await firebase
        .firestore()
        .collection('organizations')
        .doc(organizationId)
        .update({
          layout,
        });

      toastr.success('', 'Layout updated successfully');

      return dispatch(
        ORGANIZATIONS_UPDATE_LAYOUT_SUCCESS({
          id: organizationId,
          layout,
        })
      );
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        ORGANIZATIONS_UPDATE_LAYOUT_FAIL({
          error: errorMessage,
        })
      );
    }
  };
};

export const organizationsCleanUp = () => (dispatch) =>
  dispatch(ORGANIZATIONS_CLEAN_UP());
