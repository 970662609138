/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import { QuestionType } from 'utils/surveyBuilder/enums';
import classes from './QuestionChoice.module.scss';

import './QuestionChoice.scss';

const QuestionChoice = ({
  choiceIndex,
  canBeRemoved,
  readOnly,
  visible,
  choiceValue,
  questionType,
  onAddChoice,
  onDeleteChoice,
  onChangeChoice,
  onChangeVisibility,
  onChangeOpenEnded,
  onChangeNotApplicable,
  isDisplaying,
  isBeingDragged,
  userIsDragging,
  dragProps,
  duplicated,
  openEnded,
  notApplicable,
  deploymentId,
  hasActions,
  newChoice,
}) => (
  <div
    {...dragProps.draggableProps}
    ref={dragProps.innerRef}
    className={classNames(
      'question-choice',
      'field',
      'is-grouped',
      classes['question-choice-container'],
      {
        'question-choice--hidden': !visible,
      }
    )}
  >
    <div
      className={classNames('control', 'question-choice__icons', {
        'question-choice__icons--displaying': isDisplaying,
      })}
    >
      {!isDisplaying && (
        <span
          {...dragProps.dragHandleProps}
          className={classNames(
            'icon',
            'is-small',
            'question-choice__drag-icon',
            {
              'question-choice__drag-icon--hidden': readOnly || isDisplaying,
              'question-choice__drag-icon--dragging': isBeingDragged,
              'question-choice__drag-icon--being-dragged-over':
                userIsDragging && !isBeingDragged,
            }
          )}
        >
          <i className="mdi mdi-drag" />
        </span>
      )}
      {isDisplaying && (
        <div className="question-choice__tags">
          {!visible && (
            <span className="tag is-info question-choice__tag">Hidden</span>
          )}
          {notApplicable && (
            <span className="tag is-info question-choice__tag">N/A</span>
          )}
          {openEnded && (
            <span
              className="tag is-primary question-choice__tag"
              style={{ display: openEnded ? 'inherit' : 'none' }}
            >
              Open Ended
            </span>
          )}
        </div>
      )}
      <span className="icon is-medium">
        <i
          className={classNames('mdi', {
            'mdi-radiobox-blank': questionType === QuestionType.SINGLE_RESPONSE,
            'mdi-checkbox-blank-outline':
              questionType === QuestionType.MULTIPLE_REPONSE,
            'mdi-arrow-down-drop-circle':
              questionType === QuestionType.DROPDOWN,
            'mdi-sort-numeric-ascending':
              questionType === QuestionType.RANK_ORDER,
          })}
        />
      </span>
    </div>
    <div
      className={classNames(
        'control is-expanded question-choice__choice',
        classes['choice-field']
      )}
    >
      {!isDisplaying && (
        <div className="field" style={{ width: '100%' }}>
          <div className="control is-expanded">
            <input
              id="question-choice-value"
              className={classNames('input', { 'is-danger': duplicated })}
              type="text"
              required
              readOnly={isDisplaying}
              disabled={readOnly || (deploymentId && !newChoice)}
              value={choiceValue}
              onChange={(e) =>
                onChangeChoice({ choiceIndex, option: e.target.value })
              }
              form="create-question-form"
              tabIndex={choiceIndex + 1}
            />
          </div>
          {duplicated && (
            <p className="help is-danger">This choice is duplicated</p>
          )}
        </div>
      )}
      {isDisplaying && (
        <p className="question-choice__content--displaying">{choiceValue}</p>
      )}
    </div>
    <div className={classNames('control', classes['choice-options'])}>
      <button
        id="change-visibility-question-choice"
        className={classNames('icon', 'is-medium', 'question-choice__action', {
          'question-choice__action--hidden': isDisplaying,
        })}
        data-tip="Visibility"
        data-for="choices-options"
        type="button"
        onClick={() => onChangeVisibility(choiceIndex)}
      >
        <i
          className={classNames('mdi', {
            'mdi-eye': visible,
            'mdi-eye-off': !visible,
          })}
        />
      </button>
      <button
        id="make-not-aplicable-question-choice"
        className={classNames('icon', 'is-medium', 'question-choice__action', {
          'question-choice__action--hidden': isDisplaying,
        })}
        data-tip="Not Applicable"
        data-for="choices-options"
        type="button"
        onClick={() => onChangeNotApplicable(choiceIndex)}
      >
        <i
          className={classNames('mdi', 'mdi-cancel', {
            'question-choice__action--selected': notApplicable,
          })}
        />
      </button>
      {(questionType === QuestionType.SINGLE_RESPONSE ||
        questionType === QuestionType.MULTIPLE_REPONSE) && (
        <button
          id="make-open-ended-question-choice"
          className={classNames(
            'icon',
            'is-medium',
            'question-choice__action',
            {
              'question-choice__action--hidden': isDisplaying,
            }
          )}
          data-tip="Open ended"
          data-for="choices-options"
          type="button"
          onClick={() => onChangeOpenEnded(choiceIndex)}
        >
          <i
            className={classNames('mdi', 'mdi-comment-quote', {
              'question-choice__action--selected': openEnded,
            })}
          />
        </button>
      )}
      <button
        id="add-question-choice"
        className={classNames('icon', 'is-medium', 'question-choice__action', {
          'question-choice__action--hidden': readOnly || isDisplaying,
        })}
        data-tip="Add choice"
        data-for="choices-options"
        type="button"
        onClick={() => onAddChoice(choiceIndex + 1)}
      >
        <i className="mdi mdi-plus-circle-outline" />
      </button>
      <button
        id="delete-question-choice"
        className={classNames('icon', 'is-medium', 'question-choice__action', {
          'question-choice__action--hidden':
            !canBeRemoved || readOnly || isDisplaying,
          [classes.hidden]: deploymentId && !newChoice,
        })}
        data-tip={
          hasActions
            ? 'This choice cannot be removed because it has actions'
            : 'Delete choice'
        }
        data-for="choices-options"
        type="button"
        onClick={() => onDeleteChoice(choiceIndex)}
      >
        <i className="mdi mdi-minus-circle-outline" />
      </button>
      <ReactTooltip id="choices-options" />
    </div>
  </div>
);

QuestionChoice.propTypes = {
  questionType: PropTypes.oneOf([
    QuestionType.MULTIPLE_REPONSE,
    QuestionType.SINGLE_RESPONSE,
    QuestionType.DROPDOWN,
    QuestionType.RANK_ORDER,
  ]).isRequired,
  choiceValue: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  openEnded: PropTypes.bool.isRequired,
  notApplicable: PropTypes.bool.isRequired,
  duplicated: PropTypes.bool,
  onChangeVisibility: PropTypes.func,
  onChangeOpenEnded: PropTypes.func,
  choiceIndex: PropTypes.number,
  onDeleteChoice: PropTypes.func,
  onAddChoice: PropTypes.func,
  onChangeChoice: PropTypes.func,
  canBeRemoved: PropTypes.bool,
  readOnly: PropTypes.bool,
  isDisplaying: PropTypes.bool,
  userIsDragging: PropTypes.bool,
  isBeingDragged: PropTypes.bool,
  dragProps: PropTypes.object,
  hasActions: PropTypes.bool,
};

QuestionChoice.defaultProps = {
  canBeRemoved: false,
  readOnly: false,
  userIsDragging: false,
  isBeingDragged: false,
  isDisplaying: false,
  dragProps: {},
  choiceIndex: null,
  onChangeVisibility: null,
  duplicated: false,
  onChangeOpenEnded: null,
};

export default QuestionChoice;
