import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import parse from 'html-react-parser';

import {
  createSurveyResponse,
  createSurveyResponseSave,
  surveysClearState,
  updateHitsOnSurvey,
  fetchDeployment,
  fetchPreview,
  clearSurveyPreviewStorage,
  clearSurveysResponseSaveState,
  clearSurveysResponseLoadState,
} from 'state/actions/surveys';
import { setUserLocale } from 'state/actions/preferences';
import {
  SurveyElement,
  SurveyDeploymentStatus,
  QuestionType,
} from 'utils/surveyBuilder/enums';
import {
  ActionBuilderConditionTypes,
  AvailableActions,
  ConditionOperators,
  LanguageIsoCode,
  LanguageTranslation,
  OrderTypes,
  RegularOperators,
} from 'utils/enums';
import {
  alphabeticalOrderAsc,
  alphabeticalOrderDesc,
  browserLocale,
  findLastIndexFromArray,
  getTranslation,
  mergeDemographics,
  shuffleArray,
} from 'utils';
import {
  selectLiveSurveyDataState,
  selectFetchResponseInProgressState,
} from 'state/selectors/surveys';
import { fetchResponseInProgress } from 'state/actions/surveys/fetch/fetchResponseInProgress';
import useNewModal from 'hooks/useNewModal';
import getDeploymentStatus from 'utils/surveyBuilder/getDeploymentStatus';
import Page from 'components/LiveSurvey/Page';
import Section from 'components/LiveSurvey/Section';
import Question from 'components/LiveSurvey/Question';
import TextBox from 'components/LiveSurvey/TextBox';
import Pagination from 'components/LiveSurvey/Pagination';
import QuestionGroup from 'components/LiveSurvey/QuestionGroup';
import Item from 'components/LiveSurvey/Item';
import PageLoader from 'components/PageLoader';
import Footer from 'components/LiveSurvey/Footer';
import ConfirmNextPageModal from 'components/LiveSurvey/Modals/ConfirmNextPageModal';
import { useChangeInputHandler } from 'hooks/LiveSurvey';
import useLastAnsweredPageGetter from './hooks/useLastAnsweredPageGetter';

import classes from './LiveSurvey.module.scss';

const randomizeQuestionChoices = (items) =>
  items.map((item) => {
    if (item.type === SurveyElement.QUESTION) {
      const { choicesOrder } = item.question;
      let { choices } = item.question;

      if (choicesOrder === OrderTypes.RANDOM) {
        choices = shuffleArray([...choices]);
      }

      return { ...item, question: { ...item.question, choices } };
    }

    return item;
  });

const randomizeItems = (items) => {
  if (items[0].type === SurveyElement.TEXT_BOX) {
    return [items[0], ...shuffleArray([...items.slice(1)])];
  }
  return shuffleArray([...items]);
};

const randomizeItemsAndChoices = (section) => {
  let items = section?.items;

  if (items.length > 0) {
    if (section.randomize) {
      items = randomizeItems(items);
    }

    items = randomizeQuestionChoices(items);
  }

  return items;
};

const orderQuestionChoicesAlphabetically = (section, surveyLanguage) => {
  let items = section?.items;

  items = items.map((item) => {
    if (item.type === SurveyElement.QUESTION) {
      const { choicesOrder } = item.question;
      let { choices } = item.question;

      if (choicesOrder === OrderTypes.ASC) {
        choices = alphabeticalOrderAsc([...choices], surveyLanguage);
      }

      if (choicesOrder === OrderTypes.DESC) {
        choices = alphabeticalOrderDesc([...choices], surveyLanguage);
      }

      return { ...item, question: { ...item.question, choices } };
    }

    return item;
  });

  return items;
};

const LiveSurvey = () => {
  const { deploymentUrl } = useParams();
  const { pathname } = useLocation();

  const isPreview = pathname.split('/').at(-1) === 'preview';

  const {
    respondedSurvey,
    creatingResponse,
    fetchingSurvey,
    errorFetching,
    fetchedSurvey,
    surveyPages,
    availableLanguages,
    surveyItems,
  } = useSelector(selectLiveSurveyDataState({ isPreview }), shallowEqual);

  const { responseInProgress, success } = useSelector(
    selectFetchResponseInProgressState
  );

  const [response, setResponse] = useState({
    valid: false,
    waveId: null,
    surveyId: null,
    deploymentId: null,
  });
  const [questionResponses, setQuestionResponses] = useState(null);
  const [pageIndex, setPageIndex] = useState(0);
  const [validateQuestions, setValidateQuestions] = useState(false);
  const [surveyLanguage, setSurveyLanguage] = useState({
    code: LanguageIsoCode.EN,
    select: {
      label: LanguageTranslation[LanguageIsoCode.EN],
      code: LanguageIsoCode.EN,
    },
  });

  const [surveySections, setSurveySections] = useState([]);
  const [randomizedPages, setRandomizedPages] = useState([]);
  const [surveyActions, setSurveyActions] = useState(null);
  const [pagesWithActions, setPagesWithActions] = useState(surveyPages || []);
  const [visiblePages, setVisiblePages] = useState(surveyPages || []);
  const [previewIsAnswered, setPreviewIsAnswered] = useState(false);

  const dispatch = useDispatch();

  const topOfThePage = useRef(null);

  const onChangeInputHandler = useChangeInputHandler(setQuestionResponses);

  const {
    isOpen: confirmNextPageModalIsOpen,
    toggle: confirmNextPageModalToggle,
  } = useNewModal();

  useEffect(() => {
    if (pageIndex >= 0) {
      if (topOfThePage.current?.scrollIntoView) {
        topOfThePage.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [pageIndex]);

  useEffect(() => {
    document.documentElement.className = '';
    document.body.className = '';

    return () => {
      document.documentElement.className =
        'has-aside-left has-aside-mobile-transition has-aside-expanded';

      document.body.className = 'has-navbar-fixed-top';
    };
  }, []);

  useEffect(() => {
    if (!isPreview && fetchedSurvey) {
      dispatch(updateHitsOnSurvey(fetchedSurvey.surveyId, fetchedSurvey.id));
    }
  }, [isPreview, fetchedSurvey]);

  useEffect(() => {
    if (isPreview) {
      dispatch(fetchPreview());
    } else {
      dispatch(fetchDeployment(deploymentUrl));
    }
  }, [dispatch, deploymentUrl, isPreview]);

  useEffect(() => {
    if (fetchedSurvey) {
      const questions = {};

      const notParsedQuestions = [];

      Object.values(fetchedSurvey.items).forEach((item) => {
        if (item.type === SurveyElement.QUESTION) {
          const { question, visible, id } = item;

          notParsedQuestions.push({
            question: { ...question, visible, id },
          });
        }

        if (item.type === SurveyElement.QUESTION_GROUP) {
          const {
            id,
            questionGroup: { questions: q, choices },
          } = item;

          q.forEach((question) => {
            notParsedQuestions.push({
              question: {
                ...question,
                choices,
                group: id,
              },
            });
          });
        }
      });

      notParsedQuestions.forEach(({ question }) => {
        const choices = {};
        const openEndedChoices = {};
        const openEndedChoiceAnswers = {};

        question.choices.forEach((choice) => {
          if (choice.questionChoices) {
            return choice.questionChoices.forEach((questionChoice) => {
              openEndedChoices[choice.dataSetId] = {
                ...(openEndedChoices?.[choice.dataSetId] || {}),
                [questionChoice.id]: questionChoice.openEnded,
              };

              openEndedChoiceAnswers[choice.dataSetId] = {
                ...(openEndedChoiceAnswers?.[choice.dataSetId] || {}),
                [questionChoice.id]: '',
              };

              choices[choice.dataSetId] = {
                ...(choices?.[choice.dataSetId] || {}),
                [questionChoice.id]: false,
              };
              choices[choice.dataSetId][questionChoice.id] = false;
            });
          }

          openEndedChoices[choice.id] = choice.openEnded;
          openEndedChoiceAnswers[choice.id] = '';

          if (question.type === 'rank-order') {
            choices[choice.id] = null;
          } else {
            choices[choice.id] = false;
          }

          return null;
        });

        questions[question.id] = {
          type: question.type,
          choices,
          openEndedChoices,
          openEndedChoiceAnswers,
          openAnswer: '',
          value: question.value ?? null,
          required: question.required,
          visible: question.visible,
          sliderProps: question.sliderProps
            ? {
                ...question.sliderProps,
                value:
                  question.sliderProps.defaultValue ?? question.sliderProps.min,
              }
            : null,
          regionalBreakdown: question.regionalBreakdown || false,
          group: question.group || null,
        };
      });

      const surveyHasUserLanguage = fetchedSurvey.availableLanguages.some(
        (code) =>
          code === browserLocale &&
          fetchedSurvey.languageCompletion[code] === 100
      );

      setSurveyActions(fetchedSurvey.actions);
      setResponse((prevState) => ({
        ...prevState,
        waveId: fetchedSurvey.waveId,
        surveyId: fetchedSurvey.surveyId,
        deploymentId: fetchedSurvey.id,
      }));
      setQuestionResponses(questions);
      setSurveyLanguage((prevState) => ({
        ...prevState,
        code: surveyHasUserLanguage
          ? browserLocale
          : fetchedSurvey.defaultLanguage,
        select: surveyHasUserLanguage
          ? {
              label: LanguageTranslation[browserLocale],
              value: browserLocale,
            }
          : {
              label: LanguageTranslation[fetchedSurvey.defaultLanguage],
              value: fetchedSurvey.defaultLanguage,
            },
      }));
    }
  }, [fetchedSurvey]);

  useEffect(() => {
    dispatch(clearSurveysResponseLoadState());
    dispatch(clearSurveysResponseSaveState());
  }, [dispatch]);

  useEffect(() => {
    const cleanStateBeforeUnload = () => {
      dispatch(clearSurveyPreviewStorage());
      dispatch(clearSurveysResponseLoadState());
      dispatch(clearSurveysResponseSaveState());
    };

    const preventUserFromLeaving = (e) => {
      e.preventDefault();
      e.returnValue = '';
    };

    const handleBeforeUnload = (e) => {
      if (!isPreview && !respondedSurvey) {
        preventUserFromLeaving(e);
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      cleanStateBeforeUnload();
    };
  }, [isPreview, respondedSurvey]);

  useEffect(() => {
    return () => {
      dispatch(surveysClearState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (surveyPages) {
      const pages = [...surveyPages];
      setPagesWithActions(pages);
    }
  }, [surveyPages.length]);

  useEffect(() => {
    const visible = [];

    pagesWithActions.forEach((page) => {
      if (page.visible) {
        visible.push({ ...page });
      }
    });

    setVisiblePages(visible);
  }, [pagesWithActions]);

  useEffect(() => {
    const sections =
      visiblePages[pageIndex]?.sections.map((section) => {
        const items = randomizeItemsAndChoices(section);
        return { ...section, items };
      }) || [];

    const modifiedSections = sections.map((section) => {
      const items = orderQuestionChoicesAlphabetically(
        section,
        surveyLanguage.code
      );
      return { ...section, items };
    });

    setSurveySections(modifiedSections);
  }, [pageIndex, visiblePages]);

  useEffect(() => {
    const languageCode = surveyLanguage.code.toLowerCase().replace('-', '');

    dispatch(setUserLocale(languageCode));
  }, [surveyLanguage.code]);

  const removeSelectedOptions = useCallback(
    (questionsId) => {
      const allQuestionsIds = [];

      questionsId.forEach((element) => {
        if (Array.isArray(element)) {
          allQuestionsIds.push(...element);
        } else {
          allQuestionsIds.push(element);
        }
      });

      allQuestionsIds.forEach((questionId) => {
        const { choices: prevChoices } = questionResponses[questionId];
        const choices = {};

        Object.keys(prevChoices).forEach((key) => {
          if (prevChoices[key] === true || prevChoices[key] === undefined) {
            choices[key] = false;
          } else {
            Object.keys(prevChoices[key]).forEach((subKey) => {
              if (!choices[key]) {
                choices[key] = {};
              }

              choices[key][subKey] = false;
            });
          }
        });

        setQuestionResponses((prevState) => ({
          ...prevState,
          [questionId]: {
            ...questionResponses[questionId],
            choices,
          },
        }));
      });
    },
    [questionResponses]
  );

  const getQuestionsIdFromPage = useCallback(
    (pageId) => {
      const questionsIds = [];

      pagesWithActions.forEach((page) => {
        if (page.id === pageId) {
          page.sections.forEach((section) => {
            section.items.forEach(({ question, questionGroup }) => {
              if (question) {
                questionsIds.push(question.id);
              }
              if (questionGroup) {
                questionGroup.questions.forEach(({ id }) => {
                  questionsIds.push(id);
                });
              }
            });
          });
        }
      });

      return questionsIds;
    },
    [pagesWithActions]
  );

  const getQuestionsIdFromSection = useCallback(
    (sectionId) => {
      const questionsIds = [];

      pagesWithActions.forEach((page) => {
        page.sections.forEach((section) => {
          if (section.id === sectionId) {
            section.items.forEach(({ question, questionGroup }) => {
              if (question) {
                questionsIds.push(question.id);
              }
              if (questionGroup) {
                questionGroup.questions.forEach(({ id }) => {
                  questionsIds.push(id);
                });
              }
            });
          }
        });
      });

      return questionsIds;
    },
    [pagesWithActions]
  );

  const getQuestionsIdFromItem = useCallback(
    (itemId) => {
      const questionsIds = [];

      pagesWithActions.forEach((page) => {
        page.sections.forEach((section) => {
          section.items.forEach((item) => {
            if (item.id === itemId) {
              if (item.question) {
                questionsIds.push(item.question.id);
              }
              if (item.questionGroup) {
                item.questionGroup.questions.forEach(({ id }) => {
                  questionsIds.push(id);
                });
              }
            }
          });
        });
      });

      return questionsIds;
    },
    [pagesWithActions]
  );

  const meetCondition = useCallback(
    (condition) => {
      const { subject, criteria, values } = condition;

      const questionResponse = questionResponses[subject];

      if (criteria === ConditionOperators.HAS_A_RESPONSE && questionResponse) {
        const hasResponse = Object.values(questionResponse.choices).some(
          (choice) => choice
        );

        return hasResponse;
      }

      if (criteria === ConditionOperators.HAS_NO_RESPONSE && questionResponse) {
        const hasNoResponse = Object.values(questionResponse.choices).every(
          (choice) => !choice
        );

        return hasNoResponse;
      }

      if (criteria === ConditionOperators.CONTAINS && questionResponse) {
        const choice = values[0].split('.');

        if (choice.length > 1) {
          const containsResponse =
            questionResponse.choices[choice[0]][choice[1]];

          return containsResponse;
        }

        const containsResponse = questionResponse.choices[values[0]];

        return containsResponse;
      }

      if (
        criteria === ConditionOperators.DOES_NOT_CONTAIN &&
        questionResponse
      ) {
        const choice = values[0].split('.');

        if (choice.length > 1) {
          const notContainsResponse =
            !questionResponse.choices[choice[0]][choice[1]];

          return notContainsResponse;
        }

        const notContainsResponse = !questionResponse.choices[values[0]];

        return notContainsResponse;
      }

      if (criteria === ConditionOperators.CONTAINS_ONE_OF && questionResponse) {
        const containsOneOfResponse = values.some((value) => {
          const choice = value.split('.');

          if (choice.length > 1) {
            return questionResponse.choices[choice[0]][choice[1]];
          }

          return questionResponse.choices[value];
        });

        return containsOneOfResponse;
      }

      if (
        criteria === ConditionOperators.DOES_NOT_CONTAIN_ANY_OF &&
        questionResponse
      ) {
        const containsNoneOfResponse = values.every((value) => {
          const choice = value.split('.');

          if (choice.length > 1) {
            return !questionResponse.choices[choice[0]][choice[1]];
          }

          return !questionResponse.choices[value];
        });

        return containsNoneOfResponse;
      }

      if (criteria === ConditionOperators.CONTAINS_ALL_OF && questionResponse) {
        const containsAllOfResponse = values.every((value) => {
          const choice = value.split('.');

          if (choice.length > 1) {
            return questionResponse.choices[choice[0]][choice[1]];
          }

          return questionResponse.choices[value];
        });

        return containsAllOfResponse;
      }
      return true;
    },
    [questionResponses]
  );

  const meetsAllOrConditions = useCallback(
    (conditions) => {
      return conditions.some((condition) => meetCondition(condition));
    },
    [meetCondition]
  );

  const applyAction = useCallback(
    (actionToApply, receivedRandomizedPages) => {
      const { action, subject, choices, randomize } = actionToApply;

      if (action === AvailableActions.HIDE_QUESTION) {
        const pages = [...pagesWithActions];

        pages.forEach((page, indexOfPage) => {
          page.sections.forEach((section, sectionIndex) => {
            section.items.forEach((item, itemIndex) => {
              if (
                item.question?.id === subject ||
                (!item.questionGroup && item.id === subject)
              ) {
                pages[indexOfPage].sections[sectionIndex].items[
                  itemIndex
                ].visible = false;
              } else if (item.questionGroup) {
                item.questionGroup.questions.forEach(
                  (question, questionIndex) => {
                    if (question.id === subject) {
                      pages[indexOfPage].sections[sectionIndex].items[
                        itemIndex
                      ].questionGroup.questions[questionIndex].visible = false;
                    }
                  }
                );
              }
            });
          });
        });

        setPagesWithActions(pages);
      }
      if (action === AvailableActions.SHOW_QUESTION) {
        const pages = [...pagesWithActions];

        pages.forEach((page, indexOfPage) => {
          page.sections.forEach((section, sectionIndex) => {
            section.items.forEach((item, itemIndex) => {
              if (
                item.question?.id === subject ||
                (!item.questionGroup && item.id === subject)
              ) {
                pages[indexOfPage].sections[sectionIndex].items[
                  itemIndex
                ].visible = true;
              } else if (item.questionGroup) {
                item.questionGroup.questions.forEach(
                  (question, questionIndex) => {
                    if (question.id === subject) {
                      pages[indexOfPage].sections[sectionIndex].items[
                        itemIndex
                      ].questionGroup.questions[questionIndex].visible = true;
                    }
                  }
                );
              }
            });
          });
        });

        setPagesWithActions(pages);
      }
      if (action === AvailableActions.HIDE_TEXT_BOX) {
        const pages = [...pagesWithActions];

        pages.forEach((page, indexOfPage) => {
          page.sections.forEach((section, sectionIndex) => {
            section.items.forEach((item, itemIndex) => {
              if (item.id === subject) {
                pages[indexOfPage].sections[sectionIndex].items[
                  itemIndex
                ].visible = false;
              }
            });
          });
        });

        setPagesWithActions(pages);
      }
      if (action === AvailableActions.SHOW_TEXT_BOX) {
        const pages = [...pagesWithActions];

        pages.forEach((page, indexOfPage) => {
          page.sections.forEach((section, sectionIndex) => {
            section.items.forEach((item, itemIndex) => {
              if (item.id === subject) {
                pages[indexOfPage].sections[sectionIndex].items[
                  itemIndex
                ].visible = true;
              }
            });
          });
        });

        setPagesWithActions(pages);
      }
      if (action === AvailableActions.HIDE_SECTION) {
        const pages = [...pagesWithActions];
        pages.forEach((page, indexOfPage) => {
          page.sections.forEach((section, sectionIndex) => {
            if (section.id === subject)
              pages[indexOfPage].sections[sectionIndex].visible = false;
          });
        });

        setPagesWithActions(pages);
      }
      if (action === AvailableActions.SHOW_SECTION) {
        const pages = [...pagesWithActions];
        pages.forEach((page, indexOfPage) => {
          page.sections.forEach((section, sectionIndex) => {
            if (section.id === subject)
              pages[indexOfPage].sections[sectionIndex].visible = true;
          });
        });

        setPagesWithActions(pages);
      }
      if (action === AvailableActions.HIDE_PAGE) {
        const pages = [...pagesWithActions];
        pages.forEach((page, index) => {
          if (page.id === subject) pages[index].visible = false;
        });

        setPagesWithActions(pages);
      }
      if (action === AvailableActions.SHOW_PAGE) {
        const pages = [...pagesWithActions];
        pages.forEach((page, index) => {
          if (page.id === subject) pages[index].visible = true;
        });

        setPagesWithActions(pages);
      }
      if (action === AvailableActions.HIDE_CHOICES) {
        const pages = [...pagesWithActions];
        pages.forEach((page, indexOfPage) => {
          page.sections.forEach((section, sectionIndex) => {
            section.items.forEach((item, itemIndex) => {
              if (
                item.question?.id === subject ||
                (!item.questionGroup && item.id === subject)
              ) {
                item.question.choices.forEach((choice, choiceIndex) => {
                  if (choices.includes(choice.id)) {
                    pages[indexOfPage].sections[sectionIndex].items[
                      itemIndex
                    ].question.choices[choiceIndex].visible = false;
                  }
                });
              } else if (item.questionGroup && item.id === subject) {
                item.questionGroup.choices.forEach((choice, choiceIndex) => {
                  if (choices.includes(choice.id)) {
                    pages[indexOfPage].sections[sectionIndex].items[
                      itemIndex
                    ].questionGroup.choices[choiceIndex].visible = false;
                  }
                });
              }
            });
          });
        });

        setPagesWithActions(pages);
      }
      if (action === AvailableActions.SHOW_CHOICES) {
        const pages = [...pagesWithActions];
        pages.forEach((page, indexOfPage) => {
          page.sections.forEach((section, sectionIndex) => {
            section.items.forEach((item, itemIndex) => {
              if (
                item.question?.id === subject ||
                (!item.questionGroup && item.id === subject)
              ) {
                item.question.choices.forEach((choice, choiceIndex) => {
                  if (choices.includes(choice.id)) {
                    pages[indexOfPage].sections[sectionIndex].items[
                      itemIndex
                    ].question.choices[choiceIndex].visible = true;
                  }
                });
              } else if (item.questionGroup && item.id === subject) {
                item.questionGroup.choices.forEach((choice, choiceIndex) => {
                  if (choices.includes(choice.id)) {
                    pages[indexOfPage].sections[sectionIndex].items[
                      itemIndex
                    ].questionGroup.choices[choiceIndex].visible = true;
                  }
                });
              }
            });
          });
        });
        setPagesWithActions(pages);
      }
      if (action === AvailableActions.RANDOMIZE_QUESTIONS) {
        const pages = [...pagesWithActions];
        const subjects = [...subject];
        const sortedSubjects = subjects.sort(() => Math.random() - 0.5);
        const questions = sortedSubjects.splice(0, randomize);
        pages.forEach((page, indexOfPage) => {
          page.sections.forEach((section, sectionIndex) => {
            section.items.forEach((item, itemIndex) => {
              if (
                questions.includes(item.question?.id) ||
                (!item.questionGroup && questions.includes(item.id))
              ) {
                pages[indexOfPage].sections[sectionIndex].items[
                  itemIndex
                ].visible = true;
              } else if (item.questionGroup) {
                item.questionGroup.questions.forEach(
                  (question, questionIndex) => {
                    if (questions.includes(question.id)) {
                      pages[indexOfPage].sections[sectionIndex].items[
                        itemIndex
                      ].questionGroup.questions[questionIndex].visible = true;
                    }
                  }
                );
              }
            });
          });
        });
        setPagesWithActions(pages);
      }
      if (action === AvailableActions.RANDOMIZE_SECTIONS) {
        const pages = [...pagesWithActions];
        const subjects = [...subject];
        const sortedSubjects = subjects.sort(() => Math.random() - 0.5);
        const sections = sortedSubjects.splice(0, randomize);
        pages.forEach((page, indexOfPage) => {
          page.sections.forEach((section, sectionIndex) => {
            if (sections.includes(section.id)) {
              pages[indexOfPage].sections[sectionIndex].visible = true;
            }
          });
        });
        setPagesWithActions(pages);
      }
      if (action === AvailableActions.RANDOMIZE_PAGES) {
        const pages = [...pagesWithActions];
        const subjects = [...subject];

        const arePreRandomizedPages = subjects.filter((pageId) =>
          receivedRandomizedPages.includes(pageId)
        );

        if (arePreRandomizedPages.length) {
          pages.forEach((page, indexOfPage) => {
            if (arePreRandomizedPages.includes(page.id)) {
              pages[indexOfPage].visible = true;
            }
          });
        } else {
          const sortedSubjects = subjects.sort(() => Math.random() - 0.5);
          const sections = sortedSubjects.splice(0, randomize);
          pages.forEach((page, indexOfPage) => {
            if (sections.includes(page.id)) {
              pages[indexOfPage].visible = true;
            }
          });

          setRandomizedPages((prevPages) => [...prevPages, ...sections]);
        }

        setPagesWithActions(pages);
      }
      if (action === AvailableActions.RANDOMIZE_CHOICES) {
        const pages = [...pagesWithActions];
        const allChoices = [...choices];
        const sortedChoices = allChoices.sort(() => Math.random() - 0.5);
        const showChoices = sortedChoices.splice(0, randomize);
        pages.forEach((page, indexOfPage) => {
          page.sections.forEach((section, sectionIndex) => {
            section.items.forEach((item, itemIndex) => {
              if (
                item.question?.id === subject ||
                (!item.questionGroup && item.id === subject)
              ) {
                item.question.choices.forEach((choice, indexChoice) => {
                  if (showChoices.includes(choice.id)) {
                    pages[indexOfPage].sections[sectionIndex].items[
                      itemIndex
                    ].question.choices[indexChoice].visible = true;
                  }
                });
              } else if (item.questionGroup && item.id === subject) {
                item.questionGroup.choices.forEach((choice, indexChoice) => {
                  if (showChoices.includes(choice.id)) {
                    pages[indexOfPage].sections[sectionIndex].items[
                      itemIndex
                    ].questionGroup.choices[indexChoice].visible = true;
                  }
                });
              }
            });
          });
        });
        setPagesWithActions(pages);
      }
      if (action === AvailableActions.SHOW_QUESTION_GROUP) {
        const pages = [...pagesWithActions];
        pages.forEach((page, indexOfPage) => {
          page.sections.forEach((section, sectionIndex) => {
            section.items.forEach((item, itemIndex) => {
              if (item.id === subject) {
                pages[indexOfPage].sections[sectionIndex].items[
                  itemIndex
                ].visible = true;
              }
            });
          });
        });
        setPagesWithActions(pages);
      }
      if (action === AvailableActions.HIDE_QUESTION_GROUP) {
        const pages = [...pagesWithActions];
        pages.forEach((page, indexOfPage) => {
          page.sections.forEach((section, sectionIndex) => {
            section.items.forEach((item, itemIndex) => {
              if (item.id === subject) {
                pages[indexOfPage].sections[sectionIndex].items[
                  itemIndex
                ].visible = false;
              }
            });
          });
        });
        setPagesWithActions(pages);
      }
    },
    [pagesWithActions]
  );

  const removeAppliedAction = useCallback(
    (actionToRemove) => {
      if (surveySections.length > 0) {
        const { action, subject, choices } = actionToRemove;
        if (action === AvailableActions.HIDE_QUESTION) {
          const pages = [...pagesWithActions];
          pages.forEach((page, indexOfPage) => {
            page.sections.forEach((section, sectionIndex) => {
              section.items.forEach((item, itemIndex) => {
                if (
                  item.question?.id === subject ||
                  (!item.questionGroup && item.id === subject)
                ) {
                  pages[indexOfPage].sections[sectionIndex].items[
                    itemIndex
                  ].visible = true;
                } else if (item.questionGroup) {
                  item.questionGroup.questions.forEach(
                    (question, questionIndex) => {
                      if (question.id === subject) {
                        pages[indexOfPage].sections[sectionIndex].items[
                          itemIndex
                        ].questionGroup.questions[questionIndex].visible = true;
                      }
                    }
                  );
                }
              });
            });
          });
          setPagesWithActions(pages);
          removeSelectedOptions([subject]);
        }
        if (action === AvailableActions.SHOW_QUESTION) {
          const pages = [...pagesWithActions];
          pages.forEach((page, indexOfPage) => {
            page.sections.forEach((section, sectionIndex) => {
              section.items.forEach((item, itemIndex) => {
                if (
                  item.question?.id === subject ||
                  (!item.questionGroup && item.id === subject)
                ) {
                  pages[indexOfPage].sections[sectionIndex].items[
                    itemIndex
                  ].visible = false;
                } else if (item.questionGroup) {
                  item.questionGroup.questions.forEach(
                    (question, questionIndex) => {
                      if (question.id === subject) {
                        pages[indexOfPage].sections[sectionIndex].items[
                          itemIndex
                        ].questionGroup.questions[
                          questionIndex
                        ].visible = false;
                      }
                    }
                  );
                }
              });
            });
          });
          setPagesWithActions(pages);
          removeSelectedOptions([subject]);
        }
        if (action === AvailableActions.HIDE_TEXT_BOX) {
          const pages = [...pagesWithActions];
          pages.forEach((page, indexOfPage) => {
            page.sections.forEach((section, sectionIndex) => {
              section.items.forEach((item, itemIndex) => {
                if (item.id === subject) {
                  pages[indexOfPage].sections[sectionIndex].items[
                    itemIndex
                  ].visible = true;
                }
              });
            });
          });
          setPagesWithActions(pages);
        }
        if (action === AvailableActions.SHOW_TEXT_BOX) {
          const pages = [...pagesWithActions];
          pages.forEach((page, indexOfPage) => {
            page.sections.forEach((section, sectionIndex) => {
              section.items.forEach((item, itemIndex) => {
                if (item.id === subject) {
                  pages[indexOfPage].sections[sectionIndex].items[
                    itemIndex
                  ].visible = false;
                }
              });
            });
          });
          setPagesWithActions(pages);
        }
        if (action === AvailableActions.HIDE_SECTION) {
          const pages = [...pagesWithActions];
          pages.forEach((page, indexOfPage) => {
            page.sections.forEach((section, sectionIndex) => {
              if (section.id === subject)
                pages[indexOfPage].sections[sectionIndex].visible = true;
            });
          });
          setPagesWithActions(pages);
          const questionsIds = getQuestionsIdFromSection(subject);
          removeSelectedOptions(questionsIds);
        }
        if (action === AvailableActions.SHOW_SECTION) {
          const pages = [...pagesWithActions];
          pages.forEach((page, indexOfPage) => {
            page.sections.forEach((section, sectionIndex) => {
              if (section.id === subject)
                pages[indexOfPage].sections[sectionIndex].visible = false;
            });
          });
          setPagesWithActions(pages);
          const questionsIds = getQuestionsIdFromSection(subject);
          removeSelectedOptions(questionsIds);
        }
        if (action === AvailableActions.HIDE_PAGE) {
          const pages = [...pagesWithActions];
          pages.forEach((page, index) => {
            if (page.id === subject) pages[index].visible = true;
          });
          setPagesWithActions(pages);
          const questionsIds = getQuestionsIdFromPage(subject);
          removeSelectedOptions(questionsIds);
        }
        if (action === AvailableActions.SHOW_PAGE) {
          const pages = [...pagesWithActions];
          pages.forEach((page, index) => {
            if (page.id === subject) pages[index].visible = false;
          });
          setPagesWithActions(pages);
          const questionsIds = getQuestionsIdFromPage(subject);
          removeSelectedOptions(questionsIds);
        }
        if (action === AvailableActions.HIDE_CHOICES) {
          const pages = [...pagesWithActions];
          pages.forEach((page, indexOfPage) => {
            page.sections.forEach((section, sectionIndex) => {
              section.items.forEach((item, itemIndex) => {
                if (
                  item.question?.id === subject ||
                  (!item.questionGroup && item.id === subject)
                ) {
                  item.question.choices.forEach((choice, choiceIndex) => {
                    if (choices.includes(choice.id)) {
                      pages[indexOfPage].sections[sectionIndex].items[
                        itemIndex
                      ].question.choices[choiceIndex].visible = true;
                    }
                  });
                } else if (item.questionGroup && item.id === subject) {
                  item.questionGroup.choices.forEach((choice, choiceIndex) => {
                    if (choices.includes(choice.id)) {
                      pages[indexOfPage].sections[sectionIndex].items[
                        itemIndex
                      ].questionGroup.choices[choiceIndex].visible = true;
                    }
                  });
                }
              });
            });
          });
          setPagesWithActions(pages);
          removeSelectedOptions([subject]);
        }
        if (action === AvailableActions.SHOW_CHOICES) {
          const pages = [...pagesWithActions];
          pages.forEach((page, indexOfPage) => {
            page.sections.forEach((section, sectionIndex) => {
              section.items.forEach((item, itemIndex) => {
                if (
                  item.question?.id === subject ||
                  (!item.questionGroup && item.id === subject)
                ) {
                  item.question.choices.forEach((choice, choiceIndex) => {
                    if (choices.includes(choice.id)) {
                      pages[indexOfPage].sections[sectionIndex].items[
                        itemIndex
                      ].question.choices[choiceIndex].visible = false;
                    }
                  });
                } else if (item.questionGroup && item.id === subject) {
                  item.questionGroup.choices.forEach((choice, choiceIndex) => {
                    if (choices.includes(choice.id)) {
                      pages[indexOfPage].sections[sectionIndex].items[
                        itemIndex
                      ].questionGroup.choices[choiceIndex].visible = false;
                    }
                  });
                }
              });
            });
          });
          setPagesWithActions(pages);
          removeSelectedOptions([subject]);
        }
        if (action === AvailableActions.RANDOMIZE_QUESTIONS) {
          const pages = [...pagesWithActions];
          pages.forEach((page, indexOfPage) => {
            page.sections.forEach((section, sectionIndex) => {
              section.items.forEach((item, itemIndex) => {
                if (
                  subject.includes(item.question?.id) ||
                  (!item.questionGroup && subject.includes(item.id))
                ) {
                  pages[indexOfPage].sections[sectionIndex].items[
                    itemIndex
                  ].visible = false;
                } else if (item.questionGroup) {
                  item.questionGroup.questions.forEach(
                    (question, questionIndex) => {
                      if (subject.includes(question.id)) {
                        pages[indexOfPage].sections[sectionIndex].items[
                          itemIndex
                        ].questionGroup.questions[
                          questionIndex
                        ].visible = false;
                      }
                    }
                  );
                }
              });
            });
          });
          setPagesWithActions(pages);
          removeSelectedOptions([subject]);
        }
        if (action === AvailableActions.RANDOMIZE_SECTIONS) {
          const pages = [...pagesWithActions];
          pages.forEach((page, indexOfPage) => {
            page.sections.forEach((section, sectionIndex) => {
              if (subject.includes(section.id)) {
                pages[indexOfPage].sections[sectionIndex].visible = false;
              }
            });
          });
          setPagesWithActions(pages);
          const questionsId = getQuestionsIdFromSection(subject);
          removeSelectedOptions(questionsId);
        }
        if (action === AvailableActions.RANDOMIZE_PAGES) {
          const pages = [...pagesWithActions];
          pages.forEach((page, indexOfPage) => {
            if (subject.includes(page.id)) {
              pages[indexOfPage].visible = false;
            }
          });

          setRandomizedPages((prevPagesIds) => {
            const newPagesIds = [...prevPagesIds];

            subject.forEach((pageId) => {
              const pageIdIndex = newPagesIds.indexOf(pageId);

              if (pageIdIndex !== -1) {
                newPagesIds.splice(pageIdIndex, 1);
              }
            });

            return newPagesIds;
          });

          setPagesWithActions(pages);
          const questionsId = getQuestionsIdFromPage(subject);
          removeSelectedOptions(questionsId);
        }
        if (action === AvailableActions.RANDOMIZE_CHOICES) {
          const pages = [...pagesWithActions];
          pages.forEach((page, indexOfPage) => {
            page.sections.forEach((section, sectionIndex) => {
              section.items.forEach((item, itemIndex) => {
                if (
                  item.question?.id === subject ||
                  (!item.questionGroup && item.id === subject)
                ) {
                  item.question.choices.forEach((choice, indexChoice) => {
                    if (choices.includes(choice.id)) {
                      pages[indexOfPage].sections[sectionIndex].items[
                        itemIndex
                      ].question.choices[indexChoice].visible = false;
                    }
                  });
                } else if (item.questionGroup && item.id === subject) {
                  item.questionGroup.choices.forEach((choice, indexChoice) => {
                    if (choices.includes(choice.id)) {
                      pages[indexOfPage].sections[sectionIndex].items[
                        itemIndex
                      ].questionGroup.choices[indexChoice].visible = false;
                    }
                  });
                }
              });
            });
          });
          setPagesWithActions(pages);
          removeSelectedOptions([subject]);
        }
        if (action === AvailableActions.SHOW_QUESTION_GROUP) {
          const pages = [...pagesWithActions];
          pages.forEach((page, indexOfPage) => {
            page.sections.forEach((section, sectionIndex) => {
              section.items.forEach((item, itemIndex) => {
                if (item.id === subject) {
                  pages[indexOfPage].sections[sectionIndex].items[
                    itemIndex
                  ].visible = false;
                }
              });
            });
          });
          setPagesWithActions(pages);
          const questionsId = getQuestionsIdFromItem(subject);
          removeSelectedOptions(questionsId);
        }
        if (action === AvailableActions.HIDE_QUESTION_GROUP) {
          const pages = [...pagesWithActions];
          pages.forEach((page, indexOfPage) => {
            page.sections.forEach((section, sectionIndex) => {
              section.items.forEach((item, itemIndex) => {
                if (item.id === subject) {
                  pages[indexOfPage].sections[sectionIndex].items[
                    itemIndex
                  ].visible = true;
                }
              });
            });
          });
          setPagesWithActions(pages);
          const questionsId = getQuestionsIdFromItem(subject);
          removeSelectedOptions(questionsId);
        }
      }
    },
    [
      surveySections,
      randomizedPages,
      pagesWithActions,
      getQuestionsIdFromSection,
      getQuestionsIdFromItem,
      getQuestionsIdFromPage,
      removeSelectedOptions,
    ]
  );

  useEffect(() => {
    if (surveyActions && questionResponses && surveySections) {
      surveyActions.forEach(({ conditions, actions, applied }, indexAction) => {
        const separatedConditions = {};
        let index = 0;

        conditions.forEach((condition) => {
          if (condition.type === ActionBuilderConditionTypes.CONDITION) {
            let previousConditions = [];
            if (separatedConditions[index]) {
              previousConditions = [...separatedConditions[index]];
            }
            previousConditions.push(condition);
            separatedConditions[index] = previousConditions;
          } else if (
            RegularOperators[condition.operator] === RegularOperators.AND
          ) {
            index += 1;
          }
        });

        const meetsAndConditions = Object.values(separatedConditions).every(
          (orConditions) => meetsAllOrConditions(orConditions)
        );

        if (meetsAndConditions && !applied) {
          actions.forEach((action) => applyAction(action, randomizedPages));
          const newActions = [...surveyActions];
          newActions[indexAction].applied = true;
          setSurveyActions(newActions);
        } else if (!meetsAndConditions && applied) {
          actions.forEach((action) => removeAppliedAction(action));
          const newActions = [...surveyActions];
          newActions[indexAction].applied = false;
          setSurveyActions(newActions);
        }
      });
    }
  }, [
    randomizedPages,
    surveyActions,
    surveySections,
    questionResponses,
    meetsAllOrConditions,
    applyAction,
    removeAppliedAction,
  ]);

  const areRequiredChoiceQuestionsAnswered = (responses) => {
    return (
      responses &&
      Object.values(responses)
        .filter(
          (question) =>
            question.required &&
            question.visible &&
            (question.type === QuestionType.SINGLE_RESPONSE ||
              question.type === QuestionType.MULTIPLE_REPONSE ||
              question.type === QuestionType.DROPDOWN)
        )
        .every((requiredQuestion) => {
          const choicesValues = Object.values(requiredQuestion.choices);

          if (
            choicesValues.some(
              (choiceValue) => typeof choiceValue === 'boolean'
            )
          ) {
            return choicesValues.some((choiceValue) => choiceValue);
          }

          return choicesValues.every((choiceValue) =>
            Object.values(choiceValue).some((choice) => choice)
          );
        })
    );
  };

  const areRequiredRankOrderQuestionsAnswered = (responses) => {
    return (
      responses &&
      Object.values(responses)
        .filter(
          (question) =>
            question.required &&
            question.visible &&
            question.type === QuestionType.RANK_ORDER
        )
        .every((requiredQuestion) =>
          Object.values(requiredQuestion.choices).every((choice) => choice)
        )
    );
  };

  const areRequiredTextAreaQuestionsAnswered = (responses) => {
    return (
      responses &&
      Object.values(responses)
        .filter(
          (question) =>
            question.required &&
            question.visible &&
            question.type === QuestionType.TEXT_AREA
        )
        .every((requiredQuestion) => requiredQuestion.openAnswer !== '')
    );
  };

  const areRequiredSliderQuestionsAnswered = (responses) => {
    return (
      responses &&
      Object.values(responses)
        .filter(
          (question) =>
            question.required &&
            question.visible &&
            question.type === QuestionType.SLIDER
        )
        .every((requiredQuestion) =>
          Number.isInteger(requiredQuestion.sliderProps?.value)
        )
    );
  };

  // Returns an array with the id of the required questions
  // Each position of the array correspond to each page of the survey
  const getRequiredQuestionsPerPage = () => {
    const requiredQuestions = [];

    visiblePages.forEach((page, index) => {
      page.sections.forEach((section) => {
        if (section.visible) {
          const questions = section.items
            .filter(
              (item) =>
                item.type !== QuestionType.TEXT_BOX &&
                item.question?.required &&
                item.visible
            )
            .map((item) => item.id);

          const questionsInGroup = [];
          section.items
            .filter(
              (item) =>
                item.type !== QuestionType.TEXT_BOX &&
                item.questionGroup &&
                item.visible
            )
            .forEach(({ questionGroup }) =>
              questionGroup.questions.forEach((question) => {
                if (question.required && question.visible)
                  questionsInGroup.push(question.id);
              })
            );

          requiredQuestions[index] = [
            ...(requiredQuestions[index] ? requiredQuestions[index] : []),
            ...questions,
            ...questionsInGroup,
          ];
        }
      });
    });

    return requiredQuestions;
  };

  // Returns the number of pages that the survey will show in the pagination
  // depending on if the required questions of the current page are answered or not.
  const getAnsweredPages = () => {
    let pagesToDisplay = 1;
    const requiredQuestionsPerPage = getRequiredQuestionsPerPage();

    for (let index = 0; index < requiredQuestionsPerPage.length; index += 1) {
      const currentPageQuestions = requiredQuestionsPerPage[index];

      if (currentPageQuestions) {
        const currentPageResponses = {};

        currentPageQuestions.forEach((question) => {
          const questionResponse = { [question]: questionResponses[question] };
          Object.assign(currentPageResponses, questionResponse);
        });

        const areCurrentPageQuestionsAnswered =
          areRequiredChoiceQuestionsAnswered(currentPageResponses) &&
          areRequiredTextAreaQuestionsAnswered(currentPageResponses);

        if (!areCurrentPageQuestionsAnswered) {
          return pagesToDisplay;
        }

        if (areCurrentPageQuestionsAnswered) {
          if (pagesToDisplay < visiblePages.length) {
            pagesToDisplay += 1;
          }
        }
      }
    }

    return pagesToDisplay;
  };

  // Returns an array of the questions ids that are required
  // and are not answered in the current page of the survey
  const getNotAnsweredQuestionsInCurrentPage = () => {
    const requiredQuestionsPerPage = getRequiredQuestionsPerPage();
    const currentPageQuestions = requiredQuestionsPerPage[pageIndex] || [];
    const notAnsweredQuestions = [];

    currentPageQuestions.forEach((question) => {
      const questionResponse = { [question]: questionResponses[question] };
      let isAnswered = false;

      if (questionResponse) {
        const { type: questionType } = questionResponse[question];

        if (questionType === QuestionType.RANK_ORDER) {
          isAnswered = areRequiredRankOrderQuestionsAnswered(questionResponse);
        }

        if (questionType === QuestionType.TEXT_AREA) {
          isAnswered = areRequiredTextAreaQuestionsAnswered(questionResponse);
        }

        if (questionType === QuestionType.SLIDER) {
          isAnswered = areRequiredSliderQuestionsAnswered(questionResponse);
        }

        if (
          questionType === QuestionType.SINGLE_RESPONSE ||
          questionType === QuestionType.MULTIPLE_REPONSE ||
          questionType === QuestionType.DROPDOWN
        ) {
          isAnswered = areRequiredChoiceQuestionsAnswered(questionResponse);
        }
      }

      if (!isAnswered) {
        notAnsweredQuestions.push(question);
      }
    });

    return notAnsweredQuestions;
  };

  const disablePreviousPagesGoBackFrom = findLastIndexFromArray(
    visiblePages || [],
    (page, index) => page?.preventBacktracking && index < pageIndex
  );

  const disableNextPagesGoAheadOf = visiblePages.findIndex(
    (page, index) => page?.preventBacktracking && index > pageIndex
  );

  const onPreviousPageHandler = () => {
    if (pageIndex - 1 <= disablePreviousPagesGoBackFrom) {
      return null;
    }

    return setPageIndex((prevState) => prevState - 1);
  };

  const onNextPageHandler = () => {
    setValidateQuestions(true);

    if (getNotAnsweredQuestionsInCurrentPage().length === 0) {
      setValidateQuestions(false);

      if (visiblePages[pageIndex].preventBacktracking) {
        return confirmNextPageModalToggle();
      }

      return setPageIndex((prevState) => prevState + 1);
    }

    return null;
  };

  const onConfirmNextPage = () => {
    setPageIndex((prevState) => prevState + 1);

    confirmNextPageModalToggle();
  };

  const onGoToPageHandler = (index) => setPageIndex(index);

  const getQuestionsDemographics = () => {
    const demographics = [];

    if (questionResponses) {
      const questionsWithDemographicTags = surveyItems.filter(
        ({ type, question }) =>
          type === SurveyElement.QUESTION &&
          question.demographicsId &&
          Object.values(questionResponses[question.id].choices).some(
            (choice) => choice
          )
      );

      questionsWithDemographicTags.forEach(({ question }) => {
        const choices = [];

        question.demographicsId.forEach((demographicId) => {
          question.choices.forEach((choice) => {
            if (questionResponses[question.id].choices[choice.id]) {
              choices.push(choice.option.en);
            }
          });

          const demographic = {
            demographicId,
            name: choices,
          };

          demographics.push(demographic);
        });
      });
    }

    return demographics;
  };

  const getQuestionGroupsDemographics = () => {
    const questionGroupDemographics = [];

    if (questionResponses) {
      const questionGroupsWithDemographicTags = surveyItems.filter(
        ({ type, questionGroup }) =>
          type === SurveyElement.QUESTION_GROUP &&
          questionGroup.questions.filter(
            (question) =>
              question.demographicsId &&
              Object.values(questionResponses[question.id].choices).some(
                (choice) => choice
              )
          )
      );

      questionGroupsWithDemographicTags.forEach(({ questionGroup }) => {
        questionGroup.questions.forEach((question) => {
          const choices = [];

          if (question.demographicsId) {
            question.demographicsId.forEach((demographicId) => {
              questionGroup.choices.forEach((choice) => {
                if (questionResponses[question.id].choices[choice.id]) {
                  choices.push(choice.option.en);
                }
              });

              const demographic = {
                demographicId,
                name: choices,
              };

              questionGroupDemographics.push(demographic);
            });
          }
        });
      });
    }

    return questionGroupDemographics;
  };

  const areRequiredQuestionsAnswered =
    areRequiredChoiceQuestionsAnswered(questionResponses) &&
    areRequiredTextAreaQuestionsAnswered(questionResponses) &&
    areRequiredRankOrderQuestionsAnswered(questionResponses) &&
    areRequiredSliderQuestionsAnswered(questionResponses);

  const canSubmit =
    areRequiredQuestionsAnswered && visiblePages.length - 1 === pageIndex;

  const onSubmitSurveyHandler = (event, saveResponse) => {
    // eslint-disable-next-line no-unused-expressions
    event?.preventDefault();

    if (!saveResponse) {
      setValidateQuestions(true);
    }

    if (saveResponse || canSubmit) {
      const questionsDemographics = getQuestionsDemographics();
      const questionGroupDemographics = getQuestionGroupsDemographics();
      const demographics = mergeDemographics(
        questionsDemographics,
        questionGroupDemographics
      );
      const { organizationName } = fetchedSurvey;

      const shownResponses = {};

      Object.values(pagesWithActions).forEach((page) => {
        if (page.visible) {
          page.sections.forEach((section) => {
            if (section.visible) {
              section.items.forEach((item) => {
                if (item.visible && item.question) {
                  const questionId = item.question.id;
                  const visibleChoices = {};
                  const questionResponse = { ...questionResponses[questionId] };

                  item.question.choices.forEach((choice) => {
                    if (choice.visible) {
                      visibleChoices[choice.id] =
                        questionResponse.choices[choice.id] ?? false;
                    }
                  });

                  questionResponse.choices = visibleChoices;
                  shownResponses[questionId] = questionResponse;
                } else if (item.visible && item.questionGroup) {
                  item.questionGroup.questions.forEach((question) => {
                    const questionId = question.id;
                    const visibleChoices = {};
                    const questionResponse = {
                      ...questionResponses[questionId],
                    };

                    item.questionGroup.choices.forEach((choice) => {
                      if (choice.questionChoices) {
                        choice.questionChoices.forEach((questionChoice) => {
                          if (questionChoice.visible) {
                            if (!visibleChoices[questionChoice.dataSetId]) {
                              visibleChoices[questionChoice.dataSetId] = {};
                            }

                            visibleChoices[questionChoice.dataSetId][
                              questionChoice.id
                            ] =
                              questionResponse.choices[
                                questionChoice.dataSetId
                              ][questionChoice.id] ?? false;
                          }
                        });

                        return;
                      }

                      if (choice.visible) {
                        visibleChoices[choice.id] =
                          questionResponse.choices[choice.id] ?? false;
                      }
                    });

                    questionResponse.choices = visibleChoices;
                    shownResponses[questionId] = questionResponse;
                  });
                } else if (!item.visible && item.question) {
                  const questionId = item.question.id;
                  const questionResponse = { ...questionResponses[questionId] };

                  Object.keys(questionResponse.choices).forEach((choice) => {
                    questionResponse.choices[choice] = false;
                  });

                  shownResponses[questionId] = questionResponse;
                } else if (!item.visible && item.questionGroup) {
                  item.questionGroup.questions.forEach((question) => {
                    const questionId = question.id;
                    const questionResponse = {
                      ...questionResponses[questionId],
                    };

                    item.questionGroup.choices.forEach((choice) => {
                      if (choice.questionChoices) {
                        choice.questionChoices.forEach((questionChoice) => {
                          if (!questionResponse[questionChoice.dataSetId]) {
                            questionResponse[questionChoice.dataSetId] = {};
                          }

                          questionResponse.choices[questionChoice.dataSetId][
                            questionChoice.id
                          ] = false;
                        });

                        return;
                      }

                      questionResponse.choices[choice] = false;
                    });

                    shownResponses[questionId] = questionResponse;
                  });
                }
              });
            } else {
              section.items.forEach((item) => {
                if (item.question) {
                  const questionId = item.question.id;
                  const questionResponse = { ...questionResponses[questionId] };

                  Object.keys(questionResponse.choices).forEach((choice) => {
                    questionResponse.choices[choice] = false;
                  });

                  shownResponses[questionId] = questionResponse;
                } else if (item.questionGroup) {
                  item.questionGroup.questions.forEach((question) => {
                    const questionId = question.id;
                    const questionResponse = {
                      ...questionResponses[questionId],
                    };

                    item.questionGroup.choices.forEach((choice) => {
                      if (choice.questionChoices) {
                        choice.questionChoices.forEach((questionChoice) => {
                          if (!questionResponse[questionChoice.dataSetId]) {
                            questionResponse[questionChoice.dataSetId] = {};
                          }

                          questionResponse.choices[questionChoice.dataSetId][
                            questionChoice.id
                          ] = false;
                        });

                        return;
                      }

                      questionResponse.choices[choice] = false;
                    });

                    shownResponses[questionId] = questionResponse;
                  });
                }
              });
            }
          });
        } else {
          page.sections.forEach((section) => {
            section.items.forEach((item) => {
              if (item.question) {
                const questionId = item.question.id;
                const questionResponse = { ...questionResponses[questionId] };

                Object.keys(questionResponse.choices).forEach((choice) => {
                  questionResponse.choices[choice] = false;
                });
                shownResponses[questionId] = questionResponse;
              } else if (item.questionGroup) {
                item.questionGroup.questions.forEach((question) => {
                  const questionId = question.id;
                  const questionResponse = { ...questionResponses[questionId] };

                  item.questionGroup.choices.forEach((choice) => {
                    if (choice.questionChoices) {
                      choice.questionChoices.forEach((questionChoice) => {
                        if (!questionResponse[questionChoice.dataSetId]) {
                          questionResponse[questionChoice.dataSetId] = {};
                        }

                        questionResponse.choices[questionChoice.dataSetId][
                          questionChoice.id
                        ] = false;
                      });

                      return;
                    }

                    questionResponse.choices[choice] = false;
                  });

                  shownResponses[questionId] = questionResponse;
                });
              }
            });
          });
        }
      });

      if (isPreview) {
        setPreviewIsAnswered(true);

        return;
      }

      if (saveResponse) {
        dispatch(
          createSurveyResponseSave({
            ...response,
            randomizedPages,
            pagesWithActions,
            questionResponses,
            shownResponses,
            demographics,
            organizationName,
            pageIndex,
          })
        );

        return;
      }

      dispatch(
        createSurveyResponse({
          ...response,
          questionResponses: shownResponses,
          demographics,
          organizationName,
        })
      );
    }
  };

  const deploymentStatus = getDeploymentStatus(
    fetchedSurvey?.startDate,
    fetchedSurvey?.endDate,
    fetchedSurvey?.waveStatus
  );

  const isQuestionNotAnswered = (questionId) => {
    if (validateQuestions) {
      return getNotAnsweredQuestionsInCurrentPage().includes(questionId);
    }
    return false;
  };

  const isQuestionGroupNotAnswered = (questionGroup) => {
    if (validateQuestions) {
      const notAnswered = getNotAnsweredQuestionsInCurrentPage();
      const includes = questionGroup.questions.some((question) =>
        notAnswered.includes(question.id)
      );
      return includes;
    }
    return false;
  };

  const isChoiceInRequiredQuestion = (questionId) => {
    if (validateQuestions) {
      const notAnswered = getNotAnsweredQuestionsInCurrentPage();
      return notAnswered.includes(questionId);
    }
    return false;
  };

  const onChangeLanguageHandler = (select) => {
    const languageCode = select.value.toLowerCase().replace('-', '');

    dispatch(setUserLocale(languageCode));
    setSurveyLanguage((prevState) => ({
      ...prevState,
      code: select.value,
      select,
    }));
  };

  const onClearAnswerHandler = (questionId) => {
    setQuestionResponses((prevState) => {
      const question = prevState[questionId];
      const choices = {};

      Object.keys(question.choices).forEach((choiceId) => {
        choices[choiceId] = false;
      });

      return {
        ...prevState,
        [questionId]: {
          ...question,
          choices,
        },
      };
    });
  };

  const { getLastAnsweredPageIndex } = useLastAnsweredPageGetter({
    props: { visiblePages },
    helpers: {
      areRequiredChoiceQuestionsAnswered,
      areRequiredTextAreaQuestionsAnswered,
    },
  });

  useEffect(() => {
    if (success) {
      const {
        valid,
        waveId,
        surveyId,
        deploymentId,
        pageIndex: savedResponsePageIndex,
        questionResponses: savedQuestionResponses,
        randomizedPages: savedRandomizedPages,
      } = responseInProgress;

      setResponse({ valid, waveId, surveyId, deploymentId });
      setPageIndex(savedResponsePageIndex);
      setRandomizedPages(savedRandomizedPages || []);

      setQuestionResponses(savedQuestionResponses);

      onGoToPageHandler(
        savedResponsePageIndex ||
          getLastAnsweredPageIndex(savedQuestionResponses)
      );
      dispatch(clearSurveysResponseLoadState());
    }
  }, [success, responseInProgress]);

  const onSaveResponseHandler = () => {
    onSubmitSurveyHandler(null, true);
  };

  const onLoadResponseHandler = (responseCode) => {
    dispatch(fetchResponseInProgress({ responseCode }));
  };

  useEffect(() => () => dispatch(clearSurveysResponseSaveState()), [dispatch]);

  const shouldShowPages =
    ((!isPreview && !respondedSurvey) || (isPreview && !previewIsAnswered)) &&
    questionResponses &&
    deploymentStatus === SurveyDeploymentStatus.ACTIVE;

  return (
    <>
      <ConfirmNextPageModal
        isOpen={confirmNextPageModalIsOpen}
        toggle={confirmNextPageModalToggle}
        onSubmit={onConfirmNextPage}
      />
      <div className={classes.survey}>
        <PageLoader loading={fetchingSurvey} background="#0072bc" />
        {!fetchingSurvey && (
          <section className="section" ref={topOfThePage}>
            <form onSubmit={onSubmitSurveyHandler}>
              {shouldShowPages && (
                <Page
                  id="survey-page"
                  title={fetchedSurvey.title[surveyLanguage.code]}
                  subtitle={visiblePages[pageIndex]?.title[surveyLanguage.code]}
                  selectedLanguage={surveyLanguage.select}
                  availableLanguages={availableLanguages}
                  onChangeLanguage={onChangeLanguageHandler}
                  onSaveResponse={onSaveResponseHandler}
                  onLoadResponse={onLoadResponseHandler}
                  languageCompletion={fetchedSurvey?.languageCompletion}
                  defaultLanguage={fetchedSurvey?.defaultLanguage}
                >
                  {surveySections.map(
                    (section) =>
                      section.visible && (
                        <Section
                          id="survey-section"
                          key={section.id}
                          title={section?.title[surveyLanguage.code]}
                        >
                          {section?.items.map(
                            (item) =>
                              item.visible && (
                                <Item key={item.id}>
                                  {item.type === SurveyElement.TEXT_BOX && (
                                    <TextBox
                                      content={parse(
                                        item.richText?.[surveyLanguage.code] ||
                                          item.content[surveyLanguage.code]
                                      )}
                                    />
                                  )}
                                  {item.type === SurveyElement.QUESTION && (
                                    <Question
                                      id={item.id}
                                      type={item.question.type}
                                      choices={item.question.choices.map(
                                        (choice) => ({
                                          ...choice,
                                          option:
                                            choice.option[surveyLanguage.code],
                                        })
                                      )}
                                      content={parse(
                                        item.question.richText?.[
                                          surveyLanguage.code
                                        ] ||
                                          item.question.content[
                                            surveyLanguage.code
                                          ]
                                      )}
                                      onChangeInput={onChangeInputHandler}
                                      currentResponse={
                                        questionResponses[item.id]
                                      }
                                      singleLine={item.question.singleLine}
                                      required={item.question.required}
                                      error={isQuestionNotAnswered(item.id)}
                                      onClearAnswer={onClearAnswerHandler}
                                      disableDeselect={
                                        fetchedSurvey?.disableDeselect
                                      }
                                      sliderProps={item.question.sliderProps}
                                    />
                                  )}
                                  {item.type ===
                                    SurveyElement.QUESTION_GROUP && (
                                    <QuestionGroup
                                      onChangeInput={onChangeInputHandler}
                                      currentResponse={questionResponses}
                                      label={getTranslation(
                                        item.questionGroup.label
                                      )}
                                      questions={item.questionGroup.questions
                                        .filter((question) => question.visible)
                                        .map((question) => ({
                                          ...question,
                                          content: parse(
                                            question?.richText?.[
                                              surveyLanguage.code
                                            ] ||
                                              question.content[
                                                surveyLanguage.code
                                              ]
                                          ),
                                        }))}
                                      surveyLanguage={surveyLanguage}
                                      choices={item.questionGroup.choices}
                                      columnWidths={
                                        item.questionGroup.columnWidths
                                      }
                                      error={isQuestionGroupNotAnswered(
                                        item.questionGroup
                                      )}
                                      isChoiceInRequiredQuestion={
                                        isChoiceInRequiredQuestion
                                      }
                                    />
                                  )}
                                </Item>
                              )
                          )}
                          <hr />
                        </Section>
                      )
                  )}
                  <Pagination
                    loading={creatingResponse}
                    pageIndex={pageIndex}
                    actualPages={getAnsweredPages()}
                    onNextPage={onNextPageHandler}
                    onPreviousPage={onPreviousPageHandler}
                    onGoToPage={onGoToPageHandler}
                    disablePreviousPagesGoBackFrom={
                      disablePreviousPagesGoBackFrom
                    }
                    disableNextPagesGoAheadOf={disableNextPagesGoAheadOf}
                    totalPages={visiblePages.length}
                  />
                </Page>
              )}
              {(respondedSurvey || (isPreview && previewIsAnswered)) && (
                <Page
                  id="thank-you-message"
                  title={fetchedSurvey.title[surveyLanguage.code]}
                  subtitle="Thank you!"
                >
                  <Section>
                    <p className="content is-medium">
                      Thank you for taking the time to fill out the survey. Your
                      time and attention to detail is greatly appreciated.
                    </p>
                  </Section>
                  <Section title="Comments">
                    <p className="content is-medium">
                      We&apos;d love to hear from you. Let us know how you found
                      the survey process or how you think we can improve it. If
                      you&apos;d like to get in touch with us, please email us
                      at{' '}
                      <a href="mailto: abc@example.com">
                        comments@presagegroup.com
                      </a>
                      . Please note that all comments are strictly anonymous and
                      confidential and are not included in the analysis of the
                      survey data.
                    </p>
                  </Section>
                </Page>
              )}
              {errorFetching && (
                <Page id="not-found-survey-message" title="Survey not found">
                  <p className="content is-medium">
                    The survey you are trying to access does not exist. If you
                    think you are receiving this message in error, please
                    contact your manager or system administrator.
                  </p>
                </Page>
              )}
              {deploymentStatus === SurveyDeploymentStatus.PENDING && (
                <Page
                  id="survey-not-available-message"
                  title="Survey not available"
                >
                  <p className="content is-medium">
                    The survey you are trying to access is not yet available to
                    be responded. If you think you are receiving this message in
                    error, please contact your manager or system administrator.
                  </p>
                </Page>
              )}
              {deploymentStatus === SurveyDeploymentStatus.CLOSED && (
                <Page id="survey-closed-message" title="Survey closed">
                  <p className="content is-medium">
                    The survey you are trying to access is closed. If you think
                    you are receiving this message in error, please contact your
                    manager or system administrator.
                  </p>
                </Page>
              )}
              {deploymentStatus === SurveyDeploymentStatus.INACTIVE && (
                <Page id="survey-closed-message" title="Survey inactive">
                  <p className="content is-medium">
                    The survey you are trying to access is inactive. If you
                    think you are receiving this message in error, please
                    contact your manager or system administrator.
                  </p>
                </Page>
              )}
            </form>
            <Footer />
          </section>
        )}
      </div>
    </>
  );
};

export default LiveSurvey;
