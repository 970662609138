/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-restricted-globals */
import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';

import { selectTagFormDataState } from 'state/selectors/tags';
import { LanguageName } from 'utils/enums';
import classes from './ConstructTagScores.module.scss';
import ScoreItem from './ScoreItem';

const availableTranslationsOptions = Object.keys(LanguageName).map(
  (languageCode) => {
    return {
      label: LanguageName[languageCode],
      value: languageCode,
    };
  }
);

const ConstructTagScores = ({
  tag,
  ranges,
  setRanges,
  onAddRange,
  onDeleteRange,
  onSubmit,
  goBackPath,
  canSubmit,
  isEditing,
  language,
  onChangeLanguage,
}) => {
  const { loading } = useSelector(selectTagFormDataState, shallowEqual);

  const [range, setRange] = useState('');
  const [empty, setEmpty] = useState(false);

  const onChangeRangeHandler = useCallback((newRange) => {
    setRange(newRange);
    setEmpty(false);
  }, []);

  const onAddRangeHanlder = useCallback(() => {
    if (range !== '' && !isNaN(range)) {
      onAddRange(range);
      setRange('');
    } else {
      setEmpty(true);
    }
  }, [onAddRange, range]);

  const onChangeSelectedTranslationHandler = useCallback(
    (selectedLanguage) => {
      onChangeLanguage(selectedLanguage);
    },
    [onChangeLanguage]
  );

  return (
    <div className="card">
      <header className="card-header">
        <p className="card-header-title">
          <span className="icon">
            <i className="mdi mdi-tag" />
          </span>
          Scores
        </p>
        <Select
          defaultValue={language}
          className={classes.select}
          options={availableTranslationsOptions}
          onChange={onChangeSelectedTranslationHandler}
        />
      </header>
      <div className="card-content">
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="field"> Add new range: </label>
          </div>
          <div className="field-body">
            <div className={classNames('field', classes.input)}>
              <div className="control">
                <input
                  id="range"
                  className={classNames('input', { 'is-danger': empty })}
                  type="tel"
                  required
                  name="range"
                  onChange={(e) => onChangeRangeHandler(e.target.value)}
                  value={range}
                />
              </div>
              {empty && (
                <p className="help">
                  Please enter a valid number between 0 and 100
                </p>
              )}
            </div>
            <button
              id="add-range"
              className={classNames('button', 'is-primary')}
              onClick={(event) => onAddRangeHanlder(event)}
            >
              Add
            </button>
          </div>
        </div>
        <ScoreItem
          tag={tag}
          ranges={ranges}
          setRanges={setRanges}
          onDeleteRange={onDeleteRange}
          language={language}
        />
      </div>
      <footer className={classNames('card-footer', classes.cardFooter)}>
        <div className={classNames('buttons', classes.buttons)}>
          <button
            id="create-construct-tag"
            className={classNames('button', 'is-primary', {
              'is-loading': loading,
            })}
            disabled={loading || !canSubmit}
            onClick={onSubmit}
          >
            {isEditing ? 'Update' : 'Create'}
          </button>
          <Link to={goBackPath}>
            <button id="go-back" className="button" disabled={loading}>
              Go Back
            </button>
          </Link>
        </div>
      </footer>
    </div>
  );
};

ConstructTagScores.propTypes = {
  tag: PropTypes.shape({
    active: PropTypes.bool,
    global: PropTypes.bool,
    type: PropTypes.string,
    organization: PropTypes.object || null,
    name: PropTypes.string,
    loading: PropTypes.bool,
    fetched: PropTypes.bool,
    error: PropTypes.string || null,
    scores: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  }),
  ranges: PropTypes.shape({
    values: PropTypes.arrayOf(PropTypes.number),
    update: PropTypes.arrayOf(PropTypes.number),
    descriptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  }),
  setRanges: PropTypes.func.isRequired,
  onAddRange: PropTypes.func.isRequired,
  onDeleteRange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  goBackPath: PropTypes.string.isRequired,
  canSubmit: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  language: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  onChangeLanguage: PropTypes.func.isRequired,
};

export default ConstructTagScores;
