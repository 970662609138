/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { DragDropContext } from 'react-beautiful-dnd';

import { getAvailableLanguages } from 'utils/surveyBuilder';
import { SurveyElement, SurveyType } from 'utils/surveyBuilder/enums';

import QuestionForm from './QuestionForm';
import Choices from './Choices';
import Questions from './Questions';
import ColumnsWidths from './ColumnsWidths';

const QuestionGroupBuilder = ({
  surveyType,
  organizationName,
  surveyLanguage,
  selectedLanguage,
  availableLanguages,
  onCreate,
  onUpdate,
  onCancel,
  onCheckChoicesActions,
  previousLabel,
  previousDataSet,
  previousQuestions,
  previousChoices,
  previousColumnWidths,
  deploymentId,
  canDeleteQuestion,
}) => {
  const isUpdating = useMemo(() => !!previousLabel, [previousLabel]);

  const [label, setLabel] = useState(
    previousLabel || getAvailableLanguages(availableLanguages)
  );
  const [questions, setQuestions] = useState(previousQuestions || []);
  const [dataSet, setDataSet] = useState(previousDataSet || null);
  const [choices, setChoices] = useState(previousChoices || []);
  const [columnWidths, setColumnWidths] = useState(
    previousColumnWidths?.[0] > 0 && previousColumnWidths?.[0] < 10
      ? previousColumnWidths
      : { 0: 1 }
  );
  const [editedQuestion, setEditedQuestion] = useState(null);

  const onCreateQuestionHandler = useCallback(
    (question) => {
      const isEditingQuestion = questions.find(({ id }) => question.id === id);

      if (isEditingQuestion) {
        const editedQuestions = questions.map(
          (existingQuestion) =>
            (existingQuestion.id === question.id && question) ||
            existingQuestion
        );

        return setQuestions(() => editedQuestions);
      }

      return setQuestions((prevState) => [...prevState, question]);
    },
    [questions]
  );

  const onChangeQuestionHandler = useCallback(
    ({ questionId, content: plainText, richText }) => {
      setQuestions((prevState) => {
        const comparedRichText = richText || `<p>${plainText}</p>`;

        const updatedQuestions = prevState.map((question, index) => {
          if (index === Number(questionId)) {
            return {
              ...question,
              content: {
                ...question.content,
                [surveyLanguage]: plainText,
              },
              richText: {
                ...question.richText,
                [surveyLanguage]: comparedRichText,
              },
            };
          }
          return question;
        });
        return updatedQuestions;
      });
    },
    [surveyLanguage]
  );

  const onChangeRequiredHandler = useCallback((questionId) => {
    setQuestions((prevState) => {
      const updatedQuestions = prevState.map((question, index) => {
        if (index === Number(questionId)) {
          return {
            ...question,
            required: !question.required,
          };
        }
        return question;
      });
      return updatedQuestions;
    });
  }, []);

  const onChangeColumnsWidths = useCallback(({ column, width }) => {
    setColumnWidths((prevState) => ({
      ...prevState,
      [column]: width,
    }));
  }, []);

  const onChangeQuestionVisibilityHandler = useCallback((questionIndex) => {
    setQuestions((prevState) => {
      const updatedQuestions = prevState.map((question, index) => {
        if (index === questionIndex) {
          return {
            ...question,
            visible: !question.visible,
          };
        }
        return question;
      });
      return updatedQuestions;
    });
  }, []);

  const onDeleteQuestionHandler = useCallback(
    (questionIndex) => {
      const { id: questionId } = questions[questionIndex];

      if (canDeleteQuestion(questionId)) {
        setQuestions((prevState) =>
          prevState.filter((_, index) => index !== Number(questionIndex))
        );
      } else {
        toastr.error('', 'Remove actions attached to this question first');
      }
    },
    [canDeleteQuestion, questions]
  );

  const onDeleteTagHandler = useCallback(({ tagId, questionId }) => {
    setQuestions((prevState) =>
      prevState.map((question, index) => {
        if (index === Number(questionId)) {
          const { tags } = question;
          return { ...question, tags: tags.filter(({ id }) => id !== tagId) };
        }
        return question;
      })
    );
  }, []);

  const onChangeTextBoxHandler = useCallback(
    (event) => {
      const { value } = event.target;
      setLabel((prevState) => ({ ...prevState, [surveyLanguage]: value }));
    },
    [surveyLanguage]
  );

  const onChangeChoicesHandler = useCallback((questionChoices) => {
    setChoices([...questionChoices]);
  }, []);

  const onAddChoicesHandler = useCallback(
    (newChoiceGroup) => {
      setChoices((prevState) => [
        ...prevState.filter((choice) => choice.questionChoices?.length),
        newChoiceGroup,
      ]);
    },
    [setChoices]
  );

  const onChangeChoiceVisibilityHandler = useCallback(
    ({ parentIndex, choiceIndex }) => {
      setChoices((prevState) => {
        const updatedChoices = prevState.map((choice, index) => {
          if (typeof parentIndex === 'number') {
            if (index === parentIndex) {
              const newQuestionChoices = choice.questionChoices.map(
                (questionChoice, questionChoiceIndex) => {
                  if (choiceIndex === questionChoiceIndex) {
                    return {
                      ...questionChoice,
                      visible: !questionChoice.visible,
                    };
                  }

                  return questionChoice;
                }
              );

              return {
                ...choice,
                questionChoices: newQuestionChoices,
              };
            }

            return choice;
          }

          if (index === choiceIndex) {
            return {
              ...choice,
              visible: !choice.visible,
            };
          }

          return choice;
        });

        return updatedChoices;
      });
    },
    []
  );

  const onChangeChoiceNotApplicableHandler = useCallback(
    ({ parentIndex, choiceIndex }) => {
      setChoices((prevState) => {
        const updatedChoices = prevState.map((choice, index) => {
          if (typeof parentIndex === 'number') {
            if (index === parentIndex) {
              const newQuestionChoices = choice.questionChoices.map(
                (questionChoice, questionChoiceIndex) => {
                  if (choiceIndex === questionChoiceIndex) {
                    return {
                      ...questionChoice,
                      notApplicable: !questionChoice.notApplicable,
                    };
                  }

                  return questionChoice;
                }
              );

              return {
                ...choice,
                questionChoices: newQuestionChoices,
              };
            }

            return choice;
          }

          if (index === choiceIndex) {
            return {
              ...choice,
              notApplicable: !choice.notApplicable,
            };
          }

          return choice;
        });

        return updatedChoices;
      });
    },
    []
  );

  const onChangeDataSetHandler = useCallback((newDataSet) => {
    setDataSet(newDataSet);
  }, []);

  const onSubmitHandler = useCallback(
    (event) => {
      event.preventDefault();

      const questionGroup = {
        label,
        questions,
        dataSet,
        choices,
        columnWidths,
      };

      if (isUpdating) {
        onUpdate(questionGroup);
      } else {
        onCreate(questionGroup);
      }
    },
    [
      choices,
      dataSet,
      columnWidths,
      onCreate,
      questions,
      label,
      isUpdating,
      onUpdate,
    ]
  );

  const canCreateQuestionGroup = useMemo(
    () =>
      dataSet &&
      choices.length !== 0 &&
      questions.length !== 0 &&
      questions.every(({ content }) => content[surveyLanguage].trim()) &&
      !editedQuestion,
    [dataSet, choices.length, questions, surveyLanguage, editedQuestion]
  );

  const onEditQuestionHandler = useCallback(
    (questionId) => {
      const questionToEdit = questions.find(({ id }) => questionId === id);

      setEditedQuestion(questionToEdit);
    },
    [questions]
  );

  const onDragEndHandler = useCallback(({ source, destination, type }) => {
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (type === SurveyElement.QUESTION_GROUP) {
      setQuestions((prevState) => {
        const questionsWithoutSource = prevState.toSpliced(source.index, 1);

        questionsWithoutSource.splice(
          destination.index,
          0,
          prevState[source.index]
        );

        return questionsWithoutSource;
      });
    }
  }, []);

  return (
    <>
      <form id="create-question-group-form" onSubmit={onSubmitHandler} />
      <div className="modal is-active question-modal">
        <div id="background" className="modal-background" />
        <div className="modal-content">
          <header className="modal-card-head">
            <p className="modal-card-title">Question Group</p>
            <button
              id="secondary-cancel-button"
              type="button"
              className="delete"
              aria-label="close"
              onClick={onCancel}
            />
          </header>
          <section className="modal-card-body">
            <div className="field">
              <label className="label">Label</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder="Question Group Label"
                  value={label[surveyLanguage]}
                  onChange={onChangeTextBoxHandler}
                />
              </div>
            </div>
            <hr />
            <h2 className="subtitle">Questions</h2>
            <QuestionForm
              surveyType={surveyType}
              organizationName={organizationName}
              onCreate={onCreateQuestionHandler}
              surveyLanguage={surveyLanguage}
              availableLanguages={availableLanguages}
              deploymentId={deploymentId}
              editedQuestion={editedQuestion}
              setEditedQuestion={setEditedQuestion}
            />
            <hr />
            <DragDropContext onDragEnd={onDragEndHandler}>
              <Questions
                questions={questions}
                surveyLanguage={surveyLanguage}
                availableLanguages={availableLanguages}
                onChangeQuestion={onChangeQuestionHandler}
                onDeleteQuestion={onDeleteQuestionHandler}
                onDeleteTag={onDeleteTagHandler}
                onChangeRequired={onChangeRequiredHandler}
                onChangeVisibility={onChangeQuestionVisibilityHandler}
                deploymentId={deploymentId}
                onEditQuestion={onEditQuestionHandler}
                editedQuestion={editedQuestion}
              />
            </DragDropContext>
            <hr />
            <ColumnsWidths
              widths={columnWidths}
              onChange={onChangeColumnsWidths}
            />
            <hr />
            <Choices
              choices={choices}
              organizationName={organizationName}
              surveyType={surveyType}
              onChangeChoices={onChangeChoicesHandler}
              onAddChoices={onAddChoicesHandler}
              onChangeDataSet={onChangeDataSetHandler}
              selectedDataSet={dataSet}
              selectedLanguage={selectedLanguage}
              onChangeChoiceVisibility={onChangeChoiceVisibilityHandler}
              onChangeNotApplicable={onChangeChoiceNotApplicableHandler}
              onCheckChoicesActions={onCheckChoicesActions}
              deploymentId={deploymentId}
            />
          </section>
          <footer className="modal-card-foot">
            <button
              id="submit-button"
              form="create-question-group-form"
              type="submit"
              className="button is-success"
              disabled={!canCreateQuestionGroup}
            >
              {isUpdating ? 'Update' : 'Add'}
            </button>
            <button
              id="primary-cancel-button"
              form="create-question-group-form"
              type="button"
              className="button"
              onClick={onCancel}
            >
              Cancel
            </button>
          </footer>
        </div>
      </div>
    </>
  );
};

QuestionGroupBuilder.propTypes = {
  surveyType: PropTypes.oneOf([SurveyType.ORGANIZATION, SurveyType.TEMPLATE])
    .isRequired,
  availableLanguages: PropTypes.arrayOf(PropTypes.string).isRequired,
  surveyLanguage: PropTypes.string.isRequired,
  selectedLanguage: PropTypes.string.isRequired,
  onCreate: PropTypes.func.isRequired,
  organizationName: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onUpdate: PropTypes.func,
  previousLabel: PropTypes.shape({
    en: PropTypes.string,
  }),
  previousDataSet: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    organization: PropTypes.string,
  }),
  previousQuestions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  previousChoices: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

export default QuestionGroupBuilder;
