/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import Select from 'react-select';
import classNames from 'classnames';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';

import { fetchOrganizations } from 'state/actions/organizations';

import classes from './SurveyFromTemplateModal.module.scss';

const SurveyFromTemplateModal = ({
  surveyName,
  versionName,
  creatingSurvey,
  onCreateSurveyHandler,
  onCancelHandler,
}) => {
  const dispatch = useDispatch();

  const { waves, loading } = useSelector(
    (state) => ({
      waves: state.organizations.data.map(
        ({ id, displayName, displaySiteName }) => ({
          label: displayName,
          value: {
            organizationId: id,
            organizationName: displayName,
            organizationDisplaySiteName: displaySiteName,
          },
        })
      ),
      loading: state.organizations.loading,
    }),
    shallowEqual
  );

  const [survey, setSurvey] = useState({
    organizationName: null,
    organizationId: null,
    organizationSelect: null,
    surveyName,
    versionName,
  });

  useEffect(() => {
    const filterInactives = true;
    dispatch(fetchOrganizations(filterInactives));
  }, [dispatch]);

  const onSelectOrganizationHandler = useCallback(
    (select) =>
      setSurvey((prevState) => ({
        ...prevState,
        organizationSelect: select,
        organizationName: select.value.organizationName,
        organizationId: select.value.organizationId,
        organizationDisplaySiteName: select.value.organizationDisplaySiteName,
      })),
    []
  );

  const onChangeVersionName = useCallback(
    (name) => setSurvey((prevState) => ({ ...prevState, versionName: name })),
    []
  );

  const onChangeSurveyName = useCallback(
    (name) => setSurvey((prevState) => ({ ...prevState, surveyName: name })),
    []
  );

  const onSubmitHandler = useCallback(
    (event) => {
      event.preventDefault();

      onCreateSurveyHandler(survey);
    },
    [onCreateSurveyHandler, survey]
  );

  const canSubmit = useMemo(
    () =>
      !!survey.organizationId &&
      !!survey.organizationName &&
      !!survey.surveyName &&
      !!survey.versionName,
    [
      survey.organizationId,
      survey.organizationName,
      survey.surveyName,
      survey.versionName,
    ]
  );

  return (
    <div className="modal is-active">
      <div
        id="background"
        className="modal-background"
        onClick={!creatingSurvey ? onCancelHandler : undefined}
      />
      <div className="modal-content">
        <form id="submit" onSubmit={onSubmitHandler}>
          <header className="modal-card-head">
            <p className="modal-card-title">Create survey from template</p>
            <button
              id="secondary-cancel-button"
              type="button"
              className="delete"
              aria-label="close"
              disabled={creatingSurvey}
              onClick={onCancelHandler}
            />
          </header>
          <section
            className={classNames('modal-card-body', classes['modal-body'])}
          >
            <div className="field">
              <label className="label">Survey Name</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  value={survey.surveyName}
                  onChange={(e) => onChangeSurveyName(e.target.value)}
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Version Name</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  value={survey.versionName}
                  onChange={(e) => onChangeVersionName(e.target.value)}
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Organization</label>
              <div className="control">
                <Select
                  value={survey.organizationSelect}
                  isLoading={loading}
                  options={waves}
                  onChange={onSelectOrganizationHandler}
                  maxMenuHeight={55}
                />
              </div>
            </div>
          </section>
          <footer className="modal-card-foot">
            <button
              id="submit-button"
              type="submit"
              className={classNames('button is-success', {
                'is-loading': creatingSurvey,
              })}
              disabled={!canSubmit}
            >
              Create Survey
            </button>
            <button
              id="primary-cancel-button"
              type="button"
              className="button is-light"
              disabled={creatingSurvey}
              onClick={onCancelHandler}
            >
              Cancel
            </button>
          </footer>
        </form>
      </div>
    </div>
  );
};

SurveyFromTemplateModal.propTypes = {
  surveyName: PropTypes.string.isRequired,
  versionName: PropTypes.string.isRequired,
  creatingSurvey: PropTypes.bool.isRequired,
  onCreateSurveyHandler: PropTypes.func.isRequired,
  onCancelHandler: PropTypes.func.isRequired,
};

export default SurveyFromTemplateModal;
