/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { LanguageName } from 'utils/enums';

import classes from './AddTranslation.module.scss';

const AddTranslation = ({ availableLanguages, onAddTranslation }) => {
  const [{ firstClick, selectedTranslation }, setState] = useState({
    firstClick: true,
    selectedTranslation: null,
  });

  const onFirstClickHandler = () =>
    setState((prevState) => ({ ...prevState, firstClick: false }));

  if (firstClick) {
    return (
      <div className="field">
        <div className="control">
          <button
            id="add-translation-first-button"
            className="button"
            onClick={onFirstClickHandler}
          >
            <span className="icon">
              <i className="mdi mdi-plus-circle-outline" />
            </span>
            <span>Add Translation</span>
          </button>
        </div>
      </div>
    );
  }

  const availableTranslationsOptions = availableLanguages.map(
    (languageCode) => ({
      label: LanguageName[languageCode],
      value: languageCode,
    })
  );

  const onChangeSelectedTranslationHandler = ({ value: translation }) =>
    setState((prevState) => ({
      ...prevState,
      selectedTranslation: translation,
    }));

  const onAddTranslationHandler = () => {
    if (selectedTranslation) {
      onAddTranslation(selectedTranslation);
      setState((prevState) => ({
        ...prevState,
        firstClick: true,
        selectedTranslation: null,
      }));
    }
  };

  const onClickCancelHandler = () =>
    setState((prevState) => ({ ...prevState, firstClick: true }));

  return (
    <form>
      <div className="field">
        <label className="label">Add Translation</label>
        <div
          className={classNames('control', classes['available-translations'])}
        >
          <Select
            id="available-languages-dropdown"
            maxMenuHeight={100}
            options={availableTranslationsOptions}
            onChange={onChangeSelectedTranslationHandler}
          />
        </div>
      </div>
      <div className="field is-grouped">
        <div className="control">
          <button
            id="add-translation-second-button"
            className="button is-primary"
            type="button"
            onClick={onAddTranslationHandler}
          >
            Add
          </button>
        </div>
        <div className="control">
          <button
            id="add-translation-cancel-button"
            className="button is-light"
            type="button"
            onClick={onClickCancelHandler}
          >
            Cancel
          </button>
        </div>
      </div>
    </form>
  );
};

AddTranslation.propTypes = {
  availableLanguages: PropTypes.arrayOf(PropTypes.string).isRequired,
  onAddTranslation: PropTypes.func.isRequired,
};

export default AddTranslation;
