/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';

const DropArea = ({ dropId, type, children }) => (
  <Droppable droppableId={dropId} type={type}>
    {(provided) => (
      <div
        id="children-placeholder"
        ref={provided.innerRef}
        {...provided.droppableProps}
      >
        {children}
        {provided.placeholder}
      </div>
    )}
  </Droppable>
);

DropArea.propTypes = {
  dropId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default DropArea;
