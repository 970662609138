import React, { useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { InstantSearch } from 'react-instantsearch-dom';
import { Link, Redirect } from 'react-router-dom';
import classNames from 'classnames';

import {
  REACT_APP_ALGOLIA_DATA_SETS_CREATED_AT_ASC_INDEX,
  REACT_APP_ALGOLIA_DATA_SETS_CREATED_AT_DESC_INDEX,
  REACT_APP_ALGOLIA_DATA_SETS_INDEX,
  REACT_APP_ALGOLIA_DATA_SETS_TAG_DISPLAY_NAME_ASC_INDEX,
  REACT_APP_ALGOLIA_DATA_SETS_TAG_DISPLAY_NAME_DESC_INDEX,
} from 'constants/environment';
import { useFormatDate, useAlgoliaSearch } from 'hooks';
import { roles, initAlgoliaClient } from 'utils';
import {
  SearchBox,
  Table,
  SortBy,
  Menu,
  ToggleRefinement,
  Filter,
  FilterOption,
} from 'components/Algolia';
import Modal from 'components/Modal';
import { deleteDataSet, clearData } from 'state/actions/dataSets';
import paths from 'pages/Router/paths';
import classes from './DataSets.module.scss';

const searchClient = initAlgoliaClient();

const index = REACT_APP_ALGOLIA_DATA_SETS_INDEX;
const indexTagDisplayNameAsc =
  REACT_APP_ALGOLIA_DATA_SETS_TAG_DISPLAY_NAME_ASC_INDEX;
const indexTagDisplayNameDesc =
  REACT_APP_ALGOLIA_DATA_SETS_TAG_DISPLAY_NAME_DESC_INDEX;
const indexCreatedAtAsc = REACT_APP_ALGOLIA_DATA_SETS_CREATED_AT_ASC_INDEX;
const indexCreatedAtDesc = REACT_APP_ALGOLIA_DATA_SETS_CREATED_AT_DESC_INDEX;

const DataSets = () => {
  const { deleting, deleted, isAdmin, defaultOrganization, error } =
    useSelector(
      (state) => ({
        error: state.dataSets.error,
        deleting: state.dataSets.deleting,
        deleted: state.dataSets.deleted,
        isAdmin: state.auth.userData.role === roles.ADMIN,
        defaultOrganization: state.preferences.defaultOrganization,
      }),
      shallowEqual
    );

  const dispatch = useDispatch();

  const { search, refresh } = useAlgoliaSearch();

  const [deleteModal, setDeleteModal] = React.useState({
    open: false,
    dataSetId: null,
  });

  useEffect(() => {
    if (deleted) {
      refresh();
      setDeleteModal((prevState) => ({
        dataSetId: null,
        open: !prevState.open,
      }));
      dispatch(clearData());
    }
  }, [deleted, dispatch, refresh]);

  useEffect(() => {
    if (error) {
      setDeleteModal((prevState) => ({
        tagId: null,
        open: !prevState.open,
      }));
      dispatch(clearData());
    }
  }, [error, dispatch]);

  const onDeleteTagHandler = useCallback(() => {
    dispatch(deleteDataSet(deleteModal.dataSetId));
  }, [dispatch, deleteModal.dataSetId]);

  const onRemoveButtonClickHandler = useCallback((dataSetId) => {
    setDeleteModal((prevState) => ({
      dataSetId,
      open: !prevState.isOpen,
    }));
  }, []);

  const onCloseModalHandler = useCallback(() => {
    setDeleteModal({ dataSetId: null, open: false });
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Tag',
        accessor: 'tagDisplayName',
        Cell: ({ row }) => (
          <>
            <Link to={`${paths.DATA_SETS}/${row.original.objectID}`}>
              {row.original.tagDisplayName}
            </Link>
          </>
        ),
      },
      {
        Header: 'Organization',
        accessor: 'tagOrganizationName',
        Cell: ({ row }) => (
          <>
            {row.original.tagOrganizationName === 'global'
              ? 'Global'
              : row.original.tagOrganizationName}
          </>
        ),
      },
      {
        Header: 'Start wave',
        accessor: 'startWave',
        Cell: ({ row }) => (
          <>{row.original.startWave ? row.original.startWave : 'None'}</>
        ),
      },
      {
        Header: 'No. of items',
        accessor: 'numberOfItems',
      },
      {
        Header: 'Active',
        accessor: 'active',
        Cell: ({ row }) => (
          <small className="has-text-grey is-abbr-like">
            {row.original.active ? (
              <span className="icon">
                <i className="mdi mdi-check" />
              </span>
            ) : (
              <span className="icon">
                <i className="mdi mdi-close" />
              </span>
            )}
          </small>
        ),
      },
      {
        Header: 'Created at',
        accessor: 'createdAt',
        Cell: ({ row }) => (
          <small className="has-text-grey is-abbr-like">
            {useFormatDate(row.original.createdAt, {
              weekday: 'short',
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
          </small>
        ),
      },
      {
        Header: 'Created by',
        accessor: 'createdBy',
        Cell: ({ row }) => (
          <small className="has-text-grey is-abbr-like">
            {row.original.createdBy}
          </small>
        ),
      },
      {
        Header: '',
        accessor: 'actions',
        Cell: ({ row }) => (
          <div className={classNames('buttons is-right', classes.actions)}>
            <Link
              to={`${paths.DATA_SETS}/${row.original.objectID}`}
              className="button is-small is-primary"
            >
              <span className="icon is-small">
                <i className="mdi mdi-account-edit" />
              </span>
            </Link>
            <button
              type="button"
              className="button is-small is-danger"
              onClick={() => onRemoveButtonClickHandler(row.original.objectID)}
            >
              <span className="icon is-small">
                <i className="mdi mdi-trash-can" />
              </span>
            </button>
          </div>
        ),
      },
    ],
    [onRemoveButtonClickHandler]
  );

  const redirect = useMemo(
    () => !isAdmin && <Redirect to={paths.ROOT} />,
    [isAdmin]
  );

  return (
    <>
      {redirect}
      {deleteModal.open && (
        <Modal
          isActive={deleteModal.open}
          isLoading={deleting}
          confirmButtonMessage="Delete"
          title="Confirm action"
          body="This will permanently delete the data set. Action can not be undone."
          cancelButtonMessage="Cancel"
          onConfirmation={onDeleteTagHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      <InstantSearch
        searchClient={searchClient}
        indexName={index}
        refresh={search.refresh}
      >
        <section className="hero is-hero-bar">
          <div className="hero-body">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <h1 className="title">Data Sets</h1>
                </div>
              </div>
              <div className="level-right">
                <Link to={paths.ADD_DATA_SET} className="button">
                  New Data Set
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section className="section is-main-section">
          <Filter>
            <FilterOption label="Organization">
              <Menu
                predefinedOption={defaultOrganization}
                predefinedRefinement={defaultOrganization?.value.displayName}
                attribute="tagOrganizationName"
                emptyPlaceholder="No organizations"
                defaultPlaceholder="Select an organization"
                isSearchable
                isClearable
                limit={20}
              />
            </FilterOption>
            <FilterOption label="Global" check>
              <ToggleRefinement
                attribute="tagOrganizationName"
                value="global"
                label="Global"
              />
            </FilterOption>
            <FilterOption label="Active" check>
              <ToggleRefinement
                attribute="active"
                value
                defaultRefinement
                label="Active"
              />
            </FilterOption>
            <FilterOption label="Sort by">
              <SortBy
                defaultRefinement={indexCreatedAtDesc}
                items={[
                  { value: index, label: 'Featured' },
                  { value: indexTagDisplayNameAsc, label: 'Tag name asc.' },
                  { value: indexTagDisplayNameDesc, label: 'Tag name desc.' },
                  { value: indexCreatedAtAsc, label: 'Date asc.' },
                  { value: indexCreatedAtDesc, label: 'Date desc.' },
                ]}
                defaultValue={4}
              />
            </FilterOption>
          </Filter>
          <div className="card has-table has-mobile-sort-spaced">
            <header className="card-header">
              <p className="card-header-title table-header">
                <span>Search</span>
                <SearchBox />
              </p>
            </header>
            <Table columns={columns} defaultPerPageOption={10} />
          </div>
        </section>
      </InstantSearch>
    </>
  );
};

export default DataSets;
