import firebase from 'firebase.js';
import { firebaseError } from 'utils';
import { Collections } from 'utils/enums';
import { collection } from 'utils/firebase';

export const createQuestionChoices = async ({ questionChoices, locale }) => {
  const choicesRollbackBatch = firebase.firestore().batch();

  try {
    const createChoicesBatch = firebase.firestore().batch();

    questionChoices.forEach(({ id, choice }) => {
      const questionChoiceRef = collection(Collections.QUESTION_CHOICE).doc(id);

      createChoicesBatch.set(questionChoiceRef, choice);
      choicesRollbackBatch.delete(questionChoiceRef);
    });

    await createChoicesBatch.commit();
  } catch (error) {
    console.error(error);

    const errorMessage = firebaseError(error.code, locale);

    throw new Error(errorMessage);
  }

  const rollback = async () => {
    await choicesRollbackBatch.commit();
  };

  return rollback;
};
