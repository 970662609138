/* eslint-disable no-param-reassign */

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Prompt, useParams, useLocation, Redirect } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import isEqual from 'lodash.isequal';
import cloneDeep from 'lodash.clonedeep';

import {
  validSurveyType,
  listSurveysPath,
  surveyState,
} from 'utils/surveyBuilder';
import { calculateTranslationCompletion, isString } from 'utils';
import {
  SurveyType,
  SurveyElement,
  SurveyTypes,
} from 'utils/surveyBuilder/enums';
import { LanguageIsoCode, LanguageName } from 'utils/enums';
import {
  surveysClearState,
  fetchSurvey,
  clearSelectedVersion,
  clearSurveyUpdatedState,
  clearSurveyCreatedDeploymentState,
} from 'state/actions/surveys';
import { fetchOrganizations } from 'state/actions/organizations';
import { selectSurveyBuilderState } from 'state/selectors/surveys';
import paths from 'pages/Router/paths';
import useModal from 'hooks/useModal';
import Header from 'components/SurveyBuilder/Header';
import Form from 'components/SurveyBuilder/Form';
import Modals from 'components/SurveyBuilder/Modals';

import './SurveyBuilder.scss';

const SurveyBuilder = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { pathname } = useLocation();

  const isUpdating = useMemo(
    () => !!params.surveyId && (!!params.versionId || !!params.deploymentId),
    [params.surveyId, params.versionId, params.deploymentId]
  );

  const [survey, setSurvey] = useState(
    surveyState({
      surveyType: params.type,
      defaultLanguage: LanguageIsoCode.EN,
    })
  );

  const [surveyInitialState, setSurveyInitialState] = useState(
    cloneDeep(
      surveyState({
        surveyType: params.type,
        defaultLanguage: LanguageIsoCode.EN,
      })
    )
  );

  const [questionItems, setQuestionItems] = useState([]);
  const [questionGroupItems, setQuestionGroupItems] = useState([]);
  const [textBoxItems, setTextBoxItems] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);

  const { modal, onOpenModalHandler, onCloseModalHandler } = useModal();

  const {
    defaultOrganization,
    success,
    fetched,
    fetchedSurvey,
    errorFetchingSurvey,
    versions,
    creatingVersion,
    selectedVersion,
    createdDeployment,
    updatedSurvey,
    createdFromTemplate,
  } = useSelector(selectSurveyBuilderState, shallowEqual);

  const {
    versionTitle: surveyVersionTitle,
    pagesOrder: surveyPagesOrder,
    defaultLanguage: surveyDefaultLanguage,
    items: surveyItems,
    pages: surveyPages,
    sections: surveySections,
    availableLanguages: surveyAvailableLanguages,
    type: surveyType,
    canSelectOrganization: surveyCanSelectOrganization,
    surveyId: surveySurveyId,
  } = survey;

  useEffect(() => {
    if (isUpdating && params.versionId) {
      dispatch(
        fetchSurvey(params.surveyId, params.versionId, SurveyTypes.VERSIONS)
      );
    }
  }, [dispatch, params.surveyId, params.versionId, isUpdating, SurveyTypes]);

  useEffect(() => {
    if (isUpdating && params.deploymentId) {
      dispatch(
        fetchSurvey(
          params.surveyId,
          params.deploymentId,
          SurveyTypes.DEPLOYMENTS
        )
      );
    }
  }, [dispatch, params.surveyId, params.deploymentId, isUpdating, SurveyTypes]);

  useEffect(() => {
    if (updatedSurvey) {
      dispatch(
        fetchSurvey(params.surveyId, params.versionId, SurveyTypes.VERSIONS)
      );
      dispatch(clearSurveyUpdatedState());
    }
  }, [dispatch, params.surveyId, params.versionId, updatedSurvey, SurveyTypes]);

  useEffect(() => {
    if (createdDeployment) {
      dispatch(
        fetchSurvey(params.surveyId, params.versionId, SurveyTypes.VERSIONS)
      );
      dispatch(clearSurveyCreatedDeploymentState());
    }
  }, [
    dispatch,
    params.surveyId,
    params.versionId,
    createdDeployment,
    SurveyTypes,
  ]);

  useEffect(() => {
    if (isUpdating && params.versionId && fetched && fetchedSurvey) {
      const { organizationId, organizationName, defaultLanguage } =
        fetchedSurvey;

      let organization = {};

      if (organizationId) {
        organization = {
          label: organizationName,
          value: { id: organizationId, displayName: organizationName },
        };
      }

      const { id, name } = fetchedSurvey.versions
        .filter((version) => version.id === params.versionId)
        .pop();

      const newData = {
        ...fetchedSurvey,
        organization,
        id: organizationId,
        displayName: organizationName,
        versionSelect: {
          label: name,
          value: id,
        },
        selectedLanguage: defaultLanguage,
        languageSelect: {
          label: LanguageName[defaultLanguage],
          value: defaultLanguage,
        },
      };

      setSurvey((prevState) => ({ ...prevState, ...newData }));
      setSurveyInitialState((prevState) => ({
        ...prevState,
        ...cloneDeep(newData),
      }));
    }
  }, [isUpdating, fetched, fetchedSurvey, params.versionId]);

  useEffect(() => {
    if (
      isUpdating &&
      params.deploymentId &&
      fetched &&
      fetchedSurvey &&
      fetchedSurvey.versionId
    ) {
      const { organizationId, organizationName, defaultLanguage } =
        fetchedSurvey;

      let organization = {};

      if (organizationId) {
        organization = {
          label: organizationName,
          value: { id: organizationId, displayName: organizationName },
        };
      }

      const { id, name } = fetchedSurvey.versions
        .filter((version) => version.id === fetchedSurvey.versionId)
        .pop();

      const newData = {
        ...fetchedSurvey,
        organization,
        versionSelect: {
          label: name,
          value: id,
        },
        selectedLanguage: defaultLanguage,
        languageSelect: {
          label: LanguageName[defaultLanguage],
          value: defaultLanguage,
        },
      };

      setSurvey((prevState) => ({ ...prevState, ...newData }));
      setSurveyInitialState((prevState) => ({
        ...prevState,
        ...cloneDeep(newData),
      }));
    }
  }, [isUpdating, fetched, fetchedSurvey, params.deploymentId]);

  useEffect(() => {
    if (surveyType === SurveyType.ORGANIZATION && !isUpdating) {
      const filterInactives = true;
      dispatch(fetchOrganizations(filterInactives));
    }
  }, [dispatch, surveyType, isUpdating]);

  useEffect(() => {
    return () => dispatch(surveysClearState());
  }, [dispatch]);

  useEffect(() => {
    if (createdFromTemplate) {
      dispatch(surveysClearState());
      onCloseModalHandler();
    }
  }, [dispatch, createdFromTemplate, onCloseModalHandler]);

  useEffect(() => {
    if (surveyType === SurveyType.ORGANIZATION && !isUpdating) {
      setSurvey((prevState) => ({
        ...prevState,
        organization: defaultOrganization,
        organizationId: defaultOrganization?.value.id || null,
        organizationName: defaultOrganization?.value.displayName || null,
        organizationDisplaySiteName:
          defaultOrganization?.value.displaySiteName || null,
      }));
      setSurveyInitialState((prevState) => ({
        ...prevState,
        organization: defaultOrganization,
        organizationId: defaultOrganization?.value.id || null,
        organizationName: defaultOrganization?.value.displayName || null,
        organizationDisplaySiteName:
          defaultOrganization?.value.displaySiteName || null,
      }));
    }
  }, [defaultOrganization, surveyType, isUpdating]);

  useEffect(() => {
    if (selectedVersion) {
      dispatch(clearSelectedVersion());
    }
  }, [dispatch, selectedVersion]);

  const canSelectOrganization = useCallback(() => {
    const surveyQuestions = Object.values(surveyItems).filter(
      (item) => item.type === SurveyElement.QUESTION
    );

    const surveyQuestionGroups = Object.values(surveyItems).filter(
      (item) => item.type === SurveyElement.QUESTION_GROUP
    );

    const hasOrganizationDataSet =
      surveyQuestions.some(
        ({ question }) =>
          question.dataSet.organization !== null &&
          question.dataSet.organization !== 'global'
      ) ||
      surveyQuestionGroups.some(
        ({ questionGroup }) =>
          questionGroup.dataSet.organization !== null &&
          questionGroup.dataSet.organization !== 'global'
      );
    const hasOrganizationTag =
      surveyQuestions.some(({ question }) =>
        question.tags.some((tag) => tag.organizationName !== 'global')
      ) ||
      surveyQuestionGroups.some(({ questionGroup }) =>
        questionGroup.questions.some((question) =>
          question.tags.some((tag) => tag.organizationName !== 'global')
        )
      );

    if (
      surveyCanSelectOrganization &&
      (hasOrganizationDataSet || hasOrganizationTag)
    ) {
      setSurvey((prevState) => ({
        ...prevState,
        canSelectOrganization: false,
      }));
      setSurveyInitialState((prevState) => ({
        ...prevState,
        canSelectOrganization: false,
      }));
    }

    if (
      !surveyCanSelectOrganization &&
      !hasOrganizationDataSet &&
      !hasOrganizationTag
    ) {
      setSurvey((prevState) => ({
        ...prevState,
        canSelectOrganization: true,
      }));
      setSurveyInitialState((prevState) => ({
        ...prevState,
        canSelectOrganization: true,
      }));
    }
  }, [surveyItems, surveyCanSelectOrganization]);

  useEffect(() => {
    if (surveyType === SurveyType.ORGANIZATION && !isUpdating) {
      canSelectOrganization();
    }
  }, [surveyType, canSelectOrganization, isUpdating]);

  useEffect(() => {
    if (createdDeployment) {
      onCloseModalHandler();
    }
  }, [createdDeployment, onCloseModalHandler]);

  const surveyItemsDependency = useMemo(
    () => JSON.stringify(surveyItems),
    [surveyItems]
  );

  useEffect(() => {
    if (surveyItems) {
      const questions = {};
      const questionGroups = {};
      const textBoxes = {};
      Object.values(surveyItems).forEach((item) => {
        if (item.type === SurveyElement.QUESTION) {
          questions[item.id] = item;
        }
        if (item.type === SurveyElement.TEXT_BOX) {
          textBoxes[item.id] = item;
        }
        if (item.type === SurveyElement.QUESTION_GROUP) {
          questionGroups[item.id] = item;
        }
      });
      setQuestionItems(questions);
      setTextBoxItems(textBoxes);
      setQuestionGroupItems(questionGroups);
    }
  }, [surveyItems, surveyItemsDependency]);

  const {
    sanitizedSurveyPages,
    sanitizedSurveySections,
    sanitizedSurveyItems,
  } = useMemo(() => {
    const sanitizedSurveyPagesInternal = {};
    const sanitizedSurveySectionsInternal = {};
    const sanitizedSurveyItemsInternal = {};

    const sectionsIds = [];
    const itemsIds = [];

    survey.pagesOrder.forEach((pageId) => {
      const page = survey.pages[pageId];

      sanitizedSurveyPagesInternal[page.id] = { ...page, collapsed: false };

      sectionsIds.push(...page.sectionIds);
    });

    sectionsIds.forEach((sectionId) => {
      const section = survey.sections[sectionId];

      sanitizedSurveySectionsInternal[section.id] = {
        ...section,
        collapsed: false,
      };
      itemsIds.push(...section.itemIds);
    });

    itemsIds.forEach((itemId) => {
      const item = survey.items[itemId];

      sanitizedSurveyItemsInternal[item.id] = { ...item, collapsed: false };
    });

    return {
      sanitizedSurveyPages: sanitizedSurveyPagesInternal,
      sanitizedSurveySections: sanitizedSurveySectionsInternal,
      sanitizedSurveyItems: sanitizedSurveyItemsInternal,
    };
  }, [survey]);

  const {
    sanitizedSurveyInitialPages,
    sanitizedSurveyInitialSections,
    sanitizedSurveyInitialItems,
  } = useMemo(() => {
    const sanitizedSurveyInitialPagesInternal = {};
    const sanitizedSurveyInitialSectionsInternal = {};
    const sanitizedSurveyInitialItemsInternal = {};

    const sectionsIds = [];
    const itemsIds = [];

    surveyInitialState.pagesOrder.forEach((pageId) => {
      const page = surveyInitialState.pages[pageId];

      sanitizedSurveyInitialPagesInternal[page.id] = {
        ...page,
        collapsed: false,
      };
      sectionsIds.push(...page.sectionIds);
    });

    sectionsIds.forEach((sectionId) => {
      const section = surveyInitialState.sections[sectionId];

      sanitizedSurveyInitialSectionsInternal[section.id] = {
        ...section,
        collapsed: false,
      };
      itemsIds.push(...section.itemIds);
    });

    itemsIds.forEach((itemId) => {
      const item = surveyInitialState.items[itemId];

      sanitizedSurveyInitialItemsInternal[item.id] = {
        ...item,
        collapsed: false,
      };
    });

    return {
      sanitizedSurveyInitialPages: sanitizedSurveyInitialPagesInternal,
      sanitizedSurveyInitialSections: sanitizedSurveyInitialSectionsInternal,
      sanitizedSurveyInitialItems: sanitizedSurveyInitialItemsInternal,
    };
  }, [surveyInitialState]);

  const surveyWithoutCollapsables = useMemo(
    () => ({
      ...survey,
      pages: sanitizedSurveyPages,
      sections: sanitizedSurveySections,
      items: sanitizedSurveyItems,
    }),
    [sanitizedSurveyPages, sanitizedSurveySections, sanitizedSurveyItems]
  );

  const initialSurveyWithoutCollapsables = useMemo(
    () => ({
      ...survey,
      pages: sanitizedSurveyInitialPages,
      sections: sanitizedSurveyInitialSections,
      items: sanitizedSurveyInitialItems,
    }),
    [
      sanitizedSurveyInitialPages,
      sanitizedSurveyInitialSections,
      sanitizedSurveyInitialItems,
    ]
  );

  useEffect(() => {
    if (
      !creatingVersion &&
      !success &&
      isEqual(surveyWithoutCollapsables, initialSurveyWithoutCollapsables)
    ) {
      return setHasChanges(false);
    }

    return setHasChanges(true);
  }, [
    surveyWithoutCollapsables,
    initialSurveyWithoutCollapsables,
    success,
    creatingVersion,
  ]);

  const invalidSurveyTypeRedirect = useMemo(
    () =>
      !isUpdating &&
      !validSurveyType(params.type) && <Redirect to={paths.ROOT} />,
    [params.type, isUpdating]
  );

  const changeVersionRedirect = useMemo(
    () =>
      selectedVersion && (
        <Redirect
          to={`${paths.SURVEYS}/${surveySurveyId}/versions/${selectedVersion}`}
        />
      ),
    [selectedVersion, surveySurveyId]
  );

  const redirect = useMemo(() => {
    return (
      ((!isUpdating && success) || errorFetchingSurvey) && (
        <Redirect to={listSurveysPath(surveyType)} />
      )
    );
  }, [success, isUpdating, errorFetchingSurvey, surveyType]);

  const redirectCreatedFromTemplate = useMemo(
    () =>
      createdFromTemplate && (
        <Redirect
          to={`${paths.SURVEYS}/${createdFromTemplate.surveyId}/versions/${createdFromTemplate.versionId}`}
        />
      ),
    [createdFromTemplate]
  );

  const [
    complexSurveyItems,
    complexSurveyPages,
    complexSurveyPagesOrder,
    complexSurveySections,
  ] = [
    JSON.stringify(surveyItems),
    JSON.stringify(surveyPages),
    JSON.stringify(surveyPagesOrder),
    JSON.stringify(surveySections),
  ];

  const defaultTranslationContent = useMemo(
    () => {
      const defaultTranslations = [surveyVersionTitle[surveyDefaultLanguage]];

      // eslint-disable-next-line no-unused-expressions
      surveyPagesOrder?.forEach((pageId) => {
        const { title: pageTitle, sectionIds } = { ...surveyPages[pageId] };

        defaultTranslations.push(pageTitle[surveyDefaultLanguage]);

        sectionIds.forEach((sectionId) => {
          const { title: sectionTitle, itemIds } = {
            ...surveySections[sectionId],
          };

          defaultTranslations.push(sectionTitle[surveyDefaultLanguage]);

          itemIds.forEach((itemId) => {
            const {
              type: itemType,
              content: itemContent,
              richText: itemRichText,
              question,
              questionGroup,
            } = surveyItems[itemId];

            if (itemType === SurveyElement.TEXT_BOX) {
              defaultTranslations.push(
                itemRichText
                  ? itemRichText?.[surveyDefaultLanguage]
                  : itemContent?.[surveyDefaultLanguage]
              );
            }

            if (itemType === SurveyElement.QUESTION) {
              const { content, richText, choices: questionChoices } = question;

              defaultTranslations.push(
                richText?.[surveyDefaultLanguage] ||
                  content?.[surveyDefaultLanguage]
              );

              questionChoices.forEach(({ option }) => {
                defaultTranslations.push(option[surveyDefaultLanguage]);
              });
            }

            if (itemType === SurveyElement.QUESTION_GROUP) {
              const { questions, choices, dataSet } = questionGroup;

              questions.forEach(({ content }) => {
                defaultTranslations.push(content[surveyDefaultLanguage]);
              });

              choices.forEach(({ questionChoices, option, dataSetId }) => {
                if (dataSetId) {
                  let datasetTitle = null;
                  
                  if (Array.isArray(dataSet)) {
                    datasetTitle = dataSet.find(
                      ({ id }) => id === dataSetId
                    ).title;
                  }

                  if (datasetTitle) {
                    if (isString(datasetTitle)) {
                      defaultTranslations.push(datasetTitle);
                    } else {
                      defaultTranslations.push(
                        datasetTitle[surveyDefaultLanguage] || ''
                      );
                    }
                  }
                }

                if (questionChoices) {
                  return questionChoices.forEach(({ option: choiceOption }) => {
                    defaultTranslations.push(
                      choiceOption[surveyDefaultLanguage]
                    );
                  });
                }

                // We need this line to not break old implementation
                return defaultTranslations.push(option[surveyDefaultLanguage]);
              });
            }
          });
        });
      });

      return defaultTranslations;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      surveyDefaultLanguage,
      complexSurveyItems,
      complexSurveyPages,
      complexSurveyPagesOrder,
      complexSurveySections,
      surveyVersionTitle,
    ]
  );

  const defaultTranslation = useMemo(
    () => ({
      code: surveyDefaultLanguage,
      name: LanguageName[surveyDefaultLanguage],
      content: defaultTranslationContent,
    }),
    [surveyDefaultLanguage, defaultTranslationContent]
  );

  const translations = useMemo(
    () =>
      surveyAvailableLanguages
        .filter((languageCode) => languageCode !== surveyDefaultLanguage)
        .map((languageCode, index) => {
          const translation = [
            {
              type: SurveyElement.SURVEY_TITLE,
              value: surveyVersionTitle[languageCode],
            },
          ];
          const dataSetsMissingTranslations = [];

          surveyPagesOrder.forEach((pageId) => {
            const { title: pageTitle, sectionIds } = {
              ...surveyPages[pageId],
            };

            translation.push({
              type: SurveyElement.PAGE,
              id: pageId,
              value: pageTitle[languageCode],
            });

            sectionIds.forEach((sectionId) => {
              const { title: sectionTitle, itemIds } = {
                ...surveySections[sectionId],
              };

              translation.push({
                type: SurveyElement.SECTION,
                id: sectionId,
                value: sectionTitle[languageCode],
              });

              itemIds.forEach((itemId) => {
                const {
                  type: itemType,
                  content: itemContent,
                  richText: itemRichText,
                  question,
                  questionGroup,
                } = {
                  ...surveyItems[itemId],
                };

                if (itemType === SurveyElement.TEXT_BOX) {
                  translation.push({
                    type: SurveyElement.TEXT_BOX,
                    id: itemId,
                    value:
                      itemRichText?.[languageCode] || itemContent[languageCode],
                  });
                }

                if (itemType === SurveyElement.QUESTION) {
                  const {
                    choices: questionChoices,
                    richText: questionRichText,
                    dataSet,
                  } = question;

                  translation.push({
                    type: SurveyElement.QUESTION,
                    id: itemId,
                    value: questionRichText?.[languageCode] || '',
                  });

                  const choicesAreFromDataSet = !!dataSet.id;

                  const dataSetMissingTranslations =
                    choicesAreFromDataSet &&
                    questionChoices.some(
                      (questionChoice) => !questionChoice.option[languageCode]
                    );

                  if (dataSetMissingTranslations) {
                    const isAlreadyContained = dataSetsMissingTranslations.some(
                      ({ id: dataSetId }) => dataSetId === dataSet.id
                    );

                    if (!isAlreadyContained) {
                      dataSetsMissingTranslations.push(dataSet);
                    }
                  }

                  questionChoices.forEach(({ option }, choiceId) => {
                    translation.push({
                      type: SurveyElement.QUESTION_CHOICE,
                      id: itemId,
                      choiceId,
                      value: dataSetMissingTranslations
                        ? ''
                        : option[languageCode],
                      missingTranslation: dataSetMissingTranslations,
                      isFromDataSet: choicesAreFromDataSet,
                    });
                  });
                }

                if (itemType === SurveyElement.QUESTION_GROUP) {
                  const { questions, choices, dataSet } = questionGroup;

                  questions.forEach(({ content }, questionIndex) => {
                    translation.push({
                      type: SurveyElement.QUESTION_FROM_QUESTION_GROUP,
                      id: {
                        questionIndex,
                        itemId,
                      },
                      value: content[languageCode],
                    });
                  });

                  const choicesAreFromDataSet = !!dataSet.id;

                  const dataSetMissingTranslations =
                    choicesAreFromDataSet &&
                    choices.some(
                      (questionChoice) => !questionChoice.option[languageCode]
                    );

                  if (dataSetMissingTranslations) {
                    const isAlreadyContained = dataSetsMissingTranslations.some(
                      ({ id: dataSetId }) => dataSetId === dataSet.id
                    );

                    if (!isAlreadyContained) {
                      dataSetsMissingTranslations.push(dataSet);
                    }
                  }

                  choices.forEach(
                    ({ questionChoices, dataSetId, option }, choiceId) => {
                      if (dataSetId) {
                        let datasetTitle = null;
                  
                        if (Array.isArray(dataSet)) {
                          datasetTitle = dataSet.find(
                            ({ id }) => id === dataSetId
                          ).title;
                        }
      

                        if (datasetTitle) {
                          if (isString(datasetTitle)) {
                            translation.push({
                              type: SurveyElement.QUESTION_CHOICE_DATASET_TITLE,
                              id: { itemId, choiceId, dataSetId },
                              choiceId,
                              value: '',
                            });
                          } else {
                            translation.push({
                              type: SurveyElement.QUESTION_CHOICE_DATASET_TITLE,
                              id: { itemId, choiceId, dataSetId },
                              choiceId,
                              value: datasetTitle[languageCode] || '',
                            });
                          }
                        }
                      }

                      if (questionChoices) {
                        return questionChoices.forEach(
                          (
                            { option: questionChoiceOption },
                            questionChoiceId
                          ) => {
                            translation.push({
                              type: SurveyElement.QUESTION_CHOICE,
                              id: itemId,
                              choiceId,
                              questionChoiceId,
                              value:
                                dataSetMissingTranslations ||
                                !questionChoiceOption[languageCode]
                                  ? ''
                                  : questionChoiceOption[languageCode],
                              missingTranslation: dataSetMissingTranslations,
                              isFromDataSet: choicesAreFromDataSet,
                            });
                          }
                        );
                      }

                      return translation.push({
                        type: SurveyElement.QUESTION_CHOICE,
                        id: itemId,
                        choiceId,
                        value: dataSetMissingTranslations
                          ? ''
                          : option[languageCode],
                        missingTranslation: dataSetMissingTranslations,
                        isFromDataSet: choicesAreFromDataSet,
                      });
                    }
                  );
                }
              });
            });
          });

          const percentageCompleted = calculateTranslationCompletion(
            defaultTranslation.content,
            translation.map(({ value }) => value)
          );

          return {
            code: languageCode,
            name: LanguageName[languageCode],
            percentageCompleted,
            content: translation,
            index,
            dataSetsMissingTranslations,
          };
        }),
    [
      surveyAvailableLanguages,
      surveyDefaultLanguage,
      defaultTranslation,
      surveyItems,
      surveyPages,
      surveyPagesOrder,
      surveySections,
      surveyVersionTitle,
    ]
  );

  const translationsDependency = useMemo(
    () => JSON.stringify(translations),
    [translations]
  );

  useEffect(() => {
    if (translations.length > 0) {
      const languageCompletion = {};

      translations.forEach(({ code, percentageCompleted }) => {
        languageCompletion[code] = percentageCompleted;
      });

      setSurvey((prevState) => ({ ...prevState, languageCompletion }));
      setSurveyInitialState((prevState) => ({
        ...prevState,
        languageCompletion: cloneDeep(languageCompletion),
      }));
    }
  }, [translationsDependency, translations]);

  return (
    <>
      <Prompt
        when={hasChanges}
        message={(location) =>
          location.pathname.startsWith(pathname) || errorFetchingSurvey
            ? true
            : 'There are unsaved changes, are you sure you want to navigate away?'
        }
      />
      <Modals
        survey={survey}
        setSurvey={setSurvey}
        modal={modal}
        params={params}
        deploymentId={params.deploymentId}
        onCloseModalHandler={onCloseModalHandler}
        modalType={modal.type}
        defaultTranslation={defaultTranslation}
        translations={translations}
        questionItems={questionItems}
        questionGroupItems={questionGroupItems}
        textBoxItems={textBoxItems}
      />
      {redirect}
      {changeVersionRedirect}
      {invalidSurveyTypeRedirect}
      {redirectCreatedFromTemplate}
      <Header
        onOpenModalHandler={onOpenModalHandler}
        survey={survey}
        isUpdating={isUpdating}
        creatingVersion={creatingVersion}
        versions={versions}
      />
      <section className="section is-main-section survey-builder">
        {isUpdating && !fetched ? (
          <ClipLoader />
        ) : (
          <Form
            survey={survey}
            isUpdating={isUpdating}
            setSurvey={setSurvey}
            onOpenModalHandler={onOpenModalHandler}
          />
        )}
      </section>
    </>
  );
};

export default SurveyBuilder;
