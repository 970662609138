import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import classNames from 'classnames';
import { InstantSearch } from 'react-instantsearch-dom';

import { REACT_APP_ALGOLIA_GROUPS_INDEX } from 'constants/environment';
import { useFormatDate, useAlgoliaSearch } from 'hooks';
import { roles, initAlgoliaClient } from 'utils';
import { clearGroupsData, deleteGroup } from 'state/actions/groups';
import {
  Filter,
  FilterOption,
  Menu,
  ToggleRefinement,
  SearchBox,
  Table,
} from 'components/Algolia';
import paths from 'pages/Router/paths';
import Modal from 'components/Modal';
import classes from './Groups.module.scss';

const searchClient = initAlgoliaClient();

const groupIndex = REACT_APP_ALGOLIA_GROUPS_INDEX;

const { ADMIN } = roles;

const Groups = () => {
  const { role, loading, deleted, defaultOrganization } = useSelector(
    (state) => ({
      role: state.auth.userData.role,
      loading: state.groups.loading,
      deleted: state.groups.deleted,
      defaultOrganization: state.preferences.defaultOrganization,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const [deleteModal, setDeleteModal] = React.useState({
    groupId: null,
    isOpen: false,
  });

  const { search, refresh } = useAlgoliaSearch();

  useEffect(() => {
    if (deleted && !loading) {
      refresh();
      setDeleteModal((prevState) => ({
        groupId: null,
        isOpen: !prevState.isOpen,
      }));
      dispatch(clearGroupsData());
    }
  }, [deleted, loading, dispatch, refresh]);

  const onRemoveButtonClickHandler = (groupId) => {
    setDeleteModal((prevState) => ({
      groupId,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ groupId: null, isOpen: false });
  };

  const onDeleteGroupHandler = () => {
    dispatch(deleteGroup(deleteModal.groupId));
  };

  const redirect = role !== ADMIN && <Redirect to={paths.ROOT} />;

  const columns = [
    {
      Header: 'Name',
      accessor: 'displayName',
      Cell: ({ row }) => (
        <Link to={`${paths.GROUPS}/${row.original.objectID}`}>
          {row.original.displayName}
        </Link>
      ),
    },
    {
      Header: 'Organization',
      accessor: 'organizationName',
    },
    {
      Header: 'Manager',
      accessor: 'manager',
      Cell: ({ row }) =>
        row.original.manager
          ? `${row.original.manager.firstName} ${row.original.manager.lastName}`
          : 'None',
    },
    {
      Header: 'Description',
      accessor: 'description',
    },
    {
      Header: 'Users',
      accessor: 'usersCount',
      Cell: ({ row }) => (
        <Link to={`${paths.USERS}?group=${row.original.objectID}`}>
          {row.original.usersCount}
        </Link>
      ),
    },
    {
      Header: 'Active',
      accessor: 'active',
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {row.original.active ? (
            <span className="icon">
              <i className="mdi mdi-check" />
            </span>
          ) : (
            <span className="icon">
              <i className="mdi mdi-close" />
            </span>
          )}
        </small>
      ),
    },
    {
      Header: 'Created at',
      accessor: 'createdAt',
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {useFormatDate(row.original.createdAt, {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })}
        </small>
      ),
    },
    {
      Header: 'Created by',
      accessor: 'createdBy',
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {row.original.createdBy}
        </small>
      ),
    },
    {
      Header: '',
      id: 'actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <>
          {role === ADMIN && (
            <div className={classNames('buttons is-right', classes.actions)}>
              <Link
                to={`${paths.GROUPS}/${row.original.objectID}`}
                className="button is-small is-primary"
              >
                <span className="icon is-small">
                  <i className="mdi mdi-account-edit" />
                </span>
              </Link>

              <button
                type="button"
                className="button is-small is-danger"
                onClick={() =>
                  onRemoveButtonClickHandler(row.original.objectID)
                }
              >
                <span className="icon is-small">
                  <i className="mdi mdi-trash-can" />
                </span>
              </button>
            </div>
          )}
        </>
      ),
      disableSortBy: true,
    },
  ];

  return (
    <>
      {redirect}
      {deleteModal.isOpen && (
        <Modal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage="Delete"
          title="Confirm action"
          body="This will permanently delete the group and organizations charts may be affected. Action cannot be undone."
          cancelButtonMessage="Cancel"
          onConfirmation={onDeleteGroupHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      <InstantSearch
        searchClient={searchClient}
        indexName={groupIndex}
        refresh={search.refresh}
      >
        <section className="hero is-hero-bar">
          <div className="hero-body">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <h1 className="title">Groups</h1>
                </div>
              </div>
              <div className="level-right">
                <div className="level-item">
                  <Link to={paths.ADD_GROUP} className="button">
                    New Group
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section is-main-section">
          <Filter>
            <FilterOption label="Organization">
              <Menu
                predefinedRefinement={defaultOrganization?.value.displayName}
                predefinedOption={defaultOrganization}
                attribute="organizationName"
                emptyPlaceholder="No organizations"
                defaultPlaceholder="Select an organization"
                isSearchable
                isClearable
                limit={20}
              />
            </FilterOption>
            <FilterOption label="Active" check>
              <ToggleRefinement
                attribute="active"
                value
                defaultRefinement
                label="Active"
              />
            </FilterOption>
          </Filter>
          <div className="card has-table has-mobile-sort-spaced">
            <header className="card-header">
              <p className="card-header-title table-header">
                <span>Search</span>
                <SearchBox />
              </p>
            </header>
            <Table columns={columns} defaultPerPageOption={10} />
          </div>
        </section>
      </InstantSearch>
    </>
  );
};

export default Groups;
