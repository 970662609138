/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import Proptypes from 'prop-types';
import './Filter.scss';

const Filter = ({ children, label = true }) => (
  <div className="field is-horizontal">
    {label && (
      <div className="field-label is-normal field-label-shrink">
        <label className="label">Filter by:</label>
      </div>
    )}
    <div className="field-body algolia-filter">{children}</div>
  </div>
);

Filter.propTypes = {
  label: Proptypes.bool,
};

export default Filter;
