import { toastr } from 'react-redux-toastr';

import {updateSurveyService} from 'services/surveys';
import {
  SURVEYS_UPDATE_SURVEY_FAIL,
  SURVEYS_UPDATE_SURVEY_INIT,
  SURVEYS_UPDATE_SURVEY_SUCCESS,
} from 'state/actionCreators/surveys';

export const updateSurvey = (
  surveyData
) => {
  return async (dispatch, getState) => {
    dispatch(SURVEYS_UPDATE_SURVEY_INIT());

    try {
      await updateSurveyService({ surveyData }, { getState });
    } catch (error) {
      console.log(error);

      toastr.error('Error', error);
      
      return dispatch(SURVEYS_UPDATE_SURVEY_FAIL({ error }));
    }
    
    toastr.success('', 'Survey updated successfully');
    return dispatch(SURVEYS_UPDATE_SURVEY_SUCCESS());
  };
};
