import React, { useEffect, useMemo, useCallback } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';

import {
  validateDataItems,
  defaultDataItems,
  emptyDataItem,
  calculateTranslationCompletion,
} from 'utils';
import { fetchDataSet } from 'utils/fetching';
import { LanguageIsoCode, LanguageName } from 'utils/enums';
import {
  createDataSet,
  updateDataSet,
  clearData,
} from 'state/actions/dataSets';
import paths from 'pages/Router/paths';
import { dataSetChoiceIsDuplicated } from 'utils/surveyBuilder';

import Information from '../../components/DataSet/Information';
import DataItems from '../../components/DataSet/DataItems';
import Translations from '../../components/Translations';

const DataSet = () => {
  const { id } = useParams();

  const { success, loading } = useSelector(
    (state) => ({
      success: state.dataSets.success,
      loading: state.dataSets.loading,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const isEditing = useMemo(() => !!id, [id]);

  const [dataSet, setDataSet] = React.useState({
    id,
    global: false,
    active: true,
    organization: null,
    tag: null,
    startWave: null,
    loading: isEditing,
    title: {
      en: '',
    },
  });
  const [dataItems, setDataItems] = React.useState({
    data: defaultDataItems,
    previous: [],
  });
  const [translationsSidebar, setTranslationsSidebar] = React.useState(false);

  useEffect(() => {
    return () => dispatch(clearData());
  }, [dispatch]);

  useEffect(() => {
    if (isEditing) {
      fetchDataSet(id, setDataSet, setDataItems);
    }
  }, [isEditing, id]);

  const onSubmitHandler = useCallback(
    (event) => {
      event.preventDefault();

      const data = {
        ...dataSet.tag.value,
        title: dataSet.title,
        startWave: dataSet.startWave?.value || null,
        active: dataSet.active,
      };
      if (isEditing) {
        dispatch(updateDataSet(id, data, dataItems.data, dataItems.previous));
      } else {
        dispatch(createDataSet(data, dataItems.data));
      }
    },
    [
      dispatch,
      id,
      isEditing,
      dataSet.tag,
      dataSet.startWave,
      dataItems,
      dataSet.active,
      dataSet.title,
    ]
  );

  const redirect = useMemo(
    () => (success || dataSet.error) && <Redirect to={paths.DATA_SETS} />,
    [success, dataSet.error]
  );

  const canSubmit = useMemo(
    () =>
      !!dataSet.title && !!dataSet.tag && validateDataItems(dataItems.data.en),
    [dataSet.title, dataSet.tag, dataItems.data.en]
  );

  const onDeleteItemHandler = useCallback((dataItemId) => {
    setDataItems((prevState) => {
      const { data } = prevState;

      Object.entries(data).forEach(([key, value]) => {
        data[key] = value.filter((_, index) => index !== dataItemId);
      });

      return {
        ...prevState,
        data,
      };
    });
  }, []);

  const onAddItemHandler = useCallback(
    () =>
      setDataItems((prevState) => {
        const { data } = prevState;

        Object.entries(data).forEach(([key, value]) => {
          data[key] = [...value, emptyDataItem];
        });

        return {
          ...prevState,
          data,
        };
      }),
    []
  );

  const checkDuplicated = useCallback(() => {
    setDataItems((prevState) => {
      const data = {};
      const updatedDataItems = prevState.data.en.map((item, index) => {
        const otherItems = [...prevState.data.en];
        otherItems.splice(index, 1);
        const duplicated =
          (item.option && dataSetChoiceIsDuplicated(item.option, otherItems)) ||
          false;
        return { ...item, duplicated };
      });

      data.en = updatedDataItems;

      return {
        ...prevState,
        data: {
          ...prevState.data,
          ...data,
        },
      };
    });
  }, []);

  const onChangeDataItemHandler = useCallback(
    ({ id: dataItemId, value, name }) => {
      setDataItems((prevState) => {
        const data = {};

        if (name !== 'option') {
          Object.entries(prevState.data).forEach(
            ([languageCode, translations]) => {
              const updatedDataItems = translations.map((item, index) => {
                if (index === dataItemId) {
                  return { ...item, [name]: value };
                }

                return item;
              });

              data[languageCode] = updatedDataItems;
            }
          );
        } else {
          const updatedDataItems = prevState.data.en.map((item, index) => {
            if (index === dataItemId) {
              return { ...item, [name]: value };
            }
            return item;
          });

          data.en = updatedDataItems;
        }

        return {
          ...prevState,
          data: {
            ...prevState.data,
            ...data,
          },
        };
      });
      checkDuplicated();
    },
    [checkDuplicated]
  );

  const onReseDataSetItemsListHandler = useCallback(
    () =>
      setDataItems((prevState) => {
        let { data } = prevState;

        if (defaultDataItems.en.length > 0) {
          defaultDataItems.en.splice(1, defaultDataItems.en.length);
        }
        data = defaultDataItems;

        return {
          ...prevState,
          data,
        };
      }),
    []
  );

  const onOpenTranslationsHandler = useCallback(
    () => setTranslationsSidebar(true),
    []
  );

  const onCloseTranslationsHandler = useCallback(
    () => setTranslationsSidebar(false),
    []
  );

  const onAddTranslationHandler = useCallback(
    (language) =>
      setDataItems((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          [language]: prevState.data.en.map(() => emptyDataItem),
        },
      })),
    []
  );

  const onUpdateTranslationHandler = useCallback(
    ({ code, id: dataItemId, value, isDataItem = true }) => {
      if (!isDataItem) {
        return setDataSet((prevState) => ({
          ...prevState,
          title: {
            ...prevState.title,
            [code]: value,
          },
        }));
      }

      return setDataItems((prevState) => {
        const updatedDataItems = prevState.data[code].map((item, index) => {
          // This + 1 is necessary to add support for the title
          if (index + 1 === dataItemId) {
            return { ...item, option: value };
          }

          return item;
        });

        return {
          ...prevState,
          data: {
            ...prevState.data,
            [code]: updatedDataItems,
          },
        };
      });
    },
    []
  );

  const onRemoveTranslationHandler = useCallback(
    (language) =>
      setDataItems((prevState) => {
        // eslint-disable-next-line no-param-reassign
        delete prevState.data[language];
        return { ...prevState };
      }),
    []
  );

  const additionalTranslations = Object.entries(dataItems.data)
    .filter(([languageIsoCode]) => languageIsoCode !== LanguageIsoCode.EN)
    .map(([languageIsoCode, field], index) => {
      const content = [
        dataSet.title[languageIsoCode],
        ...field.map(({ option }) => option),
      ];

      const translatingFrom = [
        dataSet.title[LanguageIsoCode.EN],
        ...dataItems.data[LanguageIsoCode.EN].map(({ option }) => option),
      ];

      return {
        name: LanguageName[languageIsoCode],
        code: languageIsoCode,
        percentageCompleted: calculateTranslationCompletion(
          translatingFrom,
          content
        ),
        content,
        index,
      };
    });

  return (
    <>
      {redirect}
      {translationsSidebar && (
        <Translations
          defaultTranslation={{
            code: LanguageIsoCode.EN,
            name: LanguageName[LanguageIsoCode.EN],
            content: [
              dataSet.title[LanguageIsoCode.EN],
              ...dataItems.data.en.map(({ option }) => option),
            ],
          }}
          additionalTranslations={additionalTranslations}
          onClose={onCloseTranslationsHandler}
          onAddTranslation={onAddTranslationHandler}
          onRemoveTranslation={onRemoveTranslationHandler}
          onUpdateTranslation={onUpdateTranslationHandler}
        />
      )}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">Data Set</h1>
        </div>
      </section>
      <section className="section is-main-section">
        {isEditing && dataSet.loading ? (
          <ClipLoader />
        ) : (
          <form onSubmit={onSubmitHandler}>
            <Information
              dataSet={dataSet}
              setDataSet={setDataSet}
              isEditing={isEditing}
              onChangeDataItem={onChangeDataItemHandler}
            />
            <DataItems
              dataItems={dataItems.data.en}
              loading={loading}
              isEditing={isEditing}
              canSubmit={canSubmit}
              onAddItem={onAddItemHandler}
              onTranslate={onOpenTranslationsHandler}
              onResetList={onReseDataSetItemsListHandler}
              onDeleteItem={onDeleteItemHandler}
              onChangeDataItem={onChangeDataItemHandler}
            />
          </form>
        )}
      </section>
    </>
  );
};

export default DataSet;
