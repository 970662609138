/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';
import { v4 as uuid } from 'uuid';

import { SurveyElement } from 'utils/surveyBuilder/enums';
import { clearSavedSectionsState } from 'state/actions/SavedSections';
import { selectSectionSaveFetch } from 'state/selectors/sectionsSaves';
import useNewModal from 'hooks/useNewModal';
import LoadSectionModal from 'components/SurveyBuilder/Modals/LoadSectionModal';
import Section from 'components/SurveyBuilder/Section';
import DragItem from 'components/DragAndDrop/DragItem';
import Button from 'components/Button';

import classes from './Sections.module.scss';

const Sections = ({
  survey,
  setSurvey,
  pageId,
  pageIndex,
  sections,
  onAddSection,
  onOpenModal,
  deploymentId = null,
  canDeleteSection,
}) => {
  const dispatch = useDispatch();
  const { toggle, isOpen, closeModal } = useNewModal();

  const { success, sectionSave } = useSelector(selectSectionSaveFetch);

  useEffect(() => {
    if (success) {
      setSurvey((prevState) => {
        const {
          pages: statePages,
          sections: stateSections,
          items: stateItems,
        } = prevState;
        const { section: sectionToLoad, items: itemsToLoad } = sectionSave;
        const surveyPage = statePages[pageId];

        const newSection = {
          ...sectionToLoad,
          id: uuid(),
        };

        const newItems = itemsToLoad.reduce((acc, item) => {
          const newItemId = uuid();
          const newQuestionId = uuid();

          const itemIndex = newSection.itemIds.findIndex(
            (id) => id === item.id
          );

          if (itemIndex > -1) {
            newSection.itemIds[itemIndex] = newItemId;
          }

          const newItem = { ...item, id: newItemId };

          if (item.type === SurveyElement.QUESTION) {
            newItem.question.id = newQuestionId;
          }
          
          if (item.type === SurveyElement.QUESTION_GROUP) {
            newItem.questionGroup = {
              ...newItem.questionGroup,
              questions: newItem.questionGroup.questions.map((question) => {
                const newInnerQuestionId = uuid();

                return {
                  ...question,
                  id: newInnerQuestionId,
                };
              }),
            
            };
          }

          acc[newItemId] = newItem;

          return acc;
        }, {});

        return {
          ...prevState,
          pages: {
            ...statePages,
            [pageId]: {
              ...surveyPage,
              sectionIds: [...(surveyPage.sectionIds || []), newSection.id],
            },
          },
          sections: {
            ...stateSections,
            [newSection.id]: {
              ...newSection,
            },
          },
          items: {
            ...stateItems,
            ...newItems,
          },
        };
      });

      const newSection = document.querySelector(
        `[data-section-id="${sectionSave.section.id}"]`
      );

      if (newSection) {
        newSection.scrollIntoView({ behavior: 'smooth' });
      }

      dispatch(clearSavedSectionsState());
    }
  }, [success, sectionSave]);

  return (
    <>
      <LoadSectionModal
        isOpen={isOpen}
        toggle={toggle}
        closeModal={closeModal}
      />
      <Droppable droppableId={pageId.toString()} type="section">
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {sections.length === 0 && !snapshot.isDraggingOver && (
              <div
                id="empty-section-message"
                className="container has-text-centered"
              >
                <h3 className="title is-4">This Page is empty.</h3>
                <h4 className="subtitle is-6">
                  Click the button below to add a new section to this page.
                </h4>
                <Button
                  id="add-section"
                  icon="mdi mdi-plus-circle"
                  onClick={onAddSection}
                >
                  Add Section
                </Button>
                <Button
                  id="add-section"
                  icon="mdi mdi-download"
                  className={classes.loadSectionButton}
                  onClick={toggle}
                >
                  Load Section
                </Button>
              </div>
            )}
            {sections.map(
              ({ title, visible, id, collapsed, items, randomize }, index) => {
                return (
                  <DragItem key={id} dragId={id} index={index}>
                    <Section
                      survey={survey}
                      setSurvey={setSurvey}
                      pageId={pageId}
                      pageIndex={pageIndex}
                      sectionId={id}
                      sectionIndex={index}
                      title={title}
                      visible={visible}
                      collapsed={collapsed}
                      randomize={randomize}
                      onAddSection={onAddSection}
                      items={items}
                      onOpenModal={onOpenModal}
                      deploymentId={deploymentId}
                      canDeleteSection={canDeleteSection}
                    />
                  </DragItem>
                );
              }
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </>
  );
};

Sections.propTypes = {
  pageId: PropTypes.string.isRequired,
  sections: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  survey: PropTypes.shape({}).isRequired,
  setSurvey: PropTypes.func.isRequired,
  pageIndex: PropTypes.number.isRequired,
  onAddSection: PropTypes.func.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  canDeleteSection: PropTypes.func.isRequired,
  deploymentId: PropTypes.string,
};

export default Sections;
