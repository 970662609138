import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Select from 'react-select';

import {
  setUserLocale,
  setDefaultOrganization,
} from 'state/actions/preferences';
import { useFormatMessage } from 'hooks';
import { logout } from 'state/actions/auth';
import { fetchOrganizations } from 'state/actions/organizations';
import paths from 'pages/Router/paths';
import defaultLogo from 'assets/user-default-log.svg';
import { selectNavbarOrganizationParsedState } from 'state/selectors/organizations';
import Link from '../Link';

import './NavBar.scss';

const NavBar = ({ handleMobileToggle, asideMobileActive }) => {
  const dispatch = useDispatch();

  const { loading, organizations } = useSelector(
    selectNavbarOrganizationParsedState
  );

  const [navMobileActive, setNavMobileActive] = useState(false);

  const { name, logoUrl, locale, defaultOrganization } = useSelector(
    (state) => ({
      name: `${state.auth.userData.firstName} ${state.auth.userData.lastName}`,
      logoUrl: state.auth.userData.logoUrl,
      locale: state.preferences.locale,
      defaultOrganization: state.preferences.defaultOrganization,
    }),
    shallowEqual
  );

  useEffect(() => {
    const filterInactives = true;

    dispatch(fetchOrganizations(filterInactives));
  }, [dispatch]);

  const onClickLogoutHandler = () => {
    dispatch(logout());
  };

  const onMobileToggleHandler = useCallback(() => {
    setNavMobileActive(!navMobileActive);
  }, [setNavMobileActive, navMobileActive]);

  const changeLocaleHandler = useCallback(
    (local) => {
      dispatch(setUserLocale(local));
    },
    [dispatch]
  );

  // Related to language dropdown
  // const locales = availableLocales.filter((local) => local !== locale);

  const customStyles = {
    control: (styles) => ({
      ...styles,
      width: 180,
      borderStyle: 'none',
      boxShadow: 'none',
    }),
  };

  useEffect(() => {
    if (locale !== 'en') {
      changeLocaleHandler('en');
    }
  }, [locale, changeLocaleHandler]);

  return (
    <nav id="navbar-main" className="navbar is-fixed-top">
      <div className="navbar-brand">
        <span
          className="navbar-item is-hidden-desktop jb-aside-mobile-toggle"
          onClick={handleMobileToggle}
        >
          <span className="icon">
            <i
              className={classNames(
                'mdi',
                'mdi-24px',
                { 'mdi-forwardburger': !asideMobileActive },
                { 'mdi-backburger': asideMobileActive }
              )}
            />
          </span>
        </span>
      </div>
      <div className="navbar-brand is-right">
        <span
          className="navbar-item is-hidden-desktop jb-navbar-menu-toggle"
          data-target="navbar-menu"
          onClick={onMobileToggleHandler}
        >
          <span className="icon">
            <i
              className={classNames(
                'mdi',
                { 'mdi-dots-vertical': !navMobileActive },
                { 'mdi-close': navMobileActive }
              )}
            />
          </span>
        </span>
      </div>
      <div
        className={classNames('navbar-menu', 'fadeIn', 'animated', 'faster', {
          'is-active': navMobileActive,
        })}
        id="navbar-menu"
      >
        <div className="navbar-end">
          <div className="navbar-item">
            <Select
              styles={customStyles}
              isDisabled={loading}
              placeholder="Organizations..."
              isLoading={loading}
              isClearable
              options={organizations}
              onChange={(org) => dispatch(setDefaultOrganization(org))}
              value={defaultOrganization}
            />
          </div>

          {
            // disabled until update languages
            /* <div className="navbar-item has-dropdown has-dropdown-with-icons has-divider has-user-avatar is-hoverable">
            <span className="navbar-link is-arrowless">
              <div className="is-user-avatar">
                <span>
                  <img id={locale} src={flags[locale]} alt={`${locale} flag`} />
                </span>
              </div>
              <span className="icon">
                <i className="mdi mdi-chevron-down" />
              </span>
            </span>
            <div className="navbar-dropdown">
              {locales.map(
                (local) =>
                  // Temporal condition until internationalization finished
                  (local === 'en' || local === 'es') && (
                    <span
                      onClick={() => changeLocaleHandler(local)}
                      className="navbar-item"
                      id={local}
                      key={local}
                    >
                      <div className="is-user-avatar">
                        <span>
                          <img src={flags[local]} alt={`${local} flag`} />
                        </span>
                      </div>
                    </span>
                  )
              )}
            </div>
          </div> */
          }
          <div className="navbar-item has-dropdown has-dropdown-with-icons has-divider has-user-avatar is-hoverable">
            <span className="navbar-link is-arrowless">
              <div className="is-user-avatar">
                <img src={logoUrl || defaultLogo} alt="User profile" />
              </div>
              <div className="is-user-name">
                <span>{name}</span>
              </div>
              <span className="icon">
                <i className="mdi mdi-chevron-down" />
              </span>
            </span>
            <div className="navbar-dropdown">
              <Link to={paths.PROFILE} onClick={onMobileToggleHandler}>
                <span className="icon">
                  <i className="mdi mdi-account" />
                </span>
                <span>{useFormatMessage('NavBar.profile')}</span>
              </Link>
              <hr className="navbar-divider" />
              <span
                className="navbar-item logout-button"
                id="logout"
                onClick={onClickLogoutHandler}
              >
                <span className="icon">
                  <i className="mdi mdi-logout" />
                </span>
                <span>{useFormatMessage('NavBar.logOut')}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

NavBar.propTypes = {
  handleMobileToggle: PropTypes.func.isRequired,
  asideMobileActive: PropTypes.bool,
};

export default NavBar;
