import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';

import ErrorBoundary from 'components/ErrorBoundary';
import Layout from 'components/Layout';
import paths from '../paths';

const PrivateRoute = ({ path, component: Component }) => {
  const { id } = useSelector(
    (state) => ({
      id: state.auth.userData.id,
    }),
    shallowEqual
  );

  return (
    <Route
      path={path}
      render={() =>
        id ? (
          <Layout>
            <ErrorBoundary>
              <Component />
            </ErrorBoundary>
          </Layout>
        ) : (
          <Redirect to={paths.LOGIN} />
        )
      }
    />
  );
};

PrivateRoute.propType = {
  path: PropTypes.string.isRequired,
  component: PropTypes.element.isRequired,
};

export default PrivateRoute;
