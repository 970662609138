/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const Type = {
  Text: 'text',
  Number: 'number',
};

const Input = ({
  className = '',
  type = Type.Text,
  value,
  placeHolder = '',
  onChange,
  ...rest
}) => (
  <input
    className={classNames('input', className)}
    type={type}
    placeholder={placeHolder}
    value={value}
    onChange={onChange}
    {...rest}
  />
);

Input.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf([Type.Text, Type.Number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  placeHolder: PropTypes.string,
};

export default Input;
