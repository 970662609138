import firebase from 'firebase.js';
import { firebaseError } from 'utils';
import { Collections } from 'utils/enums';
import { collection } from 'utils/firebase';

export const setSurveyVersion = async ({
  surveyId,
  surveyVersionId,
  survey,
  surveyVersion,
  locale,
}) => {
  const db = firebase.firestore();
  const surveyRef = collection(Collections.SURVEYS).doc(surveyId);
  const versionRef = surveyRef
    .collection(Collections.VERSIONS)
    .doc(surveyVersionId);

  try {
    const rollbackSurveyData = await db.runTransaction(async (transaction) => {
      const surveySnapshot = await transaction.get(surveyRef);

      const currentSurveyData = surveySnapshot.data();

      transaction.set(surveyRef, survey, { merge: true });
      transaction.set(versionRef, surveyVersion);

      return currentSurveyData;
    });

    return async () => {
      await db.runTransaction(async (transaction) => {
        transaction.set(surveyRef, rollbackSurveyData);
        transaction.delete(versionRef);
      });
    };
  } catch (error) {
    console.error(error);

    const errorMessage = firebaseError(error.code, locale);
    throw new Error(errorMessage);
  }
};
