/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Card = ({ visible, children }) => (
  <div
    id="children-placeholder"
    className={classNames('card', {
      'survey-item--hidden': !visible,
    })}
  >
    {children}
  </div>
);

Card.propTypes = {
  visible: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default Card;
