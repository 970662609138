import React from 'react';

import Chart from '../Chart';

const Charts = React.memo(({ charts, columnId }) =>
  charts.map((chart, index) => (
    <Chart key={chart.id} chart={chart} index={index} columnId={columnId} />
  ))
);

export default Charts;
