import React, { useState } from 'react';

import Checkbox from 'components/Checkbox';
import NewModal from 'components/NewModal';

import classes from './BacktrackingModal.module.scss';

const BacktrackingModal = ({ isOpen, toggle, survey, setSurvey }) => {
  const { pagesOrder, pages } = survey;

  const [pagesWithBacktracking, setPagesWithBacktracking] = useState(() => {
    const pagesArray = Object.entries(pages);

    const newPagesWithBacktracking = {};

    if (pagesArray.length) {
      pagesArray.forEach(([pageId, page]) => {
        newPagesWithBacktracking[pageId] = !!page?.preventBacktracking;
      });
    }

    return newPagesWithBacktracking;
  });

  const onChangePageBacktrackingState = ({ pageId }) =>
    setPagesWithBacktracking((prevState) => ({
      ...prevState,
      [pageId]: !prevState[pageId],
    }));

  const onSubmitBacktrackingChanges = () => {
    setSurvey((prevState) => {
      const editedPages = { ...prevState.pages };

      Object.entries(pagesWithBacktracking).forEach(
        ([pageId, preventBacktracking]) => {
          editedPages[pageId] = { ...editedPages[pageId], preventBacktracking };
        }
      );

      return {
        ...prevState,
        pages: {
          ...editedPages,
        },
      };
    });

    toggle();
  };

  return (
    <NewModal
      isOpen={isOpen}
      toggle={toggle}
      title="Backtracking"
      showSubmitButton
      submitButtonProps={{
        onSubmit: onSubmitBacktrackingChanges,
        canSubmit: true,
        loading: false,
        buttonText: 'Apply Changes',
      }}
    >
      <div className={classes.container}>
        {pagesOrder.map((pageId, index) => {
          const pageTitle = pages[pageId].title?.en || `Page ${index + 1}`;
          if (index === pagesOrder.length - 1) {
            return null;
          }

          return (
            <div key={pageId} className={classes['page-block']}>
              {index + 1}. {pageTitle}
              <div
                className={classes['prevent-backtracking-block']}
                onClick={() => onChangePageBacktrackingState({ pageId })}
              >
                Prevent Backtracking:
                <Checkbox
                  name={pageId}
                  checked={pagesWithBacktracking[pageId]}
                  onChange={() => onChangePageBacktrackingState({ pageId })}
                />
              </div>
            </div>
          );
        })}
      </div>
    </NewModal>
  );
};

export default BacktrackingModal;
