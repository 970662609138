/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

import { SurveyElement } from 'utils/surveyBuilder/enums';
import DropArea from 'components/DragAndDrop/DropArea';
import DragItem from 'components/DragAndDrop/DragItem';
import EmptyMessage from 'components/EmptyMessage';

import Question from './Question';

const Questions = ({
  questions,
  surveyLanguage,
  onChangeQuestion,
  onChangeRequired,
  onChangeVisibility,
  onDeleteQuestion,
  onDeleteTag,
  deploymentId,
  onEditQuestion,
  editedQuestion,
}) => (
  <DropArea dropId="question-group-questions" type={SurveyElement.QUESTION_GROUP}>
    {questions.map(({ content, richText, tags, visible, required, id }, index) => (
      <DragItem key={id} dragId={id} index={index}>
        <Question
          key={`${content}.${index}`}
          id={index.toString()}
          index={index}
          content={content[surveyLanguage]}
          richText={richText?.[surveyLanguage]}
          visible={visible}
          tags={tags}
          number={index + 1}
          onChange={onChangeQuestion}
          onChangeVisibility={onChangeVisibility}
          onDelete={onDeleteQuestion}
          onDeleteTag={onDeleteTag}
          required={required}
          onChangeRequired={onChangeRequired}
          deploymentId={deploymentId}
          onEdit={onEditQuestion}
          questionId={id}
          editedQuestion={editedQuestion}
        />
      </DragItem>
    ))}
    {questions.length === 0 && (
      <EmptyMessage message="There are no questions" />
    )}
  </DropArea>
);

Questions.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  surveyLanguage: PropTypes.string.isRequired,
  onChangeQuestion: PropTypes.func.isRequired,
  onChangeVisibility: PropTypes.func.isRequired,
  onDeleteQuestion: PropTypes.func.isRequired,
  onDeleteTag: PropTypes.func.isRequired,
  onChangeRequired: PropTypes.func.isRequired,
};

export default Questions;
