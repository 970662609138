import { createReducer } from 'redux-act';

import {
  SURVEYS_CREATE_SURVEY_INIT,
  SURVEYS_CREATE_SURVEY_SUCCESS,
  SURVEYS_CREATE_SURVEY_FAIL,
  SURVEYS_CLEAR_STATE,
  SURVEYS_FETCH_DATA_INIT,
  SURVEYS_FETCH_DATA_SUCCESS,
  SURVEYS_FETCH_DATA_FAIL,
  SURVEYS_UPDATE_SURVEY_INIT,
  SURVEYS_UPDATE_SURVEY_SUCCESS,
  SURVEYS_UPDATE_SURVEY_FAIL,
  SURVEYS_CREATE_SURVEY_VERSION_INIT,
  SURVEYS_CREATE_SURVEY_VERSION_SUCCESS,
  SURVEYS_CREATE_SURVEY_VERSION_FAIL,
  SURVEYS_SELECT_SURVEY_VERSION,
  SURVEYS_CLEAR_SELECTED_VERSION,
  SURVEYS_CREATE_DEPLOYMENT_INIT,
  SURVEYS_CREATE_DEPLOYMENT_SUCCESS,
  SURVEYS_CREATE_DEPLOYMENT_FAIL,
  SURVEYS_DELETE_DEPLOYMENT_INIT,
  SURVEYS_DELETE_DEPLOYMENT_SUCCESS,
  SURVEYS_DELETE_DEPLOYMENT_FAIL,
  SURVEYS_FETCH_DEPLOYMENT_INIT,
  SURVEYS_FETCH_DEPLOYMENT_SUCCESS,
  SURVEYS_FETCH_DEPLOYMENT_FAIL,
  SURVEYS_CREATE_SURVEY_REPONSE_INIT,
  SURVEYS_CREATE_SURVEY_REPONSE_SUCCESS,
  SURVEYS_CREATE_SURVEY_REPONSE_FAIL,
  SURVEYS_CREATE_ORGANIZATION_SURVEY_FROM_TEMPLATE_INIT,
  SURVEYS_CREATE_ORGANIZATION_SURVEY_FROM_TEMPLATE_SUCCESS,
  SURVEYS_CREATE_ORGANIZATION_SURVEY_FROM_TEMPLATE_FAIL,
  SURVEYS_UPDATE_CLEAR_STATE,
  SURVEYS_CREATED_DEPLOYMENT_CLEAR_STATE,
  SURVEYS_FETCH_DEPLOYMENTS_INIT,
  SURVEYS_FETCH_DEPLOYMENTS_SUCCESS,
  SURVEYS_FETCH_DEPLOYMENTS_FAIL,
  SURVEYS_FETCH_PREVIEW_INIT,
  SURVEYS_FETCH_PREVIEW_SUCCESS,
  SURVEYS_UPDATE_DEPLOYMENT_FAIL,
  SURVEYS_UPDATE_DEPLOYMENT_INIT,
  SURVEYS_UPDATE_DEPLOYMENT_SUCCESS,
  SURVEYS_UPDATE_DEPLOYMENTS_HITS_FAIL,
  SURVEYS_UPDATE_DEPLOYMENTS_HITS_INIT,
  SURVEYS_UPDATE_DEPLOYMENTS_HITS_SUCCESS,
  SURVEYS_PREVIEW_CLEANUP,
  SURVEYS_COPY_SURVEY_INIT,
  SURVEYS_COPY_SURVEY_SUCCESS,
  SURVEYS_COPY_SURVEY_FAIL,
  SURVEYS_COPY_SURVEY_CLEAN_UP,
  SURVEYS_UPDATE_DEPLOYMENT_START_AND_END_DATES_INIT,
  SURVEYS_UPDATE_DEPLOYMENT_START_AND_END_DATES_FAIL,
  SURVEYS_UPDATE_DEPLOYMENT_START_AND_END_DATES_SUCCESS,
  SURVEYS_UPDATE_DEPLOYMENT_START_AND_END_DATES_CLEAR_STATE,
  SURVEYS_CREATE_SURVEY_RESPONSE_SAVE_INIT,
  SURVEYS_CREATE_SURVEY_RESPONSE_SAVE_SUCCESS,
  SURVEYS_CREATE_SURVEY_RESPONSE_SAVE_FAIL,
  SURVEYS_FETCH_SURVEY_RESPONSE_SAVE_INIT,
  SURVEYS_FETCH_SURVEY_RESPONSE_SAVE_SUCCESS,
  SURVEYS_FETCH_SURVEY_RESPONSE_SAVE_FAIL,
  SURVEYS_RESPONSE_SAVE_STATE_CLEAN_UP,
  SURVEYS_LOAD_RESPONSE_SAVE_STATE_CLEAN_UP,
} from '../../actionCreators/surveys';

export const initialState = {
  data: [],
  responseInProgress: null,
  preview: null,
  deployment: null,
  loading: false,
  error: null,
  errorFetching: null,
  success: false,
  updated: false,
  fetched: false,
  creatingVersion: false,
  selectedVersion: null,
  creatingDeployment: false,
  updatingDeployment: false,
  createdDeployment: false,
  creatingResponse: false,
  createdResponse: false,
  creatingFromTemplate: false,
  createdFromTemplate: null,
  deletingDeployment: false,
  deletedDeployment: false,
  updatingDeploymentHits: false,
  successUpdatingDeploymentDates: false,
  updatingDeploymentDates: false,
  creatingSurveyCopy: false,
  createdSurveyCopy: false,
  errorCreatingSurveyCopy: null,
  creatingResponseSave: false,
  successCreatingResponseSave: false,
  fetchingResponse: false,
  successFetchingResponse: false,
  errorFetchingResponse: null,
};

export const surveyReducer = createReducer(
  {
    [SURVEYS_CREATE_SURVEY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [SURVEYS_CREATE_SURVEY_SUCCESS]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
    }),
    [SURVEYS_CREATE_SURVEY_FAIL]: (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
    [SURVEYS_FETCH_DATA_INIT]: (state) => ({
      ...state,
      loading: true,
      fetched: false,
    }),
    [SURVEYS_FETCH_DATA_SUCCESS]: (state, { surveys }) => ({
      ...state,
      data: surveys,
      loading: false,
      fetched: true,
      error: null,
    }),
    [SURVEYS_FETCH_DATA_FAIL]: (state, { error }) => ({
      ...state,
      loading: false,
      fetched: true,
      errorFetching: error,
    }),
    [SURVEYS_UPDATE_SURVEY_INIT]: (state) => ({
      ...state,
      loading: true,
      updated: false,
    }),
    [SURVEYS_UPDATE_SURVEY_SUCCESS]: (state) => ({
      ...state,
      loading: false,
      updated: true,
      error: null,
    }),
    [SURVEYS_UPDATE_SURVEY_FAIL]: (state, { error }) => ({
      ...state,
      loading: false,
      updated: false,
      error,
    }),
    [SURVEYS_CREATE_SURVEY_VERSION_INIT]: (state) => ({
      ...state,
      creatingVersion: true,
    }),
    [SURVEYS_CREATE_SURVEY_VERSION_SUCCESS]: (state) => ({
      ...state,
      error: null,
      creatingVersion: false,
      fetched: false,
    }),
    [SURVEYS_CREATE_SURVEY_VERSION_FAIL]: (state, { error }) => ({
      ...state,
      error,
      creatingVersion: false,
    }),
    [SURVEYS_SELECT_SURVEY_VERSION]: (state, { version }) => ({
      ...state,
      selectedVersion: version,
    }),
    [SURVEYS_CLEAR_SELECTED_VERSION]: (state) => ({
      ...state,
      selectedVersion: null,
    }),
    [SURVEYS_CREATE_DEPLOYMENT_INIT]: (state) => ({
      ...state,
      creatingDeployment: true,
      createdDeployment: false,
    }),
    [SURVEYS_CREATE_DEPLOYMENT_SUCCESS]: (state) => ({
      ...state,
      creatingDeployment: false,
      createdDeployment: true,
      error: null,
    }),
    [SURVEYS_CREATE_DEPLOYMENT_FAIL]: (state, { error }) => ({
      ...state,
      creatingDeployment: false,
      createdDeployment: false,
      error,
    }),
    [SURVEYS_COPY_SURVEY_INIT]: (state) => ({
      ...state,
      creatingSurveyCopy: true,
      createdSurveyCopy: false,
    }),
    [SURVEYS_COPY_SURVEY_SUCCESS]: (state) => ({
      ...state,
      creatingSurveyCopy: false,
      createdSurveyCopy: true,
      errorCreatingSurveyCopy: null,
    }),
    [SURVEYS_COPY_SURVEY_FAIL]: (state, { error }) => ({
        ...state,
        creatingSurveyCopy: false,
        createdSurveyCopy: false,
        errorCreatingSurveyCopy: error,
    }),
    [SURVEYS_COPY_SURVEY_CLEAN_UP]: (state) => ({
      ...state,
      creatingSurveyCopy: false,
      createdSurveyCopy: false,
      errorCreatingSurveyCopy: null,
    }),
    [SURVEYS_DELETE_DEPLOYMENT_INIT]: (state) => ({
      ...state,
      deletingDeployment: true,
      deletedDeployment: false,
    }),
    [SURVEYS_DELETE_DEPLOYMENT_SUCCESS]: (state) => ({
      ...state,
      deletingDeployment: false,
      deletedDeployment: true,
      error: null,
    }),
    [SURVEYS_DELETE_DEPLOYMENT_FAIL]: (state, { error }) => ({
      ...state,
      deletingDeployment: false,
      deletedDeployment: false,
      error,
    }),
    [SURVEYS_UPDATE_DEPLOYMENT_INIT]: (state) => ({
      ...state,
      updatingDeployment: true,
    }),
    [SURVEYS_UPDATE_DEPLOYMENT_SUCCESS]: (state) => ({
      ...state,
      updatingDeployment: false,
      error: null,
    }),
    [SURVEYS_UPDATE_DEPLOYMENT_FAIL]: (state, { error }) => ({
      ...state,
      updatingDeployment: false,
      error,
    }),
    [SURVEYS_UPDATE_DEPLOYMENT_START_AND_END_DATES_INIT]: (state) => ({
      ...state,
      successUpdatingDeploymentDates: false,
      updatingDeploymentDates: true,
      error: null,
    }),
    [SURVEYS_UPDATE_DEPLOYMENT_START_AND_END_DATES_FAIL]: (state, { error }) => ({
      ...state,
      updatingDeploymentDates: false,
      error,
    }),
    [SURVEYS_UPDATE_DEPLOYMENT_START_AND_END_DATES_SUCCESS]: (state) => ({
      ...state,
      successUpdatingDeploymentDates: true,
      updatingDeploymentDates: false,
      error: null,
    }),
    [SURVEYS_UPDATE_DEPLOYMENT_START_AND_END_DATES_CLEAR_STATE]: (state) => ({
      ...state,
      successUpdatingDeploymentDates: false,
      updatingDeploymentDates: false,
      error: null,
    }),
    [SURVEYS_FETCH_DEPLOYMENT_INIT]: (state) => ({
      ...state,
      loading: true,
      createdResponse: false,
    }),
    [SURVEYS_FETCH_DEPLOYMENT_SUCCESS]: (state, { deployment }) => ({
      ...state,
      deployment,
      loading: false,
      errorFetching: null,
    }),
    [SURVEYS_FETCH_DEPLOYMENT_FAIL]: (state, { error }) => ({
      ...state,
      deployment: null,
      loading: false,
      errorFetching: error,
    }),
    [SURVEYS_FETCH_SURVEY_RESPONSE_SAVE_INIT]: (state) => ({
      ...state,
      fetchingResponse: true,
      successFetchingResponse: false,
    }),
    [SURVEYS_FETCH_SURVEY_RESPONSE_SAVE_SUCCESS]: (state, { responseInProgress }) => ({
      ...state,
      responseInProgress,
      fetchingResponse: false,
      successFetchingResponse: true,
      errorFetchingResponse: null,
    }),
    [SURVEYS_FETCH_SURVEY_RESPONSE_SAVE_FAIL]: (state, { error }) => ({
      ...state,
      responseInProgress: null,
      fetchingResponse: false,
      errorFetchingingResponse: error,
    }),
    [SURVEYS_CREATE_SURVEY_REPONSE_INIT]: (state) => ({
      ...state,
      creatingResponse: true,
      createdResponse: false,
    }),
    [SURVEYS_CREATE_SURVEY_REPONSE_SUCCESS]: (state) => ({
      ...state,
      creatingResponse: false,
      createdResponse: true,
      error: null,
    }),
    [SURVEYS_CREATE_SURVEY_REPONSE_FAIL]: (state, { error }) => ({
      ...state,
      creatingResponse: false,
      createdResponse: false,
      error,
    }),
    [SURVEYS_CREATE_SURVEY_RESPONSE_SAVE_INIT]: (state) => ({
      ...state,
      creatingResponseSave: true,
      successCreatingResponseSave: false,
    }),
    [SURVEYS_CREATE_SURVEY_RESPONSE_SAVE_SUCCESS]: (state, { responseCode }) => ({
      ...state,
      creatingResponseSave: false,
      successCreatingResponseSave: true,
      responseCode,
      error: null,
    }),
    [SURVEYS_CREATE_SURVEY_RESPONSE_SAVE_FAIL]: (state, { error }) => ({
      ...state,
      creatingResponseSave: false,
      successCreatingResponseSave: false,
      error,
    }),
    [SURVEYS_CREATE_ORGANIZATION_SURVEY_FROM_TEMPLATE_INIT]: (state) => ({
      ...state,
      creatingFromTemplate: true,
      createdFromTemplate: null,
    }),
    [SURVEYS_CREATE_ORGANIZATION_SURVEY_FROM_TEMPLATE_SUCCESS]: (
      state,
      { surveyId, versionId }
    ) => ({
      ...state,
      creatingFromTemplate: false,
      createdFromTemplate: {
        surveyId,
        versionId,
      },
      error: null,
    }),
    [SURVEYS_CREATE_ORGANIZATION_SURVEY_FROM_TEMPLATE_FAIL]: (
      state,
      { error }
    ) => ({
      ...state,
      creatingFromTemplate: false,
      createdFromTemplate: null,
      error,
    }),
    [SURVEYS_UPDATE_CLEAR_STATE]: (state) => ({
      ...state,
      updated: false,
    }),
    [SURVEYS_CREATED_DEPLOYMENT_CLEAR_STATE]: (state) => ({
      ...state,
      createdDeployment: false,
    }),
    [SURVEYS_FETCH_DEPLOYMENTS_INIT]: (state) => ({
      ...state,
      data: [],
      loading: true,
      fetched: false,
    }),
    [SURVEYS_FETCH_DEPLOYMENTS_SUCCESS]: (state, { deployments }) => ({
      ...state,
      loading: false,
      fetched: true,
      error: null,
      data: deployments,
    }),
    [SURVEYS_FETCH_DEPLOYMENTS_FAIL]: (state, { error }) => ({
      ...state,
      loading: false,
      fetched: true,
      preview: null,
      error,
    }),
    [SURVEYS_FETCH_PREVIEW_INIT]: (state) => ({
      ...state,
      preview: null,
      loading: true,
      fetched: false,
    }),
    [SURVEYS_FETCH_PREVIEW_SUCCESS]: (state, { preview }) => ({
      ...state,
      loading: false,
      fetched: true,
      error: null,
      preview,
    }),
    [SURVEYS_PREVIEW_CLEANUP]: (state) => ({
      ...state,
      preview: null,
    }),
    [SURVEYS_UPDATE_DEPLOYMENTS_HITS_INIT]: (state) => ({
      ...state,
      updatingDeploymentHits: true,
    }),
    [SURVEYS_UPDATE_DEPLOYMENTS_HITS_SUCCESS]: (state) => ({
      ...state,
      updatingDeploymentHits: false,
    }),
    [SURVEYS_UPDATE_DEPLOYMENTS_HITS_FAIL]: (state, { error }) => ({
      ...state,
      error,
      updatingDeploymentHits: false,
    }),
    [SURVEYS_RESPONSE_SAVE_STATE_CLEAN_UP]: (state) => ({
      ...state,
      responseCode: null,
      errorCreatingSurveyCopy: null,
      creatingResponseSave: false,
      successCreatingResponseSave: false,
    }),
    [SURVEYS_LOAD_RESPONSE_SAVE_STATE_CLEAN_UP]: (state) => ({
      ...state,
      responseInProgress: null,
      fetchingResponse: false,
      successFetchingResponse: false,
      errorFetchingResponse: null,
    }),
    [SURVEYS_CLEAR_STATE]: () => initialState,
  },
  initialState
);
