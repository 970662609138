import { useState } from 'react';

const useNewModal = (openInitialState = false) => {
  const [isOpen, setIsOpen] = useState(openInitialState);

  const toggle = () => setIsOpen((prevState) => !prevState);

  const openModal = () => setIsOpen(true);

  const closeModal = () => setIsOpen(false);

  return { isOpen, toggle, openModal, closeModal };
};

export default useNewModal;
