import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { useFormatMessage } from 'hooks';

import './Pagination.scss';

const Pagination = ({
  loading,
  pageIndex,
  disablePreviousPagesGoBackFrom,
  disableNextPagesGoAheadOf,
  actualPages,
  onNextPage,
  onPreviousPage,
  onGoToPage,
  totalPages,
}) => {
  const showNextPageInPagination = actualPages - 1 !== pageIndex;
  const areMorePages = pageIndex + 1 < totalPages;
  const isFinalPage = pageIndex + 1 === totalPages;

  const nextPageTranslated = useFormatMessage('LiveSurvey.nextPage');
  const previousPageTranslated = useFormatMessage('LiveSurvey.previousPage');
  const submitTranslated = useFormatMessage('LiveSurvey.submit');

  return (
    <nav
      className="pagination is-right"
      role="navigation"
      aria-label="pagination"
    >
      <button
        id="previous-page-button"
        type="button"
        className="button pagination-previous is-info"
        disabled={
          pageIndex === 0 || pageIndex - 1 <= disablePreviousPagesGoBackFrom
        }
        onClick={
          pageIndex === 0 || pageIndex - 1 <= disablePreviousPagesGoBackFrom
            ? null
            : onPreviousPage
        }
      >
        {previousPageTranslated}
      </button>
      {areMorePages && (
        <button
          id="next-page-button"
          type="button"
          className="button pagination-next is-info"
          onClick={onNextPage}
        >
          {nextPageTranslated}
        </button>
      )}
      {isFinalPage && (
        <button
          id="submit-button"
          type="submit"
          className={classNames('button pagination-next is-info', {
            'is-loading': loading,
          })}
        >
          {submitTranslated}
        </button>
      )}
      <ul className="pagination-list">
        {pageIndex !== 0 && (
          <li>
            <button
              id="pagination-button"
              type="button"
              className="pagination-link"
              onClick={() =>
                disablePreviousPagesGoBackFrom >= 0 ? null : onGoToPage(0)
              }
              disabled={disablePreviousPagesGoBackFrom >= 0}
            >
              1
            </button>
          </li>
        )}
        {pageIndex > 3 && (
          <li>
            <span className="pagination-ellipsis">…</span>
          </li>
        )}
        {pageIndex === 3 && (
          <li>
            <button
              id="pagination-button"
              type="button"
              className="pagination-link"
              disabled={disablePreviousPagesGoBackFrom >= 1}
              onClick={() =>
                disablePreviousPagesGoBackFrom >= 1 ? null : onGoToPage(1)
              }
            >
              2
            </button>
          </li>
        )}
        {pageIndex - 1 > 0 && (
          <li>
            <button
              id="pagination-button"
              type="button"
              className="pagination-link"
              disabled={pageIndex - 1 <= disablePreviousPagesGoBackFrom}
              onClick={() =>
                pageIndex - 1 <= disablePreviousPagesGoBackFrom
                  ? null
                  : onPreviousPage()
              }
            >
              {pageIndex}
            </button>
          </li>
        )}
        <li>
          <button
            id="pagination-button"
            type="button"
            className="pagination-link is-current"
            aria-current="true"
          >
            {pageIndex + 1}
          </button>
        </li>
        {showNextPageInPagination && (
          <li>
            <button
              id="pagination-button"
              type="button"
              className="pagination-link"
              disabled={pageIndex <= disablePreviousPagesGoBackFrom}
              onClick={() => onNextPage()}
            >
              {pageIndex + 2}
            </button>
          </li>
        )}
        {actualPages - pageIndex === 4 && (
          <li>
            <button
              id="pagination-button"
              type="button"
              className="pagination-link"
              disabled={actualPages - 2 >= disableNextPagesGoAheadOf}
              onClick={() =>
                actualPages - 2 >= disableNextPagesGoAheadOf
                  ? null
                  : onGoToPage(actualPages - 2)
              }
            >
              {actualPages - 1}
            </button>
          </li>
        )}
        {actualPages - pageIndex > 4 && (
          <li>
            <span className="pagination-ellipsis">…</span>
          </li>
        )}
        {pageIndex + 2 < actualPages && (
          <li>
            <button
              id="pagination-button"
              type="button"
              className="pagination-link"
              disabled={actualPages - 1 >= disableNextPagesGoAheadOf}
              onClick={() =>
                actualPages - 1 >= disableNextPagesGoAheadOf
                  ? null
                  : onGoToPage(actualPages - 1)
              }
            >
              {actualPages}
            </button>
          </li>
        )}
      </ul>
    </nav>
  );
};

Pagination.propTypes = {
  loading: PropTypes.bool.isRequired,
  pageIndex: PropTypes.number.isRequired,
  actualPages: PropTypes.number.isRequired,
  disablePreviousPagesGoBackFrom: PropTypes.number.isRequired,
  disableNextPagesGoAheadOf: PropTypes.number.isRequired,
  onNextPage: PropTypes.func.isRequired,
  onGoToPage: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
};

export default Pagination;
