/* eslint-disable no-param-reassign */
import { v4 as uuid } from 'uuid';

import { paginateArray } from 'utils';
import { AvailableActions, TagType } from 'utils/enums';
import {
  groupsCollection,
  questionChoicesCollection,
} from 'utils/firebase/surveys';

export const demographicsAssign = (demographics, questionDemographics) => {
  const newDemographics = [];
  // Iteration on the current demographics tags.
  // If the tagId and waveId are the same, merge the choices of the tag in the one that's already created.
  // If the tag is different, then add the demographic tag to the demographics array to be created.
  if (questionDemographics.length > 0) {
    if (demographics.length === 0) {
      newDemographics.push(...questionDemographics);
    } else {
      questionDemographics.forEach((questionDemographic) => {
        const sameQuestionDemographic = demographics.find(
          ({ demographic }) =>
            demographic.tagId === questionDemographic.demographic.tagId &&
            demographic.waveId === questionDemographic.demographic.waveId
        );

        if (sameQuestionDemographic) {
          const newContent = [...sameQuestionDemographic.demographic.content];

          questionDemographic.demographic.content.forEach((option) => {
            if (!newContent.includes(option)) {
              newContent.push(option);
            }
          });

          sameQuestionDemographic.demographic.content = newContent;
        }

        if (!sameQuestionDemographic) {
          newDemographics.push(questionDemographic);
        }
      });
    }
  }

  return newDemographics;
};

export const newActionsQuestionAssign = (
  newActions,
  oldQuestionId,
  questionId
) => {
  for (let index = 0; index < newActions.length; index += 1) {
    const { actions: oldActions, conditions: oldConditions } = newActions[index];
    
    for (let indexAction = 0; indexAction < oldActions.length; indexAction += 1) {
      const action = oldActions[indexAction];
      
      if (action.action === AvailableActions.RANDOMIZE_QUESTIONS) {
        action.subject.forEach((subjectId, indexSubject) => {
          if (subjectId === oldQuestionId) {
            newActions[index].actions[indexAction].subject[
              indexSubject
            ] = questionId;
          }
        });
      }

      if (action.subject === oldQuestionId) {
        newActions[index].actions[indexAction].subject = questionId;
      }
    }

    for (let indexCondition = 0; indexCondition < oldConditions.length; indexCondition += 1) {
      const condition = oldConditions[indexCondition];
      
      if (condition.subject === oldQuestionId) {
        newActions[index].conditions[indexCondition].subject =
          questionId;
      }
    }
  }

  // const newActionsArray = [];

  // newActions.forEach(
  //   ({ actions: oldActions, conditions: oldConditions }, index) => {
  //     oldActions.forEach((action, indexAction) => {
  //       if (action.action === AvailableActions.RANDOMIZE_QUESTIONS) {
  //         action.subject.forEach((subjectId, indexSubject) => {
  //           if (subjectId === oldQuestionId) {
  //             newActionsArray[index].actions[indexAction].subject[
  //               indexSubject
  //             ] = questionId;
  //           }
  //         });
  //       }

  //       if (action.subject === oldQuestionId) {
  //         newActionsArray[index].actions[indexAction].subject = questionId;
  //       }
  //     });

  //     oldConditions.forEach((condition, indexCondition) => {
  //       if (condition.subject === oldQuestionId) {
  //         newActionsArray[index].conditions[indexCondition].subject =
  //           questionId;
  //       }
  //     });
  //   }
  // );

  // return newActionsArray;
};

export const newActionQuestionGroupAssign = (
  newActions,
  oldQuestionId,
  questionInGroupId
) => {
  newActions.forEach(
    ({ actions: oldActions, conditions: oldConditions }, index) => {
      oldActions.forEach((action, indexAction) => {
        if (action.action === AvailableActions.RANDOMIZE_QUESTIONS) {
          action.subject.forEach((subjectId, indexSubject) => {
            if (subjectId === oldQuestionId) {
              newActions[index].actions[indexAction].subject[indexSubject] =
                questionInGroupId;
            }
          });
        }

        if (action.subject === oldQuestionId) {
          newActions[index].actions[indexAction].subject = questionInGroupId;
        }
      });

      oldConditions.forEach((condition, indexCondition) => {
        if (condition.subject === oldQuestionId) {
          newActions[index].conditions[indexCondition].subject =
            questionInGroupId;
        }
      });
    }
  );
};

const fetchGroupsByName = async (organizationName, groupsNames) => {
  const groups = [];

  if (groupsNames.length > 0) {
    const groupsPromises = groupsNames.map((groupName) =>
      groupsCollection
        .where('organizationName', '==', organizationName)
        .where('name', '==', groupName.toLowerCase().trim())
        .get()
    );

    const groupsResult = await Promise.all(groupsPromises);

    groupsResult.forEach((group) => {
      if (!group.empty) {
        const groupData = { id: group.docs[0].id, ...group.docs[0].data() };
        groups.push(groupData);
      }
    });
  }

  return groups;
};

const fetchQuestionChoicesById = async (questionChoicesIds) => {
  const questionChoices = [];

  if (questionChoicesIds.length > 0) {
    const questionChoicesPromises = questionChoicesIds.map((questionChoiceId) =>
      questionChoicesCollection.doc(questionChoiceId).get()
    );

    const questionChoicesResult = await Promise.all(questionChoicesPromises);

    questionChoicesResult.forEach((questionChoice) => {
      if (questionChoice.exists) {
        const questionChoiceData = {
          id: questionChoice.id,
          ...questionChoice.data(),
        };
        questionChoices.push(questionChoiceData);
      }
    });
  }

  return questionChoices;
};

export const getGroupsForRegionalBreakdownChoices = async (
  organizationName,
  questionResponses
) => {
  const answeredChoicesIds = [];

  Object.values(questionResponses).forEach(({ choices, regionalBreakdown }) => {
    if (regionalBreakdown) {
      Object.entries(choices).forEach(([choiceId, value]) => {
        if (value) {
          answeredChoicesIds.push(choiceId);
        }
      });
    }
  });

  const questionChoices = await fetchQuestionChoicesById(answeredChoicesIds);

  const questionChoicesValues = questionChoices.map(({ option }) => option.en);

  const groups = await fetchGroupsByName(
    organizationName,
    questionChoicesValues
  );

  return groups.map((group) => group.id);
};

export const getQuestionDemographics = (
  waveId,
  tags,
  choices,
  previousDemographics
) => {
  const demographicTags = tags.filter(
    ({ type }) => type === TagType.DEMOGRAPHIC
  );

  const content = choices.flatMap(({ option, questionChoices }) => {
    
    if (questionChoices) {
      return questionChoices.map(({ option: choiceOption }) => choiceOption.en);
    }

    return option.en;
  });

  if (previousDemographics.length === 0) {
    return demographicTags.map(({ id: tagId, displayName: tagName }) => ({
      id: uuid(),
      demographic: {
        tagId,
        tagName,
        waveId,
        content,
      },
    }));
  }

  return demographicTags.map(({ id: tagId, displayName: tagName }) => {
    let demographic = null;

    const samePrevDemographic = previousDemographics.filter(
      (prevDemographic) =>
        prevDemographic.tagId === tagId && prevDemographic.waveId === waveId
    );

    if (samePrevDemographic.length > 0) {
      const newChoices = [...content];
      samePrevDemographic[0].content.forEach((option) => {
        if (!content.includes(option)) {
          newChoices.push(option);
        }
      });
      demographic = {
        id: samePrevDemographic[0].id,
        demographic: {
          tagId,
          tagName,
          waveId,
          content: newChoices,
        },
      };
    } else {
      demographic = {
        id: uuid(),
        demographic: {
          tagId,
          tagName,
          waveId,
          content,
        },
      };
    }

    return demographic;
  });
};

export const getAllGroups = async (organizationName) => {
  const groups = [];

  const queryResult = await groupsCollection
    .where('active', '==', true)
    .where('organizationName', '==', organizationName)
    .get();

  queryResult.forEach((group) => {
    const groupData = { id: group.id, group: { ...group.data() } };
    groups.push(groupData);
  });

  return groups;
};

export const checkForDuplicatedGroups = (
  groupOrganization,
  groupName,
  groupList
) =>
  groupList.find(
    ({ group }) =>
      group.organizationName === groupOrganization && group.name === groupName
  );

export const getGroupsToCreate = async (
  questionChoices,
  waveId,
  userData,
  organizationName
) => {
  const firestoreGroups = await getAllGroups(organizationName);
  const groups = [];

  questionChoices.forEach(({ choice }) => {
    const groupData = {
      id: uuid(),
      group: {
        organizationName,
        name: choice.option.en.toLowerCase().trim(),
        displayName: choice.option.en.trim(),
        description: '',
        manager: null,
        active: true,
        expectedCount: null,
        waves: [waveId],
        createdAt: null,
        createdBy: `${userData.firstName} ${userData.lastName}`,
        usersCount: 0,
      },
    };

    // check for duplicated groups in the current array to create
    const isCurrentGroupDuplicated = checkForDuplicatedGroups(
      organizationName,
      groupData.group.name,
      groups
    );

    // check if the group is already created in firestore
    const groupExistsInFirestore = checkForDuplicatedGroups(
      organizationName,
      groupData.group.name,
      firestoreGroups
    );

    if (!isCurrentGroupDuplicated && !groupExistsInFirestore) {
      groups.push(groupData);
    }

    if (groupExistsInFirestore) {
      const waveIdAlreadyExists =
        groupExistsInFirestore.group.waves.includes(waveId);

      if (!waveIdAlreadyExists) {
        groupExistsInFirestore.group.waves.push(waveId);

        groups.push(groupExistsInFirestore);
      }
    }
  });

  return groups;
};

export const getChoicesFromDataSets = async (dataSetIds) => {
  const dataSetChoices = [];

  const uniqueDataSetIds = [...new Set(dataSetIds)];

  const paginatedDataSetIds = paginateArray(uniqueDataSetIds, 10);

  const promises = paginatedDataSetIds.flatMap((dataSets) =>
    questionChoicesCollection.where('dataSetId', 'in', dataSets).get()
  );

  const promisesResult = await Promise.all(promises);

  const queryResult = promisesResult.flatMap((result) => result.docs);

  queryResult.forEach((choice) => {
    const choiceData = { id: choice.id, choice: { ...choice.data() } };

    dataSetChoices.push(choiceData);
  });

  return dataSetChoices;
};
