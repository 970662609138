/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';

import classes from './QuestionTag.module.scss';

const QuestionTag = ({ tag, onRemove }) => (
  <div className={classNames('tags has-addons', classes['tag-container'])}>
    <span
      id="tag-name-placeholder"
      className={classNames('tag', classes['tag-name'])}
    >
      {`${tag.displayName} (${tag.type})`}
    </span>
    {tag.weight && (
      <span
        id="tag-weight-placeholder"
        className="tag is-primary"
        data-tip="Tag weight"
      >
        {tag.weight}
      </span>
    )}
    <a
      id="remove-tag"
      className={classNames('tag', 'is-delete', classes['delete-button'], {
        'is-primary': !tag.weight,
      })}
      onClick={() => onRemove(tag)}
    />
    <ReactTooltip place="bottom" />
  </div>
);

QuestionTag.propTypes = {
  tag: PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    weight: PropTypes.number,
  }).isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default QuestionTag;
