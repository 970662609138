/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { SurveyElement } from 'utils/surveyBuilder/enums';
import paths from 'pages/Router/paths';

import classes from './Translate.module.scss';

const Translate = ({
  translationCode,
  translation,
  defaultTranslation,
  missingTranslation,
  onUpdate,
}) => {
  const tabLimiterHandler = useCallback(
    (event) => {
      if (
        event.key === 'Tab' &&
        event.currentTarget.tabIndex >= translation.length
      ) {
        event.preventDefault();
      }
    },
    [translation]
  );

  const disableTabHandler = useCallback(
    (event) => event.key === 'Tab' && event.preventDefault(),
    []
  );

  return (
    <>
      {missingTranslation.length !== 0 && (
        <>
          <span className="label">Data sets missing translation:</span>
          <div className="content">
            <ul>
              {missingTranslation.map(({ id, name }) => (
                <li key={id}>
                  <a
                    href={`${paths.DATA_SETS}/${id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
      {translation.map((field, index) => (
        <div key={`${translationCode}.${index}`} className="field">
          <div className="field-body">
            <div className="field">
              {index === 0 && (
                <label className={classNames('label', classes.title)}>
                  Original
                </label>
              )}
              <div
                className={classNames('control', 'is-expanded', {
                  'has-icons-left': field.isFromDataSet,
                })}
              >
                <textarea
                  id="default-translation-field"
                  className="input"
                  value={defaultTranslation[index]}
                  onKeyDown={disableTabHandler}
                  readOnly
                />
                {field.isFromDataSet && (
                  <span className="icon is-medium is-left">
                    <i className="mdi mdi-database" />
                  </span>
                )}
              </div>
            </div>
            <div className="field">
              {index === 0 && (
                <label className={classNames('label', classes.title)}>
                  Translation
                </label>
              )}
              <div
                className={classNames('control', 'is-expanded', {
                  'has-icons-left': field.isFromDataSet,
                })}
              >
                <textarea
                  id="translation-field"
                  className="input"
                  placeholder={
                    (field.missingTranslation && 'Missing translation') ||
                    (!defaultTranslation[index] && 'Original is empty') ||
                    'Input translation...'
                  }
                  value={field.value}
                  readOnly={field.isFromDataSet || !defaultTranslation[index]}
                  onChange={(e) => {
                    const { value } = e.target;
                    onUpdate({
                      languageCode: translationCode,
                      elementType: field.type,
                      elementId: field.id,
                      choiceId: field.choiceId,
                      questionChoiceId: field.questionChoiceId,
                      updatedTranslation: value,
                    });
                  }}
                  onKeyDown={tabLimiterHandler}
                  tabIndex={index + 1}
                />
                {field.isFromDataSet && (
                  <span className="icon is-medium is-left">
                    <i className="mdi mdi-database" />
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

Translate.propTypes = {
  translationCode: PropTypes.string.isRequired,
  translation: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf([
        SurveyElement.SURVEY_TITLE,
        SurveyElement.QUESTION_CHOICE,
        SurveyElement.QUESTION,
        SurveyElement.TEXT_BOX,
        SurveyElement.SECTION,
        SurveyElement.PAGE,
      ]).isRequired,
      value: PropTypes.string.isRequired,
      id: PropTypes.string,
      choiceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      missingTranslation: PropTypes.bool,
      isFromDataSet: PropTypes.bool,
    })
  ).isRequired,
  defaultTranslation: PropTypes.arrayOf(PropTypes.string).isRequired,
  onUpdate: PropTypes.func.isRequired,
  missingTranslation: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Translate;
