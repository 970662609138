import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import userDefaultLogo from 'assets/user-default-logo-home.svg';

import classes from './WelcomeBanner.module.scss';

const WelcomeBanner = ({ userName, userLogo, children }) => (
  <section className={classNames('hero is-hero-bar', classes.banner)}>
    <div className="hero-body">
      <div className="level">
        <div className="level-left">
          <div className="level-item">
            <img
              id="user-logo-welcome-banner-place-holder"
              className={classNames(classes.avatar)}
              alt="User avatar"
              src={userLogo || userDefaultLogo}
            />
          </div>
          <div className="level-item">
            <div>
              <h1 className="title is-spaced">
                Welcome back,{' '}
                <span id="user-name-welcome-banner-place-holder">
                  {userName}
                </span>
                !
              </h1>
              <h2 className="subtitle">Presage Next Gen</h2>
            </div>
          </div>
        </div>
        <div className="level-rigth">
          <div id="children-welcome-banner-place-holder" className="level-item">
            {children}
          </div>
        </div>
      </div>
    </div>
  </section>
);

WelcomeBanner.propTypes = {
  userName: PropTypes.string.isRequired,
  userLogo: PropTypes.string,
};

WelcomeBanner.defaultProps = {
  userLogo: '',
};

export default WelcomeBanner;
