import React, { useCallback } from 'react';
import Select from 'react-select';
import { connectSortBy } from 'react-instantsearch-dom';
import PropTypes from 'prop-types';

const SortBy = ({ items, refine, defaultValue, isSearchable, isClearable }) => {
  const onChangeHandler = useCallback(
    (option) => refine(option.value),
    [refine]
  );
  return (
    <Select
      defaultValue={items[defaultValue]}
      options={items}
      onChange={onChangeHandler}
      isSearchable={isSearchable}
      isClearable={isClearable}
    />
  );
};

SortBy.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      isRefined: PropTypes.bool.isRequired,
    })
  ).isRequired,
  refine: PropTypes.func.isRequired,
  defaultValue: PropTypes.number,
  isSearchable: PropTypes.bool,
  isClearable: PropTypes.bool,
};

SortBy.defaultProps = {
  isClearable: false,
  isSearchable: false,
  defaultValue: 0,
};

export default connectSortBy(SortBy);
