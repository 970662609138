export const SurveyType = {
  TEMPLATE: 'template',
  ORGANIZATION: 'organization',
};

export const SurveyElement = Object.freeze({
  PAGE: 'page',
  SECTION: 'section',
  ITEM: 'item',
  QUESTION_BUILDER: 'question-builder',
  QUESTION_GROUP_BUILDER: 'question-group-builder',
  CREATE_DEPLOYMENT: 'create-deployment',
  QUESTION: 'question',
  QUESTION_GROUP: 'question-group',
  QUESTION_CHOICE: 'question-choice',
  QUESTION_CHOICE_DATASET_TITLE: 'question-choice-dataset-title',
  TEXT_BOX: 'text-box',
  SURVEY_TITLE: 'survey-title',
});

export const SurveyVersionStatus = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
  INACTIVE: 'inactive',
};

export const SurveyDeploymentStatus = {
  PENDING: 'pending',
  ACTIVE: 'active',
  CLOSED: 'closed',
  INACTIVE: 'inactive',
};

export const SurveyTableClickableColumn = {
  VERSIONS: 'versions',
  DEPLOYMENTS: 'deployments',
};

export const SurveyTypes = {
  VERSIONS: 'versions',
  DEPLOYMENTS: 'deployments',
};

export const QuestionType = {
  SINGLE_RESPONSE: 'single-response',
  MULTIPLE_REPONSE: 'multiple-response',
  SLIDER: 'slider',
  TEXT_AREA: 'text-area',
  TEXT_BOX: 'text-box',
  DROPDOWN: 'dropdown',
  RANK_ORDER: 'rank-order',
};
