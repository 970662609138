import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';

import paths from 'pages/Router/paths';
import firebase from 'firebase.js';

import { surveyDeploymentStatusDisplayText } from 'utils/surveyBuilder';
import getDeploymentStatus from 'utils/surveyBuilder/getDeploymentStatus';
import { SurveyDeploymentStatus } from 'utils/surveyBuilder/enums';
import { useFormatDate } from 'hooks';
import classes from './Deployment.module.scss';

const Deployment = ({
  organization,
  url,
  waveIndex,
  startDate,
  endDate,
  waveId,
  waveStatus,
  isLastElement,
  surveyId,
  deploymentId,
}) => {
  const startDateParsedToDate = new firebase.firestore.Timestamp(
    startDate.seconds,
    startDate.nanoseconds
  ).toDate();

  const endDateParsedToDate = endDate
    ? new firebase.firestore.Timestamp(
        endDate.seconds,
        endDate.nanoseconds
      ).toDate()
    : null;

  const deploymentStatus = getDeploymentStatus(
    startDateParsedToDate,
    endDateParsedToDate,
    waveStatus
  );

  const endDateFormatted = useFormatDate(endDateParsedToDate, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  return (
    <div
      className={classNames('columns is-vcentered is-multiline', {
        [classes['border-bottom']]: !isLastElement,
      })}
    >
      <div className="column deployment-atribute__container">
        <span className="deployment-attribute__title--mobile">
          <strong>Organization</strong>
        </span>
        {organization}
      </div>
      <div className="column deployment-atribute__container has-text-centered">
        <span className="deployment-attribute__title--mobile">
          <strong>Wave</strong>
        </span>
        <a
          href={`${paths.WAVES}/${waveId}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {waveIndex}
        </a>
      </div>
      <div className="column deployment-atribute__container">
        <span className="deployment-attribute__title--mobile">
          <strong>Start date</strong>
        </span>
        {useFormatDate(startDateParsedToDate, {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        })}
      </div>
      <div className="column deployment-atribute__container">
        <span className="deployment-attribute__title--mobile">
          <strong>End date</strong>
        </span>
        {endDate ? endDateFormatted : 'Forever'}
      </div>
      <div className="column deployment-atribute__container">
        <span className="deployment-attribute__title--mobile">
          <strong>Status</strong>
        </span>
        <span
          className={classNames('tag survey-details__status-tag', {
            'is-warning': deploymentStatus === SurveyDeploymentStatus.PENDING,
            'is-success': deploymentStatus === SurveyDeploymentStatus.ACTIVE,
            'is-danger': deploymentStatus === SurveyDeploymentStatus.CLOSED,
            'is-light': deploymentStatus === SurveyDeploymentStatus.INACTIVE,
          })}
        >
          {surveyDeploymentStatusDisplayText(deploymentStatus)}
        </span>
      </div>
      <div className={classNames('column has-text-right', classes.actions)}>
        <span className="deployment-attribute__title--mobile" />
        <a
          href={`${paths.LIVE_SURVEY}/${url}`}
          rel="noopener noreferrer"
          target="_blank"
          className={classNames(
            'button is-primary is-small',
            classes['deployment-action']
          )}
          data-tip="View live survey"
        >
          <span className="icon is-small">
            <i className="mdi mdi-eye" />
          </span>
        </a>
        <Link
          to={`${paths.SURVEYS}/${surveyId}/deployments/${deploymentId}`}
          className="button is-link is-small"
          data-tip="Edit deployment"
        >
          <span className="icon is-small">
            <i className="mdi mdi-pencil" />
          </span>
        </Link>
      </div>
      <ReactTooltip />
    </div>
  );
};

Deployment.propTypes = {
  url: PropTypes.string.isRequired,
  waveIndex: PropTypes.number.isRequired,
  waveId: PropTypes.string.isRequired,
  organization: PropTypes.string.isRequired,
  isLastElement: PropTypes.bool.isRequired,
  startDate: PropTypes.shape({
    seconds: PropTypes.number.isRequired,
    nanoseconds: PropTypes.number.isRequired,
  }).isRequired,
  endDate: PropTypes.shape({
    seconds: PropTypes.number.isRequired,
    nanoseconds: PropTypes.number.isRequired,
  }),
  surveyId: PropTypes.string.isRequired,
  deploymentId: PropTypes.string.isRequired,
};

Deployment.defaultProps = {
  endDate: null,
};

export default Deployment;
