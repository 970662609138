/* eslint-disable no-param-reassign */
import { v4 as uuid } from 'uuid';

import { SurveyType } from 'utils/surveyBuilder/enums';
import {
  createQuestionInGroupObject,
  newActionQuestionGroupAssign,
  trimMultipleLanguageContent,
} from '..';

export const processQuestionGroupItem = ({
  item,
  surveyId,
  surveyVersionId,
  organizationId,
  questions,
  questionTag,
  newActions,
  surveyType,
  isCreatingVersionFromVersion,
}) => {
  const {
    id: questionGroupId,
    questionGroup: {
      dataSet: { id: dataSetId },
      questions: questionsInGroup,
    },
  } = item;

  const template =
    surveyType === SurveyType.TEMPLATE && !isCreatingVersionFromVersion
      ? {
          questionGroupId,
          surveyId,
          versionId: surveyVersionId,
        }
      : null;

  item.questionGroup.template = template;

  questionsInGroup.forEach(
    (
      {
        id: oldQuestionId,
        content,
        tags,
        type: questionInGroupType,
        required,
        choicesOrder,
      },
      questionIndex
    ) => {
      const questionInGroupId = isCreatingVersionFromVersion
        ? uuid()
        : oldQuestionId;

      item.questionGroup.questions[questionIndex].id = questionInGroupId;

      const questionContentParsed = trimMultipleLanguageContent(content);

      const newQuestionInGroup = createQuestionInGroupObject({
        id: questionInGroupId,
        content: questionContentParsed,
        type: questionInGroupType,
        dataSetId: dataSetId ?? null,
        surveyId,
        surveyVersionId,
        required,
        choicesOrder,
        template,
        regionalBreakdown: false,
        organizationId,
      });

      questions.push(newQuestionInGroup);

      tags.forEach(({ id: tagId, weight }) => {
        questionTag.push({
          tagId,
          questionId: questionInGroupId,
          weight,
        });
      });

      if (isCreatingVersionFromVersion) {
        newActionQuestionGroupAssign(
          newActions,
          oldQuestionId,
          questionGroupId
        );
      }
    }
  );
};
