/* eslint-disable no-param-reassign */
import { v4 as uuid } from 'uuid';

import { AvailableActions } from 'utils/enums';
import {
  createQuestionChoiceObject,
  createQuestionObject,
  newActionsQuestionAssign,
  trimMultipleLanguageContent,
} from 'utils/actions';
import { SurveyType } from 'utils/surveyBuilder/enums';

const enabledActions = {
  [AvailableActions.HIDE_CHOICES]: AvailableActions.HIDE_CHOICES,
  [AvailableActions.SHOW_CHOICES]: AvailableActions.SHOW_CHOICES,
  [AvailableActions.RANDOMIZE_CHOICES]: AvailableActions.RANDOMIZE_CHOICES,
};

export const processQuestionItem = ({
  item,
  surveyId,
  surveyType,
  surveyVersionId,
  organizationId,
  questions,
  questionTag,
  newActions,
  questionChoices,
  isCreatingVersionFromVersion,
}) => {
  const { id: itemQuestionId, question } = item;
  const {
    id: oldQuestionId,
    content,
    dataSet: { id: dataSetId },
    tags,
    choices,
    type: questionType,
    required,
    choicesOrder,
    regionalBreakdown,
  } = question;

  const questionId = isCreatingVersionFromVersion ? uuid() : itemQuestionId;

  const template =
    surveyType === SurveyType.TEMPLATE
      ? {
          questionId,
          surveyId,
          versionId: surveyVersionId,
        }
      : null;

  item.question.template = template;

  if (isCreatingVersionFromVersion) {
    item.question.id = questionId;
  }

  const questionContentParsed = trimMultipleLanguageContent(content);

  const newQuestion = createQuestionObject({
    id: questionId,
    content: questionContentParsed,
    type: questionType,
    dataSetId,
    surveyId,
    surveyVersionId,
    template,
    required,
    choicesOrder,
    regionalBreakdown,
    organizationId,
  });

  questions.push(newQuestion);

  tags.forEach(({ id: tagId, weight }) => {
    questionTag.push({ tagId, questionId, weight });
  });

  if (isCreatingVersionFromVersion) {
    newActionsQuestionAssign(
      newActions,
      oldQuestionId,
      questionId
    );
  }

  if (!dataSetId) {
    choices.forEach(
      (
        {
          id: oldChoiceId,
          option,
          paramOne,
          paramTwo,
          openEnded,
          notApplicable,
          visible,
          sortOrder,
        },
        index
      ) => {
        const choiceId = isCreatingVersionFromVersion ? uuid() : oldChoiceId;

        if (isCreatingVersionFromVersion) {
          newActions.forEach(
            (
              { actions: changedActions, conditions: changedConditions },
              newActionIndex
            ) => {
              changedActions.forEach((action, actionIndex) => {
                if (
                  enabledActions[action.action] &&
                  action.subject === questionId
                ) {
                  action.choices.forEach((currentChoiceId, choiceIndex) => {
                    if (currentChoiceId === oldChoiceId) {
                      newActions[newActionIndex].actions[actionIndex].choices[
                        choiceIndex
                      ] = choiceId;
                    }
                  });
                }
              });

              changedConditions.forEach((condition, conditionIndex) => {
                if (condition.subject === questionId) {
                  condition.values.forEach((value, valueIndex) => {
                    if (value === oldChoiceId) {
                      newActions[newActionIndex].conditions[
                        conditionIndex
                      ].values[valueIndex] = choiceId;
                    }
                  });
                }
              });
            }
          );
        }

        item.question.choices[index].id = choiceId;

        const choiceOptionParsed = trimMultipleLanguageContent(option);

        const newQuestionChoice = createQuestionChoiceObject({
          id: choiceId,
          questionId,
          option: choiceOptionParsed,
          paramOne,
          paramTwo,
          openEnded,
          notApplicable,
          sortOrder,
          visible,
        });

        questionChoices.push(newQuestionChoice);
      }
    );
  }
};
