/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames';
import { Draggable } from 'react-beautiful-dnd';

import classes from './Chart.module.scss';

const Chart = ({ chart, index, columnId }) => {
  return (
    <Draggable draggableId={chart.id} index={index}>
      {(provided, snapshot) => (
        <div
          className={classNames(classes.chart, {
            [classes.dragging]: snapshot.isDragging,
            [classes['mx-2']]: columnId === 'storage',
            [classes['my-2']]: columnId !== 'storage',
            [classes['chart-in-storage']]: columnId === 'storage',
          })}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          {`(${chart.weight}) ${chart.content}`}
        </div>
      )}
    </Draggable>
  );
};

export default Chart;
