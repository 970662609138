import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Spinner from 'react-spinners/ClipLoader';

import useNewModal from 'hooks/useNewModal';
import { selectSectionSaveCreation } from 'state/selectors/sectionsSaves';
import {
  clearSavedSectionsState,
  updateSectionSave,
  createSectionSave,
} from 'state/actions/SavedSections';
import SaveSectionConfirmationModal from 'components/SurveyBuilder/Modals/SaveSectionConfirmationModal';
import NewModal from 'components/NewModal';
import { colors } from 'styles';

const CustomFooter = ({ loading, canSubmit, onSubmit, onCancel }) => (
  <footer className="modal-card-foot">
    <div className="is-flex level container">
      <button
        id="primary-cancel-button"
        type="button"
        className="button is-light"
        disabled={loading}
        onClick={onCancel}
      >
        Cancel
      </button>
      <button
        type="button"
        onClick={onSubmit}
        className={classNames('button is-success', {
          'is-loading': loading,
        })}
        disabled={!canSubmit || loading}
      >
        {loading ? <Spinner size="18" color={colors.white} /> : 'Save Section'}
      </button>
    </div>
  </footer>
);

const SaveSectionModal = ({
  isOpen,
  toggle,
  closeModal,
  survey,
  sectionId,
}) => {
  const dispatch = useDispatch();

  const { loading, success, sectionExists } = useSelector(
    selectSectionSaveCreation
  );

  const {
    isOpen: confirmationModalIsOpen,
    closeModal: closeConfirmationModal,
    openModal: openConfirmationModal,
  } = useNewModal();

  const [sectionIdentifier, setSectionIdentifier] = useState('');
  const [sectionDescription, setSectionDescription] = useState('');

  const { section, items } = useMemo(() => {
    const selectedSection = survey.sections[sectionId];

    const sectionItems = selectedSection.itemIds.map((itemId) => {
      const item = { ...survey.items[itemId], condensed: false };

      return item;
    });

    return { items: sectionItems, section: selectedSection };
  }, [survey]);

  const onSaveSection = useCallback(() => {
    if (sectionIdentifier && sectionDescription) {
      dispatch(
        createSectionSave({
          section,
          items,
          sectionIdentifier,
          sectionDescription,
        })
      );
    }
  }, [section, items, sectionIdentifier, sectionDescription, dispatch]);

  const onUpdateSection = useCallback(() => {
    if (sectionIdentifier && sectionDescription) {
      dispatch(
        updateSectionSave({
          section,
          items,
          sectionIdentifier,
          sectionDescription,
        })
      );
    }
  }, [section, items, sectionIdentifier, sectionDescription, dispatch]);

  const onChangeSectionIdentifierHandler = (event) => {
    setSectionIdentifier(event.target.value);
  };
  const onChangeSectionDescriptionHandler = (event) => {
    setSectionDescription(event.target.value);
  };

  useEffect(() => {
    if (success) {
      dispatch(clearSavedSectionsState());
      closeConfirmationModal();
      closeModal();
    }
  }, [success, closeConfirmationModal, closeModal]);

  useEffect(() => {
    if (sectionExists && isOpen) {
      openConfirmationModal();
    }
    
    if (sectionExists && !isOpen) {
      dispatch(clearSavedSectionsState());
    }
  }, [sectionExists]);

  useEffect(() => {
    dispatch(clearSavedSectionsState());
  }, []);

  const onUnmount = useCallback(() => {
    setSectionIdentifier('');
    setSectionDescription('');
    dispatch(clearSavedSectionsState());
  }, []);

  return (
    <>
      <SaveSectionConfirmationModal
        isOpen={confirmationModalIsOpen}
        onConfirm={onUpdateSection}
        closeModal={closeConfirmationModal}
      />
      <NewModal
        title="Save Section"
        isOpen={isOpen}
        toggle={loading ? null : toggle}
        CustomFooter={CustomFooter}
        footerProps={{
          loading: confirmationModalIsOpen ? false : loading,
          canSubmit: sectionIdentifier && sectionDescription,
          onSubmit: onSaveSection,
          onCancel: closeModal,
        }}
        onUnmount={onUnmount}
      >
        <div className="field">
          <span className="label">Identifier:</span>
          <div>
            <input
              className="input"
              value={sectionIdentifier}
              onChange={onChangeSectionIdentifierHandler}
              disabled={loading}
            />
          </div>
          <span className="label">Description:</span>
          <div>
            <input
              className="input"
              value={sectionDescription}
              onChange={onChangeSectionDescriptionHandler}
              disabled={loading}
            />
          </div>
        </div>
      </NewModal>
    </>
  );
};

SaveSectionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  survey: PropTypes.objectOf(PropTypes.any).isRequired,
  sectionId: PropTypes.string.isRequired,
};

export default SaveSectionModal;
