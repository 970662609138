import { createAction } from 'redux-act';

export const SURVEYS_FETCH_DATA_INIT = createAction('SURVEYS_FETCH_DATA_INIT');
export const SURVEYS_FETCH_DATA_SUCCESS = createAction(
  'SURVEYS_FETCH_DATA_SUCCESS'
);
export const SURVEYS_FETCH_DATA_FAIL = createAction('SURVEYS_FETCH_DATA_FAIL');

export const SURVEYS_CREATE_SURVEY_INIT = createAction(
  'SURVEYS_CREATE_SURVEY_INIT'
);
export const SURVEYS_CREATE_SURVEY_SUCCESS = createAction(
  'SURVEYS_CREATE_SURVEY_SUCCESS'
);
export const SURVEYS_CREATE_SURVEY_FAIL = createAction(
  'SURVEYS_CREATE_SURVEY_FAIL'
);

export const SURVEYS_UPDATE_SURVEY_INIT = createAction(
  'SURVEYS_UPDATE_SURVEY_INIT'
);
export const SURVEYS_UPDATE_SURVEY_SUCCESS = createAction(
  'SURVEYS_UPDATE_SURVEY_SUCCESS'
);
export const SURVEYS_UPDATE_SURVEY_FAIL = createAction(
  'SURVEYS_UPDATE_SURVEY_FAIL'
);
export const SURVEYS_UPDATE_CLEAR_STATE = createAction(
  'SURVEYS_UPDATE_CLEAR_STATE'
);

export const SURVEYS_CREATE_SURVEY_VERSION_INIT = createAction(
  'SURVEYS_CREATE_SURVEY_VERSION_INIT'
);
export const SURVEYS_CREATE_SURVEY_VERSION_SUCCESS = createAction(
  'SURVEYS_CREATE_SURVEY_VERSION_SUCCESS'
);
export const SURVEYS_CREATE_SURVEY_VERSION_FAIL = createAction(
  'SURVEYS_CREATE_SURVEY_VERSION_FAIL'
);

export const SURVEYS_SELECT_SURVEY_VERSION = createAction(
  'SURVEYS_SELECT_SURVEY_VERSION'
);

export const SURVEYS_CLEAR_STATE = createAction('SURVEYS_CLEAR_STATE');

export const SURVEYS_CLEAR_SELECTED_VERSION = createAction(
  'SURVEYS_CLEAR_SELECTED_VERSION'
);

export const SURVEYS_CREATE_DEPLOYMENT_INIT = createAction(
  'SURVEYS_CREATE_DEPLOYMENT_INIT'
);
export const SURVEYS_CREATE_DEPLOYMENT_SUCCESS = createAction(
  'SURVEYS_CREATE_DEPLOYMENT_SUCCESS'
);
export const SURVEYS_CREATE_DEPLOYMENT_FAIL = createAction(
  'SURVEYS_CREATE_DEPLOYMENT_FAIL'
);

export const SURVEYS_DELETE_DEPLOYMENT_INIT = createAction(
  'SURVEYS_DELETE_DEPLOYMENT_INIT'
);
export const SURVEYS_DELETE_DEPLOYMENT_SUCCESS = createAction(
  'SURVEYS_DELETE_DEPLOYMENT_SUCCESS'
);
export const SURVEYS_DELETE_DEPLOYMENT_FAIL = createAction(
  'SURVEYS_DELETE_DEPLOYMENT_FAIL'
);

export const SURVEYS_UPDATE_DEPLOYMENT_INIT = createAction(
  'SURVEYS_UPDATE_DEPLOYMENT_INIT'
);
export const SURVEYS_UPDATE_DEPLOYMENT_SUCCESS = createAction(
  'SURVEYS_UPDATE_DEPLOYMENT_SUCCESS'
);
export const SURVEYS_UPDATE_DEPLOYMENT_FAIL = createAction(
  'SURVEYS_UPDATE_DEPLOYMENT_FAIL'
);

export const SURVEYS_FETCH_DEPLOYMENT_INIT = createAction(
  'SURVEYS_FETCH_DEPLOYMENT_INIT'
);
export const SURVEYS_FETCH_DEPLOYMENT_SUCCESS = createAction(
  'SURVEYS_FETCH_DEPLOYMENT_SUCCESS'
);
export const SURVEYS_FETCH_DEPLOYMENT_FAIL = createAction(
  'SURVEYS_FETCH_DEPLOYMENT_FAIL'
);

export const SURVEYS_FETCH_SURVEY_RESPONSE_SAVE_INIT = createAction(
  'SURVEYS_FETCH_SURVEY_RESPONSE_SAVE_INIT'
);
export const SURVEYS_FETCH_SURVEY_RESPONSE_SAVE_SUCCESS = createAction(
  'SURVEYS_FETCH_SURVEY_RESPONSE_SAVE_SUCCESS'
);
export const SURVEYS_FETCH_SURVEY_RESPONSE_SAVE_FAIL = createAction(
  'SURVEYS_FETCH_SURVEY_RESPONSE_SAVE_FAIL'
);

export const SURVEYS_CREATE_SURVEY_REPONSE_INIT = createAction(
  'SURVEYS_CREATE_SURVEY_REPONSE_INIT'
);
export const SURVEYS_CREATE_SURVEY_REPONSE_SUCCESS = createAction(
  'SURVEYS_CREATE_SURVEY_REPONSE_SUCCESS'
);
export const SURVEYS_CREATE_SURVEY_REPONSE_FAIL = createAction(
  'SURVEYS_CREATE_SURVEY_REPONSE_FAIL'
);

export const SURVEYS_CREATE_SURVEY_RESPONSE_SAVE_INIT = createAction(
  'SURVEYS_CREATE_SURVEY_RESPONSE_SAVE_INIT'
);
export const SURVEYS_CREATE_SURVEY_RESPONSE_SAVE_SUCCESS = createAction(
  'SURVEYS_CREATE_SURVEY_RESPONSE_SAVE_SUCCESS'
);
export const SURVEYS_CREATE_SURVEY_RESPONSE_SAVE_FAIL = createAction(
  'SURVEYS_CREATE_SURVEY_RESPONSE_SAVE_FAIL'
);

export const SURVEYS_CREATE_ORGANIZATION_SURVEY_FROM_TEMPLATE_INIT =
  createAction('SURVEYS_CREATE_ORGANIZATION_SURVEY_FROM_TEMPLATE_INIT');
export const SURVEYS_CREATE_ORGANIZATION_SURVEY_FROM_TEMPLATE_SUCCESS =
  createAction('SURVEYS_CREATE_ORGANIZATION_SURVEY_FROM_TEMPLATE_SUCCESS');
export const SURVEYS_CREATE_ORGANIZATION_SURVEY_FROM_TEMPLATE_FAIL =
  createAction('SURVEYS_CREATE_ORGANIZATION_SURVEY_FROM_TEMPLATE_FAIL');

export const SURVEYS_FETCH_DEPLOYMENTS_INIT = createAction(
  'SURVEYS_FETCH_DEPLOYMENTS_INIT'
);
export const SURVEYS_FETCH_DEPLOYMENTS_SUCCESS = createAction(
  'SURVEYS_FETCH_DEPLOYMENTS_SUCCESS'
);
export const SURVEYS_FETCH_DEPLOYMENTS_FAIL = createAction(
  'SURVEYS_FETCH_DEPLOYMENTS_FAIL'
);

export const SURVEYS_FETCH_PREVIEW_INIT = createAction(
  'SURVEYS_FETCH_PREVIEW_INIT'
);
export const SURVEYS_FETCH_PREVIEW_SUCCESS = createAction(
  'SURVEYS_FETCH_PREVIEW_SUCCESS'
);

export const SURVEYS_PREVIEW_CLEANUP = createAction('SURVEYS_PREVIEW_CLEANUP');

export const SURVEYS_UPDATE_DEPLOYMENTS_HITS_INIT = createAction(
  'SURVEYS_UPDATE_DEPLOYMENTS_HITS_INIT'
);
export const SURVEYS_UPDATE_DEPLOYMENTS_HITS_SUCCESS = createAction(
  'SURVEYS_UPDATE_DEPLOYMENTS_HITS_SUCCESS'
);
export const SURVEYS_UPDATE_DEPLOYMENTS_HITS_FAIL = createAction(
  'SURVEYS_UPDATE_DEPLOYMENTS_HITS_FAIL'
);

export const SURVEYS_UPDATE_DEPLOYMENT_START_AND_END_DATES_INIT = createAction(
  'SURVEYS_UPDATE_DEPLOYMENT_START_AND_END_DATES_INIT'
);
export const SURVEYS_UPDATE_DEPLOYMENT_START_AND_END_DATES_SUCCESS = createAction(
  'SURVEYS_UPDATE_DEPLOYMENT_START_AND_END_DATES_SUCCESS'
);
export const SURVEYS_UPDATE_DEPLOYMENT_START_AND_END_DATES_FAIL = createAction(
  'SURVEYS_UPDATE_DEPLOYMENT_START_AND_END_DATES_FAIL'
);

export const SURVEYS_CREATED_DEPLOYMENT_CLEAR_STATE = createAction(
  'SURVEYS_CREATED_DEPLOYMENT_CLEAR_STATE'
);

export const SURVEYS_UPDATE_DEPLOYMENT_START_AND_END_DATES_CLEAR_STATE = createAction(
  'SURVEYS_UPDATE_DEPLOYMENT_START_AND_END_DATES_CLEAR_STATE'
);

export const SURVEYS_COPY_SURVEY_INIT = createAction(
  'SURVEYS_COPY_SURVEY_INIT'
);
export const SURVEYS_COPY_SURVEY_SUCCESS = createAction(
  'SURVEYS_COPY_SURVEY_SUCCESS'
);
export const SURVEYS_COPY_SURVEY_FAIL = createAction(
  'SURVEYS_COPY_SURVEY_FAIL'
);

export const SURVEYS_COPY_SURVEY_CLEAN_UP = createAction(
  'SURVEYS_COPY_SURVEY_CLEAN_UP'
);

export const SURVEYS_RESPONSE_SAVE_STATE_CLEAN_UP = createAction(
  'SURVEYS_RESPONSE_SAVE_STATE_CLEAN_UP'
);

export const SURVEYS_LOAD_RESPONSE_SAVE_STATE_CLEAN_UP = createAction(
  'SURVEYS_LOAD_RESPONSE_SAVE_STATE_CLEAN_UP'
);
