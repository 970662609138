/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Select from 'react-select';

import {
  getQuestionsWithActionsOptions,
  getActionsForSelectedQuestion,
  getTransformedActions,
} from 'utils/actionBuilder';
import classes from './ActionsList.module.scss';
import ActionsListItem from './ActionsListItem';
import EmptyList from './EmptyList';

const ActionsList = ({
  actions,
  onEditAction,
  onDeleteAction,
  questionGroups,
  allQuestions,
  textBoxes,
  sections,
  pages,
  pagesOrder,
  searchItem,
}) => {
  const [transformedActions, setTransformedActions] = useState([]);
  const [actionsToShow, setActionsToShow] = useState([]);
  const [selectedSearchQuestion, setSelectedSearchQuestion] = useState(null);
  const [searchQuestionOptions, setSearchQuestionOptions] = useState([]);

  useEffect(() => {
    if (searchItem) {
      const isQuestion = allQuestions[searchItem];
      const isGroup = questionGroups[searchItem];
      let title = '';
      if (isQuestion) {
        title = isQuestion.title;
      } else {
        title = isGroup.questionGroup.label.en;
      }
      const transformedItem = {
        label: title,
        value: { id: searchItem, title },
      };
      setSelectedSearchQuestion(transformedItem);
    }
  }, [searchItem, setSelectedSearchQuestion, questionGroups, allQuestions]);

  useEffect(() => {
    const transformed = getTransformedActions(
      actions,
      allQuestions,
      questionGroups,
      textBoxes,
      sections,
      pages
    );
    setTransformedActions(transformed);
    setActionsToShow(transformed);
  }, [
    actions,
    allQuestions,
    questionGroups,
    textBoxes,
    sections,
    pages,
    pagesOrder,
  ]);

  useEffect(() => {
    const questionsWithActions = getQuestionsWithActionsOptions(
      transformedActions,
      pages,
      pagesOrder,
      sections,
      allQuestions,
      questionGroups
      );
      setSearchQuestionOptions(questionsWithActions);
    }, [
    transformedActions,
    pages,
    pagesOrder,
    sections,
    allQuestions,
    questionGroups,
  ]);

  useEffect(() => {
    if (selectedSearchQuestion) {
      const selectedActions = getActionsForSelectedQuestion(
        transformedActions,
        selectedSearchQuestion
      );
      setActionsToShow(selectedActions);
    } else {
      setActionsToShow(transformedActions);
    }
  }, [selectedSearchQuestion, transformedActions]);

  const onChangeSearchQuestionHanlder = useCallback((question) => {
    setSelectedSearchQuestion(question);
  }, []);

  return actions.length === 0 ? (
    <EmptyList />
  ) : (
    <section
      className={classNames('modal-card-body', classes['modal-card-list-body'])}
    >
      <div className="card has-table has-mobile-sort-spaced">
        <header className="card-header">
          <div className="card-header-title table-header has-mobile-sort-spaced">
            <span>Filter by: </span>
            <div className={classes.select}>
              <Select
                value={selectedSearchQuestion}
                placeholder="Select question"
                options={searchQuestionOptions}
                onChange={onChangeSearchQuestionHanlder}
                isClearable
                isSearchable
              />
            </div>
          </div>
        </header>
        <table
          className={classNames('table is-bordered is-striped', classes.table)}
        >
          <thead>
            <tr>
              <th className={classes.th}>Conditions</th>
              <th className={classes.th}>Actions</th>
              <th className={classes.edit}> </th>
            </tr>
          </thead>
          <tbody>
            {actionsToShow.map((action, index) => {
              const label = `${index}`;
              return (
                <ActionsListItem
                  key={label}
                  actions={action.actions}
                  conditions={action.conditions}
                  id={action.id}
                  onEditAction={onEditAction}
                  onDeleteAction={onDeleteAction}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </section>
  );
};

ActionsList.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      actions: PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.shape({
            action: PropTypes.string,
            subject: PropTypes.string,
          }),
          PropTypes.shape({
            action: PropTypes.string,
            subject: PropTypes.string,
            choices: PropTypes.arrayOf(
              PropTypes.shape({ id: PropTypes.string, title: PropTypes.string })
            ),
          }),
          PropTypes.shape({
            action: PropTypes.string,
            subject: PropTypes.arrayOf(PropTypes.string),
            randomize: PropTypes.string,
          }),
        ])
      ),
      conditions: PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.shape({
            subject: PropTypes.string,
            criteria: PropTypes.string,
            values: PropTypes.arrayOf(
              PropTypes.shape({
                id: PropTypes.string,
                title: PropTypes.string,
              })
            ),
            type: PropTypes.string,
          }),
          PropTypes.shape({
            operator: PropTypes.string,
            type: PropTypes.string,
          }),
        ])
      ),
      id: PropTypes.string,
    })
  ),
  onEditAction: PropTypes.func.isRequired,
  onDeleteAction: PropTypes.func.isRequired,
  questionGroups: PropTypes.objectOf(PropTypes.objectOf(PropTypes.any)),
  allQuestions: PropTypes.objectOf(PropTypes.objectOf(PropTypes.any)),
  textBoxes: PropTypes.objectOf(PropTypes.objectOf(PropTypes.any)),
  sections: PropTypes.objectOf(PropTypes.objectOf(PropTypes.any)),
  pages: PropTypes.objectOf(PropTypes.objectOf(PropTypes.any)),
  pagesOrder: PropTypes.arrayOf(PropTypes.string),
};

export default ActionsList;
