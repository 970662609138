import { createReducer } from 'redux-act';

import {
  ORGANIZATIONS_CLEAR_DATA,
  ORGANIZATIONS_FETCH_DATA_FAIL,
  ORGANIZATIONS_FETCH_DATA_INIT,
  ORGANIZATIONS_FETCH_DATA_SUCCESS,
  ORGANIZATIONS_CREATE_ORGANIZATION_FAIL,
  ORGANIZATIONS_CREATE_ORGANIZATION_SUCCESS,
  ORGANIZATIONS_CREATE_ORGANIZATION_INIT,
  ORGANIZATIONS_MODIFY_ORGANIZATION_FAIL,
  ORGANIZATIONS_MODIFY_ORGANIZATION_SUCCESS,
  ORGANIZATIONS_MODIFY_ORGANIZATION_INIT,
  ORGANIZATIONS_DELETE_ORGANIZATION_FAIL,
  ORGANIZATIONS_DELETE_ORGANIZATION_INIT,
  ORGANIZATIONS_DELETE_ORGANIZATION_SUCCESS,
  ORGANIZATIONS_CLEAN_UP,
  ORGANIZATIONS_UPDATE_LAYOUT_FAIL,
  ORGANIZATIONS_UPDATE_LAYOUT_INIT,
  ORGANIZATIONS_UPDATE_LAYOUT_SUCCESS,
} from 'state/actions/organizations';

export const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
  updatingLayout: false,
};

export const organizationsReducer = createReducer(
  {
    [ORGANIZATIONS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [ORGANIZATIONS_FETCH_DATA_SUCCESS]: (state, { organizations }) => ({
      ...state,
      data: organizations,
      loading: false,
      error: null,
    }),
    [ORGANIZATIONS_FETCH_DATA_FAIL]: (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
    [ORGANIZATIONS_CLEAR_DATA]: (state) => ({
      ...initialState,
      data: state.data,
    }),
    [ORGANIZATIONS_CREATE_ORGANIZATION_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [ORGANIZATIONS_CREATE_ORGANIZATION_SUCCESS]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
    }),
    [ORGANIZATIONS_CREATE_ORGANIZATION_FAIL]: (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
    [ORGANIZATIONS_MODIFY_ORGANIZATION_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [ORGANIZATIONS_MODIFY_ORGANIZATION_SUCCESS]: (
      state,
      { id, organization }
    ) => {
      const { data } = state;
      return {
        ...state,
        data: data.map((elem) => {
          if (elem.id === id) {
            return organization;
          }
          return elem;
        }),
        loading: false,
        error: null,
        success: true,
      };
    },
    [ORGANIZATIONS_MODIFY_ORGANIZATION_FAIL]: (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
    [ORGANIZATIONS_DELETE_ORGANIZATION_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [ORGANIZATIONS_DELETE_ORGANIZATION_SUCCESS]: (state, { id }) => {
      const { data } = state;

      return {
        ...state,
        data: data.filter((elem) => elem.id !== id),
        loading: false,
        error: null,
        deleted: true,
      };
    },
    [ORGANIZATIONS_DELETE_ORGANIZATION_FAIL]: (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
    [ORGANIZATIONS_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
    }),
    [ORGANIZATIONS_UPDATE_LAYOUT_INIT]: (state) => ({
      ...state,
      updatingLayout: true,
    }),
    [ORGANIZATIONS_UPDATE_LAYOUT_SUCCESS]: (state, { id, layout }) => {
      const { data } = state;

      return {
        ...state,
        data: data.map((elem) => {
          if (elem.id === id) {
            return { ...elem, layout };
          }
          return elem;
        }),
        updatingLayout: false,
        error: null,
        deleted: true,
      };
    },
    [ORGANIZATIONS_UPDATE_LAYOUT_FAIL]: (state, { error }) => ({
      ...state,
      updatingLayout: false,
      error,
    }),
  },
  initialState
);
