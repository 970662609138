import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';
import { v4 as uuidv4 } from 'uuid';
import algoliasearch from 'algoliasearch';
import { getAuth, sendSignInLinkToEmail } from 'firebase/auth';

import { REACT_APP_ALGOLIA_USERS_INDEX } from 'constants/environment';
import { firebaseError, roles } from 'utils';
import firebase from 'firebase.js';
import { checkUserData, AUTH_UPDATE_USER_DATA } from './auth';

export const USERS_FETCH_DATA_INIT = createAction('USERS_FETCH_DATA_INIT');
export const USERS_FETCH_DATA_SUCCESS = createAction(
  'USERS_FETCH_DATA_SUCCESS'
);
export const USERS_FETCH_DATA_FAIL = createAction('USERS_FETCH_DATA_FAIL');

export const USERS_DELETE_USER_INIT = createAction('USERS_DELETE_USER_INIT');
export const USERS_DELETE_USER_SUCCESS = createAction(
  'USERS_DELETE_USER_SUCCESS'
);
export const USERS_DELETE_USER_FAIL = createAction('USERS_DELETE_USER_FAIL');

export const USERS_CREATE_USER_INIT = createAction('USERS_CREATE_USER_INIT');
export const USERS_CREATE_USER_SUCCESS = createAction(
  'USERS_CREATE_USER_SUCCESS'
);
export const USERS_CREATE_USER_FAIL = createAction('USERS_CREATE_USER_FAIL');

export const USERS_MODIFY_USER_INIT = createAction('USERS_MODIFY_USER_INIT');
export const USERS_MODIFY_USER_SUCCESS = createAction(
  'USERS_MODIFY_USER_SUCCESS'
);
export const USERS_MODIFY_USER_FAIL = createAction('USERS_MODIFY_USER_FAIL');

export const USERS_CLEAN_UP = createAction('USERS_CLEAN_UP');

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_ADMIN_KEY
);
const index = searchClient.initIndex(REACT_APP_ALGOLIA_USERS_INDEX);

const appAuth = getAuth();

export const fetchUsers = (
  filterInactives = false,
  filterByOrganization = '',
  filterByManager = false
) => {
  return async (dispatch, getState) => {
    dispatch(checkUserData());

    dispatch(USERS_FETCH_DATA_INIT());

    const { id } = getState().auth.userData;

    const users = [];

    try {
      let baseQuery = firebase.firestore().collection('users');

      if (filterInactives) {
        baseQuery = baseQuery.where('active', '==', true);
      }
      if (filterByOrganization) {
        baseQuery = baseQuery.where(
          'organizationName',
          '==',
          filterByOrganization
        );
      }
      if (filterByManager) {
        baseQuery = baseQuery.where('manager', '==', true);
      }

      const queryResult = await baseQuery.get();

      queryResult.forEach((user) => {
        const userData = user.data();
        users.push({
          id: user.id,
          ...userData,
          email: userData.email || '',
        });
      });
    } catch (error) {
      toastr.error(error.message);
      return dispatch(USERS_FETCH_DATA_FAIL({ error }));
    }

    return dispatch(
      USERS_FETCH_DATA_SUCCESS({
        users: users.filter((user) => user.id !== id),
      })
    );
  };
};

const deleteLogo = (oldLogo) => {
  const logoPath = oldLogo.split('users%2F').pop().split('?alt=media').shift();
  return firebase.storage().ref(`users/${logoPath}`).delete();
};

export const deleteUser = (id, logoUrl) => {
  return async (dispatch, getState) => {
    dispatch(USERS_DELETE_USER_INIT());
    const { locale } = getState().preferences;

    const deleteLogoTask = logoUrl ? deleteLogo(logoUrl) : null;

    const deleteUserTask = firebase
      .firestore()
      .collection('users')
      .doc(id)
      .delete();

    const deleteUserFromAlgolia = index.deleteObject(id).wait();

    try {
      await Promise.all([
        deleteLogoTask,
        deleteUserTask,
        deleteUserFromAlgolia,
      ]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        USERS_DELETE_USER_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'The user was deleted.');
    return dispatch(USERS_DELETE_USER_SUCCESS({ id }));
  };
};

const uploadLogo = (uid, file) => {
  const storageRef = firebase.storage().ref();

  const fileExtension = file.name.split('.').pop();

  const fileName = `${uid}.${fileExtension}`;

  return storageRef.child(`users/${fileName}`).put(file);
};

const getLogoUrl = async (uid, file) => {
  const storageRef = firebase.storage().ref();
  const fileExtension = file.name.split('.').pop();
  const fileName = `${uid}.${fileExtension}`;

  const logoUrl = await storageRef.child(`users/${fileName}`).getDownloadURL();

  return logoUrl;
};

export const createUser = ({
  firstName,
  lastName,
  email,
  role,
  manager,
  active,
  organizationName,
  organizationId,
  groups,
  file,
}) => {
  return async (dispatch, getState) => {
    dispatch(USERS_CREATE_USER_INIT());
    const { locale } = getState().preferences;
    const { RESPONDENT, REPORT_VIEWER } = roles;

    let uid;

    if (role !== RESPONDENT) {
      try {
        const createUserAuth = firebase
          .functions()
          .httpsCallable('httpsCreateUser');

        const response = await createUserAuth({ email, role });
        uid = response.data.uid;
      } catch (error) {
        const errorMessage = firebaseError(error.message, locale);
        toastr.error('', errorMessage);
        return dispatch(
          USERS_CREATE_USER_FAIL({
            error: errorMessage,
          })
        );
      }
    } else {
      if (email) {
        try {
          const checkEmailExistance = firebase
            .functions()
            .httpsCallable('httpsCheckEmail');

          await checkEmailExistance(email);
        } catch (error) {
          const errorMessage = firebaseError(error.message, locale);
          toastr.error('', errorMessage);
          return dispatch(
            USERS_CREATE_USER_FAIL({
              error: errorMessage,
            })
          );
        }
      }
      uid = uuidv4();
    }

    let uploadLogoTask = null;
    let logoUrl = null;
    if (file) {
      logoUrl = await getLogoUrl(uid, file);
      uploadLogoTask = uploadLogo(uid, file);
    }

    const { userData } = getState().auth;

    const user = {
      firstName,
      lastName,
      email,
      role,
      manager,
      active,
      organizationName,
      organizationId,
      groups,
      logoUrl,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      createdBy: `${userData.firstName} ${userData.lastName}`,
    };

    const createUserDbTask = firebase
      .firestore()
      .collection('users')
      .doc(uid)
      .set(user);

    const actionCodeSettings = {
      url:
        role === REPORT_VIEWER
          ? process.env.REACT_APP_DASHBOARD_LOGIN_PAGE_URL
          : process.env.REACT_APP_LOGIN_PAGE_URL,
      handleCodeInApp: true,
    };

    const sendSignInLinkToEmailTask =
      role === RESPONDENT
        ? null
        : sendSignInLinkToEmail(appAuth, email, actionCodeSettings);

    const createUserAlgolia = await index
      .saveObject({ objectID: uid, ...user, createdAt: +new Date() })
      .wait();

    try {
      await Promise.all([
        createUserDbTask,
        uploadLogoTask,
        sendSignInLinkToEmailTask,
        createUserAlgolia,
      ]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        USERS_CREATE_USER_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'User created successfully');
    return dispatch(USERS_CREATE_USER_SUCCESS({ user: { id: uid, ...user } }));
  };
};

export const modifyUser = ({
  firstName,
  lastName,
  role,
  roleBefore,
  active,
  manager,
  id,
  isEditing,
  isProfile,
  email,
  emailBefore,
  groups,
  organizationName,
  organizationId,
  file,
  logoUrl,
}) => {
  return async (dispatch, getState) => {
    dispatch(USERS_MODIFY_USER_INIT());
    const { locale } = getState().preferences;
    const { RESPONDENT } = roles;

    let deleteLogoTask;
    let newLogoUrl = null;

    if (file) {
      await uploadLogo(id, file);

      newLogoUrl = await getLogoUrl(id, file);
      deleteLogoTask = logoUrl && deleteLogo(logoUrl);
    }

    const emailChanged = email !== emailBefore;

    if (email && emailChanged) {
      try {
        const checkEmailExistance = firebase
          .functions()
          .httpsCallable('httpsCheckEmail');

        await checkEmailExistance(email);
      } catch (error) {
        const errorMessage = firebaseError(error.message, locale);
        toastr.error('', errorMessage);

        return dispatch(
          USERS_CREATE_USER_FAIL({
            error: errorMessage,
          })
        );
      }
    }

    const userData = {
      firstName,
      lastName,
      role,
      active,
      manager,
      groups,
      organizationName,
      organizationId,
      logoUrl: newLogoUrl || logoUrl,
      email,
    };

    const updateUserDbTask = firebase
      .firestore()
      .collection('users')
      .doc(id)
      .update(userData);

    const updateTagInAlgolia = index
      .partialUpdateObject({
        objectID: id,
        ...userData,
      })
      .wait();

    const { uid } = appAuth.currentUser;

    if (id === uid) {
      dispatch(AUTH_UPDATE_USER_DATA({ ...userData, id }));
    }

    const actionCodeSettings = {
      url: process.env.REACT_APP_LOGIN_PAGE_URL,
      handleCodeInApp: true,
    };

    const sendSignInLinkToEmailTask =
      roleBefore === RESPONDENT && role !== RESPONDENT
        ? sendSignInLinkToEmail(appAuth, email, actionCodeSettings)
        : null;

    try {
      await Promise.all([
        deleteLogoTask,
        updateUserDbTask,
        sendSignInLinkToEmailTask,
        updateTagInAlgolia,
      ]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        USERS_MODIFY_USER_FAIL({
          error: errorMessage,
        })
      );
    }

    if (isProfile) {
      toastr.success('', 'Profile updated successfully');
    } else if (isEditing) {
      toastr.success('', 'User updated successfully');
    }

    return dispatch(USERS_MODIFY_USER_SUCCESS({ user: { ...userData, id } }));
  };
};

export const usersCleanUp = () => (dispatch) => dispatch(USERS_CLEAN_UP());
