import React, { useEffect, useMemo } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';

import {
  clearData,
  createWave,
  fetchWaves,
  modifyWave,
} from 'state/actions/waves';
import { isUntilDateValid } from 'utils';
import paths from 'pages/Router/paths';
import WaveForm from './WaveForm';

const Wave = () => {
  const { id } = useParams();
  const { success, error, waveData, loading } = useSelector(
    (state) => ({
      success: state.waves.success,
      error: state.waves.error,
      loading: state.waves.loading,
      waveData: state.waves.data.find((element) => element.id === id),
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const isEditing = useMemo(() => !!id, [id]);

  const [wave, setWave] = React.useState({
    index: 0,
    name: '',
    startingOn: new Date(),
    until: null,
    expectedResponses: '',
    reportingThreshold: 15,
    organization: null,
  });

  useEffect(() => {
    if (id) {
      if (!waveData) {
        const options = null;
        dispatch(fetchWaves(options, id));
      }

      if (waveData) {
        setWave({
          ...waveData,
          startingOn: new Date(waveData.startingOn),
          until: waveData.until ? new Date(waveData.until) : null,
        });
      }
    }
  }, [id, waveData, dispatch, setWave]);

  const onSubmitHandler = (event) => {
    event.preventDefault();
    const waveInfo = {
      ...wave,
      waveId: id,
      index: Number(wave.index),
      name: wave.name || null,
      expectedResponses: wave.expectedResponses
        ? Number(wave.expectedResponses)
        : null,
      startingOn: wave.startingOn,
      until: wave.until || null,
      organizationName: wave.organization.value.displayName,
      organizationId: wave.organization.value.id,
      reportingThreshold: Number(wave.reportingThreshold),
    };
    if (!isEditing) dispatch(createWave(waveInfo));
    else dispatch(modifyWave(waveInfo));
  };

  const validUntilDate = isUntilDateValid(wave?.startingOn, wave?.until);

  const canSubmit =
    wave.index !== '' &&
    wave.startingOn !== null &&
    validUntilDate &&
    !!wave.organization &&
    wave.reportingThreshold !== '';

  const redirect = (success || error) && <Redirect to={paths.WAVES} />;

  useEffect(() => {
    return () => dispatch(clearData());
  }, [dispatch]);

  return (
    <>
      {redirect}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">{id ? 'Edit Wave' : 'New Wave'}</h1>
        </div>
      </section>

      <section className="section is-main-section">
        {isEditing && !wave.index ? (
          <ClipLoader />
        ) : (
          <WaveForm
            waveId={id}
            wave={wave}
            setWave={setWave}
            canSubmit={canSubmit}
            validUntilDate={validUntilDate}
            loading={loading}
            onSubmitHandler={onSubmitHandler}
            isEditing={isEditing}
          />
        )}
      </section>
    </>
  );
};

export default Wave;
