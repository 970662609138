import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';

import firebase from 'firebase.js';

export const TASKS_FETCH_DATA_INIT = createAction('TASKS_FETCH_DATA_INIT');
export const TASKS_FETCH_DATA_FAIL = createAction('TASKS_FETCH_DATA_FAIL');
export const TASKS_FETCH_DATA_SUCCESS = createAction(
  'TASKS_FETCH_DATA_SUCCESS'
);
export const TASKS_CLEAN_UP = createAction('TASKS_CLEAN_UP');

export const fetchTasks = () => {
  return async (dispatch) => {
    dispatch(TASKS_FETCH_DATA_INIT());

    const tasks = [];

    try {
      const result = await firebase.firestore().collection('tasks').get();

      result.forEach((task) => {
        const taskData = {
          id: task.id,
          ...task.data(),
        };

        tasks.push({
          ...taskData,
          createTime: taskData.createTime.toDate(),
          finishTime: taskData.finishTime.toDate(),
        });
      });
    } catch (error) {
      toastr.error(error.message);
      return dispatch(TASKS_FETCH_DATA_FAIL({ error }));
    }

    return dispatch(
      TASKS_FETCH_DATA_SUCCESS({
        tasks,
      })
    );
  };
};

export const tasksCleanUp = () => (dispatch) => dispatch(TASKS_CLEAN_UP());
