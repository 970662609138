import React, { useCallback } from 'react';
import { connectHitsPerPage } from 'react-instantsearch-dom';
import PropTypes from 'prop-types';

const ResultsPerPage = ({ items, refine, setPageSize, defaultRefinement }) => {
  const onChangeHandler = useCallback(
    (event) => {
      const pageSize = event.target.value;
      refine(pageSize);
      setPageSize(pageSize);
    },
    [refine, setPageSize]
  );
  return (
    <span className="select">
      <select onChange={onChangeHandler} defaultValue={defaultRefinement}>
        {items.map((item) => (
          <option key={item.value} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    </span>
  );
};

ResultsPerPage.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  refine: PropTypes.func.isRequired,
  setPageSize: PropTypes.func.isRequired,
  defaultRefinement: PropTypes.number,
};

ResultsPerPage.defaultProps = {
  defaultRefinement: 5,
};

export default connectHitsPerPage(ResultsPerPage);
