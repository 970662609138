import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { useFormatDate } from 'hooks';
import { shallowEqual, useSelector } from 'react-redux';
import Spinner from 'react-spinners/ClipLoader';

import { colors } from 'styles';
import paths from 'pages/Router/paths';
import { selectTriggerAnalyticsState } from 'state/selectors/analytics';
import { surveyDeploymentStatusDisplayText } from 'utils/surveyBuilder';
import getDeploymentStatus from 'utils/surveyBuilder/getDeploymentStatus';
import { SurveyDeploymentStatus } from 'utils/surveyBuilder/enums';
import firebase from 'firebase.js';

import classes from './Deployment.module.scss';

const Deployment = ({
  deploymentId,
  surveyId,
  url,
  waveIndex,
  waveName,
  waveId,
  waveStatus,
  versionName,
  versionId,
  startDate,
  endDate,
  createdAt,
  createdBy,
  totalResponses,
  unprocessedResponses,
  lastTimeProcessed,
  onTriggerAnalytics,
  onDelete,
}) => {
  const { loading } = useSelector(selectTriggerAnalyticsState, shallowEqual);

  const startDateParsedToDate =
    firebase.firestore.Timestamp.fromMillis(startDate).toDate();

  const endDateParsedToDate = endDate
    ? firebase.firestore.Timestamp.fromMillis(endDate).toDate()
    : null;

  const deploymentStatus = getDeploymentStatus(
    startDateParsedToDate,
    endDateParsedToDate,
    waveStatus
  );

  const endDateFormatted = useFormatDate(endDate, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  const lastTimeProcessedFormatted = useFormatDate(lastTimeProcessed, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });

  const onDeleteDeploymentHandler = useCallback(() => {
    onDelete(deploymentId, surveyId);
  }, [deploymentId, surveyId]);

  const deploymentIsLoading = useMemo(
    () =>
      loading &&
      loading.find(
        (loadingDeploymentId) => loadingDeploymentId === deploymentId
      ),
    [loading]
  );

  return (
    <div className="columns is-vcentered is-multiline">
      <div className="column deployment-atribute__container">
        <span className="deployment-attribute__title--mobile">
          <strong>Status</strong>
        </span>
        <span
          className={classNames('tag survey-details__status-tag', {
            'is-warning': deploymentStatus === SurveyDeploymentStatus.PENDING,
            'is-success': deploymentStatus === SurveyDeploymentStatus.ACTIVE,
            'is-danger': deploymentStatus === SurveyDeploymentStatus.CLOSED,
            'is-light': deploymentStatus === SurveyDeploymentStatus.INACTIVE,
          })}
        >
          {surveyDeploymentStatusDisplayText(deploymentStatus)}
        </span>
      </div>
      <div className="column deployment-atribute__container">
        <span className="deployment-attribute__title--mobile">
          <strong>Wave index</strong>
        </span>
        <a
          href={`${paths.WAVES}/${waveId}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {waveIndex}
        </a>
      </div>
      <div className="column deployment-atribute__container">
        <span className="deployment-attribute__title--mobile">
          <strong>Wave name</strong>
        </span>
        {waveName || '-'}
      </div>
      <div className="column deployment-atribute__container">
        <span className="deployment-attribute__title--mobile">
          <strong>Version</strong>
        </span>
        <a
          href={`${paths.SURVEYS}/${surveyId}/versions/${versionId}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {versionName}
        </a>
      </div>
      <div className="column deployment-atribute__container">
        <span className="deployment-attribute__title--mobile">
          <strong>Unprocessed</strong>
        </span>
        {unprocessedResponses}
      </div>
      <div className="column deployment-atribute__container">
        <span className="deployment-attribute__title--mobile">
          <strong>Processed</strong>
        </span>
        {totalResponses - unprocessedResponses}
      </div>
      <div className="column deployment-atribute__container">
        <span className="deployment-attribute__title--mobile">
          <strong>Start date</strong>
        </span>
        {useFormatDate(startDate, {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        })}
      </div>
      <div className="column deployment-atribute__container">
        <span className="deployment-attribute__title--mobile">
          <strong>End date</strong>
        </span>
        {endDate ? endDateFormatted : 'Forever'}
      </div>
      <div className="column deployment-atribute__container">
        <span className="deployment-attribute__title--mobile">
          <strong>Last time processed</strong>
        </span>
        {lastTimeProcessed ? lastTimeProcessedFormatted : '-'}
      </div>
      <div className="column deployment-atribute__container">
        <span className="deployment-attribute__title--mobile">
          <strong>Created at</strong>
        </span>
        {useFormatDate(createdAt, {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        })}
      </div>
      <div className="column deployment-atribute__container">
        <span className="deployment-attribute__title--mobile">
          <strong>Created by</strong>
        </span>
        {createdBy}
      </div>
      <div className="column">
        <div className="buttons" style={{ minWidth: '105px' }}>
          <a
            href={`${paths.LIVE_SURVEY}/${url}`}
            rel="noopener noreferrer"
            target="_blank"
            className="button is-primary is-small"
            data-tip="View live survey"
          >
            <span className="icon is-small">
              <i className="mdi mdi-eye" />
            </span>
          </a>
          <Link
            to={`${paths.SURVEYS}/${surveyId}/deployments/${deploymentId}`}
            className="button is-link is-small"
            data-tip="Edit deployment"
          >
            <span className="icon is-small">
              <i className="mdi mdi-pencil" />
            </span>
          </Link>
          <Link
            to={paths.ROOT}
            className="button is-link is-small"
            data-tip="View deployment details"
          >
            <span className="icon is-small">
              <i className="mdi mdi-chart-areaspline" />
            </span>
          </Link>
          {deploymentStatus === SurveyDeploymentStatus.ACTIVE && (
            <span
              data-tip={
                loading
                  ? 'Wait until current trigger is finished'
                  : 'Trigger processing of the responses'
              }
              className={classNames(
                'button',
                classes['trigger-analytics-data-tip']
              )}
            >
              <button
                type="button"
                className={classNames(
                  'button is-success is-small',
                  classes['trigger-analytics']
                )}
                onClick={() => {
                  onTriggerAnalytics({ surveyId, deploymentId, waveId });
                }}
                disabled={loading}
              >
                <span className="icon is-small">
                  {deploymentIsLoading ? (
                    <Spinner size="18" color={colors.white} />
                  ) : (
                    <i className="mdi mdi-play" />
                  )}
                </span>
              </button>
            </span>
          )}
          <button
            type="button"
            className="button is-danger is-small"
            data-tip="Delete deployment"
            onClick={onDeleteDeploymentHandler}
          >
            <span className="icon is-small">
              <i className="mdi mdi-delete" />
            </span>
          </button>
          <ReactTooltip />
        </div>
      </div>
    </div>
  );
};

Deployment.propTypes = {
  url: PropTypes.string.isRequired,
  waveIndex: PropTypes.number.isRequired,
  waveName: PropTypes.string,
  waveId: PropTypes.string.isRequired,
  versionName: PropTypes.string.isRequired,
  versionId: PropTypes.string.isRequired,
  startDate: PropTypes.number.isRequired,
  createdAt: PropTypes.number.isRequired,
  createdBy: PropTypes.string.isRequired,
  endDate: PropTypes.number,
  totalResponses: PropTypes.number.isRequired,
  unprocessedResponses: PropTypes.number.isRequired,
  lastTimeProcessed: PropTypes.number,
};

Deployment.defaultProps = {
  lastTimeProcessed: null,
  endDate: null,
  waveName: '',
};

export default Deployment;
