import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import paths from 'pages/Router/paths';
import firebase from 'firebase.js';

import Button from 'components/Button';
import { surveyVersionStatusDisplayText } from 'utils/surveyBuilder';
import { SurveyType, SurveyVersionStatus } from 'utils/surveyBuilder/enums';
import { useFormatDate } from 'hooks';

const Version = ({
  versionId,
  surveyName,
  surveyId,
  status,
  name,
  createdAt,
  surveyType,
  onUse,
  onDelete,
}) => {
  return (
    <div className="columns is-vcentered is-mobile is-multiline">
      <div className="column surveys-has-text-centered">
        <span
          className={classNames('tag survey-details__status-tag', {
            'is-success': status === SurveyVersionStatus.ACTIVE,
            'is-danger': status === SurveyVersionStatus.ARCHIVED,
            'is-light': status === SurveyVersionStatus.INACTIVE,
          })}
        >
          {surveyVersionStatusDisplayText(status)}
        </span>
      </div>
      <div
        className={classNames('column', 'surveys-has-text-centered', {
          'is-full-mobile is-half-tablet is-three-fifths-desktop is-one-quarter-widescreen is-one-third-fullhd':
            surveyType === SurveyType.ORGANIZATION,
          'is-full-mobile is-half-tablet is-three-fifths-desktop is-three-fifths-widescreen is-one-fifth-fullhd':
            surveyType === SurveyType.TEMPLATE,
        })}
      >
        <a
          href={`${paths.SURVEYS}/${surveyId}/versions/${versionId}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {name}
        </a>
      </div>
      <div className="column surveys-has-text-centered">
        {useFormatDate(
          new firebase.firestore.Timestamp(
            createdAt.seconds,
            createdAt.nanoseconds
          ).toDate(),
          {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          }
        )}
      </div>
      <div
        className={classNames('column', {
          'is-full-mobile is-full-tablet is-full-desktop is-half-widescreen is-two-fifths-fullhd':
            surveyType === SurveyType.ORGANIZATION,
          'is-full-mobile is-full-tablet is-full-desktop is-full-widescreen is-three-fifths-fullhd':
            surveyType === SurveyType.TEMPLATE,
        })}
      >
        <div className="field is-grouped is-grouped-multiline">
          <div className="control">
            {surveyType === SurveyType.TEMPLATE && (
              <Button
                icon="mdi mdi-cursor-default-click"
                onClick={() =>
                  onUse({ surveyName, surveyId, versionId, versionName: name })
                }
              >
                Use this version
              </Button>
            )}
          </div>
          <div className="control">
            <Link
              to={`${paths.SURVEYS}/${surveyId}/versions/${versionId}`}
              className="button"
            >
              <span className="icon">
                <i className="mdi mdi-pencil" />
              </span>
              <span>Edit</span>
            </Link>
          </div>
          <div className="control">
            <Button icon="mdi mdi-delete" onClick={onDelete}>
              Delete
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

Version.propTypes = {
  surveyId: PropTypes.string.isRequired,
  versionId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  surveyType: PropTypes.oneOf([SurveyType.TEMPLATE, SurveyType.ORGANIZATION])
    .isRequired,
  status: PropTypes.oneOf([
    SurveyVersionStatus.ACTIVE,
    SurveyVersionStatus.ARCHIVED,
  ]).isRequired,
  createdAt: PropTypes.shape({
    nanoseconds: PropTypes.number.isRequired,
    seconds: PropTypes.number.isRequired,
  }).isRequired,
  onUse: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default Version;
