import { toastr } from 'react-redux-toastr';
import { copySurvey } from 'services/surveys';

import {
  SURVEYS_COPY_SURVEY_INIT,
  SURVEYS_COPY_SURVEY_SUCCESS,
  SURVEYS_COPY_SURVEY_FAIL,
} from 'state/actionCreators/surveys';

const createSurveyCopy = ({
  surveyId,
  surveyData,
  organizationId,
  newSurveyData,
}) => {
  return async (dispatch, getState) => {
    dispatch(SURVEYS_COPY_SURVEY_INIT());

    try {
      await copySurvey(
        { surveyId, surveyData, organizationId, newSurveyData },
        { dispatch, getState }
      );

      toastr.success('Success', 'Survey copied successfully');
      dispatch(SURVEYS_COPY_SURVEY_SUCCESS());
    } catch (error) {
      console.error(error);

      toastr.error('Error Copying Survey', error.message);
      dispatch(SURVEYS_COPY_SURVEY_FAIL({ error }));
    }
  };
};

export default createSurveyCopy;
