export const createSurveyVersionObject = ({
  name,
  title,
  status,
  pages,
  pagesOrder,
  sections,
  items,
  createdAt,
  createdBy,
  availableLanguages,
  defaultLanguage,
  isCondensed,
  languageCompletion,
  disableDeselect,
  actions,
  copyFrom,
}) => {
  const surveyVersion = {
    name,
    pages,
    title,
    items,
    status,
    actions,
    sections,
    createdAt,
    createdBy,
    pagesOrder,
    isCondensed,
    defaultLanguage,
    disableDeselect,
    availableLanguages,
    languageCompletion,
    ...(copyFrom && { copyFrom }),
  };

  return surveyVersion;
};
