import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';

export const PREFERENCES_SET_LOCALE = createAction('PREFERENCES_SET_LOCALE');
export const PREFERENCES_SET_DEFAULT_ORGANIZATION = createAction(
  'PREFERENCES_SET_DEFAULT_ORGANIZATION'
);

export const setUserLocale = (locale) => (dispatch) => {
  return dispatch(PREFERENCES_SET_LOCALE({ locale }));
};

export const setDefaultOrganization = (organization) => (dispatch) => {
  if (organization) {
    toastr.success('', `Using ${organization.label} organization as default`);
  } else {
    toastr.warning('', `No organization as default`);
  }
  return dispatch(PREFERENCES_SET_DEFAULT_ORGANIZATION({ organization }));
};
