import React, { useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { useFormatMessage } from 'hooks';
import UserForm from 'components/UserForm';
import { modifyUser } from 'state/actions/users';
import ChangePassword from './ChangePassword';

const Profile = () => {
  const { userData } = useSelector(
    (state) => ({
      userData: state.auth.userData,
    }),
    shallowEqual
  );

  const data = useMemo(
    () => ({
      ...userData,
      organization: userData.organizationName
        ? {
            label: userData.organizationName,
            value: {
              displayName: userData.organizationName,
            },
          }
        : null,
      role: {
        label: userData.role,
        value: userData.role,
      },
      groups: userData.groups.map((group) => ({ label: group, value: group })),
      emailBefore: userData.email || null,
    }),
    [userData]
  );

  return (
    <>
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">{useFormatMessage('Profile.profile')}</h1>
        </div>
      </section>
      <section className="section is-main-section">
        <UserForm isEditing isProfile userData={data} action={modifyUser} />
        <ChangePassword />
      </section>
    </>
  );
};

export default Profile;
