/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { LanguageIsoCode } from 'utils/enums';
import classes from './DataItem.module.scss';

const DataItem = ({
  id,
  label,
  option,
  paramOne,
  paramTwo,
  onChange,
  onDelete,
  canRemove,
  duplicated,
}) => {
  const onChangeInputHandler = (event) => {
    const { value, name } = event.target;
    onChange({ code: LanguageIsoCode.EN, id, value, name });
  };

  return (
    <div className="field is-horizontal">
      <div className="field-label is-normal">
        <label id="data-item-label-placeholder" className="label">
          {label}
        </label>
      </div>
      <div className="field-body">
        <div className={classNames('field', classes.option)}>
          <div className="control is-expanded">
            <input
              id="data-item-option-input"
              className={classNames('input', { 'is-danger': duplicated })}
              name="option"
              value={option}
              required
              onChange={onChangeInputHandler}
            />
          </div>
          {duplicated && (
            <p className="help is-danger">This choice is duplicated</p>
          )}
        </div>
        <div className={classNames('field', classes.param)}>
          <div className="control">
            <input
              id="data-item-param-one-input"
              className="input"
              name="paramOne"
              placeholder="Param 1"
              value={paramOne}
              onChange={onChangeInputHandler}
            />
          </div>
        </div>
        <div className={classNames('field', classes.param)}>
          <div className="control">
            <input
              id="data-item-param-two-input"
              className="input"
              name="paramTwo"
              placeholder="Param 2"
              value={paramTwo}
              onChange={onChangeInputHandler}
            />
          </div>
        </div>
        <div className="field">
          <div className="control">
            <button
              id="data-item-remove-item"
              type="button"
              className="button"
              disabled={id === 0 && !canRemove}
              onClick={() => onDelete(id)}
            >
              <span className="icon">
                <i className="mdi mdi-close" />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

DataItem.propTypes = {
  id: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  option: PropTypes.string.isRequired,
  paramOne: PropTypes.string.isRequired,
  paramTwo: PropTypes.string.isRequired,
  canRemove: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DataItem;
