import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Spinner from 'react-spinners/ClipLoader';

import { colors } from 'styles';
import {
  selectCreateResponseInProgressState,
  selectFetchResponseInProgressState,
} from 'state/selectors/surveys';
import useNewModal from 'hooks/useNewModal';
import NewModal from 'components/NewModal';

import classes from './Page.module.scss';

const SaveLoadTitle = () => (
  <>
    <span className="icon">
      <i className="mdi mdi-file-arrow-up-down-outline" />
    </span>
    Save/Load Response
  </>
);

const Page = ({
  title,
  subtitle,
  children,
  selectedLanguage,
  availableLanguages,
  onChangeLanguage,
  languageCompletion,
  defaultLanguage,
  onSaveResponse,
  onLoadResponse,
}) => {
  const { pathname } = useLocation();

  const { toggle, isOpen } = useNewModal();
  const [languageOptions, setLanguageOptions] = useState([]);
  const [responseCode, setResponseCode] = useState('');

  const isPreview = pathname.split('/').at(-1) === 'preview';

  const {
    loading: fetchingResponseSave,
    success: successFetchingResponseSave,
  } = useSelector(selectFetchResponseInProgressState);

  const {
    responseCode: createdResponseCode,
    loading: creatingResponseSave,
    success: successCreatingResponseSave,
  } = useSelector(selectCreateResponseInProgressState);

  const availableLanguagesMemo = useMemo(
    () => JSON.stringify(availableLanguages),
    [availableLanguages]
  );

  useEffect(() => {
    const options = [];

    if (availableLanguages) {
      availableLanguages.forEach(({ code, originalName }) => {
        const isLanguageComplete = languageCompletion[code] === 100;

        if (isLanguageComplete || code === defaultLanguage) {
          options.push({
            label: originalName,
            value: code,
          });
        }
      });
    }

    setLanguageOptions(options);
  }, [languageCompletion, defaultLanguage, availableLanguagesMemo]);

  const onLoadResponseHandler = () => {
    onLoadResponse(responseCode);
  };

  const onChangeResponseCodeHandler = ({ target: { value } }) =>
    setResponseCode(value);

  useEffect(() => {
    if (successFetchingResponseSave) {
      setResponseCode('');
      toggle();
    }
  }, [successFetchingResponseSave]);

  useEffect(() => {
    if (!successCreatingResponseSave) {
      setResponseCode('');
    }
  }, [successCreatingResponseSave]);

  const loading = creatingResponseSave || fetchingResponseSave;

  return (
    <>
      <NewModal
        title={<SaveLoadTitle />}
        isOpen={isOpen}
        toggle={loading ? null : toggle}
      >
        <div className="field">
          <span className="label">Save Response:</span>
          <div className={classes['save-response-content']}>
            <button
              className="button is-info"
              onClick={onSaveResponse}
              disabled={loading}
            >
              {creatingResponseSave ? (
                <Spinner size="18" color={colors.white} />
              ) : (
                'Save'
              )}
            </button>
            {successCreatingResponseSave && (
              <span className="label">
                Response Code:&nbsp;
                <span className={classes['response-code']}>
                  {createdResponseCode}
                </span>
              </span>
            )}
          </div>
        </div>
        <div className="field">
          <span className="label">Load Response:</span>
          <div className={classes['load-section']}>
            <input
              className="input"
              value={responseCode}
              onChange={onChangeResponseCodeHandler}
              disabled={loading}
            />
            <button
              className="button is-info"
              onClick={onLoadResponseHandler}
              disabled={loading}
            >
              {fetchingResponseSave ? (
                <Spinner size="18" color={colors.white} />
              ) : (
                'Load'
              )}
            </button>
          </div>
        </div>
      </NewModal>
      <div className={classNames('container', classes.page)}>
        <div className={classes['page-header-container']}>
          <div className={classes['page-title']}>
            <div className={classNames('level-item', classes['w-100'])}>
              {title && (
                <h1 id="title" className="title is-2">
                  {title}
                </h1>
              )}
            </div>
          </div>
          <div
            className={classNames(
              'level-item',
              classes['language-and-save-container']
            )}
          >
            {availableLanguages && (
              <div style={{ width: '100%' }}>
                <Select
                  styles={{
                    menu: (base) => ({ ...base, zIndex: 2 }),
                  }}
                  value={selectedLanguage}
                  options={languageOptions}
                  onChange={onChangeLanguage}
                />
              </div>
            )}
            {availableLanguages && !isPreview && (
              <button onClick={toggle} type="button" className="button is-info">
                <span className="icon">
                  <i className="mdi mdi-file-arrow-up-down-outline mdi-24px" />
                </span>
              </button>
            )}
          </div>
        </div>
        {subtitle && (
          <h2 id="subtitle" className="title is-3 is-spaced">
            {subtitle}
          </h2>
        )}
        <hr />
        <div id="children-placeholder">{children}</div>
      </div>
    </>
  );
};

Page.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  selectedLanguage: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  availableLanguages: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
    })
  ),
  onChangeLanguage: PropTypes.func,
  onSaveResponse: PropTypes.func.isRequired,
  onLoadResponse: PropTypes.func.isRequired,
};

Page.defaultProps = {
  title: '',
  subtitle: '',
  selectedLanguage: null,
  availableLanguages: null,
  onChangeLanguage: null,
};

export default Page;
