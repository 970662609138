import { SurveyDeploymentStatus } from '../enums';

const getDeploymentStatus = (startDate, endDate, waveStatus) => {
  if (waveStatus === null) {
    const currentDate = new Date();

    if (endDate === null && currentDate >= startDate) {
      return SurveyDeploymentStatus.ACTIVE;
    }

    if (endDate === null && currentDate < startDate) {
      return SurveyDeploymentStatus.PENDING;
    }

    if (endDate && currentDate > endDate) {
      return SurveyDeploymentStatus.CLOSED;
    }

    if (currentDate < startDate) {
      return SurveyDeploymentStatus.PENDING;
    }

    return SurveyDeploymentStatus.ACTIVE;
  }
  return SurveyDeploymentStatus.INACTIVE;
};

export default getDeploymentStatus;
