import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import classes from './Collapse.module.scss';

const Collapse = ({ className, collapsed, onCollapse }) => (
  <button
    type="button"
    className={classNames('button', className)}
    onClick={onCollapse}
  >
    <span
      className={classNames('icon', classes.icon, {
        [classes['icon-collapsed']]: collapsed,
      })}
    >
      <i className="mdi mdi-chevron-down" />
    </span>
  </button>
);

Collapse.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  onCollapse: PropTypes.func.isRequired,
  className: PropTypes.string,
};

Collapse.defaultProps = {
  className: '',
};

export default Collapse;
