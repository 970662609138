import React from 'react';
import classNames from 'classnames';

import classes from './EmptyList.module.scss';

const EmptyList = () => (
  <section
    className={classNames('modal-card-body', classes['modal-card-empty-body'])}
  >
    <div className={classNames('field', classes.information)}>
      <div className={classNames('icon', classes.icon)}>
        <i className="mdi mdi-alert mdi-48px" />
      </div>
      <small className="is-size-4">
        This survey does not have any actions yet.
      </small>
      <small>Use the create action button above to create a new one.</small>
    </div>
  </section>
);

export default EmptyList;
