import { createReducer } from 'redux-act';

import {
  AUTH_SIGN_IN_INIT,
  AUTH_SIGN_IN_FAIL,
  AUTH_FETCH_USER_DATA_INIT,
  AUTH_FETCH_USER_DATA_SUCCESS,
  AUTH_FETCH_USER_DATA_FAIL,
  AUTH_LOGOUT_INIT,
  AUTH_LOGOUT_SUCCESS,
  AUTH_RESTORE_SESSION_INIT,
  AUTH_RESTORE_SESSION_SUCCESS,
  AUTH_RESTORE_SESSION_FAIL,
  AUTH_SET_PASSWORD_INIT,
  AUTH_SET_PASSWORD_SUCCESS,
  AUTH_SET_PASSWORD_FAIL,
  AUTH_RESET_PASSWORD_INIT,
  AUTH_RESET_PASSWORD_SUCCESS,
  AUTH_RESET_PASSWORD_FAIL,
  AUTH_CLEAN_UP,
  AUTH_CHANGE_PASSWORD_INIT,
  AUTH_CHANGE_PASSWORD_SUCCESS,
  AUTH_CHANGE_PASSWORD_FAIL,
  AUTH_UPDATE_USER_DATA,
} from 'state/actions/auth';

export const initialState = {
  userData: {
    id: null,
    role: null,
  },
  loading: false,
  error: null,
  restoring: false,
  restoringError: null,
  restoredPassword: false,
  changedPassword: false,
};

export const authReducer = createReducer(
  {
    [AUTH_SIGN_IN_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [AUTH_SIGN_IN_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [AUTH_FETCH_USER_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [AUTH_FETCH_USER_DATA_SUCCESS]: (
      state,
      {
        id,
        role,
        email,
        firstName,
        lastName,
        active,
        manager,
        logoUrl,
        groups,
        organizationName,
        createdAt,
        createdBy,
      }
    ) => ({
      ...state,
      userData: {
        id,
        role,
        email,
        firstName,
        lastName,
        active,
        manager,
        logoUrl,
        groups,
        organizationName,
        createdAt,
        createdBy,
      },
      loading: false,
      error: null,
    }),
    [AUTH_FETCH_USER_DATA_FAIL]: (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
    [AUTH_LOGOUT_INIT]: () => ({ ...initialState }),
    [AUTH_LOGOUT_SUCCESS]: (state) => ({ ...state }),
    [AUTH_RESTORE_SESSION_INIT]: (state) => ({ ...state, restoring: true }),
    [AUTH_RESTORE_SESSION_SUCCESS]: (state) => ({
      ...state,
      restoring: false,
      restoringError: null,
    }),
    [AUTH_RESTORE_SESSION_FAIL]: (state) => ({
      ...state,
      restoring: false,
      restoringError: true,
    }),
    [AUTH_SET_PASSWORD_INIT]: (state) => ({ ...state, loading: true }),
    [AUTH_SET_PASSWORD_SUCCESS]: (state) => ({
      ...state,
      loading: false,
      error: null,
    }),
    [AUTH_SET_PASSWORD_FAIL]: (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
    [AUTH_RESET_PASSWORD_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [AUTH_RESET_PASSWORD_SUCCESS]: (state) => ({
      ...state,
      loading: false,
      error: null,
      restoredPassword: true,
    }),
    [AUTH_RESET_PASSWORD_FAIL]: (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
    [AUTH_CLEAN_UP]: (state) => ({
      ...state,
      error: null,
      changedPassword: false,
    }),
    [AUTH_CHANGE_PASSWORD_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [AUTH_CHANGE_PASSWORD_SUCCESS]: (state) => ({
      ...state,
      loading: false,
      changedPassword: true,
    }),
    [AUTH_CHANGE_PASSWORD_FAIL]: (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
    [AUTH_UPDATE_USER_DATA]: (
      state,
      {
        id,
        role,
        firstName,
        lastName,
        active,
        manager,
        groups,
        logoUrl,
        organizationName,
      }
    ) => ({
      ...state,
      userData: {
        id,
        role,
        email: state.userData.email,
        firstName,
        lastName,
        active,
        manager,
        groups,
        logoUrl: logoUrl || state.userData.logoUrl,
        organizationName,
      },
    }),
  },
  initialState
);
