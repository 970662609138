/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useCallback, useMemo } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Select from 'react-select/creatable';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';

import {
  fetchTags,
  fetchOrganizations,
  fetchQuestionsWithDataSet,
} from 'utils/fetching';
import { createTag, clearCreateTagState } from 'state/actions/tags';
import { useChangeHandler } from 'hooks';
import paths from 'pages/Router/paths';
import Input from 'components/Input';

const Information = ({ dataSet, setDataSet, isEditing }) => {
  const { defaultOrganization, successCreatingTag, createdTag, creatingTag } =
    useSelector(
      (state) => ({
        defaultOrganization: state.preferences.defaultOrganization,
        successCreatingTag: state.tags.successCreatingTag,
        createdTag: state.tags.createdTag,
        creatingTag: state.tags.creatingTag,
      }),
      shallowEqual
    );

  const dispatch = useDispatch();

  const [organizationOptions, setOrganizationOptions] = React.useState({
    loading: false,
    fetched: false,
    data: [],
  });
  const [tagOptions, setTagOptions] = React.useState({
    loading: false,
    fetched: false,
    data: [],
  });
  const [waveOptions, setWaveOptions] = React.useState(
    isEditing && !dataSet.global ? dataSet.organization?.value.waves : []
  );
  const [isDataSetInQuestion, setIsDataSetInQuestion] = React.useState({
    loading: false,
    fetched: false,
    value: false,
    error: null,
    organization: null,
  });

  const [selectedOrganization, setSelectedOrganization] = React.useState(null);

  useEffect(() => {
    if (dataSet.global) {
      fetchTags(tagOptions, setTagOptions, 'Data Set', 'global');
    } else {
      fetchOrganizations(setOrganizationOptions);
    }

    if (dataSet.organization) {
      const organization = dataSet.organization.value.displayName;

      fetchOrganizations(setOrganizationOptions);
      fetchTags(tagOptions, setTagOptions, 'Data Set', organization);
    }
  }, [dataSet.global, dataSet.organization, tagOptions]);

  useEffect(() => {
    if (isEditing) {
      fetchQuestionsWithDataSet(
        isDataSetInQuestion,
        setIsDataSetInQuestion,
        dataSet.id
      );
    }
  }, [isEditing, isDataSetInQuestion]);

  useEffect(() => {
    if (
      isEditing &&
      dataSet.organization &&
      !selectedOrganization &&
      isDataSetInQuestion.value
    ) {
      setSelectedOrganization(dataSet.organization);
    }
  }, [
    isEditing,
    dataSet.organization,
    isDataSetInQuestion,
    selectedOrganization,
  ]);

  useEffect(() => {
    if (
      isEditing &&
      !dataSet.organization &&
      isDataSetInQuestion.organization
    ) {
      let availableOrganization = null;
      organizationOptions.data.forEach((organization) => {
        if (organization.value.id === isDataSetInQuestion.organization) {
          availableOrganization = organization;
        }
      });
      setSelectedOrganization(availableOrganization);
    }
  }, [
    isEditing,
    dataSet.organization,
    isDataSetInQuestion,
    organizationOptions,
  ]);

  useEffect(() => {
    if (
      isEditing &&
      !dataSet.global &&
      !dataSet.organization &&
      isDataSetInQuestion.value
    ) {
      setDataSet((prevState) => ({
        ...prevState,
        organization: selectedOrganization,
      }));
    }
  }, [isEditing, dataSet.global, isDataSetInQuestion, selectedOrganization]);

  const onChangeHandler = useChangeHandler(setDataSet);

  const onChangeTitleHandler = ({ target }) => {

    setDataSet((prevState) => ({
      ...prevState,
      title: {
        ...prevState.title,
        en: target.value,
      },
    }));
  };

  const onOrganizationChangeHandler = useCallback(
    (select) => {
      const { waves } = select.value;
      setDataSet((prevState) => ({
        ...prevState,
        organization: select,
        tag: null,
        startWave: null,
      }));
      setTagOptions((prevState) => ({ ...prevState, fetched: false }));
      setWaveOptions(waves);
    },
    [setDataSet]
  );

  useEffect(() => {
    if (
      !isEditing &&
      !dataSet.global &&
      organizationOptions.fetched &&
      defaultOrganization
    ) {
      const organization = organizationOptions.data
        .filter((org) => org.label === defaultOrganization.label)
        .pop();
      onOrganizationChangeHandler(organization);
    }
  }, [
    defaultOrganization,
    dataSet.global,
    isEditing,
    organizationOptions.fetched,
    onOrganizationChangeHandler,
    organizationOptions.data,
  ]);

  useEffect(() => {
    if (successCreatingTag) {
      const { displayName, organizationName, type, id } = createdTag;
      setDataSet((prevState) => ({
        ...prevState,
        tag: {
          label: displayName,
          value: {
            tagDisplayName: displayName,
            tagOrganizationName: organizationName,
            tagType: type,
            tagId: id,
          },
        },
      }));
      dispatch(clearCreateTagState());
      setTagOptions((prevState) => ({ ...prevState, fetched: false }));
    }
  }, [successCreatingTag, createdTag, dispatch, setDataSet]);

  const onTagChangeHandler = useCallback(
    (select) => setDataSet((prevState) => ({ ...prevState, tag: select })),
    [setDataSet]
  );

  const onStartWaveChangeHandler = useCallback(
    (select) =>
      setDataSet((prevState) => ({
        ...prevState,
        startWave: select,
      })),
    [setDataSet]
  );

  const onGlobalChangeHandler = useCallback(
    (event) => {
      const { checked } = event.target;
      setDataSet((prevState) => ({
        ...prevState,
        global: checked,
        organization: null,
        tag: null,
        startWave: null,
      }));
      setWaveOptions([]);
      setTagOptions((prevState) => ({
        ...prevState,
        data: [],
        fetched: false,
      }));
    },
    [setDataSet]
  );

  const redirect = useMemo(
    () =>
      (organizationOptions.error || tagOptions.error) && (
        <Redirect to={paths.DATA_SETS} />
      ),
    [organizationOptions.error, tagOptions.error]
  );

  const organizationPlaceholder = useMemo(
    () =>
      organizationOptions.fetched && organizationOptions.data.length === 0
        ? 'No organizations available in the system.'
        : 'Select...',
    [organizationOptions.fetched, organizationOptions.data.length]
  );

  const tagPlaceHolder = useMemo(
    () =>
      // eslint-disable-next-line no-nested-ternary
      tagOptions.fetched && tagOptions.data.length === 0 && dataSet.organization
        ? `${dataSet.organization.value.displayName} organization has no tags.`
        : tagOptions.data.length === 0 && dataSet.global
        ? 'No global tags created.'
        : 'Select...',
    [
      tagOptions.fetched,
      tagOptions.data.length,
      dataSet.organization,
      dataSet.global,
    ]
  );

  const onCreateTagHandler = useCallback(
    (tagName) => {
      const { organization, global } = dataSet;
      dispatch(
        createTag({
          name: tagName,
          type: 'Data Set',
          active: true,
          global,
          organization: organization?.value.displayName,
        })
      );
    },
    [dispatch, dataSet]
  );

  return (
    <>
      {redirect}
      <div className="card">
        <header className="card-header">
          <p className="card-header-title">
            <span className="icon">
              <i className="mdi mdi-information" />
            </span>
            Information
          </p>
        </header>
        <div className="card-content">
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Title</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <Input
                    name="title"
                    value={dataSet.title.en}
                    onChange={onChangeTitleHandler}
                  />
                </div>
                <p className="help">
                  The title that will be displayed on the live survey.
                </p>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Organization</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div
                  className="control"
                  data-tip={
                    isDataSetInQuestion.value && !dataSet.global
                      ? 'This data set is already being used in a question from a survey of this organization'
                      : ''
                  }
                >
                  <Select
                    placeholder={organizationPlaceholder}
                    value={dataSet.organization}
                    options={organizationOptions.data}
                    isDisabled={
                      dataSet.global ||
                      organizationOptions.loading ||
                      organizationOptions.data.length === 0 ||
                      isDataSetInQuestion.loading ||
                      isDataSetInQuestion.value
                    }
                    isLoading={
                      organizationOptions.loading || isDataSetInQuestion.loading
                    }
                    onChange={onOrganizationChangeHandler}
                  />
                </div>
                <p className="help">
                  Specify which organization this data set should be used in.
                </p>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Tag Name</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <Select
                    placeholder={tagPlaceHolder}
                    value={dataSet.tag}
                    options={tagOptions.data}
                    isDisabled={
                      tagOptions.loading ||
                      (!dataSet.organization && !dataSet.global) ||
                      creatingTag
                    }
                    isLoading={tagOptions.loading || creatingTag}
                    onChange={onTagChangeHandler}
                    onCreateOption={onCreateTagHandler}
                  />
                </div>
                <p className="help">
                  The tag this data set should be linked to. Type the name of a
                  tag that doesn&apos;t exist to create it.
                </p>
              </div>
            </div>
          </div>
          <ReactTooltip />
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Start Wave</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <Select
                    value={dataSet.startWave}
                    options={waveOptions}
                    isDisabled={dataSet.global || waveOptions.length === 0}
                    onChange={onStartWaveChangeHandler}
                  />
                </div>
                <p className="help">
                  The first wave this data set should be available. Optional.
                </p>
              </div>
            </div>
          </div>
          <div className="field has-check is-horizontal">
            <div className="field-label">
              <label className="label">Global</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div
                  className="control"
                  data-tip={
                    isDataSetInQuestion.value &&
                    dataSet.global &&
                    !isDataSetInQuestion.organization
                      ? 'This data set is already being used in a question from a global survey'
                      : ''
                  }
                >
                  <label className="b-checkbox checkbox">
                    <input
                      id="global-checkbox"
                      type="checkbox"
                      name="global"
                      checked={dataSet.global}
                      onChange={onGlobalChangeHandler}
                      disabled={
                        isDataSetInQuestion.value &&
                        dataSet.global &&
                        !isDataSetInQuestion.organization
                      }
                    />
                    <span className="check is-primary" />
                  </label>
                </div>
                <p className="help">
                  Specify whether this data set should be global or not.
                </p>
              </div>
            </div>
          </div>
          <div className="field has-check is-horizontal">
            <div className="field-label">
              <label className="label">Active</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <label className="b-checkbox checkbox">
                    <input
                      id="active-checkbox"
                      type="checkbox"
                      name="active"
                      onChange={onChangeHandler}
                      checked={dataSet.active}
                    />
                    <span className="check is-primary" />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Information.propTypes = {
  dataSet: PropTypes.exact({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    global: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
    startWave: PropTypes.exact({
      label: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
    }),
    tag: PropTypes.shape({
      label: PropTypes.string.isRequired,
    }),
    organization: PropTypes.exact({
      label: PropTypes.string.isRequired,
      value: PropTypes.shape({
        id: PropTypes.string.isRequired,
        displayName: PropTypes.string.isRequired,
        waves: PropTypes.arrayOf(
          PropTypes.exact({
            label: PropTypes.number.isRequired,
            value: PropTypes.number.isRequired,
          })
        ).isRequired,
      }).isRequired,
    }),
  }).isRequired,
  setDataSet: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
};

export default Information;
