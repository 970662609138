import React from 'react';
import PropTypes from 'prop-types';

const Collapsable = ({ children, collapsed }) => {
  return <>{!collapsed && children}</>;
};

Collapsable.propTypes = {
  collapsed: PropTypes.bool.isRequired,
};

export default Collapsable;
