import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';
import algoliasearch from 'algoliasearch';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { REACT_APP_ALGOLIA_WAVES_INDEX } from 'constants/environment';
import firebase from 'firebase.js';
import { firebaseError } from 'utils';

dayjs.extend(utc);
dayjs.extend(timezone);

export const WAVES_FETCH_DATA_INIT = createAction('WAVES_FETCH_DATA_INIT');
export const WAVES_FETCH_DATA_FAIL = createAction('WAVES_FETCH_DATA_FAIL');
export const WAVES_FETCH_DATA_SUCCESS = createAction(
  'WAVES_FETCH_DATA_SUCCESS'
);
export const WAVES_CREATE_WAVE_INIT = createAction('WAVES_CREATE_WAVE_INIT');
export const WAVES_CREATE_WAVE_SUCCESS = createAction(
  'WAVES_CREATE_WAVE_SUCCESS'
);
export const WAVES_CREATE_WAVE_FAIL = createAction('WAVES_CREATE_WAVE_FAIL');
export const WAVES_DELETE_WAVE_INIT = createAction('WAVES_DELETE_WAVE_INIT');
export const WAVES_DELETE_WAVE_SUCCESS = createAction(
  'WAVES_DELETE_WAVE_SUCCESS'
);
export const WAVES_DELETE_WAVE_FAIL = createAction('WAVES_DELETE_WAVE_FAIL');
export const WAVES_CLEAR_DATA = createAction('WAVES_CLEAR_DATA');
export const WAVES_MODIFY_WAVE_INIT = createAction('WAVES_MODIFY_WAVE_INIT');
export const WAVES_MODIFY_WAVE_SUCCESS = createAction(
  'WAVES_MODIFY_WAVE_SUCCESS'
);
export const WAVES_MODIFY_WAVE_FAIL = createAction('WAVES_MODIFY_WAVE_FAIL');
export const WAVES_CLEAR_UPDATED_WAVE = createAction(
  'WAVES_CLEAR_UPDATED_WAVE'
);

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_ADMIN_KEY
);
const indexWaves = searchClient.initIndex(
  REACT_APP_ALGOLIA_WAVES_INDEX
);

const isWaveDuplicated = async ({ index, organizationName, waveId }) => {
  const duplicateWave = await firebase
    .firestore()
    .collection('waves')
    .where('organizationName', '==', organizationName)
    .where('index', '==', index)
    .get();

  return !duplicateWave.empty && duplicateWave.docs[0].id !== waveId;
};

export const createWave = ({
  index,
  name,
  startingOn,
  until,
  expectedResponses,
  reportingThreshold,
  organizationName,
  organizationId,
}) => {
  return async (dispatch, getState) => {
    dispatch(WAVES_CREATE_WAVE_INIT());
    const { locale } = getState().preferences;
    const { userData } = getState().auth;

    const startingOnParsed = dayjs(startingOn)
      .tz('America/Toronto')
      .startOf('day')
      .toDate();

    let untilParsed;

    if (until) {
      untilParsed = dayjs(until).tz('America/Toronto').endOf('day').toDate();
    }

    const wave = {
      index,
      name,
      startingOn: firebase.firestore.Timestamp.fromDate(startingOnParsed),
      until: untilParsed
        ? firebase.firestore.Timestamp.fromDate(untilParsed)
        : null,
      expectedResponses,
      reportingThreshold,
      organizationName,
      organizationId,
      responses: 0,
      processed: 0,
      invalid: 0,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      createdBy: `${userData.firstName} ${userData.lastName}`,
      hierarchy: [],
      active: null,
    };
    let duplicatedWave;

    try {
      duplicatedWave = await isWaveDuplicated(wave);

      if (!duplicatedWave) {
        const { id } = await firebase.firestore().collection('waves').add(wave);

        await indexWaves
          .saveObject({
            objectID: id,
            ...wave,
            startingOn: +new Date(startingOn),
            until: until ? +new Date(until) : null,
            createdAt: +new Date(),
          })
          .wait();

        toastr.success('', 'Wave created successfully');
        return dispatch(WAVES_CREATE_WAVE_SUCCESS());
      }
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        WAVES_CREATE_WAVE_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.error('', 'The wave index already exists!');
    return dispatch(
      WAVES_CREATE_WAVE_FAIL({
        error: 'The wave index already exists!',
      })
    );
  };
};

export const modifyWave = ({
  index,
  name,
  startingOn,
  until,
  expectedResponses,
  reportingThreshold,
  organizationName,
  organizationId,
  waveId,
  hierarchy,
  isUpdatingHierarchy,
}) => {
  return async (dispatch, getState) => {
    dispatch(WAVES_MODIFY_WAVE_INIT());

    const { locale } = getState().preferences;

    const waveData = {
      name,
      startingOn: firebase.firestore.Timestamp.fromDate(new Date(startingOn)),
      until: until
        ? firebase.firestore.Timestamp.fromDate(new Date(until))
        : null,
      expectedResponses,
      reportingThreshold,
      organizationName,
      organizationId,
      hierarchy,
    };

    let duplicatedWave;

    const updateWaveInDb = firebase
      .firestore()
      .collection('waves')
      .doc(waveId)
      .update(waveData);

    const updateWaveInAlgolia = indexWaves
      .partialUpdateObject({
        objectID: waveId,
        ...waveData,
        startingOn: +new Date(startingOn),
        until: until ? +new Date(until) : null,
      })
      .wait();

    try {
      duplicatedWave = await isWaveDuplicated({
        index,
        waveId,
        organizationName,
      });

      if (!duplicatedWave) {
        await Promise.all([updateWaveInDb, updateWaveInAlgolia]);

        if (isUpdatingHierarchy) {
          toastr.success('', 'Wave hierarchy updated successfully');
        } else {
          toastr.success('', 'Wave updated successfully');
        }

        return dispatch(WAVES_MODIFY_WAVE_SUCCESS({ ...waveData, waveId }));
      }
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        WAVES_MODIFY_WAVE_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.error('', 'The wave index already exists!');
    return dispatch(
      WAVES_MODIFY_WAVE_FAIL({
        error: 'The wave index already exists!',
      })
    );
  };
};

const addWaveToArray = (array, wave) => {
  const waveData = { id: wave.id, ...wave.data() };
  array.push({
    ...waveData,
    name: waveData.name || '',
    createdAt: waveData.createdAt.toDate(),
    startingOn: waveData.startingOn.toDate(),
    until: waveData.until ? waveData.until.toDate() : null,
    organization: {
      label: waveData.organizationName,
      value: {
        displayName: waveData.organizationName,
        id: waveData.organizationId,
      },
    },
    expectedResponses:
      waveData.expectedResponses !== null ? waveData.expectedResponses : '',
  });
};

export const fetchWaves = (options, id = null) => {
  return async (dispatch) => {
    dispatch(WAVES_FETCH_DATA_INIT());

    const waves = [];

    try {
      let baseQuery = firebase.firestore().collection('waves');
      if (id) {
        const wave = await baseQuery.doc(id).get();
        if (!wave.exists) {
          toastr.error('', 'Wave id does not exist!');
          return dispatch(WAVES_FETCH_DATA_FAIL({ error: true }));
        }
        addWaveToArray(waves, wave);
      } else {
        if (options.filterByOrganization) {
          const { filterByOrganization } = options;
          baseQuery = baseQuery.where(
            'organizationName',
            '==',
            filterByOrganization
          );
        }

        if (options.orderBy) {
          const { orderBy } = options;
          baseQuery = baseQuery.orderBy(orderBy.attribute, orderBy.order);
        }
        const allWaves = await baseQuery.get();
        allWaves.forEach((wave) => {
          addWaveToArray(waves, wave);
        });
      }
    } catch (error) {
      toastr.error(error.message);
      return dispatch(WAVES_FETCH_DATA_FAIL({ error }));
    }

    return dispatch(
      WAVES_FETCH_DATA_SUCCESS({
        waves,
      })
    );
  };
};

export const deleteWave = (id) => {
  return async (dispatch, getState) => {
    dispatch(WAVES_DELETE_WAVE_INIT());

    const { locale } = getState().preferences;

    const deleteUserFromDb = firebase
      .firestore()
      .collection('waves')
      .doc(id)
      .delete();

    const deleteUserFromAlgolia = indexWaves.deleteObject(id).wait();

    try {
      await Promise.all([deleteUserFromDb, deleteUserFromAlgolia]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        WAVES_DELETE_WAVE_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'The wave was deleted.');
    return dispatch(WAVES_DELETE_WAVE_SUCCESS({ id }));
  };
};

export const clearData = () => (dispatch) => dispatch(WAVES_CLEAR_DATA());

export const clearUpdatedWave = () => (dispatch) =>
  dispatch(WAVES_CLEAR_UPDATED_WAVE());
