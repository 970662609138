/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Select from 'react-select';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { useFormatDate } from 'hooks';
import { fetchWaves } from 'state/actions/waves';
import { validSiteNameCharacters } from 'utils';
import { selectCreateDeploymentModalState } from 'state/selectors/surveys';

const CreateDeploymentModal = ({
  organizationName,
  onCreateDeployment,
  onCancel,
  defaultUrl,
}) => {
  const dispatch = useDispatch();

  const { waves, loadingWaveOptions, creatingDeployment } = useSelector(
    selectCreateDeploymentModalState,
    shallowEqual
  );

  const [deployment, setDeployment] = useState({
    url: defaultUrl || uuidv4(),
    organizationName,
    waveId: null,
    startDate: null,
    endDate: null,
  });

  useEffect(() => {
    dispatch(
      fetchWaves({
        filterByOrganization: deployment.organizationName,
        orderBy: { attribute: 'createdAt', order: 'asc' },
      })
    );
  }, [dispatch, deployment.organizationName]);

  const onGenerateUrlHandler = useCallback(
    () => setDeployment((prevState) => ({ ...prevState, url: uuidv4() })),
    []
  );

  const onSelectWaveHandler = useCallback(
    ({ value }) =>
      setDeployment((prevState) => ({
        ...prevState,
        waveName: value.name,
        waveIndex: value.index,
        waveId: value.id,
        waveStatus: value.active,
        startDate: value.startingOn,
        endDate: value.until,
      })),
    []
  );

  const onChangeUrlHandler = useCallback(
    (url) => setDeployment((prevState) => ({ ...prevState, url })),
    []
  );

  const onSubmitHandler = useCallback(
    (e) => {
      e.preventDefault();
      onCreateDeployment(deployment);
    },
    [deployment, onCreateDeployment]
  );

  const startingDate = useFormatDate(deployment.startDate, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  const endDate = useFormatDate(deployment.endDate, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  const canSubmit = useMemo(
    () =>
      deployment.organizationName &&
      deployment.waveId &&
      validSiteNameCharacters(deployment.url) &&
      deployment.startDate,
    [
      deployment.organizationName,
      deployment.waveId,
      deployment.url,
      deployment.startDate,
    ]
  );

  return (
    <div className="modal is-active">
      <div
        id="background"
        className="modal-background"
        onClick={!creatingDeployment ? onCancel : undefined}
      />
      <div className="modal-content">
        <form id="submit" onSubmit={onSubmitHandler}>
          <header className="modal-card-head">
            <p className="modal-card-title">Create Deployment</p>
            <button
              id="secondary-cancel-button"
              type="button"
              className="delete"
              aria-label="close"
              disabled={creatingDeployment}
              onClick={onCancel}
            />
          </header>
          <section className="modal-card-body">
            <div className="field">
              <label className="label">Organization</label>
              <div className="control">
                <input
                  className="input is-static"
                  type="text"
                  readOnly
                  value={deployment.organizationName}
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Wave</label>
              <div id="portalTarget" className="control">
                <Select
                  isLoading={loadingWaveOptions}
                  options={waves}
                  onChange={onSelectWaveHandler}
                  menuPortalTarget={document.getElementById('portalTarget')}
                  styles={{
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  menuPosition="fixed"
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Start date</label>
              <div className="control">
                <input
                  className="input is-static"
                  type="text"
                  readOnly
                  value={deployment.startDate ? startingDate : ''}
                />
              </div>
            </div>
            <div className="field">
              <label className="label">End date</label>
              <div className="control">
                <input
                  className="input is-static"
                  type="text"
                  readOnly
                  value={deployment.endDate ? endDate : ''}
                />
              </div>
            </div>
            <div className="field">
              <label className="label">URL</label>
              <div className="field has-addons">
                <div className="control is-expanded">
                  <input
                    className="input"
                    type="text"
                    value={deployment.url}
                    onChange={(e) => onChangeUrlHandler(e.target.value)}
                  />
                </div>
                <div className="control">
                  <button
                    type="button"
                    className="button is-info"
                    onClick={onGenerateUrlHandler}
                  >
                    Generate
                  </button>
                </div>
              </div>
            </div>
          </section>
          <footer className="modal-card-foot">
            <button
              id="submit-button"
              type="submit"
              className={classNames('button is-success', {
                'is-loading': creatingDeployment,
              })}
              disabled={!canSubmit}
            >
              Deploy Survey
            </button>
            <button
              id="primary-cancel-button"
              type="button"
              className="button is-light"
              disabled={creatingDeployment}
              onClick={onCancel}
            >
              Cancel
            </button>
          </footer>
        </form>
      </div>
    </div>
  );
};

CreateDeploymentModal.propTypes = {
  organizationName: PropTypes.string.isRequired,
  onCreateDeployment: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  defaultUrl: PropTypes.string,
};

CreateDeploymentModal.defaultProps = {
  defaultUrl: '',
};

export default CreateDeploymentModal;
