import { createAction } from 'redux-act';

export const TAGS_FETCH_DATA_INIT = createAction('TAGS_FETCH_DATA_INIT');
export const TAGS_FETCH_DATA_SUCCESS = createAction('TAGS_FETCH_DATA_SUCCESS');
export const TAGS_FETCH_DATA_FAIL = createAction('TAGS_FETCH_DATA_FAIL');

export const TAGS_FETCH_RELATED_DATA_INIT = createAction(
  'TAGS_FETCH_RELATED_DATA_INIT'
);
export const TAGS_FETCH_RELATED_DATA_SUCCESS = createAction(
  'TAGS_FETCH_RELATED_DATA_SUCCESS'
);
export const TAGS_FETCH_RELATED_DATA_FAIL = createAction(
  'TAGS_FETCH_RELATED_DATA_FAIL'
);

export const TAGS_CREATE_TAG_INIT = createAction('TAGS_CREATE_TAG_INIT');
export const TAGS_CREATE_TAG_SUCCESS = createAction('TAGS_CREATE_TAG_SUCCESS');
export const TAGS_CREATE_TAG_FAIL = createAction('TAGS_CREATE_TAG_FAIL');

export const TAGS_CLEAR_CREATE_TAG_STATE = createAction(
  'TAGS_RESET_CREATE_TAG_STATE'
);

export const TAGS_MODIFY_TAG_INIT = createAction('TAGS_MODIFY_TAG_INIT');
export const TAGS_MODIFY_TAG_SUCCESS = createAction('TAGS_MODIFY_TAG_SUCCESS');
export const TAGS_MODIFY_TAG_FAIL = createAction('TAGS_MODIFY_TAG_FAIL');

export const TAGS_DELETE_TAG_INIT = createAction('TAGS_DELETE_TAG_INIT');
export const TAGS_DELETE_TAG_SUCCESS = createAction('TAGS_DELETE_TAG_SUCCESS');
export const TAGS_DELETE_TAG_FAIL = createAction('TAGS_DELETE_TAG_FAIL');

export const TAGS_CLEAR_DATA = createAction('TAGS_CLEAR_DATA');

export const TAGS_REMOVE_TAG = createAction('TAGS_REMOVE_TAG');

export const TAGS_ADD_TAG = createAction('TAGS_ADD_TAG');
