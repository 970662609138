import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Page from 'components/SurveyBuilder/Page';
import DropArea from 'components/DragAndDrop/DropArea';
import DragItem from 'components/DragAndDrop/DragItem';

import classes from './Pages.module.scss';

const Pages = ({
  survey,
  setSurvey,
  pages,
  onAddPage,
  onOpenModal,
  deploymentId,
}) => (
  <>
    {pages.length === 0 && (
      <div
        id="empty-survey-message"
        className={classNames(
          'container',
          'has-text-centered',
          classes['vertical-align']
        )}
      >
        <h3 className="title is-4">This Survey is empty.</h3>
        <h4 className="subtitle is-6">
          Click the button above to add a new page to this survey.
        </h4>
      </div>
    )}
    <DropArea dropId="pages" type="page">
      {pages.map(({ title, visible, id, sections }, index) => (
        <DragItem key={id} dragId={id} index={index}>
          <Page
            survey={survey}
            setSurvey={setSurvey}
            pageId={id}
            pageIndex={index}
            title={title}
            visible={visible}
            onAddPage={onAddPage}
            sections={sections}
            onOpenModal={onOpenModal}
            deploymentId={deploymentId}
          />
        </DragItem>
      ))}
    </DropArea>
  </>
);

Pages.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
};

export default Pages;
