import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import ReactTooltip from 'react-tooltip';

import TextEditor, { toolbar } from 'components/TextEditor';
import QuestionTag from 'components/SurveyBuilder/QuestionTag';
import classes from './Question.module.scss';

const getInitialState = (richText, content) => {
  if (richText) {
    const blocksFromHtml = htmlToDraft(richText);

    const { contentBlocks, entityMap } = blocksFromHtml;

    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );

    const editorState = EditorState.createWithContent(contentState);

    return {
      editorState,
    };
  }

  return {
    editorState: EditorState.createWithContent(
      ContentState.createFromText(content || '')
    ),
  };
};

const Question = ({
  id,
  index,
  number,
  content,
  richText,
  visible,
  tags,
  onChange,
  onChangeVisibility,
  onDelete,
  onDeleteTag,
  required,
  onChangeRequired,
  deploymentId,
  onEdit,
  questionId,
  editedQuestion,
}) => {
  const onDeleteTagHandler = useCallback(
    ({ id: tagId }) => {
      onDeleteTag({ tagId, questionId: id });
    },
    [onDeleteTag, id]
  );

  const onDeleteQuestionHandler = useCallback(
    () => onDelete(id),
    [id, onDelete]
  );

  const onChangeRequiredHandler = useCallback(
    () => onChangeRequired(id),
    [id, onChangeRequired]
  );

  const onEditQuestionHandler = useCallback(() => {
    onEdit(questionId);
  }, [questionId, onEdit]);

  const [questionContent, setQuestionContent] = useState(() => {
    return getInitialState(richText, content);
  });

  const questionPlainText = questionContent.editorState
    .getCurrentContent()
    .getPlainText();
  
  const questionRichText = draftToHtml(
    convertToRaw(questionContent.editorState.getCurrentContent())
  );

  const onChangeRichTextHandler = useCallback(
    (editorState) => {
      setQuestionContent({ editorState });
    },
    [onChange, id]
  );

  useEffect(() => {
    onChange({ questionId: id, content: questionPlainText, richText: questionRichText });
  }, [questionPlainText, questionRichText, onChange, id]);

  return (
    <div className={classNames('field is-grouped', classes.question)}>
      <div className={classNames('control', classes['number-label'])}>
        <p
          id="question-number-placeholder"
          className={classes.number}
        >{`${number}.`}</p>
      </div>
      <div
        className={classNames(
          'control is-expanded',
          classes['input-tag-container']
        )}
      >
        <TextEditor
          textEditorState={questionContent}
          onEditorStateChange={onChangeRichTextHandler}
          editorClassName={classes['markdown-editor']}
          overrideClasses={false}
          toolbar={{...toolbar, options: ['inline', 'colorPicker']}}
        />
        <div className={classNames('tags', classes.tags)}>
          {tags.map((tag) => (
            <QuestionTag
              key={tag.id}
              tag={tag}
              onRemove={onDeleteTagHandler}
              inQuestionGroupBuilder
            />
          ))}
        </div>
      </div>
      <div className={classNames('control', classes['choice-options'])}>
        <button
          id="question-required-button"
          className="button is-white"
          onClick={onChangeRequiredHandler}
          data-tip="Required"
          data-for="choices-options"
        >
          <div
            className={classNames(
              'icon',
              'is-medium',
              classes['required-icon'],
              'question-choice__action'
            )}
          >
            <i
              className={
                required
                  ? 'mdi mdi-checkbox-marked'
                  : 'mdi mdi-checkbox-blank-outline'
              }
            />
          </div>
        </button>
        <button
          id="change-visibility-question-choice"
          className={classNames('icon', 'is-medium', 'question-choice__action')}
          data-tip="Visibility"
          data-for="choices-options"
          type="button"
          onClick={() => onChangeVisibility(index)}
        >
          <i
            className={classNames('mdi', {
              'mdi-eye': visible,
              'mdi-eye-off': !visible,
            })}
          />
        </button>
        {!deploymentId && (
          <button
            id="question-delete-button"
            className="button is-white"
            data-tip="Delete"
            data-for="choices-options"
            onClick={onDeleteQuestionHandler}
            disabled={deploymentId}
          >
            <div
              className={classNames(
                'icon',
                'is-medium',
                'question-choice__action'
              )}
            >
              <i className="mdi mdi-close-circle-outline" />
            </div>
          </button>
        )}
        {!deploymentId && (
          <button
            id="question-edit-button"
            className="button is-white"
            data-tip={
              !editedQuestion ? 'Edit' : 'Update previous question first'
            }
            data-for="choices-options"
            onClick={!editedQuestion ? onEditQuestionHandler : undefined}
            disabled={deploymentId}
          >
            <div
              className={classNames(
                'icon',
                'is-medium',
                'question-choice__action'
              )}
            >
              <i className="mdi mdi-pencil" />
            </div>
          </button>
        )}
        <ReactTooltip id="choices-options" />
      </div>
    </div>
  );
};

Question.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  number: PropTypes.number.isRequired,
  content: PropTypes.string.isRequired,
  visible: PropTypes.bool,
  tags: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeVisibility: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDeleteTag: PropTypes.func.isRequired,
  required: PropTypes.bool,
  onChangeRequired: PropTypes.func.isRequired,
};

export default Question;
