export const createSurveyObject = ({
  name,
  type,
  versionCount,
  versions,
  deploymentCount,
  deployments,
  organizationDisplaySiteName,
  organizationName,
  organizationId,
  createdBy,
  createdAt,
  languageCompletion,
}) => {
  const survey = {
    name,
    type,
    versionCount,
    versions,
    deploymentCount,
    deployments,
    organizationDisplaySiteName,
    organizationName,
    organizationId,
    createdBy,
    createdAt,
    languageCompletion,
  };

  return survey;
};
