import { toastr } from 'react-redux-toastr';

import { deleteSectionSaveService } from 'services/sectionSave';
import {
  SAVED_SECTIONS_SECTION_DELETE_FAIL,
  SAVED_SECTIONS_SECTION_DELETE_INIT,
  SAVED_SECTIONS_SECTION_DELETE_SUCCESS,
} from 'state/actionCreators/savedSections';

export const deleteSectionSave = ({ objectID }) => {
  return async (dispatch) => {
    dispatch(SAVED_SECTIONS_SECTION_DELETE_INIT());

    try {
      await deleteSectionSaveService({
        objectID,
      });
    } catch (error) {
      toastr.error('Error Deleting the Section', error);
      return dispatch(SAVED_SECTIONS_SECTION_DELETE_FAIL({ error }));
    }

    toastr.success('', 'Section Save successfully Deleted');

    return dispatch(SAVED_SECTIONS_SECTION_DELETE_SUCCESS());
  };
};
