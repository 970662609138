import React, { useState, useEffect, useMemo } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';

import paths from 'pages/Router/paths';
import { fetchGroup } from 'utils/fetching';
import { createGroup, modifyGroup } from 'state/actions/groups';
import GroupForm from './GroupForm';

const Group = () => {
  const { id } = useParams();

  const { success } = useSelector(
    (state) => ({
      success: state.groups.success,
    }),
    shallowEqual
  );

  const isEditing = useMemo(() => !!id, [id]);

  const [group, setGroup] = useState({
    data: {
      name: '',
      organization: null,
      displayName: '',
      description: '',
      manager: null,
      active: true,
      expectedCount: '',
      waves: [],
      usersCount: 0,
    },
    loading: false,
    fetched: false,
    error: null,
  });

  useEffect(() => {
    if (isEditing) {
      fetchGroup(group, setGroup, id);
    }
  }, [group, isEditing, id]);

  const redirect = useMemo(
    () => (group.error || success) && <Redirect to={paths.GROUPS} />,
    [group.error, success]
  );

  return (
    <>
      {redirect}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">{isEditing ? 'Edit Group' : 'New Group'}</h1>
        </div>
      </section>
      <section className="section is-main-section">
        {isEditing && !group.fetched ? (
          <ClipLoader />
        ) : (
          <GroupForm
            groupData={group.data}
            action={isEditing ? modifyGroup : createGroup}
            isEditing={isEditing}
          />
        )}
      </section>
    </>
  );
};

export default Group;
