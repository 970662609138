import { Collections } from 'utils/enums';
import { collection } from 'utils/firebase';

export const checkSurveyExistance = async (
  surveyId,
  surveyName,
  surveyType
) => {
  const surveys = await collection(Collections.SURVEYS)
    .where('name', '==', surveyName)
    .where('type', '==', surveyType)
    .get();

  return surveys.docs.find((doc) => doc.id === surveyId);
};
