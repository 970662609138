import { createReducer } from 'redux-act';

import {
  GROUPS_CLEAR_DATA,
  GROUPS_FETCH_DATA_FAIL,
  GROUPS_FETCH_DATA_INIT,
  GROUPS_FETCH_DATA_SUCCESS,
  GROUPS_CREATE_GROUP_FAIL,
  GROUPS_CREATE_GROUP_INIT,
  GROUPS_CREATE_GROUP_SUCCESS,
  GROUPS_MODIFY_GROUP_FAIL,
  GROUPS_MODIFY_GROUP_INIT,
  GROUPS_MODIFY_GROUP_SUCCESS,
  GROUPS_DELETE_GROUP_FAIL,
  GROUPS_DELETE_GROUP_INIT,
  GROUPS_DELETE_GROUP_SUCCESS,
  GROUPS_CLEAN_UP,
} from 'state/actions/groups';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const groupsReducer = createReducer(
  {
    [GROUPS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [GROUPS_FETCH_DATA_SUCCESS]: (state, { groups }) => ({
      ...state,
      data: groups,
      loading: false,
      error: null,
    }),
    [GROUPS_FETCH_DATA_FAIL]: (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
    [GROUPS_CREATE_GROUP_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [GROUPS_CREATE_GROUP_SUCCESS]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
    }),
    [GROUPS_CREATE_GROUP_FAIL]: (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
    [GROUPS_MODIFY_GROUP_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [GROUPS_MODIFY_GROUP_SUCCESS]: (state, { id, group }) => {
      const { data } = state;
      return {
        ...state,
        data: data.map((elem) => {
          if (elem.id === id) {
            return group;
          }
          return elem;
        }),
        loading: false,
        error: null,
        success: true,
      };
    },
    [GROUPS_MODIFY_GROUP_FAIL]: (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
    [GROUPS_DELETE_GROUP_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [GROUPS_DELETE_GROUP_SUCCESS]: (state, { id }) => {
      const { data } = state;
      return {
        ...state,
        data: data.filter((elem) => elem.id !== id),
        loading: false,
        error: null,
        deleted: true,
      };
    },
    [GROUPS_DELETE_GROUP_FAIL]: (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
    [GROUPS_CLEAR_DATA]: (state) => ({
      ...initialState,
      data: state.data,
    }),
    [GROUPS_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
    }),
  },
  initialState
);
