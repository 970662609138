import { Collections, TagType } from 'utils/enums';
import { collection } from 'utils/firebase';

export const getPreviousDemographics = async (tags, waveId) => {
  const demographics = [];

  const demographicTags = tags.filter(
    ({ type }) => type === TagType.DEMOGRAPHIC
  );

  const demographicTagPromises = demographicTags.map((demographic) =>
    collection(Collections.DEMOGRAPHICS)
      .where('tagId', '==', demographic.id)
      .where('waveId', '==', waveId)
      .get()
  );

  const previousDemographics = await Promise.all(demographicTagPromises);

  previousDemographics.forEach((prevDemographic) => {
    if (!prevDemographic.empty) {
      demographics.push({
        id: prevDemographic.docs[0].id,
        ...prevDemographic.docs[0].data(),
      });
    }
  });

  return demographics;
};
