import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Dropdown.scss';

const Dropdown = ({ className, placeHolder, children }) => (
  <div className={classNames('dropdown', 'is-hoverable', className)}>
    <div className="dropdown-trigger">
      <button
        type="button"
        className="button"
        aria-haspopup="true"
        aria-controls="dropdown-menu4"
      >
        <span id="placeholder">{placeHolder}</span>
        <span className="icon is-small">
          <i className="mdi mdi-chevron-down" aria-hidden="true" />
        </span>
      </button>
    </div>
    <div className="dropdown-menu" id="dropdown-menu4" role="menu">
      <div id="children-placeholder" className="dropdown-content">
        {children}
      </div>
    </div>
  </div>
);

Dropdown.propTypes = {
  children: PropTypes.node.isRequired,
  placeHolder: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Dropdown.defaultProps = {
  className: '',
};

export default Dropdown;
