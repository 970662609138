import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Spinner from 'react-spinners/ClipLoader';

import { REACT_APP_ALGOLIA_SECTIONS_SAVES_INDEX } from 'constants/environment';
import { fetchSectionSave } from 'state/actions/SavedSections/fetch/fetchSectionSave';
import { selectSectionSaveFetch } from 'state/selectors/sectionsSaves';
import { clearSavedSectionsState } from 'state/actions/SavedSections';
import NewModal from 'components/NewModal';
import { colors } from 'styles';
import Autocomplete from 'components/Algolia/Autocomplete';
import SectionSaveOption, {
  getItemInputValue,
} from 'components/Algolia/Autocomplete/SectionSave';

const CustomFooter = ({ loading, canSubmit, onSubmit, onCancel }) => (
  <footer className="modal-card-foot">
    <div className="is-flex level container">
      <button
        id="primary-cancel-button"
        type="button"
        className="button is-light"
        disabled={loading}
        onClick={onCancel}
      >
        Cancel
      </button>
      <button
        type="button"
        className={classNames('button is-success', {
          'is-loading': loading,
        })}
        onClick={onSubmit}
        disabled={!canSubmit || loading}
      >
        {loading ? <Spinner size="18" color={colors.white} /> : 'Load Section'}
      </button>
    </div>
  </footer>
);

const LoadSectionModal = ({ isOpen, toggle, closeModal }) => {
  const dispatch = useDispatch();

  const [sectionIdentifier, setSectionIdentifier] = useState('');
  const [itemsCollections, setItemsCollections] = useState([]);

  const { loading, success } = useSelector(selectSectionSaveFetch);

  const onLoadSectionHandler = () => {
    dispatch(fetchSectionSave({ sectionIdentifier }));
  };

  useEffect(() => {
    if (success) {
      setSectionIdentifier('');
      closeModal();
      dispatch(clearSavedSectionsState());
    }
  }, [success, closeModal]);

  const canSubmit =
    sectionIdentifier &&
    itemsCollections.length > 0 &&
    itemsCollections.find((item) => item.objectID === sectionIdentifier);

  return (
    <NewModal
      title="Load Section"
      isOpen={isOpen}
      toggle={loading ? null : toggle}
      CustomFooter={CustomFooter}
      footerProps={{
        loading,
        canSubmit,
        onSubmit: onLoadSectionHandler,
        onCancel: closeModal,
      }}
    >
      <div className="field">
        <span className="label">Section Identifier to Load:</span>
        <div>
          <Autocomplete
            initialState=""
            setAutocompleteQuestion={setSectionIdentifier}
            setItemsCollections={setItemsCollections}
            placeholder="Section"
            sourceId="sectionSaves"
            indexName={REACT_APP_ALGOLIA_SECTIONS_SAVES_INDEX}
            ItemComponent={SectionSaveOption}
            getItemInputValue={getItemInputValue}
          />
        </div>
      </div>
    </NewModal>
  );
};

LoadSectionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default LoadSectionModal;
