import { createAction } from 'redux-act';

// Fetch Saved Section
export const SAVED_SECTIONS_FETCH_SINGLE_SECTION_INIT = createAction('SAVED_SECTIONS_FETCH_SINGLE_SECTION_INIT');

export const SAVED_SECTIONS_FETCH_SINGLE_SECTION_SUCCESS = createAction(
  'SAVED_SECTIONS_FETCH_SINGLE_SECTION_SUCCESS'
);

export const SAVED_SECTIONS_FETCH_SINGLE_SECTION_FAIL = createAction('SAVED_SECTIONS_FETCH_SINGLE_SECTION_FAIL');

// Create Section Save
export const SAVED_SECTIONS_CREATE_SECTION_SAVE_INIT = createAction(
  'SAVED_SECTIONS_CREATE_SECTION_SAVE_INIT'
);

export const SAVED_SECTIONS_CREATE_SECTION_SAVE_SUCCESS = createAction(
  'SAVED_SECTIONS_CREATE_SECTION_SAVE_SUCCESS'
);

export const SAVED_SECTIONS_CREATE_SECTION_SAVE_FAIL = createAction(
  'SAVED_SECTIONS_CREATE_SECTION_SAVE_FAIL'
);

// Delete Section Save
export const SAVED_SECTIONS_SECTION_DELETE_INIT = createAction(
  'SAVED_SECTIONS_SECTION_DELETE_INIT'
);

export const SAVED_SECTIONS_SECTION_DELETE_SUCCESS = createAction(
  'SAVED_SECTIONS_SECTION_DELETE_SUCCESS'
);
export const SAVED_SECTIONS_SECTION_DELETE_FAIL = createAction(
  'SAVED_SECTIONS_SECTION_DELETE_FAIL'
);

// Clear State
export const SAVED_SECTIONS_CLEAR_STATE = createAction(
  'SAVED_SECTIONS_CLEAR_STATE'
);