import React from 'react';

const EmptyMessage = ({ message }) => (
  <div className="content has-text-grey has-text-centered">
    <span className="icon is-medium">
      <i className="mdi mdi-emoticon-sad mdi-36px" />
    </span>
    <p>{message}</p>
  </div>
);

export default EmptyMessage;
