import { firebaseError } from 'utils';
import { checkSurveyExistance } from 'services/surveys/utils';

export const verifyIfSurveyIsDuplicated = async (
  surveyId,
  surveyName,
  surveyType,
  locale
) => {
  let surveyIsDuplicated = false;

  try {
    surveyIsDuplicated = await checkSurveyExistance(
      surveyId,
      surveyName,
      surveyType
    );
  } catch (error) {
    const errorMessage = firebaseError(error.code, locale);

    throw new Error(errorMessage);
  }

  if (surveyIsDuplicated) {
    const errorMessage = `Survey with name "${surveyName}" already exists.`;

    throw new Error(errorMessage);
  }
};
