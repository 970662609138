import { createSelector } from 'reselect';

const selectTagsState = (state) => state.tags;
const selectOrganizationsState = (state) => state.organizations;
const selectPreferencesState = (state) => state.preferences;

export const selectTagFormDataState = createSelector(
  selectTagsState,
  selectOrganizationsState,
  selectPreferencesState,
  (
    { creatingTag, loading: tagLoading, error },
    { loading: organizationsLoading, data: organizationsData },
    { defaultOrganization }
  ) => ({
    error,
    loading: creatingTag || tagLoading,
    loadingOrganizations: organizationsLoading,
    organizations: organizationsData,
    defaultOrganization,
  })
);

export const selectTagFormDataStateWithCustomError = createSelector(
  selectTagFormDataState,
  ({
    error,
    loading,
    loadingOrganizations,
    organizations,
    defaultOrganization,
  }) => ({
    error: error || {
      data: { organizations: null, organization: null, questionsData: null },
      message: '',
    },
    loading,
    loadingOrganizations,
    organizations,
    defaultOrganization,
  })
);

export const selectDataRelatedWithTag = createSelector(
  selectTagsState,
  ({ fetchingRelatedData, errorFetchingRelatedData, relatedData }) => ({
    fetchingRelatedData,
    errorFetchingRelatedData,
    relatedData,
  })
);
