/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import {
  createSurveyObject,
  createSurveyVersionObject,
  getBasicSurveyInfo,
  processQuestionGroupItem,
  processQuestionItem,
  trimMultipleLanguageContent,
} from 'utils/actions';
import { setSurveyVersion, setQuestions, createQuestionTags, createQuestionChoices, verifyIfSurveyIsDuplicated } from 'services/surveys/utils';
import { SurveyElement, SurveyVersionStatus } from 'utils/surveyBuilder/enums';
import { collection } from 'utils/firebase';
import { Collections } from 'utils/enums';
import { indexSurveys } from 'utils/algolia';

const rollbackOperations = async (operations) => {
  for (const operation of operations) {
    await operation();
  }
};

export const copySurvey = async (
  { surveyId, organizationId, surveyData, newSurveyData },
  { getState }
) => {
  const {
    pages,
    items,
    actions,
    sections,
    pagesOrder,
    versionName,
    isCondensed,
    versionTitle,
    defaultLanguage,
    availableLanguages,
    disableDeselect = false,
    languageCompletion = {},

    type,
  } = surveyData;

  const { name: newSurveyName } = newSurveyData;

  const operationsRollbacks = [];

  const {
    locale,
    createdAt,
    surveyVersionId: newSurveyVersionId,
    surveyId: newSurveyId,
    fullName,
  } = getBasicSurveyInfo({
    getState,
  });

  const versionTitleParsed = trimMultipleLanguageContent(versionTitle);

  const surveyVersion = createSurveyVersionObject({
    name: versionName?.trim() || 'Version 1',
    title: versionTitleParsed,
    status: SurveyVersionStatus.ACTIVE,
    pages,
    pagesOrder,
    sections,
    items,
    createdAt,
    createdBy: fullName,
    availableLanguages,
    defaultLanguage,
    isCondensed,
    languageCompletion,
    disableDeselect,
    actions,
    copyFrom: surveyId,
  });

  const organization = await collection(Collections.ORGANIZATIONS)
    .doc(organizationId)
    .get();

  const { displayName, displaySiteName } = organization.data();

  const survey = createSurveyObject({
    name: newSurveyName,
    type,
    versionCount: 1,
    versions: [
      {
        id: newSurveyVersionId,
        name: surveyVersion.name,
        status: surveyVersion.status,
        createdAt,
      },
    ],
    deploymentCount: 0,
    deployments: [],
    organizationDisplaySiteName: displaySiteName,
    organizationName: displayName,
    organizationId,
    createdBy: fullName,
    createdAt,
    languageCompletion,
  });

  const questions = [];
  const questionTag = [];
  const questionChoices = [];

  Object.values(items).forEach((item) => {
    if (item.type === SurveyElement.QUESTION) {
      processQuestionItem({
        item,
        surveyId: newSurveyId,
        surveyType: type,
        surveyVersionId: newSurveyVersionId,
        organizationId,
        questions,
        questionTag,
        questionChoices,
      });
    }

    if (item.type === SurveyElement.QUESTION_GROUP) {
      processQuestionGroupItem({
        item,
        surveyId: newSurveyId,
        surveyVersionId: newSurveyVersionId,
        organizationId,
        questions,
        questionTag,
        surveyType: type,
      });
    }
  });

  try {
    await verifyIfSurveyIsDuplicated(newSurveyId, newSurveyName, type, locale);

    const batchRollback = await setSurveyVersion({
      surveyId: newSurveyId,
      surveyVersionId: newSurveyVersionId,
      survey,
      surveyVersion,
      locale,
    });

    operationsRollbacks.push(batchRollback);

    if (questions.length) {
      const questionsRollback = await setQuestions({ questions, locale });

      operationsRollbacks.push(questionsRollback);
    }

    if (questionTag.length) {
      const createQuestionTagsRollback = await createQuestionTags({
        questionTags: questionTag,
        locale,
      });

      operationsRollbacks.push(createQuestionTagsRollback);
    }

    if (questionChoices.length) {
      const createQuestionChoicesRollback = await createQuestionChoices({
        questionChoices,
        locale,
      });

      operationsRollbacks.push(createQuestionChoicesRollback);
    }

    await indexSurveys
      .saveObject({ objectID: newSurveyId, ...survey, createdAt: +new Date() })
      .wait();
  } catch (error) {
    if (operationsRollbacks.length) {
      await rollbackOperations(operationsRollbacks);
    }
    
    throw error;
  }
};
