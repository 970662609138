import React from 'react';
import PropTypes from 'prop-types';

const Tick = ({ tick, count, isLast, format }) => {
  return (
    <div>
      <div
        style={{
          position: 'absolute',
          marginTop: 17,
          width: 1,
          height: 5,
          backgroundColor: 'rgb(200,200,200)',
          left: `${tick.percent}%`,
        }}
      />
      <div
        style={{
          position: 'absolute',
          marginTop: 25,
          fontSize: 10,
          textAlign: 'center',
          marginLeft: `${-(100 / count) / 2}%`,
          width: `${100 / count}%`,
          left: `${tick.percent}%`,
        }}
      >
        {isLast ? `${format(tick.value)}+` : format(tick.value)}
      </div>
    </div>
  );
};

Tick.propTypes = {
  tick: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }).isRequired,
  isLast: PropTypes.bool,
  count: PropTypes.number.isRequired,
  format: PropTypes.func,
};

Tick.defaultProps = {
  format: (d) => d,
  isLast: false,
};

export default Tick;
