/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Spinner from 'react-spinners/ClipLoader';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { colors } from 'styles';
import { SurveyTypes } from 'utils/surveyBuilder/enums';
import { fetchWaves } from 'state/actions/waves';
import {
  clearSurveyUpdateStartAndEndDatesState,
  fetchSurvey,
} from 'state/actions/surveys';
import {
  selectChangeSurveyEndAndStartDatesState,
  selectSurveyBuilderState,
  selectWaveData,
} from 'state/selectors/surveys';
import DatePicker from 'components/DatePicker';

import classes from './ChangeDeploymentDatesModal.module.scss';

const ChangeDeploymentDatesModal = ({
  organizationName,
  onChangeDeploymentDates,
  onCancel,
}) => {
  const dispatch = useDispatch();

  const { deploymentId } = useParams();

  const { fetchedSurvey } = useSelector(selectSurveyBuilderState, shallowEqual);
  const { loading, success } = useSelector(
    selectChangeSurveyEndAndStartDatesState,
    shallowEqual
  );

  const { waveName, waveId, startDate, endDate, surveyId } = fetchedSurvey;

  const { startingOn: waveStartingOn, until: waveEndingOn } = useSelector(
    selectWaveData(waveId),
    shallowEqual
  );

  const [startingOn, setStartingOn] = useState(startDate.toDate());
  const [endingOn, setEndingOn] = useState(endDate ? endDate.toDate() : null);

  useEffect(() => {
    dispatch(
      fetchWaves({
        filterByOrganization: organizationName,
        orderBy: { attribute: 'createdAt', order: 'asc' },
      })
    );
  }, [dispatch, organizationName]);
  
  useEffect(() => {
    if (success) { 
      dispatch(fetchSurvey(surveyId, deploymentId, SurveyTypes.DEPLOYMENTS));
      dispatch(clearSurveyUpdateStartAndEndDatesState());
      onCancel();
    }
    }, [dispatch, surveyId, deploymentId, SurveyTypes, success, onCancel]);

  const onSubmitHandler = useCallback(
    (e) => {
      e.preventDefault();

      onChangeDeploymentDates({
        startDate: startingOn,
        endDate: endingOn,
        deploymentId,
        surveyId,
      });
    },
    [startingOn, endingOn, deploymentId, surveyId, onChangeDeploymentDates]
  );

  const onChangeStartingDate = useCallback(
    (value) => {
      setStartingOn(value);

      if (value > endingOn) {
        setEndingOn(null);
      }
    },
    [endingOn]
  );

  const canSubmit = useMemo(() => {
    if (loading) {
      return false;
    }

    if (!startingOn) {
      return false;
    }

    if (endingOn && startingOn > endingOn) {
      return false;
    }

    return true;
  }, [startingOn, endingOn, loading]);

  return (
    <div className="modal is-active">
      <div
        id="background"
        className="modal-background"
        onClick={!loading ? onCancel : undefined}
      />
      <div className={classNames(classes['modal-content'], 'modal-content')}>
        <form id="submit" onSubmit={onSubmitHandler}>
          <header className="modal-card-head">
            <p className="modal-card-title">Change Dates</p>
            <button
              id="secondary-cancel-button"
              type="button"
              className="delete"
              aria-label="close"
              disabled={loading}
              onClick={onCancel}
            />
          </header>
          <section
            className={classNames(classes['modal-content'], 'modal-card-body')}
          >
            <div className="field">
              <label className="label">Organization</label>
              <div className="control">
                <input
                  className="input is-static"
                  type="text"
                  readOnly
                  value={organizationName}
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Wave</label>
              <div id="portalTarget" className="control">
                <h3>{waveName}</h3>
              </div>
            </div>
            <div className="field">
              <label className="label">Start Date</label>
              <div className="field-body">
                <div className="field">
                  <div className="control">
                    <DatePicker
                      name="startDate"
                      date={startingOn}
                      onChange={onChangeStartingDate}
                      minDate={
                        waveStartingOn
                          ? new Date(waveStartingOn)
                          : new Date(startDate.toDate())
                      }
                      maxDate={waveEndingOn ? new Date(waveEndingOn) : null}
                    />
                  </div>
                  <small>
                    Start date is required and can be any date in the past or
                    future.
                  </small>
                </div>
              </div>
            </div>
            <div className="field">
              <label className="label">End date</label>
              <div className="field-body">
                <div className="field">
                  <div className="control">
                    <DatePicker
                      name="endDate"
                      date={endingOn}
                      onChange={setEndingOn}
                      minDate={startingOn}
                      maxDate={waveEndingOn ? new Date(waveEndingOn) : null}
                      openToDate={endingOn || startingOn}
                    />
                  </div>
                  <small>
                    End date is not required, but if present, it must be after
                    Start date.
                  </small>
                </div>
              </div>
            </div>
          </section>
          <footer className="modal-card-foot">
            <button
              id="submit-button"
              type="submit"
              className={classNames('button is-success', {
                'is-loading': loading,
              })}
              disabled={!canSubmit}
            >
              {loading ? (
                <Spinner size="18" color={colors.white} />
              ) : (
                'Update Survey'
              )}
            </button>
            <button
              id="primary-cancel-button"
              type="button"
              className="button is-light"
              disabled={loading}
              onClick={onCancel}
            >
              Cancel
            </button>
          </footer>
        </form>
      </div>
    </div>
  );
};

ChangeDeploymentDatesModal.propTypes = {
  organizationName: PropTypes.string.isRequired,
  onChangeDeploymentDates: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ChangeDeploymentDatesModal;
