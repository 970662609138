/* eslint-disable no-param-reassign */
import { toastr } from 'react-redux-toastr';

import firebase from 'firebase.js';
import {
  SURVEYS_UPDATE_DEPLOYMENT_FAIL,
  SURVEYS_UPDATE_DEPLOYMENT_INIT,
  SURVEYS_UPDATE_DEPLOYMENT_SUCCESS,
  SURVEYS_UPDATE_SURVEY_FAIL,
} from 'state/actionCreators/surveys';
import { firebaseError } from 'utils';
import { SurveyElement } from 'utils/surveyBuilder/enums';
import {
  demographicsCollection,
  groupsCollection,
  questionChoicesCollection,
  questionsCollection,
  questionTagCollection,
  surveyDeploymentsCollection,
} from 'utils/firebase/surveys';
import {
  demographicsAssign,
  getChoicesFromDataSets,
  getGroupsToCreate,
  getQuestionDemographics,
} from 'utils/actions';
import { indexGroups } from 'utils/algolia';
import { collection } from 'utils/firebase';
import { Collections } from 'utils/enums';
import { getPreviousDemographics } from 'services/surveys/utils';

export const updateDeployment = ({
  name,
  surveyId,
  versionTitle,
  pages,
  pagesOrder,
  sections,
  items,
  waveId,
  availableLanguages,
  defaultLanguage,
  isCondensed,
  deploymentId,
  languageCompletion = {},
  organizationId,
  actions,
  totalResponses,
  unprocessedResponses,
  disableDeselect = false,
}) => {
  return async (dispatch, getState) => {
    dispatch(SURVEYS_UPDATE_DEPLOYMENT_INIT());

    const { locale } = getState().preferences;
    const { firstName, lastName } = getState().auth.userData;
    let organizationName;

    try {
      const organization = await collection(Collections.ORGANIZATIONS)
        .doc(organizationId)
        .get();

      organizationName = organization.data().displayName;
    } catch (error) {
      const errorMessage = error.message;

      toastr.error('', errorMessage);
      return dispatch(
        SURVEYS_UPDATE_DEPLOYMENT_FAIL({
          error: errorMessage,
        })
      );
    }

    const versionTitleParsed = {};

    Object.entries(versionTitle).forEach(([language, title]) => {
      versionTitleParsed[language] = title.trim();
    });

    const deployment = {
      surveyName: name.trim(),
      surveyId,
      title: versionTitleParsed,
      pages,
      pagesOrder,
      sections,
      items,
      availableLanguages,
      defaultLanguage,
      isCondensed,
      totalResponses,
      unprocessedResponses,
      lastTimeProcessed: null,
      languageCompletion,
      actions,
      disableDeselect,
    };

    const questions = [];
    const questionTag = [];
    const questionChoices = [];
    const demographics = [];
    const dataSetIds = [];

    const allQuestionsTags = [];
    Object.values(items)
      .filter((item) => item.type === SurveyElement.QUESTION)
      .forEach(({ question }) => {
        question.tags.forEach((tag) => {
          const tagAlreadyIncluded = allQuestionsTags.some(
            (tagInArray) => tagInArray.id === tag.id
          );
          if (!tagAlreadyIncluded) {
            allQuestionsTags.push(tag);
          }
        });
      });

    const previousDemographics = await getPreviousDemographics(
      allQuestionsTags,
      waveId
    );

    Object.values(items)
      .filter((item) => item.type === SurveyElement.QUESTION)
      .forEach(async ({ id, question }) => {
        const {
          content,
          dataSet,
          tags,
          choices,
          required,
          choicesOrder,
          template,
          regionalBreakdown,
          id: questionId,
          sliderProps
        } = question;

        const questionContentParsed = {};

        const questionDemographics = getQuestionDemographics(
          waveId,
          tags,
          choices,
          previousDemographics
        );

        demographics.push(
          ...demographicsAssign(demographics, questionDemographics)
        );

        const demographicsId =
          questionDemographics.length !== 0
            ? questionDemographics.map((demographic) => demographic.id)
            : null;

        items[id].question.demographicsId = demographicsId;

        Object.entries(content).forEach(([language, questionContent]) => {
          questionContentParsed[language] = questionContent.trim();
        });
        questions.push({
          id: questionId,
          question: {
            content: questionContentParsed,
            type: question.type,
            dataSetId: dataSet.id ?? null,
            sliderProps: sliderProps ?? null,
            surveyId,
            surveyVersionId: null,
            surveyDeploymentId: deploymentId,
            template,
            required,
            choicesOrder,
            demographicsId,
            regionalBreakdown,
            organizationId,
          },
        });

        tags.forEach(({ id: tagId, weight }) => {
          questionTag.push({ tagId, questionId, weight });
        });

        if (!dataSet.id) {
          choices.forEach(
            ({
              option,
              paramOne,
              paramTwo,
              openEnded,
              notApplicable,
              visible,
              sortOrder,
              id: choiceId,
            }) => {
              const choiceOptionParsed = {};

              Object.entries(option).forEach(([language, choiceOption]) => {
                choiceOptionParsed[language] = choiceOption.trim();
              });

              questionChoices.push({
                id: choiceId,
                choice: {
                  questionId,
                  option: choiceOptionParsed,
                  paramOne: paramOne?.trim() || null,
                  paramTwo: paramTwo?.trim() || null,
                  openEnded,
                  notApplicable,
                  dataSetId: null,
                  sortOrder,
                  visible,
                },
              });
            }
          );
        }

        if (dataSet.id) {
          dataSetIds.push(dataSet.id);
        }
      });

    const dataSetChoices =
      dataSetIds.length > 0 ? await getChoicesFromDataSets(dataSetIds) : [];

    Object.values(items)
      .filter((item) => item.type === SurveyElement.QUESTION_GROUP)
      .forEach(
        ({
          id: questionGroupId,
          questionGroup: {
            dataSet: { id: dataSetId },
            questions: questionsInGroup,
            choices: questionGroupChoices,
          },
        }) => {
          questionsInGroup.forEach(
            (
              {
                id: questionId,
                content,
                tags,
                type: questionInGroupType,
                required,
                choicesOrder,
                template = null,
              },
              questionIndex
            ) => {
              const questionContentParsed = {};

              const questionDemographics = getQuestionDemographics(
                waveId,
                tags,
                questionGroupChoices,
                previousDemographics
              );

              // Iteration on the current demographics tags.
              // If the tagId and waveId are the same, merge the choices of the tag in the one that's already created.
              // If the tag is different, then add the demographic tag to the demographics array to be created.
              if (demographics.length === 0) {
                demographics.push(...questionDemographics);
              } else {
                demographics.forEach(({ demographic }) => {
                  const sameQuestionDemographic = questionDemographics.find(
                    (questionDemographic) =>
                      demographic.tagId ===
                        questionDemographic.demographic.tagId &&
                      demographic.waveId ===
                        questionDemographic.demographic.waveId
                  );

                  const differentQuestionDemographic =
                    questionDemographics.find(
                      (questionDemographic) =>
                        demographic.tagId !==
                        questionDemographic.demographic.tagId
                    );

                  if (sameQuestionDemographic) {
                    const newContent = [...demographic.content];

                    sameQuestionDemographic.demographic.content.forEach(
                      (option) => {
                        if (!newContent.includes(option)) {
                          newContent.push(option);
                        }
                      }
                    );

                    demographic.content = newContent;
                  }

                  if (differentQuestionDemographic) {
                    demographics.push(differentQuestionDemographic);
                  }
                });
              }

              const demographicsId =
                questionDemographics.length !== 0
                  ? questionDemographics.map((demographic) => demographic.id)
                  : null;

              items[questionGroupId].questionGroup.questions[
                questionIndex
              ].demographicsId = demographicsId;

              Object.entries(content).forEach(([language, questionContent]) => {
                questionContentParsed[language] = questionContent.trim();
              });
              questions.push({
                id: questionId,
                question: {
                  content: questionContentParsed,
                  type: questionInGroupType,
                  dataSetId: dataSetId ?? null,
                  surveyId,
                  surveyVersionId: null,
                  surveyDeploymentId: deploymentId,
                  template,
                  required,
                  choicesOrder,
                  regionalBreakdown: false,
                  organizationId,
                  demographicsId,
                },
              });

              tags.forEach(({ id: tagId, weight }) => {
                questionTag.push({
                  tagId,
                  questionId,
                  weight,
                });
              });
            }
          );
        }
      );

    const deploymentRef =
      surveyDeploymentsCollection(surveyId).doc(deploymentId);

    try {
      const batch = firebase.firestore().batch();

      const questionsToDelete = await questionsCollection
        .where('surveyDeploymentId', '==', deploymentId)
        .get();

      const questionsData = [];

      questionsToDelete.forEach((question) => {
        batch.delete(questionsCollection.doc(question.id));

        questionsData.push({ id: question.id, question: question.data() });
      });

      const questionsId = questions.map(({ id }) => id);

      const deleteChoicesFromQuestions = firebase
        .functions()
        .httpsCallable('httpsDeleteChoicesFromQuestions');

      const removeQuestionTags = firebase
        .functions()
        .httpsCallable('httpsRemoveQuestionTags');

      await Promise.all([
        batch.commit(),
        deleteChoicesFromQuestions(questionsData),
        removeQuestionTags(questionsId),
      ]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);

      return dispatch(
        SURVEYS_UPDATE_SURVEY_FAIL({
          error: errorMessage,
        })
      );
    }

    try {
      const batch = firebase.firestore().batch();

      batch.update(deploymentRef, deployment);

      await batch.commit();
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);

      return dispatch(
        SURVEYS_UPDATE_DEPLOYMENT_FAIL({
          error: errorMessage,
        })
      );
    }

    try {
      const batch = firebase.firestore().batch();

      demographics.forEach(({ id, demographic }) =>
        batch.set(demographicsCollection.doc(id), demographic)
      );

      await batch.commit();
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);

      return dispatch(
        SURVEYS_UPDATE_DEPLOYMENT_FAIL({
          error: errorMessage,
        })
      );
    }

    try {
      const batch = firebase.firestore().batch();

      questions.forEach(({ id, question }) =>
        batch.set(questionsCollection.doc(id), question)
      );

      await batch.commit();
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);

      return dispatch(
        SURVEYS_UPDATE_DEPLOYMENT_FAIL({
          error: errorMessage,
        })
      );
    }

    try {
      const createQuestionTagTasks = [];

      questionTag.forEach((data) => {
        createQuestionTagTasks.push(questionTagCollection.add(data));
      });

      const createQuestionChoiceTasks = [];

      questionChoices.forEach(({ id, choice }) =>
        createQuestionChoiceTasks.push(
          questionChoicesCollection.doc(id).set(choice)
        )
      );

      await Promise.all([
        ...createQuestionChoiceTasks,
        ...createQuestionTagTasks,
      ]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);

      return dispatch(
        SURVEYS_UPDATE_DEPLOYMENT_FAIL({
          error: errorMessage,
        })
      );
    }

    try {
      const regionalBreakdownQuestions = questions.filter(
        ({ question }) => question.regionalBreakdown
      );

      const regionalBreakdownChoices = [];

      if (questionChoices.length > 0) {
        const choicesFromRegionalBreakdownQuestions = questionChoices.filter(
          (questionChoice) =>
            regionalBreakdownQuestions.some(
              ({ id }) => id === questionChoice.choice.questionId
            )
        );

        regionalBreakdownChoices.push(...choicesFromRegionalBreakdownQuestions);
      }

      if (dataSetChoices.length > 0) {
        const dataSetChoicesFromRegionalBreakdownQuestions =
          dataSetChoices.filter((dataSetChoice) =>
            regionalBreakdownQuestions.some(
              ({ question }) =>
                question.dataSetId === dataSetChoice.choice.dataSetId
            )
          );

        regionalBreakdownChoices.push(
          ...dataSetChoicesFromRegionalBreakdownQuestions
        );
      }

      const groups = await getGroupsToCreate(
        regionalBreakdownChoices,
        waveId,
        { firstName, lastName },
        organizationName
      );

      const batch = firebase.firestore().batch();

      const groupsToCreateOrUpdateInAlgolia = [];

      groups.forEach(({ id, group }) => {
        batch.set(groupsCollection.doc(id), {
          ...group,
          createdAt:
            group.createdAt || firebase.firestore.FieldValue.serverTimestamp(),
        });

        groupsToCreateOrUpdateInAlgolia.push({
          objectID: id,
          ...group,
          createdAt: group.createdAt
            ? +new Date(group.createdAt.toDate())
            : +new Date(),
        });
      });

      await Promise.all([
        indexGroups.partialUpdateObjects(groupsToCreateOrUpdateInAlgolia, {
          createIfNotExists: true,
        }),
        batch.commit(),
      ]);
    } catch (error) {
      const errorMessage = error.message;

      toastr.error('', errorMessage);
      return dispatch(
        SURVEYS_UPDATE_DEPLOYMENT_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'Deployment successfully updated.');

    return dispatch(SURVEYS_UPDATE_DEPLOYMENT_SUCCESS());
  };
};
