import { createReducer } from 'redux-act';

import {
  TASKS_CLEAN_UP,
  TASKS_FETCH_DATA_FAIL,
  TASKS_FETCH_DATA_INIT,
  TASKS_FETCH_DATA_SUCCESS,
} from 'state/actions/tasks';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
};

export const tasksReducer = createReducer(
  {
    [TASKS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [TASKS_FETCH_DATA_SUCCESS]: (state, { tasks }) => ({
      ...state,
      data: tasks,
      loading: false,
      error: null,
    }),
    [TASKS_FETCH_DATA_FAIL]: (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
    [TASKS_CLEAN_UP]: () => initialState,
  },
  initialState
);
