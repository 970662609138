import { createSelector } from 'reselect';

import { roles } from 'utils';
import { LanguageName, LanguageTranslation } from 'utils/enums';
import { selectAnalyticsState } from '../analytics';
import { selectAuthState } from '../auth';
import { selectOrganizationsState } from '../organizations';

const selectSurveysState = (state) => state.surveys;
const selectPreferencesState = (state) => state.preferences;
const selectWavesState = (state) => state.waves;

export const selectLiveSurveyDataState = ({ isPreview }) =>
  createSelector(
    selectSurveysState,
    ({
      deployment,
      preview,
      loading,
      errorFetching,
      creatingResponse,
      createdResponse,
    }) => {
      const survey = isPreview ? preview : deployment;
      const surveyItems = [];

      const pages =
        survey?.pagesOrder.map((pageId) => {
          const page = survey.pages[pageId];

          const sections = page.sectionIds.map((sectionId) => {
            const section = survey.sections[sectionId];

            const items = section.itemIds.map((itemId) => survey.items[itemId]);

            surveyItems.push(...items);

            return { ...section, items };
          });

          return { ...page, sections };
        }) || [];

      const availableLanguages =
        survey?.availableLanguages.map((code) => ({
          code,
          name: LanguageName[code],
          originalName: LanguageTranslation[code],
        })) || [];

      return {
        surveyPages: pages,
        fetchedSurvey: survey,
        fetchingSurvey: loading,
        errorFetching,
        creatingResponse,
        respondedSurvey: createdResponse,
        availableLanguages,
        surveyItems,
      };
    }
  );

export const selectSurveyBuilderState = createSelector(
  selectSurveysState,
  selectPreferencesState,
  selectOrganizationsState,
  (
    {
      success: surveysSuccess,
      updated,
      errorFetching,
      loading: surveysLoading,
      fetched,
      creatingVersion,
      data: surveysData,
      selectedVersion: surveysSelectedVersion,
      createdDeployment,
      creatingFromTemplate,
      createdFromTemplate,
      updatingDeployment,
    },
    { defaultOrganization },
    { data: organizationsData, loading: organizationsLoading }
  ) => ({
    success: surveysSuccess,
    updatedSurvey: updated,
    errorFetchingSurvey: errorFetching,
    loading: surveysLoading,
    fetched,
    fetchedSurvey: surveysData?.[0],
    versions: surveysData?.[0]?.versions?.map(({ name, id }) => ({
      label: name,
      value: id,
    })),
    creatingVersion,
    selectedVersion: surveysSelectedVersion,
    organizations: organizationsData.map(
      ({ displayName, id, displaySiteName }) => ({
        label: displayName,
        value: {
          organizationName: displayName,
          organizationId: id,
          organizationDisplaySiteName: displaySiteName,
        },
      })
    ),
    fetchingOrgs: organizationsLoading,
    defaultOrganization,
    createdDeployment,
    creatingFromTemplate,
    createdFromTemplate,
    updatingDeployment,
  })
);

export const selectCreateDeploymentModalState = createSelector(
  selectWavesState,
  selectSurveysState,
  (
    { data: wavesData, loading: wavesLoading },
    { creatingDeployment: surveysCreatingDeployment }
  ) => ({
    waves: wavesData.map(({ id, name, index, startingOn, until, active }) => ({
      label: name || `Wave ${index}`,
      value: {
        id,
        name,
        index,
        startingOn,
        until,
        active,
      },
    })),
    loading: wavesLoading,
    creatingDeployment: surveysCreatingDeployment,
  })
);

export const selectWaveData = (waveId) =>
  createSelector(selectWavesState, ({ data: wavesData }) => {
    const wave = wavesData.find(({ id }) => id === waveId) || {};

    return {
      ...wave,
      startingOn: wave.startingOn || null,
      until: wave.until || null,
    };
  });

export const selectCreateSurveyCopyModalState = createSelector(
  selectSurveysState,
  ({ creatingSurveyCopy, createdSurveyCopy, errorCreatingSurveyCopy }) => ({
    creatingSurveyCopy,
    createdSurveyCopy,
    errorCreatingSurveyCopy,
  })
);

export const selectHomeDataState = createSelector(
  selectAuthState,
  selectPreferencesState,
  selectSurveysState,
  selectAnalyticsState,
  (
    { userData: authUserData },
    { defaultOrganization },
    { data: surveysData, loading: surveysLoading },
    { success: analyticsSuccess }
  ) => {
    const { firstName, lastName, logoUrl } = authUserData;

    return {
      userName: `${firstName} ${lastName}`,
      userLogo: logoUrl,
      loading: surveysLoading,
      calledAnalytics: analyticsSuccess,
      surveysData,
      defaultOrganization,
    };
  }
);

export const selectSurveysByTypeState = createSelector(
  selectAuthState,
  selectPreferencesState,
  selectSurveysState,
  (
    { userData: authUserData },
    { defaultOrganization },
    {
      creatingFromTemplate,
      createdFromTemplate,
      deletingDeployment,
      deletedDeployment,
      error: surveysError,
    }
  ) => ({
    isAdminUser: authUserData.role === roles.ADMIN,
    defaultOrganization,
    creatingFromTemplate,
    createdFromTemplate,
    loading: deletingDeployment,
    deletedDeployment,
    error: surveysError,
  })
);

export const selectChangeSurveyEndAndStartDatesState = createSelector(
  selectSurveysState,
  ({ updatingDeploymentDates, successUpdatingDeploymentDates, error }) => ({
    loading: updatingDeploymentDates,
    success: successUpdatingDeploymentDates,
    error,
  })
);

export const selectFetchResponseInProgressState = createSelector(
  selectSurveysState,
  ({
    fetchingResponse,
    successFetchingResponse,
    errorFetchingResponse,
    responseInProgress,
  }) => ({
    responseInProgress,
    loading: fetchingResponse,
    success: successFetchingResponse,
    error: errorFetchingResponse,
  })
);

export const selectCreateResponseInProgressState = createSelector(
  selectSurveysState,
  ({
    responseCode,
    creatingResponseSave,
    successCreatingResponseSave,
    error,
  }) => ({
    responseCode,
    loading: creatingResponseSave,
    success: successCreatingResponseSave,
    error,
  })
);
