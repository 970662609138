import React, { useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { useFormatMessage } from 'hooks';
import paths from 'pages/Router/paths';
import { roles } from 'utils';
import NavLink from '../Link';
import classes from './Aside.module.scss';

export const SubMenu = ({ label, children, icon }) => {
  const [active, setActive] = useState(false);

  return (
    <li className={classNames({ 'is-active': active })}>
      <a
        className="has-icon has-dropdown-icon"
        onClick={() => setActive(!active)}
      >
        <span className="icon">
          <i className={classNames('mdi', icon)} />
        </span>
        <span className="menu-item-label">{label}</span>
        <div className="dropdown-icon">
          <span className="icon">
            <i
              className={classNames(
                'mdi',
                { 'mdi-minus': active },
                { 'mdi-plus': !active }
              )}
            />
          </span>
        </div>
      </a>
      <ul>{children}</ul>
    </li>
  );
};

SubMenu.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

SubMenu.defaultProps = {
  icon: 'mdi-view-list',
};

const Aside = ({ handleMobileToggle }) => {
  const { role } = useSelector(
    (state) => ({
      role: state.auth.userData.role,
    }),
    shallowEqual
  );

  const { ADMIN } = roles;

  const usersMessage = useFormatMessage('Aside.users');
  const organizationsMessage = useFormatMessage('Aside.organizations');

  return (
    <aside
      className={classNames('aside is-placed-left is-expanded', classes.aside)}
    >
      <Link to={paths.ROOT} className="aside-tools">
        <div className="aside-tools-label">
          <span>
            <b>Presage</b> Next Gen
          </span>
        </div>
      </Link>
      <div className="menu is-menu-main">
        <ul className="menu-list">
          <li>
            <NavLink
              to={paths.ROOT}
              className="has-icon"
              onClick={handleMobileToggle}
            >
              <span className="icon">
                <i className="mdi mdi-home" />
              </span>
              <span className="menu-item-label">
                {useFormatMessage('Aside.home')}
              </span>
            </NavLink>
          </li>
          {role === ADMIN && (
            <li>
              <NavLink
                to={paths.ORGANIZATIONS}
                className="has-icon"
                onClick={handleMobileToggle}
              >
                <span className="icon">
                  <i className="mdi mdi-office-building" />
                </span>
                <span className="menu-item-label">{organizationsMessage}</span>
              </NavLink>
            </li>
          )}
          {role === ADMIN && (
            <li>
              <NavLink
                to={paths.INDUSTRIES}
                className="has-icon"
                onClick={handleMobileToggle}
              >
                <span className="icon">
                  <i className="mdi mdi-chart-arc" />
                </span>
                <span className="menu-item-label">Industries</span>
              </NavLink>
            </li>
          )}
          {role === ADMIN && (
            <li>
              <NavLink
                to={paths.WAVES}
                className="has-icon"
                onClick={handleMobileToggle}
              >
                <span className="icon">
                  <i className="mdi mdi-waves" />
                </span>
                <span className="menu-item-label">Waves</span>
              </NavLink>
            </li>
          )}
          {role === ADMIN && (
            <li>
              <NavLink
                to={paths.GROUPS}
                className="has-icon"
                onClick={handleMobileToggle}
              >
                <span className="icon">
                  <i className="mdi mdi-account-group" />
                </span>
                <span className="menu-item-label">Groups</span>
              </NavLink>
            </li>
          )}
          {role === ADMIN && (
            <li>
              <NavLink
                to={paths.USERS}
                className="has-icon"
                onClick={handleMobileToggle}
              >
                <span className="icon">
                  <i className="mdi mdi-account-supervisor" />
                </span>
                <span className="menu-item-label">{usersMessage}</span>
              </NavLink>
            </li>
          )}
          {role === ADMIN && (
            <SubMenu label="Tags" icon="mdi-tag">
              <li>
                <NavLink
                  className={classes.submenuLink}
                  to={paths.TAGS_QUESTION}
                  onClick={handleMobileToggle}
                >
                  <span>Question</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={classes.submenuLink}
                  to={paths.TAGS_DATA_SET}
                  onClick={handleMobileToggle}
                >
                  <span>Data Set</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={classes.submenuLink}
                  to={paths.TAGS_CONSTRUCT}
                  onClick={handleMobileToggle}
                >
                  <span>Construct</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={classes.submenuLink}
                  to={paths.TAGS_DEMOGRAPHIC}
                  onClick={handleMobileToggle}
                >
                  <span>Demographic</span>
                </NavLink>
              </li>
            </SubMenu>
          )}
          {role === ADMIN && (
            <li>
              <NavLink
                to={paths.DATA_SETS}
                className="has-icon"
                onClick={handleMobileToggle}
              >
                <span className="icon">
                  <i className="mdi mdi-database" />
                </span>
                <span className="menu-item-label">Data Sets</span>
              </NavLink>
            </li>
          )}
          {role === ADMIN && (
            <li>
              <NavLink
                to={paths.SAVED_SECTIONS}
                className="has-icon"
                onClick={handleMobileToggle}
              >
                <span className="icon">
                  <i className="mdi mdi-text-box-multiple-outline" />
                </span>
                <span className="menu-item-label">Sections</span>
              </NavLink>
            </li>
          )}
          {role === ADMIN && (
            <SubMenu label="Surveys" icon="mdi-pencil">
              <li>
                <NavLink
                  className={classes.submenuLink}
                  to={paths.SURVEYS_ORGANIZATION}
                  onClick={handleMobileToggle}
                >
                  <span>Organization</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={classes.submenuLink}
                  to={paths.SURVEYS_TEMPLATE}
                  onClick={handleMobileToggle}
                >
                  <span>Template</span>
                </NavLink>
              </li>
            </SubMenu>
          )}
          {role === ADMIN && (
            <li>
              <NavLink
                to={paths.TASKS}
                className="has-icon"
                onClick={handleMobileToggle}
              >
                <span className="icon">
                  <i className="mdi mdi-list-status" />
                </span>
                <span className="menu-item-label">Tasks</span>
              </NavLink>
            </li>
          )}
        </ul>
      </div>
    </aside>
  );
};

Aside.propTypes = {
  handleMobileToggle: PropTypes.func.isRequired,
};

export default Aside;
