import React from 'react';
import classNames from 'classnames';
import Spinner from 'react-spinners/BeatLoader';
import PropTypes from 'prop-types';
import AnimatedNumber from 'animated-number-react';

import classes from './StatsCard.module.scss';

const StatsCard = ({ title, icon, iconColor, valueName, value, isLoading }) => (
  <div className="card">
    {title && (
      <div className={classNames('card-header', classes.header)}>
        <p id="stats-card-title-placeholder" className="card-header-title">
          {title}
        </p>
      </div>
    )}
    <div className="card-content">
      <div className="level is-mobile">
        {!isLoading && (
          <>
            <div className={classNames('level-item', classes.item)}>
              <div>
                <h2
                  id="stats-card-value-name-placeholder"
                  className="subtitle is-spaced"
                >
                  {valueName}
                </h2>
                <h1 className="title">
                  <AnimatedNumber
                    id="stats-card-value-placeholder"
                    value={value}
                    formatValue={(v) => Number(v).toFixed(0)}
                    duration={1000}
                  />
                </h1>
              </div>
            </div>
            {icon && (
              <div className={classNames('level-item', classes.item)}>
                <span
                  id="stats-card-icon"
                  className={classNames('icon', classes.icon)}
                  style={{ color: iconColor }}
                >
                  <i className={icon} />
                </span>
              </div>
            )}
          </>
        )}
        {isLoading && (
          <div className="level-item">
            <Spinner id="stats-card-loading-spinner" />
          </div>
        )}
      </div>
    </div>
  </div>
);

StatsCard.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  valueName: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

StatsCard.defaultProps = {
  title: '',
  icon: '',
  iconColor: '',
};

export default StatsCard;
