import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';
import algoliasearch from 'algoliasearch';

import { REACT_APP_ALGOLIA_INDUSTRIES_INDEX } from 'constants/environment';
import firebase from 'firebase.js';
import { firebaseError } from 'utils';

export const INDUSTRIES_CREATE_INDUSTRY_INIT = createAction(
  'INDUSTRIES_CREATE_INDUSTRY_INIT'
);
export const INDUSTRIES_CREATE_INDUSTRY_SUCCESS = createAction(
  'INDUSTRIES_CREATE_INDUSTRY_SUCCESS'
);
export const INDUSTRIES_CREATE_INDUSTRY_FAIL = createAction(
  'INDUSTRIES_CREATE_INDUSTRY_FAIL'
);
export const INDUSTRIES_CLEAR_CREATE_INDUSTRY_STATE = createAction(
  'INDUSTRIES_CLEAR_CREATE_INDUSTRY_STATE'
);
export const INDUSTRIES_MODIFY_INDUSTRY_INIT = createAction(
  'INDUSTRIES_MODIFY_INDUSTRY_INIT'
);
export const INDUSTRIES_MODIFY_INDUSTRY_SUCCESS = createAction(
  'INDUSTRIES_MODIFY_INDUSTRY_SUCCESS'
);
export const INDUSTRIES_MODIFY_INDUSTRY_FAIL = createAction(
  'INDUSTRIES_MODIFY_INDUSTRY_FAIL'
);
export const INDUSTRIES_DELETE_INDUSTRY_INIT = createAction(
  'INDUSTRIES_DELETE_INDUSTRY_INIT'
);
export const INDUSTRIES_DELETE_INDUSTRY_SUCCESS = createAction(
  'INDUSTRIES_DELETE_INDUSTRY_SUCCESS'
);
export const INDUSTRIES_DELETE_INDUSTRY_FAIL = createAction(
  'INDUSTRIES_DELETE_INDUSTRY_FAIL'
);
export const INDUSTRIES_CLEAR_DATA = createAction('INDUSTRIES_CLEAR_DATA');
export const INDUSTRIES_CLEAN_UP = createAction('INDUSTRIES_CLEAN_UP');

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_ADMIN_KEY
);
const indexIndustries = searchClient.initIndex(
  REACT_APP_ALGOLIA_INDUSTRIES_INDEX
);

const checkDuplicatedProperties = async ({ name, id }) => {
  const duplicateName = await firebase
    .firestore()
    .collection('industries')
    .where('name', '==', name)
    .get();

  if (!duplicateName.empty && duplicateName.docs[0].id !== id) {
    return 'name';
  }

  return '';
};

export const createIndustry = ({ displayName }) => {
  return async (dispatch, getState) => {
    dispatch(INDUSTRIES_CREATE_INDUSTRY_INIT());
    const { locale } = getState().preferences;
    const { userData } = getState().auth;

    const industryData = {
      name: displayName.toLowerCase().replace(/\s/g, ''),
      displayName,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      createdBy: `${userData.firstName} ${userData.lastName}`,
    };

    let duplicatedProperty;

    try {
      duplicatedProperty = await checkDuplicatedProperties(industryData);

      if (!duplicatedProperty) {
        const { id } = await firebase
          .firestore()
          .collection('industries')
          .add(industryData);

        await indexIndustries
          .saveObject({
            objectID: id,
            ...industryData,
            createdAt: +new Date(),
          })
          .wait();

        toastr.success('', 'Industry created successfully');
        return dispatch(
          INDUSTRIES_CREATE_INDUSTRY_SUCCESS({
            industry: { ...industryData, id },
          })
        );
      }
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        INDUSTRIES_CREATE_INDUSTRY_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.error('', `Industry ${duplicatedProperty} already exists!`);
    return dispatch(
      INDUSTRIES_CREATE_INDUSTRY_FAIL({
        error: `Industry ${duplicatedProperty} already exists!`,
      })
    );
  };
};

export const clearCreateIndustryState = () => (dispatch) =>
  dispatch(INDUSTRIES_CLEAR_CREATE_INDUSTRY_STATE());

export const clearIndustriesData = () => {
  return (dispatch) => {
    dispatch(INDUSTRIES_CLEAR_DATA());
  };
};

export const industriesCleanUp = () => (dispatch) =>
  dispatch(INDUSTRIES_CLEAN_UP());

export const modifyIndustry = ({ displayName, id }) => {
  return async (dispatch, getState) => {
    dispatch(INDUSTRIES_MODIFY_INDUSTRY_INIT());

    const { locale } = getState().preferences;

    const industryData = {
      name: displayName.toLowerCase().replace(/\s/g, ''),
      displayName,
    };

    let duplicatedProperty;

    try {
      duplicatedProperty = await checkDuplicatedProperties({
        ...industryData,
        id,
      });
      if (!duplicatedProperty) {
        const updateIndustryInDb = firebase
          .firestore()
          .collection('industries')
          .doc(id)
          .update(industryData);

        const updateIndustryInAlgolia = indexIndustries
          .partialUpdateObject({
            objectID: id,
            ...industryData,
          })
          .wait();

        await Promise.all([updateIndustryInDb, updateIndustryInAlgolia]);

        toastr.success('', 'Industry updated successfully');

        return dispatch(
          INDUSTRIES_MODIFY_INDUSTRY_SUCCESS({
            industry: { ...industryData, id },
          })
        );
      }
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        INDUSTRIES_MODIFY_INDUSTRY_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.error('', `Industry ${duplicatedProperty} already exists!`);
    return dispatch(
      INDUSTRIES_MODIFY_INDUSTRY_FAIL({
        error: `Industry ${duplicatedProperty} already exists!`,
      })
    );
  };
};

export const deleteIndustry = (id) => {
  return async (dispatch, getState) => {
    dispatch(INDUSTRIES_DELETE_INDUSTRY_INIT());

    const { locale } = getState().preferences;

    const deleteIndustryFromDb = firebase
      .firestore()
      .collection('industries')
      .doc(id)
      .delete();

    const deleteIndustryFromAlgolia = indexIndustries.deleteObject(id).wait();

    try {
      await Promise.all([deleteIndustryFromDb, deleteIndustryFromAlgolia]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        INDUSTRIES_DELETE_INDUSTRY_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'The industry was deleted.');
    return dispatch(INDUSTRIES_DELETE_INDUSTRY_SUCCESS({ id }));
  };
};
