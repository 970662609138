import React, { useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Redirect, Link, useLocation } from 'react-router-dom';
import { InstantSearch } from 'react-instantsearch-dom';
import classNames from 'classnames';

import { REACT_APP_ALGOLIA_USERS_CREATED_AT_ASC_INDEX, REACT_APP_ALGOLIA_USERS_CREATED_AT_DESC_INDEX, REACT_APP_ALGOLIA_USERS_INDEX } from 'constants/environment';
import { useFormatDate, useAlgoliaSearch } from 'hooks';
import { deleteUser, usersCleanUp } from 'state/actions/users';
import paths from 'pages/Router/paths';
import {
  Table,
  SearchBox,
  SortBy,
  Menu,
  ToggleRefinement,
  Filter,
  FilterOption,
} from 'components/Algolia';
import Modal from 'components/Modal';
import { fetchSelectedGroup } from 'utils/fetching';
import { roles, initAlgoliaClient } from 'utils';
import classes from './Users.module.scss';

const searchClient = initAlgoliaClient();

const index = REACT_APP_ALGOLIA_USERS_INDEX;
const indexCreatedAtAsc = REACT_APP_ALGOLIA_USERS_CREATED_AT_ASC_INDEX;
const indexCreatedAtDesc = REACT_APP_ALGOLIA_USERS_CREATED_AT_DESC_INDEX;

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Users = () => {
  const query = useQuery();
  const groupId = query.get('group') || null;

  const { role, loading, deleted, defaultOrganization } = useSelector(
    (state) => ({
      role: state.auth.userData.role,
      loading: state.users.loading,
      deleted: state.users.deleted,
      defaultOrganization: state.preferences.defaultOrganization,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const [deleteModal, setDeleteModal] = React.useState({
    userId: null,
    logoUrl: null,
    isOpen: false,
  });

  const [selectedGroup, setSelectedGroup] = React.useState({
    group: null,
    loading: false,
    fetched: false,
  });

  const { search, refresh } = useAlgoliaSearch();

  useEffect(() => {
    return () => dispatch(usersCleanUp());
  }, [dispatch]);

  useEffect(() => {
    if (deleted) {
      refresh();
      setDeleteModal((prevState) => ({
        userId: null,
        logoUrl: null,
        isOpen: !prevState.isOpen,
      }));
      dispatch(usersCleanUp());
    }
  }, [deleted, refresh, dispatch]);

  useEffect(() => {
    if (groupId) {
      fetchSelectedGroup(selectedGroup, setSelectedGroup, groupId);
    }
  }, [groupId, selectedGroup]);

  const onRemoveButtonClickHandler = useCallback((userId, logoUrl) => {
    setDeleteModal((prevState) => ({
      userId,
      logoUrl,
      isOpen: !prevState.isOpen,
    }));
  }, []);

  const onCloseModalHandler = useCallback(() => {
    setDeleteModal({ userId: null, isOpen: false });
  }, []);

  const onDeleteUserHandler = useCallback(() => {
    dispatch(deleteUser(deleteModal.userId, deleteModal.logoUrl));
  }, [dispatch, deleteModal.userId, deleteModal.logoUrl]);

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'logoUrl',
        Cell: ({ row }) => (
          <Link
            to={`${paths.USERS}/${row.original.objectID}`}
            className="has-text-black"
          >
            <div className="image">
              {row.original.logoUrl ? (
                <img src={row.original.logoUrl} alt="" className="is-rounded" />
              ) : (
                <i className="mdi mdi-account" />
              )}
            </div>
          </Link>
        ),
      },
      {
        Header: 'Full name',
        accessor: 'firstName',
        Cell: ({ row }) => (
          <Link to={`${paths.USERS}/${row.original.objectID}`}>
            {`${row.original.firstName} ${row.original.lastName}`}
          </Link>
        ),
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Role',
        accessor: 'role',
      },
      {
        Header: 'Organization',
        accessor: 'organizationName',
        Cell: ({ row }) => (
          <span>
            {row.original.role === roles.ADMIN
              ? '-'
              : row.original.organizationName}
          </span>
        ),
      },
      {
        Header: 'Groups',
        accessor: 'groups',
        Cell: ({ row }) => (
          <span>
            {row.original.role === roles.ADMIN
              ? '-'
              : row.original.groups.length}
          </span>
        ),
      },
      {
        Header: 'Manager',
        accessor: 'manager',
        Cell: ({ row }) => (
          <small className="has-text-grey is-abbr-like">
            {row.original.manager ? (
              <span className="icon">
                {row.original.role === roles.ADMIN ? (
                  '-'
                ) : (
                  <i className="mdi mdi-check" />
                )}
              </span>
            ) : (
              <span className="icon">
                {row.original.role === roles.ADMIN ? (
                  '-'
                ) : (
                  <i className="mdi mdi-close" />
                )}
              </span>
            )}
          </small>
        ),
      },
      {
        Header: 'Active',
        accessor: 'active',
        Cell: ({ row }) => (
          <small className="has-text-grey is-abbr-like">
            {row.original.active ? (
              <span className="icon">
                <i className="mdi mdi-check" />
              </span>
            ) : (
              <span className="icon">
                <i className="mdi mdi-close" />
              </span>
            )}
          </small>
        ),
      },
      {
        Header: 'Created at',
        accessor: 'created',
        Cell: ({ row }) => (
          <small className="has-text-grey is-abbr-like">
            {useFormatDate(row.original.createdAt, {
              weekday: 'short',
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
          </small>
        ),
      },
      {
        Header: 'Created by',
        accessor: 'createdBy',
        Cell: ({ row }) => (
          <small className="has-text-grey is-abbr-like">
            {row.original.createdBy}
          </small>
        ),
      },
      {
        Header: '',
        accessor: 'actions',
        Cell: ({ row }) => (
          <>
            <div className={classNames('buttons is-right', classes.actions)}>
              <Link
                to={`${paths.USERS}/${row.original.objectID}`}
                className="button is-small is-primary"
              >
                <span className="icon is-small">
                  <i className="mdi mdi-account-edit" />
                </span>
              </Link>

              <button
                type="button"
                className="button is-small is-danger"
                onClick={() =>
                  onRemoveButtonClickHandler(
                    row.original.objectID,
                    row.original.logoUrl
                  )
                }
              >
                <span className="icon is-small">
                  <i className="mdi mdi-trash-can" />
                </span>
              </button>
            </div>
          </>
        ),
      },
    ],
    [onRemoveButtonClickHandler]
  );

  const redirect = useMemo(
    () => role !== roles.ADMIN && <Redirect to={paths.ROOT} />,
    [role]
  );

  return (
    <>
      {redirect}
      {deleteModal.isOpen && (
        <Modal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage="Delete"
          title="Confirm action"
          body="This will permanently delete the user. Action can not be undone."
          cancelButtonMessage="Cancel"
          onConfirmation={onDeleteUserHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      <InstantSearch
        searchClient={searchClient}
        indexName={index}
        refresh={search.refresh}
      >
        <section className="hero is-hero-bar">
          <div className="hero-body">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <h1 className="title">Users</h1>
                </div>
              </div>
              <div className="level-right">
                <Link to={paths.ADD_USER} className="button">
                  New User
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section className="section is-main-section">
          <Filter>
            <FilterOption label="Organization">
              <Menu
                predefinedRefinement={defaultOrganization?.value.displayName}
                predefinedOption={defaultOrganization}
                attribute="organizationName"
                emptyPlaceholder="No organizations"
                defaultPlaceholder="Select an organization"
                isSearchable
                isClearable
                limit={20}
              />
            </FilterOption>
            <FilterOption label="Group">
              <Menu
                predefinedRefinement={selectedGroup.group?.value}
                predefinedOption={selectedGroup.group}
                attribute="groups"
                emptyPlaceholder="No groups"
                defaultPlaceholder="Select a group"
                isSearchable
                isClearable
                loading={selectedGroup.loading}
                limit={20}
              />
            </FilterOption>
            <FilterOption label="Manager" check>
              <ToggleRefinement attribute="manager" value label="Manager" />
            </FilterOption>
          </Filter>
          <Filter label={false}>
            <FilterOption label="Role">
              <Menu
                attribute="role"
                emptyPlaceholder="No roles"
                defaultPlaceholder="Select a role"
                isSearchable
                isClearable
              />
            </FilterOption>
            <FilterOption label="Sort by">
              <SortBy
                defaultRefinement={indexCreatedAtDesc}
                items={[
                  { value: index, label: 'Featured' },
                  { value: indexCreatedAtAsc, label: 'Date asc.' },
                  { value: indexCreatedAtDesc, label: 'Date desc.' },
                ]}
                defaultValue={2}
              />
            </FilterOption>
            <FilterOption label="Active" check>
              <ToggleRefinement
                attribute="active"
                value
                defaultRefinement
                label="Active"
              />
            </FilterOption>
          </Filter>
          <div className="card has-table has-mobile-sort-spaced">
            <header className="card-header">
              <p className="card-header-title table-header">
                <span>Search</span>
                <SearchBox />
              </p>
            </header>
            <Table columns={columns} defaultPerPageOption={10} />
          </div>
        </section>
      </InstantSearch>
    </>
  );
};

export default Users;
