import {
  SURVEYS_FETCH_PREVIEW_INIT,
  SURVEYS_FETCH_PREVIEW_SUCCESS,
} from 'state/actionCreators/surveys';
import { getLocalStorageValue } from 'utils';

export const fetchPreview = () => {
  return (dispatch) => {
    dispatch(SURVEYS_FETCH_PREVIEW_INIT());
    const preview = getLocalStorageValue('preview');

    return dispatch(SURVEYS_FETCH_PREVIEW_SUCCESS({ preview }));
  };
};
