/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import classes from './Toggle.module.scss';

const Toggle = ({
  toggled,
  onToggle,
  toggledText,
  unToggledText,
  haveMargin,
}) => (
  <label className="switch is-rounded">
    <span
      id="text-placeholder"
      className={classNames({ [classes['check-label']]: haveMargin })}
    >
      {toggled ? toggledText : unToggledText}
    </span>
    <input
      type="checkbox"
      checked={toggled}
      onChange={(e) => onToggle(e.target.checked)}
    />
    <span className="check" />
  </label>
);

Toggle.propTypes = {
  toggled: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  toggledText: PropTypes.string,
  unToggledText: PropTypes.string,
  haveMargin: PropTypes.bool,
};

Toggle.defaultProps = {
  haveMargin: true,
  toggledText: '',
  unToggledText: '',
};

export default Toggle;
