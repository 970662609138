import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import classes from './CardFooter.module.scss';

const CardFooter = ({ displayText, onClick }) => (
  <button
    id="footer"
    type="button"
    className={classNames(
      'card-footer',
      'notification',
      classes['card-footer']
    )}
    onClick={onClick}
  >
    <span className="icon">
      <i className="mdi mdi-plus-circle" />
    </span>
    <span id="text-placeholder" className="is-size-6 has-text-weight-semibold">
      {displayText}
    </span>
  </button>
);

CardFooter.propTypes = {
  displayText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CardFooter;
