import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';

import { firebaseError } from 'utils';
import { collection } from 'utils/firebase';
import { Collections } from 'utils/enums';

export const QUESTION_CHOICES_FETCH_DATA_INIT = createAction(
  'QUESTION_CHOICES_FETCH_DATA_INIT'
);
export const QUESTION_CHOICES_FETCH_DATA_SUCCESS = createAction(
  'QUESTION_CHOICES_FETCH_DATA_SUCCESS'
);
export const QUESTION_CHOICES_FETCH_DATA_FAIL = createAction(
  'QUESTION_CHOICES_FETCH_DATA_FAIL'
);
export const QUESTION_CHOICES_CLEAR_STATE = createAction(
  'QUESTION_CHOICES_CLEAR_STATE'
);

export const fetchQuestionChoices = (options = {}) => {
  return async (dispatch) => {
    dispatch(QUESTION_CHOICES_FETCH_DATA_INIT());

    const questionChoices = [];

    try {
      const questionChoicesRef = collection(Collections.QUESTION_CHOICE);
      let baseQuery = questionChoicesRef;

      if (options.filterByDataSet) {
        const { filterByDataSet } = options;
        baseQuery = baseQuery.where('dataSetId', '==', filterByDataSet);
      }

      if (options.getById) {
        const { getById } = options;
        const questionChoice = (
          await questionChoicesRef.doc(getById).get()
        ).data();

        questionChoices.push({
          id: questionChoice.id,
          ...questionChoice,
        });
      } else {
        (await baseQuery.orderBy('sortOrder', 'asc').get()).forEach(
          (questionChoice) => {
            const data = questionChoice.data();
            questionChoices.push({
              id: questionChoice.id,
              ...data,
            });
          }
        );
      }
    } catch (error) {
      const errorMessage = firebaseError(error.code, 'en');
      toastr.error(errorMessage);
      return dispatch(
        QUESTION_CHOICES_FETCH_DATA_FAIL({ error: errorMessage })
      );
    }

    return dispatch(QUESTION_CHOICES_FETCH_DATA_SUCCESS({ questionChoices }));
  };
};

export const clearQuestionChoiceState = () => (dispatch) =>
  dispatch(QUESTION_CHOICES_CLEAR_STATE());
