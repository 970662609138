import React, { Component } from 'react';
import bowser from 'bowser';

import NewModal from 'components/NewModal';

const saveErrorLog = (body) =>
  fetch(
    `https://us-central1-${process.env.REACT_APP_FIRE_BASE_PROJECT_ID}.cloudfunctions.net/httpsLogError`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    }
  )
    .then(() => {
      console.log('sucess saving error log');
    })
    .catch((reqError) => {
      console.log('error saving error log');
      console.log(reqError);
    });

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidMount() {
    if (window.addEventListener) {
      let isErrorEventSupported = false;

      // Create a dummy element and try to add an error event listener to it
      const testElement = document.createElement('div');

      if ('onerror' in testElement) {
        isErrorEventSupported = true;
      }

      testElement.remove();

      this.setState((prevState) => ({ ...prevState, isErrorEventSupported }));

      const { parsedResult: browserData } = bowser.getParser(
        window.navigator.userAgent
      );

      if (isErrorEventSupported) {
        window.addEventListener('error', (event) => {
          event.preventDefault();

          const { error } = event;

          const lastErrorStack =
            window?.sessionStorage?.getItem('lastErrorStack');

          if (lastErrorStack !== error.stack) {
            window.sessionStorage.setItem('lastErrorStack', error.stack);

            const body = JSON.stringify({
              errorMessage: error.message,
              errorStack: error.stack,
              pageURL: window.location.href,
              ...browserData,
            });
            saveErrorLog(body);

            this.setState((prevState) => ({ ...prevState, hasError: true }));
          } else {
            window.sessionStorage.removeItem('lastErrorStack');
          }

          return false;
        });
      }
    }
  }

  componentWillUnmount() {
    const { isErrorEventSupported } = this.state;

    if (isErrorEventSupported) {
      window.removeEventListener('error', this.eventListenerErrorHandler);
    } else {
      window.onerror = null;
    }
  }

  render() {
    const { hasError } = this.state;

    if (hasError) {
      return (
        <NewModal isOpen>
          <div className="flex flex-col items-center justify-center gap-8">
            <h1>Oops, there was an error!</h1>
            <p className="text-center text-lg text-secondary">
              If the error persists, please contact{' '}
              <strong>support@presagegroup.com.</strong>
            </p>
          </div>
        </NewModal>
      );
    }
    const { children } = this.props;
    return children;
  }
}
export default ErrorBoundary;
