import algoliasearch from 'algoliasearch';

import { REACT_APP_ALGOLIA_GROUPS_INDEX, REACT_APP_ALGOLIA_SECTIONS_SAVES_INDEX, REACT_APP_ALGOLIA_SURVEYS_INDEX } from 'constants/environment';

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_ADMIN_KEY
);

export const indexSurveys = searchClient.initIndex(
  REACT_APP_ALGOLIA_SURVEYS_INDEX
);

export const indexGroups = searchClient.initIndex(
  REACT_APP_ALGOLIA_GROUPS_INDEX
);

export const indexSectionsSaves = searchClient.initIndex(
  REACT_APP_ALGOLIA_SECTIONS_SAVES_INDEX
);