import { v4 as uuidv4 } from 'uuid';
import paths from 'pages/Router/paths';

import { LanguageName } from 'utils/enums';

import {
  SurveyType,
  SurveyVersionStatus,
  SurveyDeploymentStatus,
  SurveyElement,
} from './enums';

export const getAvailableLanguages = (availableLanguages) =>
  availableLanguages.reduce((o, key) => ({ ...o, [key]: '' }), {});

export const getPage = ({
  pageId = uuidv4(),
  availableLanguages,
  sectionIds = [],
}) => ({
  id: pageId,
  title: getAvailableLanguages(availableLanguages),
  visible: true,
  sectionIds,
});

export const getSection = ({ sectionId = uuidv4(), availableLanguages }) => ({
  id: sectionId,
  title: getAvailableLanguages(availableLanguages),
  visible: true,
  collapsed: false,
  itemIds: [],
  randomize: false,
});

export const surveyState = ({ surveyType, defaultLanguage }) => {
  const availableLanguages = [defaultLanguage];

  const section = getSection({ availableLanguages });

  const page = getPage({
    sectionIds: [section.id],
    availableLanguages,
  });
  
  return {
    pages: {
      [page.id]: page,
    },
    pagesOrder: [page.id],
    sections: {
      [section.id]: section,
    },
    items: {},
    name: '',
    type: surveyType || null,
    actions: [],
    versionTitle: getAvailableLanguages(availableLanguages),
    versionName: '',
    organizationId: null,
    organizationName: null,
    organizationDisplaySiteName: null,
    organization: null,
    canSelectOrganization: true,
    versionSelect: null,
    availableLanguages,
    defaultLanguage,
    selectedLanguage: defaultLanguage,
    languageSelect: {
      label: LanguageName[defaultLanguage],
      value: defaultLanguage,
    },
    isCondensed: false,
    disableDeselect: false,
  };
};

export const validSurveyType = (type) =>
  Object.values(SurveyType).includes(type);

export const surveyVersionStatusDisplayText = (surveyVersionStatus) => {
  switch (surveyVersionStatus) {
    case SurveyVersionStatus.ACTIVE:
      return 'Active';
    case SurveyVersionStatus.ARCHIVED:
      return 'Archived';
    case SurveyVersionStatus.INACTIVE:
      return 'Inactive';
    default:
      return 'Unkown status';
  }
};

export const surveyDeploymentStatusDisplayText = (surveyDeploymentStatus) => {
  switch (surveyDeploymentStatus) {
    case SurveyDeploymentStatus.PENDING:
      return 'Pending';
    case SurveyDeploymentStatus.ACTIVE:
      return 'Active';
    case SurveyDeploymentStatus.CLOSED:
      return 'Closed';
    case SurveyDeploymentStatus.INACTIVE:
      return 'Inactive';
    default:
      return 'Unkown status';
  }
};

export const changeSurveyVersionStatusDisplayText = (surveyVersionStatus) => {
  switch (surveyVersionStatus) {
    case SurveyVersionStatus.ACTIVE:
      return 'Archive';
    case SurveyVersionStatus.ARCHIVED:
      return 'Activate';
    default:
      return 'Unkown status';
  }
};

export const surveysPageTitle = (type) => {
  switch (type) {
    case SurveyType.TEMPLATE:
      return 'Template Surveys';
    case SurveyType.ORGANIZATION:
      return 'Organization Surveys';
    default:
      return 'Unkown title';
  }
};

export const addSurveyPath = (surveyType) => {
  switch (surveyType) {
    case SurveyType.TEMPLATE:
      return paths.ADD_TEMPLATE_SURVEY;
    case SurveyType.ORGANIZATION:
      return paths.ADD_ORGANIZATION_SURVEY;
    default:
      return null;
  }
};

export const listSurveysPath = (surveyType) => {
  switch (surveyType) {
    case SurveyType.TEMPLATE:
      return paths.SURVEYS_TEMPLATE;
    case SurveyType.ORGANIZATION:
      return paths.SURVEYS_ORGANIZATION;
    default:
      return paths.ROOT;
  }
};

const getItemProps = ({ itemType, availableLanguages }) => {
  switch (itemType) {
    case SurveyElement.TEXT_BOX:
      return {
        content: getAvailableLanguages(availableLanguages),
      };
    default:
      return {};
  }
};

export const getItem = ({
  itemType,
  availableLanguages,
  condensed = false,
  itemId = uuidv4(),
}) => ({
  id: itemId,
  type: itemType,
  visible: true,
  condensed,
  ...getItemProps({ itemType, availableLanguages }),
});

export const addItemSelectOptions = [
  { label: 'Text Box', value: SurveyElement.TEXT_BOX },
  { label: 'Question', value: SurveyElement.QUESTION },
  { label: 'Question group', value: SurveyElement.QUESTION_GROUP },
];

export const addItemSelectStyles = {
  control: (base) => ({
    ...base,
    '&:hover': { borderColor: '#b5b5b5' },
    boxShadow: 'none',
    border: '1px solid lightgray',
  }),
};

export const questionChoiceIsDuplicated = (option, choices, surveyLanguage) =>
  choices.some(
    (choice) =>
      choice.option[surveyLanguage].trim().toLowerCase() ===
      option.trim().toLowerCase()
  );

export const dataSetChoiceIsDuplicated = (option, choices) =>
  choices.some(
    (choice) =>
      choice.option.trim().toLowerCase() === option.trim().toLowerCase()
  );

export const isNotItemInActions = ({ actions, conditions }, itemId) => {
  const actionsHaveItem = actions.every((action) => {
    if (action.randomize && !action.choices) {
      const allSubjects = action.subject.every((subject) => {
        if (itemId === subject) {
          return false;
        }
        return true;
      });
      return allSubjects;
    }
    if (itemId === action.subject) {
      return false;
    }
    return true;
  });
  const conditionsHaveItem = conditions?.every((condition) => {
    if (itemId === condition.subject) {
      return false;
    }
    return true;
  });
  return actionsHaveItem && conditionsHaveItem;
};

export const isChoiceInActions = (
  { actions, conditions },
  itemId,
  choiceId
) => {
  const actionsHaveItem = actions.every((action) => {
    if (action.choices && itemId === action.subject) {
      const allChoices = action.choices.every((choice) => {
        if (choice === choiceId) {
          return false;
        }
        return true;
      });
      return allChoices;
    }
    return true;
  });
  const conditionsHaveItem = conditions?.every((condition) => {
    if (itemId === condition.subject) {
      const allValues = condition?.values?.every((value) => {
        if (value === choiceId) {
          return false;
        }
        return true;
      });
      return allValues;
    }
    return true;
  });
  return actionsHaveItem && conditionsHaveItem;
};

export const isElementInActions = (actions, elementId) => {
  const actionsHaveElement = actions.every((action) => {
    if (elementId === action.subject) {
      return false;
    }
    return true;
  });
  return actionsHaveElement;
};
