import { createReducer } from 'redux-act';

import {
  DATA_SETS_CREATE_DATA_SET_INIT,
  DATA_SETS_CREATE_DATA_SET_SUCCESS,
  DATA_SETS_CREATE_DATA_SET_FAIL,
  DATA_SETS_CLEAR_DATA,
  DATA_SETS_DELETE_DATA_SET_INIT,
  DATA_SETS_DELETE_DATA_SET_SUCCESS,
  DATA_SETS_DELETE_DATA_SET_FAIL,
  DATA_SETS_UPDATE_DATA_SET_INIT,
  DATA_SETS_UPDATE_DATA_SET_SUCCESS,
  DATA_SETS_UPDATE_DATA_SET_FAIL,
  DATA_SETS_FETCH_DATA_INIT,
  DATA_SETS_FETCH_DATA_SUCCESS,
  DATA_SETS_FETCH_DATA_FAIL,
} from '../../actions/dataSets';

export const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleting: false,
  deleted: false,
  createdDataSetId: null,
};

export const dataSetsReducer = createReducer(
  {
    [DATA_SETS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [DATA_SETS_FETCH_DATA_SUCCESS]: (state, { dataSets }) => ({
      ...state,
      data: dataSets,
      loading: false,
      error: null,
    }),
    [DATA_SETS_FETCH_DATA_FAIL]: (state, { error }) => ({
      ...state,
      error,
      loading: false,
    }),
    [DATA_SETS_CREATE_DATA_SET_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [DATA_SETS_CREATE_DATA_SET_SUCCESS]: (state, { id }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      createdDataSetId: id,
    }),
    [DATA_SETS_CREATE_DATA_SET_FAIL]: (state, { error }) => ({
      ...state,
      loading: false,
      error,
      createdDataSetId: null,
    }),
    [DATA_SETS_DELETE_DATA_SET_INIT]: (state) => ({
      ...state,
      deleting: true,
    }),
    [DATA_SETS_DELETE_DATA_SET_SUCCESS]: (state) => ({
      ...state,
      deleting: false,
      deleted: true,
      error: null,
    }),
    [DATA_SETS_DELETE_DATA_SET_FAIL]: (state, { error }) => ({
      ...state,
      deleting: false,
      error,
    }),
    [DATA_SETS_CLEAR_DATA]: () => initialState,
    [DATA_SETS_UPDATE_DATA_SET_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [DATA_SETS_UPDATE_DATA_SET_SUCCESS]: (state) => ({
      ...state,
      loading: false,
      success: true,
      error: null,
    }),
    [DATA_SETS_UPDATE_DATA_SET_FAIL]: (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
  },
  initialState
);
