/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Select from 'react-select';

import { colors } from 'styles';
import StatsCard from 'components/Home/StatsCard';
import WelcomeBanner from 'components/Home/WelcomeBanner';
import DeploymentsTable from 'components/DeploymentsTable';

import { fetchDeployments, surveysClearState } from 'state/actions/surveys';
import {
  triggerAnalyticsProcessing,
  analyticsClearState,
} from 'state/actions/analytics';

import { SurveyDeploymentStatus } from 'utils/surveyBuilder/enums';
import getDeploymentStatus from 'utils/surveyBuilder/getDeploymentStatus';
import { selectHomeDataState } from 'state/selectors/surveys';

const filterDeploymentFilterOptions = [
  { label: 'Overall', value: null },
  { label: 'Active', value: SurveyDeploymentStatus.ACTIVE },
  { label: 'Closed', value: SurveyDeploymentStatus.CLOSED },
  { label: 'Pending', value: SurveyDeploymentStatus.PENDING },
];

const Home = () => {
  const [deploymentFilter, setDeploymentFilter] = useState({
    filter: filterDeploymentFilterOptions[0].value,
    filterSelect: filterDeploymentFilterOptions[0],
  });

  const dispatch = useDispatch();

  const {
    userName,
    userLogo,
    loading,
    calledAnalytics,
    surveysData,
    defaultOrganization,
  } = useSelector(selectHomeDataState, shallowEqual);

  useEffect(() => {
    dispatch(fetchDeployments());
  }, [dispatch]);

  useEffect(() => {
    return () => dispatch(surveysClearState());
  }, [dispatch]);

  useEffect(() => {
    if (calledAnalytics) {
      dispatch(fetchDeployments());
    }
  }, [dispatch, calledAnalytics]);

  useEffect(() => {
    return () => dispatch(analyticsClearState());
  }, [dispatch]);

  const deployments = useMemo(
    () =>
      surveysData.filter(
        ({ startDate, endDate, organizationId, waveStatus }) => {
          const deploymentStatus = getDeploymentStatus(
            startDate,
            endDate,
            waveStatus
          );
          return (
            (deploymentFilter.filter === deploymentStatus ||
              !deploymentFilter.filter) &&
            (defaultOrganization?.value.id === organizationId ||
              !defaultOrganization)
          );
        }
      ),
    [surveysData, defaultOrganization, deploymentFilter]
  );

  const {
    totalDeployments,
    totalResponses,
    unprocessedResponses,
    processedResponses,
  } = useMemo(() => {
    const calculatedTotalResponses = deployments.reduce(
      (a, b) => a + b.totalResponses,
      0
    );
    const calculatedProcessedResponses = deployments.reduce(
      (a, b) => a + (b.totalResponses - b.unprocessedResponses),
      0
    );
    const calculatedUnprocessedResponses = deployments.reduce(
      (a, b) => a + b.unprocessedResponses,
      0
    );

    const finalProcessedResponses =
      calculatedTotalResponses < calculatedProcessedResponses
        ? calculatedTotalResponses
        : calculatedProcessedResponses;

    const finalUnprocessedResponses =
      calculatedUnprocessedResponses < 0 ? 0 : calculatedUnprocessedResponses;

    return {
      totalDeployments: deployments.length,
      totalResponses: calculatedTotalResponses,
      unprocessedResponses: finalUnprocessedResponses,
      processedResponses: finalProcessedResponses,
    };
  }, [deployments]);

  const onChangeFilterHandler = useCallback(
    (select) =>
      setDeploymentFilter((prevState) => ({
        ...prevState,
        filter: select?.value,
        filterSelect: select,
      })),
    []
  );

  const onTriggerAnalyticsHandler = useCallback(
    (deploymentData) => dispatch(triggerAnalyticsProcessing(deploymentData)),
    [dispatch]
  );

  return (
    <>
      <WelcomeBanner userName={userName} userLogo={userLogo}>
        <div className="field">
          <label className="label">Filter deployments</label>
          <div className="control" style={{ minWidth: '250px' }}>
            <Select
              isSearchable={false}
              placeholder="Deployment Status"
              isClearable={false}
              options={filterDeploymentFilterOptions}
              value={deploymentFilter.filterSelect}
              onChange={onChangeFilterHandler}
            />
          </div>
        </div>
      </WelcomeBanner>
      <section className="section is-main-section">
        <div className="tile is-ancestor">
          <div className="tile is-parent">
            <div className="tile is-child">
              <StatsCard
                isLoading={loading}
                icon="mdi mdi-cloud-upload-outline"
                valueName="Deployments"
                value={totalDeployments}
              />
            </div>
          </div>
          <div className="tile is-parent">
            <div className="tile is-child">
              <StatsCard
                isLoading={loading}
                icon="mdi mdi-chart-timeline-variant"
                iconColor="#167df0"
                valueName="Responses"
                value={totalResponses}
              />
            </div>
          </div>
          <div className="tile is-parent">
            <div className="tile is-child">
              <StatsCard
                isLoading={loading}
                icon="mdi mdi-chart-timeline-variant"
                iconColor={colors.error}
                valueName="Unprocessed"
                value={unprocessedResponses}
              />
            </div>
          </div>
          <div className="tile is-parent">
            <div className="tile is-child">
              <StatsCard
                isLoading={loading}
                icon="mdi mdi-chart-timeline-variant"
                iconColor="#48c774"
                valueName="Processed"
                value={processedResponses}
              />
            </div>
          </div>
        </div>
        <DeploymentsTable
          data={deployments}
          isLoading={loading}
          onTriggerAnalytics={onTriggerAnalyticsHandler}
        />
      </section>
    </>
  );
};

export default Home;
