export const createQuestionChoiceObject = ({
  id,
  questionId,
  option,
  paramOne,
  paramTwo,
  openEnded,
  notApplicable,
  dataSetId,
  sortOrder,
  visible,
}) => {
  const questionChoice = {
    id,
    choice: {
      questionId,
      option,
      paramOne: paramOne?.trim() ?? null,
      paramTwo: paramTwo?.trim() ?? null,
      openEnded,
      notApplicable,
      dataSetId: dataSetId ?? null,
      sortOrder,
      visible,
    },
  };

  return questionChoice;
};
