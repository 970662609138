import { toastr } from 'react-redux-toastr';

import firebase from 'firebase.js';
import {
  SURVEYS_FETCH_DEPLOYMENTS_FAIL,
  SURVEYS_FETCH_DEPLOYMENTS_INIT,
  SURVEYS_FETCH_DEPLOYMENTS_SUCCESS,
} from 'state/actionCreators/surveys';
import { firebaseError } from 'utils';

export const fetchDeployments = () => {
  return async (dispatch, getState) => {
    dispatch(SURVEYS_FETCH_DEPLOYMENTS_INIT());

    const { locale } = getState().preferences;

    const deployments = [];

    try {
      const deploymentDocs = await firebase
        .firestore()
        .collectionGroup('deployments')
        .get();

      deploymentDocs.forEach((deploymentDoc) => {
        const deploymentData = deploymentDoc.data();

        const deployment = {
          id: deploymentDoc.id,
          ...deploymentData,
          startDate: deploymentData.startDate.toDate(),
          endDate: deploymentData.endDate?.toDate() || null,
          lastTimeProcessed: deploymentData.lastTimeProcessed?.toDate() || null,
        };

        deployments.push(deployment);
      });
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        SURVEYS_FETCH_DEPLOYMENTS_FAIL({
          error: errorMessage,
        })
      );
    }

    return dispatch(SURVEYS_FETCH_DEPLOYMENTS_SUCCESS({ deployments }));
  };
};
