import React from 'react';
import SortableTree from 'react-sortable-tree';
import PropTypes from 'prop-types';

import 'react-sortable-tree/style.css';

const HierarchyTree = ({ treeData, onChangeTreeData }) => {
  return (
    <SortableTree
      treeData={treeData}
      onChange={onChangeTreeData}
      isVirtualized={false}
    />
  );
};

const treeDataShape = {
  title: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
};
treeDataShape.children = PropTypes.arrayOf(
  PropTypes.shape(treeDataShape)
).isRequired;

HierarchyTree.propTypes = {
  treeData: PropTypes.arrayOf(PropTypes.shape(treeDataShape).isRequired)
    .isRequired,
  onChangeTreeData: PropTypes.func.isRequired,
};

export default HierarchyTree;
