import { createReducer } from 'redux-act';

import {
  SAVED_SECTIONS_CREATE_SECTION_SAVE_INIT,
  SAVED_SECTIONS_CREATE_SECTION_SAVE_SUCCESS,
  SAVED_SECTIONS_CREATE_SECTION_SAVE_FAIL,
  SAVED_SECTIONS_FETCH_SINGLE_SECTION_INIT,
  SAVED_SECTIONS_FETCH_SINGLE_SECTION_SUCCESS,
  SAVED_SECTIONS_FETCH_SINGLE_SECTION_FAIL,
  SAVED_SECTIONS_CLEAR_STATE,
  SAVED_SECTIONS_SECTION_DELETE_INIT,
  SAVED_SECTIONS_SECTION_DELETE_FAIL,
  SAVED_SECTIONS_SECTION_DELETE_SUCCESS,
} from 'state/actionCreators/savedSections';

const initialState = {
  // Create and Update Section Save
  createSectionLoading: false,
  createSectionSuccess: false,
  createSectionRepeated: false,
  createSectionError: null,
  
  // Fetch Section Save
  fetchSectionLoading: false,
  fetchSectionError: null,
  fetchSectionSuccess: false,
  sectionSave: null,

  // Delete Section Save
  deleteSectionLoading: false,
  deleteSectionSuccess: false,
  deleteSectionError: null,
};

export const sectionsSavesReducer = createReducer(
  {
    // Create and Update Section Save
    [SAVED_SECTIONS_CREATE_SECTION_SAVE_INIT]: () => ({
      ...initialState,
      createSectionLoading: true,
      createSectionSuccess: false,
      createSectionRepeated: false,
    }),
    [SAVED_SECTIONS_CREATE_SECTION_SAVE_SUCCESS]: (state) => ({
      ...state,
      createSectionLoading: false,
      createSectionError: null,
      createSectionSuccess: true,
      createSectionRepeated: false,
    }),
    [SAVED_SECTIONS_CREATE_SECTION_SAVE_FAIL]: (state, { error }) => {
      if (error === 'section-exists') {
        return {
          ...state,
          createSectionError: null,
          createSectionLoading: false,
          createSectionSuccess: false,
          createSectionRepeated: true,
        };
      }

      return {
        ...state,
        createSectionLoading: false,
        createSectionSuccess: false,
        createSectionRepeated: false,
        createSectionError: error,
      };
    },

    // Delete Section Save
    [SAVED_SECTIONS_SECTION_DELETE_INIT]: () => ({
      ...initialState,
      deleteSectionLoading: true,
      deleteSectionSuccess: false,
      deleteSectionError: null,
    }),
    [SAVED_SECTIONS_SECTION_DELETE_SUCCESS]: (state) => ({
      ...state,
      deleteSectionLoading: false,
      deleteSectionSuccess: true,
      deleteSectionError: null,
    }),
    [SAVED_SECTIONS_SECTION_DELETE_FAIL]: (state, payload) => ({
      ...state,
      deleteSectionLoading: false,
      deleteSectionError: payload.error,
      deleteSectionSuccess: false,
    }),

    // Fetch Section Save
    [SAVED_SECTIONS_FETCH_SINGLE_SECTION_INIT]: () => ({
      ...initialState,
      fetchSectionLoading: true,
      fetchSectionSuccess: false,
      fetchSectionError: null,
    }),
    [SAVED_SECTIONS_FETCH_SINGLE_SECTION_SUCCESS]: (
      state,
      { sectionSave }
    ) => ({
      ...state,
      fetchSectionLoading: false,
      fetchSectionSuccess: true,
      fetchSectionError: null,
      sectionSave,
    }),
    [SAVED_SECTIONS_FETCH_SINGLE_SECTION_FAIL]: (state, payload) => ({
      ...state,
      fetchSectionLoading: false,
      fetchSectionError: payload.error,
      fetchSectionSuccess: false,
    }),

    // Clear State
    [SAVED_SECTIONS_CLEAR_STATE]: () => initialState,
  },
  initialState
);
