/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import { toastr } from 'react-redux-toastr';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { v4 as uuid } from 'uuid';

import Column from './Column';
import classes from './Layout.module.scss';

const Layout = ({ layout, setLayout }) => {
  const canDropChartInColumn = useCallback(
    (destination, source, draggableId, type) => {
      if (destination.droppableId === 'storage') {
        return true;
      }

      if (type === 'column') {
        return true;
      }

      if (destination.droppableId === source.droppableId) {
        return true;
      }

      const totalSpace = 12;

      const draggableChartWeight = layout.charts[draggableId].weight;

      const chartsIdsInDestinationColumn =
        layout.columns[destination.droppableId].chartsIds;

      const usedSpace = chartsIdsInDestinationColumn
        .map((chartId) => layout.charts[chartId].weight)
        .reduce((a, b) => a + b, 0);

      return draggableChartWeight <= totalSpace - usedSpace;
    },
    [layout.charts, layout.columns]
  );

  const onDragEnd = useCallback(
    (result) => {
      const { destination, source, draggableId, type } = result;

      if (!destination) {
        return;
      }

      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return;
      }

      if (!canDropChartInColumn(destination, source, draggableId, type)) {
        toastr.error('', 'Insufficient space');
        return;
      }

      if (type === 'column') {
        const newColumnOrder = Array.from(layout.columnOrder);
        newColumnOrder.splice(source.index, 1);
        newColumnOrder.splice(destination.index, 0, draggableId);

        setLayout((prevState) => ({
          ...prevState,
          columnOrder: newColumnOrder,
        }));

        return;
      }

      const start = layout.columns[source.droppableId];
      const finish = layout.columns[destination.droppableId];

      if (start === finish) {
        const newChartsIds = Array.from(start.chartsIds);
        newChartsIds.splice(source.index, 1);
        newChartsIds.splice(destination.index, 0, draggableId);

        const newColumn = {
          ...start,
          chartsIds: newChartsIds,
        };

        setLayout((prevState) => ({
          ...prevState,
          columns: {
            ...layout.columns,
            [newColumn.id]: newColumn,
          },
        }));

        return;
      }

      // Moving a chart from one column to another
      const startChartsIds = Array.from(start.chartsIds);
      startChartsIds.splice(source.index, 1);
      const newStart = {
        ...start,
        chartsIds: startChartsIds,
      };

      const finishchartsIds = Array.from(finish.chartsIds);
      finishchartsIds.splice(destination.index, 0, draggableId);
      const newFinish = {
        ...finish,
        chartsIds: finishchartsIds,
      };

      setLayout((prevState) => ({
        ...prevState,
        columns: {
          ...prevState.columns,
          [newStart.id]: newStart,
          [newFinish.id]: newFinish,
        },
      }));
    },
    [layout.columns, layout.columnOrder, setLayout, canDropChartInColumn]
  );

  const onAddColumnHanlder = useCallback(() => {
    const newColumn = {
      id: uuid(),
      title: 'Row',
      chartsIds: [],
    };

    setLayout((prevState) => ({
      ...prevState,
      columns: {
        ...prevState.columns,
        [newColumn.id]: newColumn,
      },
      columnOrder: [...prevState.columnOrder, newColumn.id],
    }));
  }, [setLayout]);

  const onDeleteColumnHandler = useCallback(
    (columnId) => {
      const newLayout = { ...layout };

      // remove the column only if it's not the only one
      if (newLayout.columnOrder.length > 2) {
        // move the charts to the storage before deleting the column
        const storageChartsIds = newLayout.columns.storage.chartsIds;
        const columChartsIds = newLayout.columns[columnId].chartsIds;
        const newChartsIds = storageChartsIds.concat(columChartsIds);
        newLayout.columns.storage.chartsIds = newChartsIds;

        // delete the column
        delete newLayout.columns[columnId];
        newLayout.columnOrder = newLayout.columnOrder.filter(
          (column) => column !== columnId
        );

        setLayout(newLayout);
      }
    },
    [layout, setLayout]
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="allColumns" type="column">
        {(provided) => (
          <div className={classes.container}>
            <div
              className={classes.storage}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {layout.columnOrder.map((columnId, index) => {
                const column = layout.columns[columnId];
                const charts = column.chartsIds.map(
                  (chartId) => layout.charts[chartId]
                );

                return (
                  columnId === 'storage' && (
                    <Column
                      key={column.id}
                      column={column}
                      charts={charts}
                      index={index}
                    />
                  )
                );
              })}
              {provided.placeholder}
            </div>
            <div
              className={classes['layout-container']}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {layout.columnOrder.map((columnId, index) => {
                const column = layout.columns[columnId];
                const charts = column.chartsIds.map(
                  (chartId) => layout.charts[chartId]
                );

                return (
                  columnId !== 'storage' && (
                    <Column
                      key={column.id}
                      column={column}
                      charts={charts}
                      index={index}
                      onAddColumnHanlder={onAddColumnHanlder}
                      onDeleteColumnHandler={onDeleteColumnHandler}
                    />
                  )
                );
              })}
              {provided.placeholder}
            </div>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

Layout.propTypes = {};

export default Layout;
