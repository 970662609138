/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Select from 'react-select';

import {
  reprocessResponses,
  reprocessResponsesClearState,
} from 'state/actions/reprocessResponses';
import { numberHandler } from 'utils';
import { useChangeHandler, useSelectChangeHandler } from 'hooks';
import { fetchOrganizations, fetchDefaultOrganization } from 'utils/fetching';
import paths from 'pages/Router/paths';
import DatePicker from '../../../components/DatePicker';
import classes from './WaveForm.module.scss';
import './WaveForm.scss';

const WaveForm = ({
  isEmbedded,
  waveToggle,
  setWave,
  wave,
  validUntilDate,
  canSubmit,
  loading,
  onSubmitHandler,
  isEditing,
  waveId,
}) => {
  const { defaultOrganization, isReprocessingResponses } = useSelector(
    (state) => ({
      defaultOrganization: state.preferences.defaultOrganization,
      isReprocessingResponses: state.reprocessResponses.loading,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const onChangeHandler = useChangeHandler(setWave);

  const onSelectChangeHandler = useSelectChangeHandler(setWave);

  const [organizations, setOrganizations] = React.useState({
    data: [],
    loading: false,
    fetched: false,
  });

  const [existingDefaultOrganization, setExistingDefaultOrganization] =
    React.useState({
      data: null,
      loading: false,
      fetched: false,
    });

  useEffect(() => {
    if (!isEditing) {
      const filterInactives = true;
      fetchOrganizations(setOrganizations, filterInactives);
    }
  }, [isEditing]);

  useEffect(() => {
    if (defaultOrganization) {
      fetchDefaultOrganization(
        existingDefaultOrganization,
        setExistingDefaultOrganization,
        defaultOrganization.value.displayName
      );
    }
  }, [existingDefaultOrganization, defaultOrganization]);

  useEffect(() => {
    if (
      !isEditing &&
      !isEmbedded &&
      existingDefaultOrganization.data &&
      organizations.fetched
    ) {
      const organization = organizations.data
        .filter(
          (org) =>
            org.value.displayName === defaultOrganization.value.displayName
        )
        .pop();
      setWave((prevState) => ({
        ...prevState,
        organization,
        index: organization.value.currentWave + 1,
      }));
    }
  }, [
    existingDefaultOrganization,
    isEditing,
    organizations,
    setWave,
    defaultOrganization,
  ]);

  useEffect(() => {
    return () => dispatch(reprocessResponsesClearState());
  }, [dispatch]);

  let untilDate = {
    modifier: null,
    message: { modifier: null, content: null },
  };

  if (!validUntilDate) {
    untilDate = {
      modifier: 'is-danger',
      message: {
        modifier: 'is-danger',
        content: 'Until date cannot be previous than starting on date',
      },
    };
  }

  return (
    <>
      {!isEmbedded ? (
        <div className="tile is-ancestor">
          <div className="tile is-parent">
            <div className="card tile is-child">
              <header className={classNames('card-header', classes.header)}>
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-account-edit default" />
                  </span>
                  Wave Information
                </p>
                {isEditing && (
                  <div
                    className={classNames(
                      'card-header-title',
                      classes['reprocess-button']
                    )}
                  >
                    <button
                      type="button"
                      className={classNames('button', {
                        'is-loading': isReprocessingResponses,
                      })}
                      disabled={isReprocessingResponses}
                      onClick={() => dispatch(reprocessResponses(waveId))}
                    >
                      <span>Reprocess Responses</span>
                    </button>
                  </div>
                )}
              </header>
              <div className="card-content">
                <form onSubmit={onSubmitHandler}>
                  <div className="card-content columns">
                    <div className="column">
                      <div className="field is-horizontal">
                        <div className="field-label is-normal">
                          <label className="label">Index</label>
                        </div>
                        <div className="field-body">
                          <div className="field">
                            <div className="control">
                              <input
                                id="index"
                                disabled
                                className="input"
                                type="number"
                                required
                                name="index"
                                value={wave.index}
                                onChange={onChangeHandler}
                              />
                            </div>
                            <small>
                              The wave index is used to identify the wave and
                              must be an number.
                              <br />
                              Waves are sorted by index from lowest to highest.
                            </small>
                          </div>
                        </div>
                      </div>

                      <div className="field is-horizontal">
                        <div className="field-label is-normal">
                          <label className="label">Name</label>
                        </div>
                        <div className="field-body">
                          <div className="field">
                            <div className="control">
                              <input
                                id="name"
                                className="input"
                                type="text"
                                name="name"
                                value={wave.name}
                                onChange={onChangeHandler}
                              />
                            </div>
                            <small>
                              Name is optional; when present it will be
                              displayed
                              <br />
                              along with the Wave Index to identify this wave.
                            </small>
                          </div>
                        </div>
                      </div>

                      <div className="field is-horizontal">
                        <div className="field-label is-normal">
                          <label className="label">Starting on</label>
                        </div>
                        <div className="field-body">
                          <div className="field">
                            <div className="control">
                              <DatePicker
                                name="startingOn"
                                date={new Date(wave.startingOn)}
                                setState={setWave}
                              />
                            </div>
                            <small>
                              Start date is required and can be any date in the
                              past or future.
                            </small>
                          </div>
                        </div>
                      </div>

                      <div className="field is-horizontal">
                        <div className="field-label is-normal">
                          <label className="label">Until</label>
                        </div>
                        <div className="field-body">
                          <div className="field">
                            <div className="control">
                              <DatePicker
                                name="until"
                                date={wave.until ? new Date(wave.until) : null}
                                setState={setWave}
                                className={classNames(
                                  'input',
                                  untilDate.modifier
                                )}
                              />
                            </div>
                            {untilDate.message.content && (
                              <p
                                className={classNames(
                                  'help is-',
                                  untilDate.message.modifier
                                )}
                              >
                                {untilDate.message.content}
                              </p>
                            )}
                            <small>
                              End date is not required, but if present, it must
                              be after Start date.
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="column">
                      <div className="field is-horizontal">
                        <div className="field-label is-normal">
                          <label className="label">Organization</label>
                        </div>
                        <div className="field-body">
                          <div className="field">
                            <div className="control">
                              <Select
                                isDisabled={organizations.loading || isEditing}
                                isLoading={organizations.loading}
                                options={organizations.data}
                                onChange={(select) => {
                                  onSelectChangeHandler('organization', select);
                                  setWave((prevState) => ({
                                    ...prevState,
                                    index: select.value.currentWave + 1,
                                  }));
                                }}
                                value={wave.organization}
                              />
                              <span className="check is-primary" />
                            </div>
                            <small>Select an organization for the wave.</small>
                          </div>
                        </div>
                      </div>

                      <div className="field is-horizontal">
                        <div className="field-label">
                          <label className="label">Expected responses</label>
                        </div>
                        <div className="field-body">
                          <div className="field">
                            <div className="control">
                              <input
                                className="input"
                                type="text"
                                inputMode="numeric"
                                pattern="^-?[0-9]\d*$"
                                min="0"
                                name="expectedResponses"
                                value={wave.expectedResponses}
                                onChange={(e) =>
                                  numberHandler(e, onChangeHandler)
                                }
                              />
                            </div>
                            <small>
                              The expected number of responses for this wave, or
                              empty for unknown.
                              <br />
                              If set to 0, the expected count will be the total
                              number of active users for the wave.
                            </small>
                          </div>
                        </div>
                      </div>

                      <div className="field is-horizontal">
                        <div className="field-label">
                          <label className="label">Reporting Threshold</label>
                        </div>
                        <div className="field-body">
                          <div className="field">
                            <div className="control">
                              <input
                                className="input"
                                type="text"
                                inputMode="numeric"
                                pattern="^-?[0-9]\d*$"
                                min="0"
                                name="reportingThreshold"
                                required
                                value={wave.reportingThreshold}
                                onChange={(e) =>
                                  numberHandler(e, onChangeHandler)
                                }
                              />
                            </div>
                            <small>
                              Number of responses a group/segment must have
                              before being reported on.
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label" />
                    <div className="field-body">
                      <div className="field">
                        <div className="field is-grouped">
                          <div className="control">
                            <button
                              type="submit"
                              className={classNames('button is-primary', {
                                'is-loading': loading,
                              })}
                              disabled={!canSubmit}
                            >
                              <span>Submit</span>
                            </button>
                          </div>
                          <Link to={paths.WAVES} className="button">
                            Go Back
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={classNames('card-content', { opacity: !waveToggle })}>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Name</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <input
                    id="waveName"
                    className="input"
                    type="text"
                    name="name"
                    value={wave.name}
                    onChange={onChangeHandler}
                    disabled={!waveToggle}
                  />
                </div>
                <small>
                  Name is optional; when present it will be displayed
                  <br />
                  along with the Wave Index to identify this wave.
                </small>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Starting on</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <DatePicker
                    name="startingOn"
                    date={new Date(wave.startingOn)}
                    setState={setWave}
                    disabled={!waveToggle}
                  />
                </div>
                <small>
                  Start date is required and can be any date in the past or
                  future.
                </small>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Until</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <DatePicker
                    name="until"
                    date={wave.until ? new Date(wave.until) : null}
                    setState={setWave}
                    className={classNames('input', untilDate.modifier)}
                    disabled={!waveToggle}
                  />
                </div>
                {untilDate.message.content && (
                  <p
                    className={classNames(
                      'help is-',
                      untilDate.message.modifier
                    )}
                  >
                    {untilDate.message.content}
                  </p>
                )}
                <small>
                  End date is not required, but if present, it must be after
                  Start date.
                </small>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label">
              <label className="label">Expected responses</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    inputMode="numeric"
                    pattern="^-?[0-9]\d*$"
                    min="0"
                    name="expectedResponses"
                    value={wave.expectedResponses}
                    onChange={(e) => numberHandler(e, onChangeHandler)}
                    disabled={!waveToggle}
                  />
                </div>
                <small>
                  The expected number of responses for this wave, or empty for
                  unknown.
                  <br />
                  If set to 0, the expected count will be the total number of
                  active users for the wave.
                </small>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label">
              <label className="label">Reporting Threshold</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    inputMode="numeric"
                    pattern="^-?[0-9]\d*$"
                    min="0"
                    name="reportingThreshold"
                    required
                    value={wave.reportingThreshold}
                    onChange={(e) => numberHandler(e, onChangeHandler)}
                    disabled={!waveToggle}
                  />
                </div>
                <small>
                  Number of responses a group/segment must have before being
                  reported on.
                </small>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

WaveForm.propTypes = {
  isEmbedded: PropTypes.bool,
  waveToggle: PropTypes.bool,
  canSubmit: PropTypes.bool,
  isEditing: PropTypes.bool,
  loading: PropTypes.bool,
  onSubmitHandler: PropTypes.func,
  validUntilDate: PropTypes.bool.isRequired,
  setWave: PropTypes.func.isRequired,
  wave: PropTypes.shape({
    index: PropTypes.number.isRequired,
    name: PropTypes.string,
    startingOn: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]).isRequired,
    until: PropTypes.instanceOf(Date),
    expectedResponses: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    reportingThreshold: PropTypes.number.isRequired,
    organization: PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.shape({
        displayName: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
};

WaveForm.defaultProps = {
  isEmbedded: false,
  waveToggle: false,
  canSubmit: false,
  isEditing: false,
  loading: false,
};

export default WaveForm;
