import { createSelector } from 'reselect';

export const selectSectionsSavesState = (state) => state.sectionsSaves;

export const selectSectionSaveFetch = createSelector(
  selectSectionsSavesState,
  ({
    fetchSectionLoading,
    fetchSectionError,
    fetchSectionSuccess,
    sectionSave,
  }) => ({
    loading: fetchSectionLoading,
    error: fetchSectionError,
    success: fetchSectionSuccess,
    sectionSave,
  })
);

export const selectSectionSaveCreation = createSelector(
  selectSectionsSavesState,
  ({
    createSectionLoading,
    createSectionSuccess,
    createSectionRepeated,
    createSectionError,
  }) => ({
      loading: createSectionLoading,
      error: createSectionError,
      success: createSectionSuccess,
      sectionExists: createSectionRepeated,
  })
);

export const selectSectionSaveDeletion = createSelector(
  selectSectionsSavesState,
  ({
    deleteSectionLoading,
    deleteSectionSuccess,
    deleteSectionError,
  }) => ({
      loading: deleteSectionLoading,
      error: deleteSectionError,
      success: deleteSectionSuccess,
  })
);
