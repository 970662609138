/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import paths from 'pages/Router/paths';
import classes from './DataItems.module.scss';
import DataItem from './DataItem';

const DataItems = ({
  dataItems,
  loading,
  isEditing,
  canSubmit,
  onAddItem,
  onTranslate,
  onResetList,
  onDeleteItem,
  onChangeDataItem,
}) => {
  const onUploadHandler = useCallback(() => {
    // To be implemented in a future ticket
  }, []);

  return (
    <div className="card">
      <header className="card-header">
        <p className="card-header-title">
          <span className="icon">
            <i className="mdi mdi-tag" />
          </span>
          Data Items
        </p>
      </header>
      <div className="card-content">
        <div className="field is-horizontal">
          <div className="field-label is-normal" />
          <div className="field-body">
            <div className="field">
              <div className="control buttons">
                <button
                  id="data-items-add-data-item"
                  type="button"
                  className="button"
                  onClick={onAddItem}
                >
                  <span className="icon">
                    <i className="mdi mdi-plus-circle-outline" />
                  </span>
                  <span>Add Item</span>
                </button>
                <button
                  id="upload-button"
                  type="button"
                  className="button"
                  onClick={onUploadHandler}
                >
                  <span className="icon">
                    <i className="mdi mdi-upload" />
                  </span>
                  <span>Upload</span>
                </button>
                <button
                  id="data-items-reset-list"
                  type="button"
                  className="button"
                  onClick={onResetList}
                >
                  <span className="icon">
                    <i className="mdi mdi-minus-circle-outline" />
                  </span>
                  <span>Reset List</span>
                </button>
                <button
                  id="data-items-translations"
                  className="button"
                  type="button"
                  onClick={onTranslate}
                >
                  <span className="icon">
                    <i className="mdi mdi-earth" />
                  </span>
                  <span>Translations</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        {dataItems.map(({ option, duplicated, paramOne, paramTwo }, index) => {
          const label = `${index + 1}.`;
          return (
            <DataItem
              key={label}
              id={index}
              label={label}
              option={option}
              paramOne={paramOne}
              paramTwo={paramTwo}
              onChange={onChangeDataItem}
              onDelete={onDeleteItem}
              canRemove={dataItems.length > 1}
              duplicated={duplicated}
            />
          );
        })}
      </div>
      <footer className={classNames('card-footer', classes.cardFooter)}>
        <div className={classNames('buttons', classes.buttons)}>
          <button
            id="data-items-create-data-set"
            type="submit"
            className={classNames('button', 'is-primary', {
              'is-loading': loading,
            })}
            disabled={!canSubmit || loading}
          >
            {isEditing ? 'Update' : 'Create'}
          </button>
          <Link to={paths.DATA_SETS}>
            <button
              id="data-items-go-back"
              className="button"
              disabled={loading}
            >
              Go Back
            </button>
          </Link>
        </div>
      </footer>
    </div>
  );
};

DataItems.propTypes = {
  dataItems: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  loading: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  canSubmit: PropTypes.bool.isRequired,
  onAddItem: PropTypes.func.isRequired,
  onTranslate: PropTypes.func.isRequired,
  onResetList: PropTypes.func.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
  onChangeDataItem: PropTypes.func.isRequired,
};

export default DataItems;
