/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import classes from './Column.module.scss';
import Charts from '../Charts';

const Column = ({
  column,
  charts,
  index,
  onAddColumnHanlder,
  onDeleteColumnHandler,
}) => {
  return (
    <>
      {column.id === 'storage' && (
        <div className={classes['column-container']}>
          <div className={classes['storage-title']}>
            <i
              className={classNames('mdi mdi-server', classes['storage-icon'])}
            />
            {column.title}
          </div>
          <Droppable droppableId={column.id}>
            {(droppableProvided, snapshot) => (
              <div
                className={classNames(
                  classes['chart-container'],
                  classes['flex-column'],
                  {
                    [classes.dragging]: snapshot.isDraggingOver,
                  }
                )}
                {...droppableProvided.droppableProps}
                ref={droppableProvided.innerRef}
              >
                <Charts charts={charts} columnId={column.id} />
                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      )}
      {column.id !== 'storage' && (
        <Draggable draggableId={column.id} index={index}>
          {(provided) => (
            <div
              className={classes['column-container']}
              {...provided.draggableProps}
              ref={provided.innerRef}
            >
              <div className={classes['column-header']}>
                <div className={classes['column-title']}>
                  <i
                    {...provided.dragHandleProps}
                    className={classNames('mdi mdi-drag', classes['drag-icon'])}
                  />
                  {column.title}
                </div>
                <div>
                  <i
                    className={classNames(
                      'mdi mdi-plus-circle-outline',
                      classes['add-icon']
                    )}
                    onClick={onAddColumnHanlder}
                  />
                  <i
                    className={classNames(
                      'mdi mdi-trash-can-outline',
                      classes['delete-icon']
                    )}
                    onClick={() => onDeleteColumnHandler(column.id)}
                  />
                </div>
              </div>
              <Droppable droppableId={column.id} direction="horizontal">
                {(droppableProvided, snapshot) => (
                  <div
                    className={classNames(classes['chart-container'], {
                      [classes.dragging]: snapshot.isDraggingOver,
                    })}
                    {...droppableProvided.droppableProps}
                    ref={droppableProvided.innerRef}
                  >
                    <Charts charts={charts} columnId={column.id} />
                    {droppableProvided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          )}
        </Draggable>
      )}
    </>
  );
};

export default Column;
