import { createReducer } from 'redux-act';

import {
  PREFERENCES_SET_LOCALE,
  PREFERENCES_SET_DEFAULT_ORGANIZATION,
} from 'state/actions/preferences';

export const initialState = {
  locale: null,
  defaultOrganization: null,
};

export const preferencesReducer = createReducer(
  {
    [PREFERENCES_SET_LOCALE]: (state, payload) => ({
      ...state,
      locale: payload.locale,
    }),
    [PREFERENCES_SET_DEFAULT_ORGANIZATION]: (state, payload) => ({
      ...state,
      defaultOrganization: payload.organization,
    }),
  },
  initialState
);
