import React from 'react';

import classes from './Item.module.scss';

const Item = ({ children }) => (
  <div id="children-placeholder" className={classes.item}>
    {children}
  </div>
);

export default Item;
