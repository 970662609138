/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import classes from './Handle.module.scss';

const Handle = ({
  domain: [min, max],
  handle: { id, value, percent },
  isLast,
  showValueText,
  isActive,
  disabled,
  getHandleProps,
  style = {},
  tooltipStyle = {
    text: {},
  },
}) => {
  const [mouseOver, setMouseOver] = useState(false);
  const [mouseDown, setMouseDown] = useState(false);

  const onMouseEnter = () => setMouseOver(true);

  const onMouseLeave = () => setMouseOver(false);

  const onMouseDown = () => setMouseDown(true);

  const onMouseUp = () => setMouseDown(false);

  const { backgroundColor: activeBackgroundColor, ...styles } = style;

  const backgroundColor = activeBackgroundColor ?? 'hsl(171, 100%, 41%)';
  const disabledBackroundColor = '#666';

  return (
    <>
      {(mouseOver || isActive) && !disabled ? (
        <div
          style={{
            left: `${percent}%`,
            position: 'absolute',
            marginLeft: '-11px',
            marginTop: '-35px',
            width: '78px',
            ...tooltipStyle,
          }}
        >
          <div className="tooltip">
            <span
              className={classnames('tooltiptext', classes['tooltip-text'])}
              style={tooltipStyle.text}
            >
              {showValueText && 'Value:'}{isLast ? `${value}+`: value}
            </span>
          </div>
        </div>
      ) : null}
      <div
        style={{
          left: `${percent}%`,
          position: 'absolute',
          transform: 'translate(-50%, -50%)',
          WebkitTapHighlightColor: 'rgba(0,0,0,0)',
          zIndex: 10,
          width: 26,
          height: 42,
          cursor: mouseDown ? 'grabbing' : 'grab',
          backgroundColor: 'none',
        }}
        {...getHandleProps(id, {
          onMouseEnter,
          onMouseLeave,
          onMouseUp,
          onMouseDown,
        })}
      />
      <div
        role="slider"
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={value}
        aria-label="slider"
        style={{
          left: `${percent}%`,
          position: 'absolute',
          transform: 'translate(-50%, -50%)',
          WebkitTapHighlightColor: 'rgba(0,0,0,0)',
          zIndex: 5,
          width: 24,
          height: 24,
          border: 0,
          borderRadius: '50%',
          boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.2)',
          backgroundColor: disabled ? disabledBackroundColor : backgroundColor,
          ...styles,
        }}
      />
    </>
  );
};

Handle.propTypes = {
  domain: PropTypes.arrayOf(PropTypes.number).isRequired,
  handle: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }).isRequired,
  getHandleProps: PropTypes.func.isRequired,
  isLast: PropTypes.bool,
  showValueText: PropTypes.bool,
  isActive: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.shape({}),
};

Handle.defaultProps = {
  disabled: false,
  isLast: false,
  showValueText: true,
};

export default Handle;
