/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';

import classes from './QuestionTag.module.scss';

const QuestionTag = ({ tag, onRemove, inSurveyBuilder, inQuestionGroupBuilder }) => (
  <div className={classNames('tags', classes['question-tag'])}>
    <span
      id="tag-name-placeholder"
      className={classNames('tag', classes['tag-name'], {
        [classes['in-question-builder']]: inQuestionGroupBuilder,
        'is-link': inSurveyBuilder,
      })}
    >
      {tag.displayName}
    </span>
    {tag.weight && (
      <span
        id="tag-weight-placeholder"
        className={classNames('tag', { 'is-primary': !inSurveyBuilder })}
        data-tip="Tag weight"
        data-for="tag-options"
      >
        {tag.weight}
      </span>
    )}
    {onRemove && (
      <a
        id="remove-tag"
        className={classNames('tag', 'is-delete', classes['delete-button'], {
          'is-primary': !tag.weight,
        })}
        onClick={() => onRemove(tag)}
      />
    )}
    <ReactTooltip place="bottom" id="tag-options" />
  </div>
);

QuestionTag.propTypes = {
  tag: PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    weight: PropTypes.number,
  }).isRequired,
  onRemove: PropTypes.func,
};

export default QuestionTag;
