import React, { useMemo, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';

import { getListTagPath, tagPathToType } from 'utils';
import { clearData } from 'state/actions/tags';
import TagForm from 'components/Tag/TagForm';

const Tag = () => {
  const { type, id } = useParams();

  const dispatch = useDispatch();

  const { success } = useSelector(
    (state) => ({
      success: state.tags.success || state.tags.successCreatingTag,
    }),
    shallowEqual
  );

  useEffect(() => {
    return () => {
      dispatch(clearData());
    };
  }, [dispatch]);

  const redirect = useMemo(
    () => success && <Redirect to={getListTagPath(type)} />,
    [success, type]
  );

  return (
    <>
      {redirect}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">{`${tagPathToType[type]} Tag`}</h1>
        </div>
      </section>
      <section className="section is-main-section">
        <TagForm tagType={type} tagId={id} />
      </section>
    </>
  );
};

export default Tag;
