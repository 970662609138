/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import Select from 'react-select/creatable';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import Spinner from 'react-spinners/ClipLoader';
import classNames from 'classnames';

import { TagType } from 'utils/enums';
import { SurveyType } from 'utils/surveyBuilder/enums';
import { createDataSet, fetchDataSets } from 'state/actions/dataSets';
import { clearCreateTagState, createTag, fetchTags } from 'state/actions/tags';
import classes from './CreateDataSet.module.scss';

const CreateDataSet = ({
  organizationName,
  surveyType,
  choices,
  surveyLanguage,
  onLoadFromDataSetHandler,
  dataSetSelected,
  deploymentId,
}) => {
  const {
    tagOptions,
    successCreatingDataSet,
    creatingDataSet,
    dataSetsOptions,
    createdDataSetId,
    successCreatingTag,
    creatingTag,
    fetchingTags,
    successFetchingTags,
  } = useSelector(
    (state) => ({
      tagOptions: state.tags.data
        .filter((tag) => {
          if (tag.type === TagType.DATA_SET) {
            if (organizationName) {
              return tag.organizationName === organizationName;
            }
            return tag.organizationName === 'global';
          }
          return false;
        })
        .map((tag) => ({
          label: tag.displayName,
          value: tag,
        })),
      dataSetsOptions: state.dataSets.data.map((dataSet) => ({
        label: dataSet.tagDisplayName,
        value: dataSet,
      })),
      successCreatingDataSet: state.dataSets.success,
      creatingDataSet: state.dataSets.loading,
      createdDataSetId: state.dataSets.createdDataSetId,
      successCreatingTag: state.tags.successCreatingTag,
      creatingTag: state.tags.creatingTag,
      fetchingTags: state.tags.fetchingTags,
      successFetchingTags: state.tags.successFetchingTags,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const [{ firstClick, selectedTag, dataSetId }, setState] = useState({
    firstClick: true,
    selectedTag: null,
    dataSetId: null,
  });

  // It will fetch the dataSets again when a new dataset is created
  useEffect(() => {
    if (successCreatingDataSet) {
      const organizations = ['global'];

      if (surveyType === SurveyType.ORGANIZATION && organizationName) {
        organizations.push(organizationName);
      }

      dispatch(
        fetchDataSets({
          filterByActive: true,
          filterByTagType: TagType.DATA_SET,
          filterByOrganizations: organizations,
        })
      );
    }
  }, [dispatch, surveyType, organizationName, successCreatingDataSet]);

  // It will fetch the tags again when a new dataset is created
  useEffect(() => {
    if (successCreatingTag) {
      const organizations = ['global'];

      if (surveyType === SurveyType.ORGANIZATION && organizationName) {
        organizations.push(organizationName);
      }
      dispatch(
        fetchTags({
          filterByActive: true,
          filterByOrganizations: organizations,
        })
      );
    }
  }, [dispatch, surveyType, organizationName, successCreatingTag]);

  // It will keep the id of the recently dataSet created
  // That's because when we fetch again the dataSets, it erases all the data in the store
  useEffect(() => {
    if (createdDataSetId && !dataSetId) {
      setState((prevState) => ({ ...prevState, dataSetId: createdDataSetId }));
    }
  }, [createdDataSetId, dataSetId]);

  // It will populate the Choices in the QuestionBuilder with the information
  // of the new dataSet that we just created
  useEffect(() => {
    if (dataSetId && dataSetsOptions?.length > 0) {
      const createdDataSetOption = dataSetsOptions.filter(
        (dataSet) => dataSet.value.id === dataSetId
      );

      if (createdDataSetOption.length > 0) {
        onLoadFromDataSetHandler(createdDataSetOption[0]);

        setState((prevState) => ({ ...prevState, dataSetId: null }));
      }
    }
  }, [dataSetsOptions, onLoadFromDataSetHandler, dataSetId]);

  const onChangeSelectedTagHandler = useCallback(
    (tag) =>
      setState((prevState) => ({
        ...prevState,
        selectedTag: tag,
      })),
    []
  );

  // It will populate the tag select in the QuestionBuilder with the information
  // of the new tag that we just created
  useEffect(() => {
    if (successCreatingTag && successFetchingTags && selectedTag) {
      const { label: tagName, value } = selectedTag;
      const createdTagOption = tagOptions.find(
        ({ label }) => label === tagName
      );
      if (!value && createdTagOption) {
        onChangeSelectedTagHandler(createdTagOption);
        dispatch(clearCreateTagState());
      }
    }
  }, [
    onChangeSelectedTagHandler,
    successCreatingTag,
    successFetchingTags,
    selectedTag,
    tagOptions,
    dispatch,
  ]);

  // It will reset the state to show the "Create DataSet" button again.
  useEffect(() => {
    if (successCreatingDataSet) {
      setState((prevState) => ({
        ...prevState,
        firstClick: true,
        selectedTag: null,
      }));
    }
  }, [successCreatingDataSet]);

  const getTransformedChoices = useCallback(() => {
    let transformedChoices = {};

    choices.forEach((choice) => {
      const { option, paramOne, paramTwo } = choice;

      Object.entries(option).forEach(([language, choiceText]) => {
        const choiceValues = { option: choiceText, paramOne, paramTwo };

        transformedChoices = {
          ...transformedChoices,
          [language]: transformedChoices[language]
            ? [...transformedChoices[language], choiceValues]
            : [choiceValues],
        };
      });
    });

    return transformedChoices;
  }, [choices]);

  const canCreateDataSet = useMemo(
    () =>
      selectedTag &&
      choices.every(
        ({ option, duplicated }) => option[surveyLanguage].trim() && !duplicated
      ),
    [selectedTag, JSON.stringify(choices), surveyLanguage]
  );

  const onFirstClickHandler = useCallback(
    () => setState((prevState) => ({ ...prevState, firstClick: false })),
    []
  );

  const onAddDataSetHandler = useCallback(() => {
    if (canCreateDataSet) {
      const {
        id: tagId,
        displayName: tagDisplayName,
        organizationName: tagOrganizationName,
        type: tagType,
        active,
      } = selectedTag.value;

      const dataSetTag = {
        tagId,
        tagDisplayName,
        tagOrganizationName,
        tagType,
        startWave: null,
        active,
      };

      const dataSetChoices = getTransformedChoices();

      dispatch(createDataSet(dataSetTag, dataSetChoices));
    }
  }, [canCreateDataSet, selectedTag, getTransformedChoices, dispatch]);

  const onClickCancelHandler = useCallback(
    () => setState((prevState) => ({ ...prevState, firstClick: true })),
    []
  );

  const enableDataSetButton = useMemo(
    () =>
      (surveyType === SurveyType.ORGANIZATION && organizationName) ||
      surveyType === SurveyType.TEMPLATE,
    [surveyType, organizationName]
  );

  const onCreateTagHandler = useCallback(
    (tagName) => {
      const global = surveyType === SurveyType.TEMPLATE;
      dispatch(
        createTag({
          name: tagName,
          type: TagType.DATA_SET,
          active: true,
          global,
          organization: organizationName,
        })
      );
      setState((prevState) => ({
        ...prevState,
        selectedTag: { label: tagName, value: null },
      }));
    },
    [dispatch, organizationName, surveyType]
  );

  const getTagSelectPlaceholder = useCallback(() => {
    if (tagOptions.length === 0) {
      if (surveyType === SurveyType.TEMPLATE) {
        return 'There are no global tags created.';
      }

      if (surveyType === SurveyType.ORGANIZATION && organizationName) {
        return 'Organization has no tags';
      }
    }
    return 'Select...';
  }, [surveyType, tagOptions.length, organizationName]);

  const getCreateDatasetTooltip = useCallback(() => {
    let tooltip = '';

    if (!enableDataSetButton) {
      tooltip = 'Select an organization to create a DataSet';
    }

    if (dataSetSelected) {
      tooltip = 'You already have a dataset chosen';
    }

    return tooltip;
  }, [enableDataSetButton, dataSetSelected]);

  if (firstClick) {
    return (
      <div className="field">
        <div className="control">
          <span data-tip={getCreateDatasetTooltip()}>
            <button
              id="create-dataset-first-button"
              className="button"
              disabled={!enableDataSetButton || dataSetSelected || deploymentId}
              onClick={onFirstClickHandler}
            >
              <span className="icon">
                <i className="mdi mdi-plus-circle-outline" />
              </span>
              <span>Create DataSet</span>
            </button>
          </span>
        </div>
        <ReactTooltip />
      </div>
    );
  }

  return (
    <form>
      <div className="field">
        <label className="label">Select a Tag</label>
        <div className={classNames('control', classes['select-width'])}>
          <Select
            placeholder={getTagSelectPlaceholder()}
            id="available-tags-dropdown"
            maxMenuHeight={100}
            options={tagOptions}
            onChange={onChangeSelectedTagHandler}
            value={selectedTag}
            isDisabled={fetchingTags || creatingTag}
            isLoading={fetchingTags || creatingTag}
            onCreateOption={onCreateTagHandler}
          />
        </div>
        <p className="help">
          The tag this data set should be linked to. Type the name of a tag that
          doesn&apos;t exist to create it.
        </p>
      </div>
      <div className="field is-grouped">
        <div className="control">
          <button
            id="create-dataset-second-button"
            className="button is-primary"
            type="button"
            disabled={!canCreateDataSet || creatingDataSet}
            onClick={onAddDataSetHandler}
          >
            <span className={classes['create-dataset-button']}>
              {creatingDataSet ? <Spinner color="white" size={20} /> : 'Create'}
            </span>
          </button>
        </div>
        <div className="control">
          <button
            id="create-dataset-cancel-button"
            className="button is-light"
            type="button"
            onClick={onClickCancelHandler}
          >
            Cancel
          </button>
        </div>
      </div>
    </form>
  );
};

CreateDataSet.propTypes = {
  organizationName: PropTypes.string,
  surveyType: PropTypes.string.isRequired,
  choices: PropTypes.arrayOf(Object).isRequired,
  surveyLanguage: PropTypes.string.isRequired,
  onLoadFromDataSetHandler: PropTypes.func.isRequired,
  dataSetSelected: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.instanceOf(Object).isRequired,
  }),
};

CreateDataSet.defaultProps = {
  organizationName: '',
  dataSetSelected: null,
};

export default CreateDataSet;
