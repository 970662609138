import { createReducer } from 'redux-act';

import {
  ANALYTICS_TRIGGER_PROCESSING_INIT,
  ANALYTICS_TRIGGER_PROCESSING_SUCCESS,
  ANALYTICS_TRIGGER_PROCESSING_FAIL,
  ANALYTICS_CLEAR_STATE,
} from 'state/actions/analytics';

export const initialState = {
  loading: false,
  success: false,
  error: null,
};

export const analyticsReducer = createReducer(
  {
    // eslint-disable-next-line no-unused-vars
    [ANALYTICS_TRIGGER_PROCESSING_INIT]: (_, { deploymentId }) => ({
      ...initialState,
      loading: [deploymentId],
      success: false,
    }),
    [ANALYTICS_TRIGGER_PROCESSING_SUCCESS]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
    }),
    [ANALYTICS_TRIGGER_PROCESSING_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
      success: false,
    }),
    [ANALYTICS_CLEAR_STATE]: () => initialState,
  },
  initialState
);
