import { createSelector } from 'reselect';

export const selectOrganizationsState = (state) => state.organizations;

export const selectNavbarOrganizationParsedState = createSelector(
  selectOrganizationsState,
  ({ loading, error, data }) => {
    const organizations = data.map(
      ({ displayName, id, currentWave, waves, name, displaySiteName }) => {
        const wavesOpt = waves.map((wave) => ({ label: wave, value: wave }));

        return {
          label: displayName,
          value: {
            displayName,
            waves: wavesOpt,
            id,
            currentWave,
            name,
            displaySiteName,
          },
        };
      }
    );

    return { loading, error, organizations };
  }
);
