import { toastr } from 'react-redux-toastr';
import { fetchSectionSaveService } from 'services/sectionSave';
import {
  SAVED_SECTIONS_FETCH_SINGLE_SECTION_INIT,
  SAVED_SECTIONS_FETCH_SINGLE_SECTION_FAIL,
  SAVED_SECTIONS_FETCH_SINGLE_SECTION_SUCCESS,
} from 'state/actionCreators/savedSections';

export const fetchSectionSave = ({ sectionIdentifier }) => {
  return async (dispatch) => {
    dispatch(SAVED_SECTIONS_FETCH_SINGLE_SECTION_INIT());

    try {
      const sectionSave = await fetchSectionSaveService({ sectionIdentifier });

      toastr.success('', 'Section successfully Loaded.');
      
      return dispatch(SAVED_SECTIONS_FETCH_SINGLE_SECTION_SUCCESS({ sectionSave }));
    } catch (error) {
      toastr.error('Error Loading the Section', error);
      return dispatch(SAVED_SECTIONS_FETCH_SINGLE_SECTION_FAIL({ error }));
    }

  };
};
