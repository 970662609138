import React from 'react';
import classNames from 'classnames';
import Spinner from 'react-spinners/ClipLoader';

import NewModal from 'components/NewModal';
import { colors } from 'styles';

import classes from './RemoveSectionSaveConfirmationModal.module.scss';

const CustomModalFooter = ({ toggle, onSubmit, loading }) => (
  <footer className={classNames('modal-card-foot', classes.footer)}>
    <button
      id="secondary-cancel-button"
      type="button"
      className="button"
      aria-label="close"
      onClick={toggle}
    >
      Cancel
    </button>
    <button
      type="button"
      className="button is-danger"
      onClick={onSubmit}
      disabled={loading}
    >
      {loading ? <Spinner size="18" color={colors.white} /> : 'Remove'}
    </button>
  </footer>
);

const RemoveSectionSaveConfirmationModal = ({
  isOpen,
  toggle,
  onSubmit,
  loading,
}) => {
  return (
    <NewModal
      title="Remove Section"
      isOpen={isOpen}
      toggle={toggle}
      CustomFooter={CustomModalFooter}
      footerProps={{
        onSubmit,
        toggle,
        loading,
      }}
    >
      <p>Are you sure you want to remove this section?</p>
    </NewModal>
  );
};

export default RemoveSectionSaveConfirmationModal;
