import * as yup from 'yup';

export const questionSliderFields = [
  {
    name: 'min',
    label: 'Min',
    type: 'number',
  },
  {
    name: 'max',
    label: 'Max',
    type: 'number',
  },
  {
    name: 'step',
    label: 'Step',
    type: 'number',
  },
  {
    name: 'defaultValue',
    label: 'Default Value',
    type: 'number',
  },
];

const inputNumberSchemaAssignment = (value, initialSchema) => {
  const stringSchema = yup.string();

  if (value === '') {
    return stringSchema;
  }

  return initialSchema;
};

export const sliderQuestionSchema = ({ isQuestionRequired }) =>
  yup.object().shape({
    min: yup.lazy((value) => {
      const numberSchema = yup.number().test({
        name: 'is-min-less-than-max',
        test: (testValue, context) => {
          const { max } = context.parent;

          const isValidNumber = Number.isInteger(testValue);

          if (!isValidNumber || value >= max) {
            return false;
          }

          return true;
        },
        message: 'Min must be less than max',
      });

      return inputNumberSchemaAssignment(value, numberSchema).required();
    }),
    max: yup.lazy((value) => {
      const numberSchema = yup
      .number()
      .test({
        name: 'is-max-greater-than-min',
        test: (testValue, { parent }) => {
          const { min } = parent;

          const isValidNumber = Number.isInteger(testValue);

          if (!isValidNumber || testValue <= min) {
            return false;
          }

          return true;
        },
        message: 'Max must be greater than min',
      });

      return inputNumberSchemaAssignment(value, numberSchema).required();
    }),
    step:  yup.lazy((value) => {
      const numberSchema = yup
      .number()
      .test({
        name: 'is-step-less-than-max-min-difference',
        test: (testValue, { parent }) => {
          const { min, max } = parent;
          
          const isValidNumber = Number.isInteger(testValue);
          
          const maxMinDifference = max - min;
          
          if (!isValidNumber || testValue > maxMinDifference) {
            return false;
          }
          
          return true;
        },
        message: 'Step must be less than max - min difference',
      });

      return inputNumberSchemaAssignment(value, numberSchema).required();
    }),
    defaultValue: yup.lazy((value) => {
      const numberSchema = yup.number().test({
        name: 'is-default-value-required',
        test: (testValue, { parent }) => {
          const { min, max } = parent;

          const isValidNumber = Number.isInteger(testValue);

          const isBetweenMinAndMax = testValue >= min && testValue <= max;

          if (!isValidNumber || !isBetweenMinAndMax) {
            return false;
          }

          return true;
        },
        message: 'Default value must be between Min and Max',
      });

      let finalSchema = inputNumberSchemaAssignment(value, numberSchema);

      if (isQuestionRequired) {
        finalSchema = finalSchema.required('Default Value is required');
      }

      return finalSchema;
    }),
  });
