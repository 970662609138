import React from 'react';
import classNames from 'classnames';

import { useFormatMessage } from 'hooks';
import presageLogo from 'assets/presage-logo-white.png';

import classes from './Footer.module.scss';

const Footer = () => {
  const text = useFormatMessage('LiveSurvey.advertisingText');

  const currentYear = new Date().getFullYear();

  return (
    <footer className={classes.footer}>
      <div className={classNames('container', classes['privacy-policy'])}>
        {text}
        <span className={classes.copyright}>© {currentYear} Presage</span>
      </div>
      <span className={classNames('is-size-5', classes['powered-by'])}>
        Powered by
      </span>
      <a
        href="https://presagegroup.com/"
        rel="noopener noreferrer"
        target="_blank"
      >
        <img alt="Presage Group logo" src={presageLogo} />
      </a>
    </footer>
  );
};

export default Footer;
