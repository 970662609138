/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CreatableSelect from 'react-select/creatable';
import { Link } from 'react-router-dom';

import paths from 'pages/Router/paths';
import { numberHandler } from 'utils';
import { fetchIndustries } from 'utils/fetching';
import {
  clearCreateIndustryState,
  createIndustry,
} from 'state/actions/industries';
import './OrganizationForm.scss';
import classes from './OrganizationForm.module.scss';

const OrganizationForm = ({
  organization,
  validName,
  validSiteName,
  onChangeHandler,
  onSelectChangeHandler,
  canSubmit,
}) => {
  const {
    successCreatingIndustry,
    createdIndustry,
    creatingIndustry,
    loading,
  } = useSelector(
    (state) => ({
      successCreatingIndustry: state.industries.successCreatingIndustry,
      createdIndustry: state.industries.createdIndustry,
      creatingIndustry: state.industries.creatingIndustry,
      loading: state.organizations.loading,
    }),
    shallowEqual
  );

  const [industries, setIndustries] = React.useState({
    data: [],
    loading: false,
    fetched: false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    fetchIndustries(industries, setIndustries);
  }, [industries, organization]);

  useEffect(() => {
    if (successCreatingIndustry && createdIndustry) {
      const { displayName } = createdIndustry;
      const newValue = {
        label: displayName,
        value: displayName,
      };
      onSelectChangeHandler('industry', newValue);
      dispatch(clearCreateIndustryState());
      setIndustries((prevState) => ({ ...prevState, fetched: false }));
    }
  }, [
    successCreatingIndustry,
    createdIndustry,
    dispatch,
    setIndustries,
    onSelectChangeHandler,
  ]);

  const onCreateIndustryHandler = (industryName) => {
    dispatch(
      createIndustry({
        displayName: industryName,
      })
    );
  };

  let siteNameInput = {
    modifier: null,
    message: { modifier: null, content: null },
  };

  let nameInput = {
    modifier: null,
    message: { modifier: null, content: null },
  };

  if (!validSiteName.correctCharacters) {
    siteNameInput = {
      modifier: 'is-danger',
      message: {
        modifier: 'is-danger',
        content: 'Invalid Site Name',
      },
    };
  }

  if (validSiteName.isNew) {
    siteNameInput = {
      modifier: 'is-danger',
      message: {
        modifier: 'is-danger',
        content: 'You cannot use "new" as organization site name',
      },
    };
  }

  if (!validName) {
    nameInput = {
      modifier: 'is-danger',
      message: {
        modifier: 'is-danger',
        content: "You cannot use 'global' as organization name",
      },
    };
  }

  return (
    <div className="tile is-parent">
      <div className="card tile is-child">
        <header className="card-header">
          <p className="card-header-title">
            <span className="icon">
              <i className="mdi mdi-office-building" />
            </span>
            Organization Information
          </p>
        </header>
        <div className="card-content">
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Name</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <input
                    id="name"
                    className={classNames('input', nameInput.modifier)}
                    type="text"
                    required
                    name="displayName"
                    value={organization.displayName}
                    onChange={onChangeHandler}
                  />
                </div>
                {nameInput.message.content && (
                  <p
                    className={classNames(
                      'help is-',
                      nameInput.message.modifier
                    )}
                  >
                    {nameInput.message.content}
                  </p>
                )}
                <small>
                  Common name for the organization. Must be unique in the
                  system.
                </small>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Site name</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <input
                    className={classNames('input', siteNameInput.modifier)}
                    type="text"
                    required
                    name="displaySiteName"
                    value={organization.displaySiteName}
                    onChange={onChangeHandler}
                  />
                </div>
                {siteNameInput.message.content && (
                  <p
                    className={classNames(
                      'help is-',
                      siteNameInput.message.modifier
                    )}
                  >
                    {siteNameInput.message.content}
                  </p>
                )}
                <small>
                  This is used to identify the portal for the organization. The
                  url will look like this: http://presageapp.com/sitename/
                </small>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Industry</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <CreatableSelect
                    isDisabled={industries.loading || creatingIndustry}
                    isLoading={industries.loading || creatingIndustry}
                    options={industries.data}
                    isClearable
                    onChange={(select) =>
                      onSelectChangeHandler('industry', select)
                    }
                    value={organization.industry}
                    onCreateOption={onCreateIndustryHandler}
                  />
                  <span className="check is-primary" />
                </div>
                <small>
                  Specify the industry that is the best fit for this
                  organization. This is used for informational purposes only.
                </small>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label">
              <label className="label">Processing Threshold</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    inputMode="numeric"
                    pattern="^-?[0-9]\d*$"
                    min="0"
                    name="processingThreshold"
                    required
                    value={organization.processingThreshold}
                    onChange={(e) => numberHandler(e, onChangeHandler)}
                  />
                </div>
                <small>
                  The number of new responses required before a wave will be
                  processed by the analytics process.
                </small>
              </div>
            </div>
          </div>

          <div className="field has-check is-horizontal level-item">
            <div className={classNames('field-label', classes.active)}>
              <label className="label">Active</label>
            </div>
            <div
              className={classNames('field-body', classes['active-checkbox'])}
            >
              <div className="field">
                <div className="field">
                  <div className="control">
                    <label className="b-checkbox checkbox">
                      <input
                        type="checkbox"
                        name="active"
                        onChange={onChangeHandler}
                        checked={organization.active}
                      />
                      <span className="check is-primary" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <footer>
            <div
              className={classNames(
                'field is-horizontal',
                classes['buttons-container']
              )}
            >
              <div className="field is-grouped">
                <div className="control">
                  <button
                    type="submit"
                    className={classNames('button is-primary', {
                      'is-loading': loading,
                    })}
                    disabled={!canSubmit}
                  >
                    <span>Submit</span>
                  </button>
                </div>
                <Link to={paths.ORGANIZATIONS} className="button">
                  Go Back
                </Link>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};

OrganizationForm.propTypes = {
  organization: PropTypes.shape({
    id: PropTypes.string,
    active: PropTypes.bool.isRequired,
    displayName: PropTypes.string.isRequired,
    displaySiteName: PropTypes.string.isRequired,
    processingThreshold: PropTypes.oneOfType([
      PropTypes.number.isRequired,
      PropTypes.string.isRequired,
    ]),
  }).isRequired,
  validName: PropTypes.bool.isRequired,
  validSiteName: PropTypes.shape({
    correctCharacters: PropTypes.bool.isRequired,
    isNew: PropTypes.bool.isRequired,
  }).isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  onSelectChangeHandler: PropTypes.func.isRequired,
  canSubmit: PropTypes.bool.isRequired,
};

export default OrganizationForm;
