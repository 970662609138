/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState, useCallback } from 'react';
import Select from 'react-select';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from 'react-spinners/ClipLoader';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

import { fetchDataSets } from 'state/actions/dataSets';
import {
  fetchQuestionChoices,
  clearQuestionChoiceState,
} from 'state/actions/questionChoices';
import { selectDataSetsOptionsState } from 'state/selectors/dataSets';
import { SurveyType } from 'utils/surveyBuilder/enums';
import { ReactSelectActions, TagType } from 'utils/enums';
import { isString } from 'utils';
import EmptyMessage from 'components/EmptyMessage';

import classes from './Choices.module.scss';
import Choice from './Choice';

const Choices = ({
  surveyType,
  organizationName,
  choices,
  onChangeDataSet,
  onChangeChoices,
  onAddChoices,
  selectedDataSet,
  selectedLanguage,
  onChangeChoiceVisibility,
  onChangeNotApplicable,
  onCheckChoicesActions,
  deploymentId,
}) => {
  const dispatch = useDispatch();

  const {
    dataSetsOptions,
    fetchingDataSetsOptions,
    questionChoices,
    fetchingQuestionChoices,
  } = useSelector(selectDataSetsOptionsState, shallowEqual);

  const [dataSetsSelect, setDataSetsSelect] = useState(
    Array.isArray(selectedDataSet) && selectedDataSet?.length
      ? selectedDataSet.map((dataset) => ({
          label: dataset.name,
          value: dataset,
        }))
      : []
  );

  useEffect(() => {
    const organizations = ['global'];

    if (surveyType === SurveyType.ORGANIZATION && organizationName) {
      organizations.push(organizationName);
    }

    dispatch(
      fetchDataSets({
        filterByActive: true,
        filterByTagType: TagType.DATA_SET,
        filterByOrganizations: organizations,
      })
    );
  }, [dispatch, surveyType, organizationName]);

  useEffect(() => {
    if (
      dataSetsSelect.length &&
      !fetchingQuestionChoices &&
      questionChoices.length > 0
    ) {
      const datasetSelectOption = dataSetsSelect.at(-1);
      const { id, title, name } = datasetSelectOption.value;

      const filteredChoices = questionChoices.filter(
        (choice) => choice.dataSetId === id
      );

      const newChoice = {
        dataSetId: id,
        title: title || null,
        name,
        questionChoices: filteredChoices,
      };

      onAddChoices(newChoice);
      dispatch(clearQuestionChoiceState());
    }
  }, [
    dataSetsSelect,
    fetchingQuestionChoices,
    questionChoices,
    onChangeChoices,
    dispatch,
  ]);

  const onLoadFromDataSetHandler = useCallback(
    (select, context) => {
      if (select) {
        if (
          context.action === ReactSelectActions.REMOVE ||
          context.action === ReactSelectActions.POP
        ) {
          const newChoices = select.map((datasetSelectOption) => {
            const { id, title, name } = datasetSelectOption.value;

            const newQuestionChoices = choices.find(
              (choice) => choice.dataSetId === id
            ).questionChoices;

            const newTitle =
              isString(title) || !title ? { en: title || '' } : title;

            return {
              dataSetId: id,
              title: newTitle,
              name,
              questionChoices: newQuestionChoices,
            };
          });

          const selectedDatasets = select.map((datasetSelectOption) => {
            const { id, title, name, organization } = datasetSelectOption.value;

            const newTitle =
              isString(title) || !title ? { en: title || '' } : title;

            return { id, title: newTitle, name, organization };
          });

          onChangeChoices(newChoices);
          onChangeDataSet(selectedDatasets);

          return setDataSetsSelect(select);
        }

        const { id: lastAddedDatasetId } = select.at(-1).value;

        const selectedDatasets = select.map((datasetSelectOption) => {
          const { id, title, name, organization } = datasetSelectOption.value;

          const newTitle =
            isString(title) || !title ? { en: title || '' } : title;

          return { id, title: newTitle, name, organization };
        });

        dispatch(fetchQuestionChoices({ filterByDataSet: lastAddedDatasetId }));

        onChangeDataSet(selectedDatasets);

        return setDataSetsSelect(select);
      }

      onChangeChoices([]);
      onChangeDataSet(null);
      return setDataSetsSelect([]);
    },
    [onChangeChoices, onChangeDataSet, choices, dispatch]
  );

  const selectFilter = (option, rawInput) => {
    const optionIsNotSelected = !dataSetsSelect.find(
      (selectedOption) => selectedOption.value.id === option.value.id
    );

    if (optionIsNotSelected) {
      return option.label.toLowerCase().includes(rawInput.toLowerCase());
    }

    return optionIsNotSelected;
  };

  return (
    <>
      <div className="field is-grouped is-grouped-multiline">
        <div className={classNames('control', classes.title)}>
          <h2 className="subtitle">Choices</h2>
        </div>
        <div
          className={classNames('control', classes['data-set-select'])}
          data-tip={
            onCheckChoicesActions()
              ? 'There are actions related to the choices of this data set.'
              : null
          }
        >
          <Select
            placeholder="Load from Data Set"
            isSearchable
            isClearable
            isMulti
            value={dataSetsSelect}
            options={dataSetsOptions}
            isLoading={fetchingDataSetsOptions}
            onChange={onLoadFromDataSetHandler}
            filterOption={selectFilter}
            maxMenuHeight={150}
            isDisabled={
              onCheckChoicesActions() || deploymentId || fetchingQuestionChoices
            }
          />
          <ReactTooltip />
        </div>
      </div>
      {!!choices.length &&
        choices.map((choicesProps, index) => {
          const {
            dataSetId,
            title,
            name,
            option,
            visible,
            notApplicable,
            questionChoices: choicesToRender,
          } = choicesProps;
          return (
            <div key={dataSetId}>
              {choicesToRender ? (
                <>
                  <p
                    className={classNames(classes['dataset-title'], {
                      [classes['first-dataset-title']]: index === 0,
                    })}
                  >
                    {title?.[selectedLanguage] || title?.en || 'Missing title'}
                    &nbsp;<span>({name})</span>
                  </p>
                  {choicesToRender.map(
                    (
                      {
                        option: renderOption,
                        visible: renderVisible,
                        notApplicable: renderNotApplicable,
                      },
                      renderIndex
                    ) => (
                      <Choice
                        key={`${dataSetId}.${index}.${renderIndex}`}
                        content={renderOption[selectedLanguage] || ''}
                        number={renderIndex + 1}
                        visible={renderVisible}
                        notApplicable={renderNotApplicable}
                        onChangeVisibility={onChangeChoiceVisibility}
                        onChangeNotApplicable={onChangeNotApplicable}
                        index={renderIndex}
                        parentIndex={index}
                      />
                    )
                  )}
                </>
              ) : (
                // needed to support old choices format
                <Choice
                  key={`${dataSetId}.${index}`}
                  content={option[selectedLanguage] || ''}
                  number={index + 1}
                  visible={visible}
                  notApplicable={notApplicable}
                  onChangeVisibility={onChangeChoiceVisibility}
                  onChangeNotApplicable={onChangeNotApplicable}
                  index={index}
                />
              )}
            </div>
          );
        })}
      <div className={classes.spinner}>
        {fetchingQuestionChoices && <Spinner />}
      </div>
      {!fetchingQuestionChoices && choices.length === 0 && (
        <EmptyMessage message="There are no choices" />
      )}
    </>
  );
};

Choices.propTypes = {
  surveyType: PropTypes.oneOf([SurveyType.ORGANIZATION, SurveyType.TEMPLATE])
    .isRequired,
  choices: PropTypes.arrayOf(
    // eslint-disable-next-line react/forbid-prop-types
    PropTypes.shape({ option: PropTypes.object.isRequired })
  ).isRequired,
  selectedLanguage: PropTypes.string.isRequired,
  onChangeDataSet: PropTypes.func.isRequired,
  onChangeChoices: PropTypes.func.isRequired,
  onChangeChoiceVisibility: PropTypes.func.isRequired,
  onChangeNotApplicable: PropTypes.func.isRequired,
  organizationName: PropTypes.string,
  onCheckChoicesActions: PropTypes.func,
};

export default Choices;
