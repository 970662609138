/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import classNames from 'classnames';
import Select from 'react-select';

import Table from 'components/Table';
import paths from 'pages/Router/paths';
import DatePickerStyled from 'components/DatePicker';
import { fetchOrganizations } from 'state/actions/organizations';
import { fetchTasks, tasksCleanUp } from 'state/actions/tasks';
import { useFormatDate } from 'hooks';
import { roles } from 'utils';
import classes from './Tasks.module.scss';

const { ADMIN } = roles;

const statusOptions = [
  { label: 'Success', value: 'Success' },
  { label: 'Failed', value: 'Failed' },
];

const Tasks = () => {
  const {
    role,
    loadingTasks,
    tasksList,
    organizationOptions,
    loadingOrganizations,
  } = useSelector((state) => {
    const tasks = state.tasks.data;
    const organizations = state.organizations.data
      .filter(({ id }) =>
        tasks.some(({ organizationId }) => organizationId === id)
      )
      .map((organization) => ({
        label: organization.displayName,
        value: organization,
      }));

    return {
      role: state.auth.userData.role,
      loadingTasks: state.tasks.loading,
      loadingOrganizations: state.organizations.loading,
      tasksList: tasks,
      organizationOptions: organizations,
    };
  }, shallowEqual);

  const dispatch = useDispatch();

  const [search, setSearch] = useState();

  const [tasks, setTasks] = useState([]);

  const [filters, setFilters] = useState({
    organization: null,
    status: null,
    createDate: null,
    finishDate: null,
  });

  useEffect(() => {
    if (role === ADMIN) {
      dispatch(fetchTasks());
      dispatch(fetchOrganizations());
    }

    return () => dispatch(tasksCleanUp());
  }, [dispatch, role]);

  useEffect(() => {
    let filteredTasks = tasksList;

    if (filters.organization) {
      filteredTasks = filteredTasks.filter(
        (task) => task.organizationId === filters.organization.value.id
      );
    }

    if (filters.status) {
      filteredTasks = filteredTasks.filter(
        (task) => task.status === filters.status.value
      );
    }

    if (filters.createDate) {
      filteredTasks = filteredTasks.filter(
        (task) =>
          task.createTime.setHours(0, 0, 0, 0) >=
          filters.createDate.setHours(0, 0, 0, 0)
      );
    }

    if (filters.finishDate) {
      filteredTasks = filteredTasks.filter(
        (task) =>
          task.finishTime.setHours(0, 0, 0, 0) <=
          filters.finishDate.setHours(0, 0, 0, 0)
      );
    }

    setTasks(filteredTasks);
  }, [filters, tasksList]);

  const onChangeOrganization = useCallback(
    (organization) =>
      setFilters((prevState) => ({ ...prevState, organization })),
    []
  );

  const onChangeStatus = useCallback(
    (status) => setFilters((prevState) => ({ ...prevState, status })),
    []
  );

  const redirect = role !== ADMIN && <Redirect to={paths.ROOT} />;

  const columns = useMemo(
    () => [
      {
        Header: 'Wave Id',
        accessor: 'waveId',
      },
      {
        Header: 'Organization Id',
        accessor: 'organizationId',
      },
      {
        Header: 'Created time',
        accessor: 'createTime',
        Cell: ({ row }) => (
          <small className="has-text-grey is-abbr-like">
            {useFormatDate(row.original.createTime, {
              weekday: 'short',
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
          </small>
        ),
      },
      {
        Header: 'Finished time',
        accessor: 'finishTime',
        Cell: ({ row }) => (
          <small className="has-text-grey is-abbr-like">
            {useFormatDate(row.original.finishTime, {
              weekday: 'short',
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
          </small>
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row }) => (
          <small className="has-text-grey is-abbr-like">
            {row.original.status === 'Success' && (
              <span className={classes['status-success']}>
                <i
                  className={classNames('mdi mdi-check-circle', classes.icon)}
                />
                Success
              </span>
            )}
            {row.original.status === 'Failed' && (
              <span className={classes['status-failed']}>
                <i
                  className={classNames('mdi mdi-close-circle', classes.icon)}
                />
                Failed
              </span>
            )}
          </small>
        ),
      },
    ],
    []
  );

  const data = search
    ? tasks.filter((el) => {
        const clonedElem = { ...el };
        delete clonedElem.id;
        delete clonedElem.createTime;
        delete clonedElem.finishTime;
        return Object.values(clonedElem).some((field) =>
          field.toLowerCase().includes(search.toLowerCase())
        );
      })
    : tasks;

  const validFinishDate = useMemo(
    () =>
      filters.createDate && filters.finishDate
        ? filters.createDate <= filters.finishDate
        : true,
    [filters]
  );

  let untilDate = {
    modifier: null,
    message: { modifier: null, content: null },
  };

  if (!validFinishDate) {
    untilDate = {
      modifier: 'is-danger',
      message: {
        modifier: 'is-danger',
        content: 'Finish date cannot be previous than created date',
      },
    };
  }

  return (
    <>
      {redirect}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h1 className="title">Tasks</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section is-main-section">
        <div className="field is-horizontal">
          <div className="field-label is-normal field-label-shrink">
            <label className="label">Filter by:</label>
          </div>
          <div className="field-body algolia-filter">
            <div
              className={classNames('field', 'is-horizontal', classes.width)}
            >
              <div className="field-label is-normal">
                <label className="label">Organization</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <div className="control">
                    <Select
                      value={filters.organization}
                      placeholder="Select an organization"
                      options={organizationOptions}
                      onChange={onChangeOrganization}
                      isLoading={loadingOrganizations}
                      isDisabled={loadingOrganizations}
                      isClearable
                      isSearchable
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className={classNames('field', 'is-horizontal', classes.width)}
            >
              <div className="field-label is-normal">
                <label className="label">Status</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <div className="control">
                    <Select
                      value={filters.status}
                      placeholder="Select a status"
                      options={statusOptions}
                      onChange={onChangeStatus}
                      isLoading={loadingTasks}
                      isDisabled={loadingTasks}
                      isSearchable={false}
                      isClearable
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-body algolia-filter">
            <div
              className={classNames('field', 'is-horizontal', classes.width)}
            >
              <div className="field-label is-normal">
                <label className="label">Created date</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <div className="control">
                    <DatePickerStyled
                      name="createDate"
                      date={filters.createDate}
                      setState={setFilters}
                      className={classes.date}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className={classNames('field', 'is-horizontal', classes.width)}
            >
              <div className="field-label is-normal">
                <label className="label">Finish date</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <div className="control">
                    <DatePickerStyled
                      name="finishDate"
                      date={filters.finishDate}
                      setState={setFilters}
                      className={classNames('input', untilDate.modifier)}
                    />
                  </div>
                  {untilDate.message.content && (
                    <p
                      className={classNames(
                        'help is-',
                        untilDate.message.modifier
                      )}
                    >
                      {untilDate.message.content}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card has-table has-mobile-sort-spaced">
          <header className="card-header">
            <p className="card-header-title table-header">
              <span>Search</span>
              <input
                type="text"
                className="input"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </p>
          </header>
          <div className="b-table">
            {loadingTasks ? (
              <ClipLoader />
            ) : (
              <Table columns={columns} data={data} />
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Tasks;
