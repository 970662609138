import { createReducer } from 'redux-act';
import {
  INDUSTRIES_CREATE_INDUSTRY_FAIL,
  INDUSTRIES_CREATE_INDUSTRY_INIT,
  INDUSTRIES_CREATE_INDUSTRY_SUCCESS,
  INDUSTRIES_CLEAR_CREATE_INDUSTRY_STATE,
  INDUSTRIES_MODIFY_INDUSTRY_INIT,
  INDUSTRIES_MODIFY_INDUSTRY_SUCCESS,
  INDUSTRIES_MODIFY_INDUSTRY_FAIL,
  INDUSTRIES_DELETE_INDUSTRY_INIT,
  INDUSTRIES_DELETE_INDUSTRY_SUCCESS,
  INDUSTRIES_DELETE_INDUSTRY_FAIL,
  INDUSTRIES_CLEAN_UP,
  INDUSTRIES_CLEAR_DATA,
} from 'state/actions/industries';

const initialState = {
  data: [],
  loading: false,
  error: null,
  deleted: false,
  successUpdatingIndustry: false,
  creatingIndustry: false,
  successCreatingIndustry: false,
  errorCreatingIndustry: null,
  createdIndustry: null,
};

export const industriesReducer = createReducer(
  {
    [INDUSTRIES_CREATE_INDUSTRY_INIT]: (state) => ({
      ...state,
      creatingIndustry: true,
    }),
    [INDUSTRIES_CREATE_INDUSTRY_SUCCESS]: (state, { industry }) => ({
      ...state,
      creatingIndustry: false,
      errorCreatingIndustry: null,
      successCreatingIndustry: true,
      createdIndustry: industry,
    }),
    [INDUSTRIES_CREATE_INDUSTRY_FAIL]: (state, { error }) => ({
      ...state,
      creatingIndustry: false,
      errorCreatingIndustry: error,
      successCreatingIndustry: false,
      createdIndustry: null,
    }),
    [INDUSTRIES_CLEAR_CREATE_INDUSTRY_STATE]: (state) => ({
      ...state,
      creatingIndustry: false,
      errorCreatingIndustry: null,
      successCreatingIndustry: false,
      createdIndustry: null,
    }),
    [INDUSTRIES_MODIFY_INDUSTRY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [INDUSTRIES_MODIFY_INDUSTRY_SUCCESS]: (state, { id, industry }) => {
      const { data } = state;
      return {
        ...state,
        data: data.map((elem) => {
          if (elem.id === id) {
            return industry;
          }
          return elem;
        }),
        loading: false,
        error: null,
        successUpdatingIndustry: true,
      };
    },
    [INDUSTRIES_MODIFY_INDUSTRY_FAIL]: (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
    [INDUSTRIES_DELETE_INDUSTRY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [INDUSTRIES_DELETE_INDUSTRY_SUCCESS]: (state, { id }) => {
      const { data } = state;
      return {
        ...state,
        data: data.filter((elem) => elem.id !== id),
        loading: false,
        error: null,
        deleted: true,
      };
    },
    [INDUSTRIES_DELETE_INDUSTRY_FAIL]: (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
    [INDUSTRIES_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      successUpdatingIndustry: false,
      deleted: false,
      successCreatingIndustry: false,
    }),
    [INDUSTRIES_CLEAR_DATA]: (state) => ({
      ...initialState,
      data: state.data,
    }),
  },
  initialState
);
